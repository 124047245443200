import { GestureEventListeners } from '@polymer/polymer/lib/mixins/gesture-event-listeners.js';
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import * as Gestures from '@polymer/polymer/lib/utils/gestures.js';

// var e = window.nds;
const d = CsMixin,
  c = CsDeviceMixin;

/**
 * `cs-timeline` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsTimeline extends GestureEventListeners(d(c(PolymerElement))) {
  static get properties() {
    return {
      theme: {
        type: String,
        value: 'dark',
      },
      nextCardGradientColor: {
        type: String,
        value: '',
      },
      fullListingCard: {
        type: Object,
        value: null,
      },
      headings: {
        type: Object,
        value: {
          past: 'PAST EVENTS',
          next: 'NEXT EVENT',
          upcoming: 'UPCOMING EVENTS',
        },
      },
      notSwipeable: {
        type: Boolean,
        reflectToAttribute: !0,
        readOnly: !0,
        value: !1,
        computed: '_computeNotSwipeable(_containerWidth, _contentWidth)',
      },
      _totalCards: {
        type: Number,
        value: 0,
      },
      _pastCardsWidth: {
        type: Number,
        value: 0,
      },
      _nextCardsWidth: {
        type: Number,
        value: 0,
      },
      _upcomingCardsWidth: {
        type: Number,
        value: 0,
      },
      _contentWidth: {
        type: Number,
        computed:
          '_computeContentWidth(_pastCardsWidth, _nextCardsWidth, _upcomingCardsWidth)',
      },
      _containerWidth: Number,
      _cardWidth: {
        type: Number,
        computed: '_computeCardWidth(_contentWidth, _totalCards)',
      },
      _viewportWidth: Number,
      _cardIndex: {
        type: Number,
        value: 0,
      },
      _centreCardIndex: Number,
      _timelineCenterPositionX: Number,
      _cardScrollNum: {
        type: Number,
        value: 1,
      },
      _originalCards: Array,
      _debounceDelay: {
        type: Number,
        value: 20,
      },
      _forwardDisabled: {
        type: Boolean,
        computed: '_computeForwardDisabled(_centreCardIndex)',
      },
      _backDisabled: {
        type: Boolean,
        computed: '_computeBackDisabled(_centreCardIndex)',
      },
      _visualExtraWidth: Number,
      _showSpecialHeading: {
        type: Boolean,
        value: !1,
      },
      _canScrollHorizontal: {
        type: Boolean,
        value: !0,
      },
      _initCentreCardIndex: {
        type: Number,
        value: 0,
      },
      _maxMultipleCardIndex: {
        type: Number,
        computed:
          '_computeMaxMultipleCardIndex(_initCentreCardIndex, _cardScrollNum, _totalCards)',
      },
      _minMultipleCardIndex: {
        type: Number,
        computed:
          '_computeMinMultipleCardIndex(_initCentreCardIndex, _cardScrollNum)',
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-common-spacing"></style>
      <style>
        :host {
          --next-card-gradient-color: rgb(249, 59, 120);
          --next-card-gradient-color-from: rgba(249, 59, 120, 0.3);
          --next-card-gradient-color-to: rgba(249, 59, 120, 0);
          --card-gap: 8px;
          --past-cards-group-width: auto;
          --next-cards-group-width: auto;
          --upcoming-cards-group-width: auto;
          --all-cards-width: auto;
          --container-width: auto;
          --past-cards-label-width: auto;
          --next-cards-label-width: auto;
          --upcoming-cards-label-width: auto;
          --translate-x: 0;
          --translate-progress-x: 0;
          --translate-label-x: 0;
          --transition-duration: 500ms;
        }
        @media screen and (min-width: 321px) {
          :host {
            --card-gap: 10px;
          }
        }
        .cspl-timeline {
          display: block;
          overflow: hidden;
          width: 100%;
        }
        .cspl-timeline__container,
        .cspl-timeline__headings-container,
        .cspl-timeline__next-event-group,
        .cspl-timeline__past-event-group,
        .cspl-timeline__upcoming-event-group {
          display: flex;
          flex-flow: row nowrap;
          position: relative;
        }
        .cspl-timeline__container {
          align-items: center;
          transform: translateX(var(--translate-x));
          transition: transform var(--transition-duration) ease-in-out;
          width: var(--all-cards-width);
        }
        .cspl-timeline__container[no-animation] {
          transition: none;
        }
        .cspl-timeline__next-event-group {
          background: linear-gradient(
            180deg,
            var(--next-card-gradient-color-from),
            var(--next-card-gradient-color-to)
          );
          border-top: 5px solid var(--next-card-gradient-color);
          padding: 10px 8px 0 8px;
        }
        @media screen and (min-width: 1024px) {
          .cspl-timeline__next-event-group {
            padding: 16px 18px 0 18px;
          }
          .cspl-timeline__past-event-group,
          .cspl-timeline__upcoming-event-group {
            padding-top: 20px;
          }
        }
        .cspl-timeline__past-event-group cs-event-card {
          margin-right: var(--card-gap);
        }
        .cspl-timeline__next-event-group cs-event-card,
        .cspl-timeline__upcoming-event-group cs-event-card {
          margin-left: var(--card-gap);
        }
        .cspl-timeline__next-event-group cs-event-card:first-child {
          margin-left: 0;
        }
        :host([rtl]) .cspl-timeline__past-event-group cs-event-card {
          margin-left: var(--card-gap);
          margin-right: 0;
        }
        :host([rtl]) .cspl-timeline__next-event-group cs-event-card,
        :host([rtl]) .cspl-timeline__upcoming-event-group cs-event-card {
          margin-left: 0;
          margin-right: var(--card-gap);
        }
        :host([rtl])
          .cspl-timeline__next-event-group
          cs-event-card:first-child {
          margin-left: 0;
          margin-right: 0;
        }
        .cspl-timeline__headings-container {
          padding: 24px 0 7px;
          transform: translateX(var(--translate-label-x));
          width: calc(
            var(--past-cards-label-width) + var(--next-cards-label-width) +
              var(--upcoming-cards-label-width)
          );
        }
        .cspl-timeline__headings-container-transition {
          transition: transform var(--transition-duration) ease-in-out;
        }
        @media screen and (min-width: 321px) {
          .cspl-timeline__headings-container {
            padding-top: 28px;
          }
        }
        @media screen and (min-width: 768px) {
          .cspl-timeline__headings-container {
            padding-top: 32px;
          }
        }
        @media screen and (min-width: 1024px) {
          .cspl-timeline__headings-container {
            padding: 36px 0 11px;
          }
        }
        .cspl-timeline__headings-line {
          border-bottom: 2px solid #d6d6d6;
          height: 0;
          margin-bottom: -2px;
        }
        :host([theme='light']) .cspl-timeline__headings-line {
          border-bottom-color: #858585;
        }
        .cspl-timeline__card-label {
          color: var(--cs-dark-color);
          font-family: var(--cs-theme-secondary-font-family);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 112%;
          text-align: center;
          text-transform: var(--cs-text-case, uppercase);
          transition: width var(--transition-duration) ease-in-out;
          white-space: nowrap;
        }
        .cspl-timeline__card-label.past {
          width: var(--past-cards-label-width);
        }
        .cspl-timeline__card-label.next {
          width: var(--next-cards-label-width);
        }
        .cspl-timeline__card-label.upcoming {
          width: var(--upcoming-cards-label-width);
        }
        @media screen and (min-width: 1024px) {
          .cspl-timeline__card-label {
            font-size: 22px;
          }
        }
        :host([theme='light']) .cspl-timeline__card-label {
          color: var(--cs-light-color);
        }
        .cspl-timeline__controls {
          align-items: center;
          color: var(--cs-light-color);
          display: flex;
          height: 40px;
          justify-content: center;
          margin: 16px 0 24px 0;
          pointer-events: auto;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        }
        @media screen and (min-width: 321px) {
          .cspl-timeline__controls {
            margin-bottom: 28px;
          }
        }
        @media screen and (min-width: 768px) {
          .cspl-timeline__controls {
            margin-bottom: 32px;
          }
        }
        @media screen and (min-width: 1024px) {
          .cspl-timeline__controls {
            margin-bottom: 36px;
          }
        }
        :host([not-swipeable]) .cspl-timeline__controls {
          display: none;
        }
        .cspl-timeline__control iron-icon {
          color: var(--cs-dark-color);
          cursor: pointer;
          height: 40px;
          width: 40px;
        }
        .cspl-timeline__control-back {
          left: calc(50% - 130px);
          position: absolute;
        }
        .cspl-timeline__control-back iron-icon {
          transform: rotate(180deg);
        }
        .cspl-timeline__control-forward {
          position: absolute;
          right: calc(50% - 130px);
        }
        @media only screen and (min-width: 768px) {
          .cspl-timeline__control-back {
            left: calc(50% - 160px);
          }
          .cspl-timeline__control-forward {
            right: calc(50% - 160px);
          }
        }
        .cspl-timeline__control[is-disabled] iron-icon {
          color: #bfbfbf;
        }
        :host([theme='light']) .cspl-timeline__control iron-icon {
          color: var(--cs-light-color);
        }
        :host([theme='light']) .cspl-timeline__control[is-disabled] iron-icon {
          color: #4f4f4f;
        }
        @media only screen and (min-width: 1024px) {
          .cspl-timeline__control:not([is-disabled]):hover {
            transform: scale(1.125);
            transition: transform 180ms ease-out;
          }
        }
        .cspl-timeline__control-progress-bar {
          border: 2px solid #bfbfbf;
          border-radius: 45px;
          height: 16px;
          overflow: hidden;
          position: relative;
          width: 140px;
        }
        :host([theme='light']) .cspl-timeline__control-progress-bar {
          border: 2px solid #4f4f4f;
        }
        @media only screen and (min-width: 1024px) {
          .cspl-timeline__control-progress-bar:hover {
            background-color: #bfbfbf;
          }
          :host([theme='light']) .cspl-timeline__control-progress-bar:hover {
            background-color: #4f4f4f;
          }
        }
        .cspl-timeline__control-progress-indicator {
          background-color: #878787;
          border-radius: 45px;
          height: 100%;
          position: absolute;
          transform: translateX(var(--translate-progress-x));
          transition: transform var(--transition-duration) ease-in-out;
          width: 46px;
          will-change: transform;
        }
        .cspl-timeline__control-progress-indicator[no-animation] {
          transition: none;
        }
        @media only screen and (min-width: 768px) {
          .cspl-timeline__control-progress-bar {
            width: 200px;
          }
          .cspl-timeline__control-progress-indicator {
            width: 66px;
          }
        }
        @media only screen and (min-width: 1024px) {
          .cspl-timeline__control-progress-indicator:hover {
            background-color: #4f4f4f;
          }
          :host([theme='light'])
            .cspl-timeline__control-progress-indicator:hover {
            background-color: #bfbfbf;
          }
        }
      </style>
      <div class="cspl-timeline" id="timeline">
        <div
          class="cspl-timeline__headings-container"
          id="headingsContainer"
        ></div>
        <div class="cspl-timeline__headings-line"></div>
        <div
          class="cspl-timeline__container"
          id="container"
          on-track="_handleTrackEvent"
        ></div>
        <div class="cspl-timeline__controls">
          <div
            is-disabled$="[[ _backDisabled ]]"
            class="cspl-timeline__control cspl-timeline__control-back"
          >
            <iron-icon
              icon="cs-action-40:paddle"
              on-click="_onPaddleTap"
              direction="-1"
            ></iron-icon>
          </div>
          <div class="cspl-timeline__control-progress-bar">
            <div
              id="progressIndicator"
              class="cspl-timeline__control-progress-indicator"
            ></div>
          </div>
          <div
            is-disabled$="[[ _forwardDisabled ]]"
            class="cspl-timeline__control cspl-timeline__control-forward"
          >
            <iron-icon
              icon="cs-action-40:paddle"
              on-click="_onPaddleTap"
              direction="1"
            ></iron-icon>
          </div>
        </div>
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super(),
      (this._handleTouchmoveEvent = this._handleTouchmoveEvent.bind(this)),
      (this._initLayout = this._initLayout.bind(this));
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready(),
      this.nextCardGradientColor &&
        this._updateNextCardGradientColor(this.nextCardGradientColor);
  }

  _testSpecialHeading() {
    return !(
      this._viewportWidth < 1456 ||
      this.shadowRoot.querySelectorAll('cs-event-card[status="next"]').length >
        1
    );
  }
  _getVisualExtraWidth() {
    return parseInt((this._containerWidth - this._nextCardsWidth) / 2, 10);
  }
  _computeMinMultipleCardIndex(e, t) {
    return e % t;
  }
  _computeMaxMultipleCardIndex(e, t, i) {
    if (0 !== i) {
      var n = i - 1;
      return n - ((n - e) % t);
    }
    return i;
  }
  _computeForwardDisabled(e) {
    return this.rtl ? 0 === e : e === this._totalCards - 1;
  }
  _computeBackDisabled(e) {
    return this.rtl ? e === this._totalCards - 1 : 0 === e;
  }
  _getCardScrollNum() {
    var e = 1;
    return (
      this._viewportWidth >= 768 && (e = 2),
      this._viewportWidth >= 1456 && (e = 3),
      e
    );
  }
  _getTimelineCenterPositionX() {
    var e = this.$.timeline.getBoundingClientRect();
    return e.left + e.width / 2;
  }
  _setCardLabel(e) {
    var t = document.createElement('span'),
      i = 0;
    switch (e) {
      case 'next':
        i = this._containerWidth;
        break;
      case 'upcoming':
        i = 2 * this._containerWidth;
    }
    (t.className = 'cspl-timeline__card-label '.concat(e)),
      (t.innerText = this.headings[e]),
      t.setAttribute('position-x', i),
      this.$.headingsContainer.appendChild(t);
  }
  _adjustCardsOrder(e) {
    return e.length < 2 || e.find(e => e.getAttribute('empty-card'))
      ? e
      : e.sort((e, t) => {
          return (
            new Date(e.getAttribute('activationDate')).getTime() -
            new Date(t.getAttribute('activationDate')).getTime()
          );
        });
  }
  _createEmptyCard(e) {
    var t = document.createElement('cs-event-card');
    return (
      t.setAttribute('status', e),
      t.setAttribute('empty-card', !0),
      t.setAttribute('theme', this._getEventCardTheme()),
      t
    );
  }
  _createFullListCard(e) {
    var t = document.createElement('cs-event-card');
    return (
      t.setAttribute('status', 'upcoming'),
      t.setAttribute('full-listing-card', JSON.stringify(e)),
      t.setAttribute('theme', this._getEventCardTheme()),
      t
    );
  }
  _getEventCardTheme() {
    return 0 !== this._originalCards.length
      ? this._originalCards[0].theme
      : 'light';
  }
  _adjustEmptyCard(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
      i = window.screen.width;
    return 'next' !== e || t.length
      ? ('past' !== e && 'upcoming' !== e) || t.length
        ? 'past' === e && 1 === t.length && i >= 1024
          ? (t.unshift(this._createEmptyCard('past')), t)
          : 'upcoming' === e && 1 === t.length && i >= 1024
          ? (t.push(this._createEmptyCard('upcoming')), t)
          : t
        : (i >= 768 && t.push(this._createEmptyCard(e)),
          i >= 1024 && t.push(this._createEmptyCard(e)),
          t)
      : (t.push(this._createEmptyCard('next')), t);
  }
  _setCards() {
    var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
    (this.$.container.innerHTML = ''),
      this.$.container.setAttribute('no-animation', !0),
      this._setPositionX(0, 0),
      (this.$.headingsContainer.innerHTML = '');
    var t = [...this.querySelectorAll('cs-event-card')];
    e ? (this._originalCards = t) : (t = this._originalCards);
    var i = [],
      n = [],
      a = [];
    t.forEach(e => {
      var t = e.getAttribute('status');
      'past' === t && i.push(e),
        'next' === t && n.push(e),
        'upcoming' === t && a.push(e);
    }),
      (this._cardIndex = 0),
      this._attachAdjustedCardsToDom('past', i),
      this._attachAdjustedCardsToDom('next', n),
      this._attachAdjustedCardsToDom('upcoming', a),
      (this._totalCards = this._cardIndex),
      this.$.container.removeAttribute('no-animation');
  }
  _toggleCard(e) {
    this.shadowRoot.querySelectorAll('cs-event-card').forEach(t => {
      null !== t.getAttribute('open') &&
        t.getAttribute('card-index') !== e.toString() &&
        t.removeAttribute('open');
    });
  }
  _attachAdjustedCardsToDom(e, t) {
    var i = this._adjustCardsOrder(this._adjustEmptyCard(e, t));
    if (
      ('upcoming' === e &&
        this.fullListingCard &&
        i.push(this._createFullListCard(this.fullListingCard)),
      i.length)
    ) {
      var n = document.createElement('div');
      (n.className = 'cspl-timeline__'.concat(e, '-event-group')),
        (n.id = ''.concat(e, 'EventGroup')),
        i.forEach(e => {
          var t =
            e.shadowRoot &&
            e.shadowRoot.querySelector('.cspl-event-card__side-toggle');
          e.setAttribute('card-index', this._cardIndex),
            t &&
              t.addEventListener(
                'click',
                this._toggleCard.bind(this, this._cardIndex)
              ),
            n.appendChild(e),
            (this._cardIndex = this._cardIndex + 1);
        }),
        this.$.container.appendChild(n),
        i.forEach(e => {
          var t = this._getCentreCardPositionX(e);
          e.setAttribute('position-x', t);
        });
    }
    this._setCardLabel(e);
  }
  _getCentreCardPositionX(e) {
    var t = e.getBoundingClientRect();
    return t.left + t.width / 2 - this._timelineCenterPositionX;
  }
  _updateNextCardGradientColor(e) {
    this.updateStyles({
      '--next-card-gradient-color': 'rgb('.concat(e, ')'),
      '--next-card-gradient-color-from': 'rgba('.concat(e, ', 0.3)'),
      '--next-card-gradient-color-to': 'rgba('.concat(e, ', 0)'),
    });
  }
  _getCardsDimension() {
    var e = this.shadowRoot.querySelector('#pastEventGroup'),
      t = this.shadowRoot.querySelector('#nextEventGroup'),
      i = this.shadowRoot.querySelector('#upcomingEventGroup');
    (this._pastCardsWidth = this._getNodeWidth(e)),
      (this._nextCardsWidth = this._getNodeWidth(t)),
      (this._upcomingCardsWidth = this._getNodeWidth(i));
  }
  _getNodeWidth(e) {
    return e ? parseInt(window.getComputedStyle(e).width, 10) : 0;
  }
  _computeContentWidth(e, t, i) {
    return e + t + i;
  }
  _computeCardWidth(e, t) {
    return parseInt(e / t, 10);
  }
  _updateCardLabelDimension() {
    var e = this._showSpecialHeading
        ? this._visualExtraWidth
        : this._containerWidth,
      t = this._showSpecialHeading
        ? this._nextCardsWidth
        : this._containerWidth,
      i = this._showSpecialHeading
        ? this._visualExtraWidth
        : this._containerWidth;
    this.updateStyles({
      '--past-cards-group-width': ''.concat(this._pastCardsWidth, 'px'),
      '--next-cards-group-width': ''.concat(this._nextCardsWidth, 'px'),
      '--upcoming-cards-group-width': ''.concat(this._upcomingCardsWidth, 'px'),
      '--all-cards-width': ''.concat(this._contentWidth, 'px'),
      '--container-width': ''.concat(this._containerWidth, 'px'),
      '--past-cards-label-width': ''.concat(e, 'px'),
      '--next-cards-label-width': ''.concat(t, 'px'),
      '--upcoming-cards-label-width': ''.concat(i, 'px'),
    });
  }
  _setCardLabelCentre() {
    if (this._showSpecialHeading)
      this.updateStyles({
        '--translate-label-x': 0,
      });
    else {
      var e = this.rtl ? 1 : -1;
      this._setLabelPosition(this._containerWidth * e);
    }
  }
  _setTimelineCentre() {
    var e = this.shadowRoot.querySelectorAll('cs-event-card[status="next"]')
        .length,
      t = this.shadowRoot.querySelectorAll('cs-event-card[status="past"]')
        .length;
    if (this._viewportWidth >= 768) {
      var i = this.rtl ? 1 : -1,
        n =
          (this._pastCardsWidth +
            this._nextCardsWidth / 2 -
            this._containerWidth / 2) *
          i,
        a =
          (((this._progressWidth - this._progressBarWidth) *
            (this._pastCardsWidth + this._nextCardsWidth / 2)) /
            this._contentWidth) *
          i *
          -1;
      (this._centreCardIndex = t + Math.ceil(e / 2) - 1),
        this._setPositionX(n, a);
    } else
      (this._centreCardIndex = t),
        this._setPositionX(...this._getPositionXByCardIndex(t));
    this._initCentreCardIndex = this._centreCardIndex;
  }
  _getPositionXByCardIndex(e) {
    var t = this.rtl ? -1 : 1,
      i =
        -1 *
        this.shadowRoot
          .querySelector('cs-event-card[card-index="'.concat(e, '"]'))
          .getAttribute('position-x'),
      n = this._totalCards - 1;
    return [i, e * ((this._progressWidth - this._progressBarWidth) / n) * t];
  }
  _handleTrackEvent(e) {
    this.isTouch && !this.notSwipeable && this._setTrackAction(e.detail);
  }
  _handleTouchmoveEvent(e) {
    this._canScrollHorizontal && e.cancelable && e.preventDefault();
  }
  _initProgressBar() {
    this.notSwipeable ||
      ((this._progressWidth = 136),
      (this._progressBarWidth = 46),
      this._viewportWidth >= 768 &&
        ((this._progressWidth = 196), (this._progressBarWidth = 66)));
  }
  _onPaddleTap(e) {
    var t = parseInt(e.target.getAttribute('direction'), 10);
    this.debounce(
      'scrollDebounceJob',
      () => {
        this._scrollTo(...this._getPosition(t)),
          this._scrollLabelTo(this._getPositionLabel());
      },
      this._debounceDelay
    );
  }
  _getPosition(e) {
    var t =
        arguments.length > 1 && void 0 !== arguments[1]
          ? arguments[1]
          : this._cardScrollNum,
      i = this._getNextCentreCardIndex(e, t);
    return (this._centreCardIndex = i), this._getPositionXByCardIndex(i);
  }
  _getPositionLabel() {
    var e = this.shadowRoot
        .querySelector(
          'cs-event-card[card-index="'.concat(this._centreCardIndex, '"]')
        )
        .getAttribute('status'),
      t = this.shadowRoot
        .querySelector('.cspl-timeline__card-label.'.concat(e))
        .getAttribute('position-x');
    return this.rtl ? t : -1 * t;
  }
  _getNextCentreCardIndex(e, t) {
    var i = this.rtl ? -1 : 1,
      n = this._centreCardIndex + t * i * e;
    return this._cardScrollNumForwardChange && e * i == -1
      ? ((this._cardScrollNumForwardChange = !1),
        (n = this._maxMultipleCardIndex))
      : this._cardScrollNumBackChange && e * i == 1
      ? ((this._cardScrollNumBackChange = !1), (n = this._minMultipleCardIndex))
      : (n > this._totalCards - 1 &&
          ((n = this._totalCards - 1), (this._cardScrollNumForwardChange = !0)),
        n < 0 && ((n = 0), (this._cardScrollNumBackChange = !0)),
        n);
  }
  _scrollTo(e, t) {
    var i = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
    this.toggleAttribute('no-animation', i, this.$.container),
      this.toggleAttribute('no-animation', i, this.$.progressIndicator),
      this._setPositionX(e, t);
  }
  _scrollLabelTo(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.toggleAttribute('no-animation', t, this.$.headingsContainer),
      this._setLabelPosition(e);
  }
  _checkPosition(e) {
    var t = e,
      i = this._getPositionXByCardIndex(0)[0],
      n = this._getPositionXByCardIndex(this._totalCards - 1)[0],
      a = this.rtl ? n : i,
      r = this.rtl ? i : n;
    return (
      t > a
        ? ((t = a),
          (this._centreCardIndex = this.rtl ? this._totalCards - 1 : 0))
        : t < r &&
          ((t = r),
          (this._centreCardIndex = this.rtl ? 0 : this._totalCards - 1)),
      t
    );
  }
  _setPositionX(e, t) {
    this.updateStyles({
      '--translate-x': ''.concat(e, 'px'),
      '--translate-progress-x': ''.concat(t, 'px'),
    });
  }
  _setLabelPosition(e) {
    this._showSpecialHeading
      ? Math.abs(e) === this._containerWidth
        ? (this.$.headingsContainer.classList.remove(
            'cspl-timeline__headings-container-transition'
          ),
          this.updateStyles({
            '--past-cards-label-width': ''.concat(this._visualExtraWidth, 'px'),
            '--next-cards-label-width': ''.concat(this._nextCardsWidth, 'px'),
            '--upcoming-cards-label-width': ''.concat(
              this._visualExtraWidth,
              'px'
            ),
            '--translate-label-x': 0,
          }))
        : (this.$.headingsContainer.classList.add(
            'cspl-timeline__headings-container-transition'
          ),
          this.updateStyles({
            '--past-cards-label-width': ''.concat(this._containerWidth, 'px'),
            '--next-cards-label-width': ''.concat(this._containerWidth, 'px'),
            '--upcoming-cards-label-width': ''.concat(
              this._containerWidth,
              'px'
            ),
            '--translate-label-x': ''.concat(e, 'px'),
          }))
      : (this.$.headingsContainer.classList.add(
          'cspl-timeline__headings-container-transition'
        ),
        this.updateStyles({
          '--translate-label-x': ''.concat(e, 'px'),
        }));
  }
  _setTrackAction(e) {
    var t = this._getPositionXByCardIndex(this._centreCardIndex);
    switch (e.state) {
      case 'start':
        var i = Math.abs(e.dx),
          n = Math.abs(e.dy);
        this._canScrollHorizontal = !(
          Math.abs(Math.atan2(n, i)) >=
          0.25 * Math.PI
        );
        break;
      case 'track':
        if (!1 === this._canScrollHorizontal) return;
        var a = e.dx,
          r = e.dy;
        if (Math.abs(r) > Math.abs(a)) return;
        var o = t.map((e, t) => {
          if (0 === t) {
            var i = this._checkPosition(e + a);
            return (a = i - e), i;
          }
          return 1 === t
            ? e + (this._progressWidth / this._contentWidth) * a * -1
            : null;
        });
        this._scrollTo(...o, !0);
        break;
      case 'end':
        this._handleTimelineEndTrack(e);
    }
  }
  _handleTimelineEndTrack(e) {
    if (this._canScrollHorizontal) {
      var t = Math.abs(e.dx);
      if (!(Math.abs(e.dy) > t)) {
        var i = parseInt(t / this._cardWidth, 10),
          n = i + (t / this._cardWidth - i >= 0.25 ? 1 : 0),
          a = e.dx > 0 ? -1 : 1;
        this.debounce(
          'scrollDebounceJob',
          () => {
            this._scrollTo(...this._getPosition(a, n)),
              this._scrollLabelTo(this._getPositionLabel());
          },
          this._debounceDelay
        );
      }
    }
  }
  _setTransitionDuration() {
    var e = 500;
    this._viewportWidth >= 768 && (e = 700),
      this._viewportWidth >= 1024 && (e = 800),
      this.updateStyles({
        '--transition-duration': ''.concat(e, 'ms'),
      });
  }
  _reflowContent(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    (this._viewportWidth !== window.innerWidth || t) &&
      ((this._viewportWidth = window.innerWidth),
      (this._containerWidth = this._getNodeWidth(this.$.timeline)),
      (this._timelineCenterPositionX = this._getTimelineCenterPositionX()),
      this._setCards(t),
      this._totalCards &&
        ((this._cardScrollNum = this._getCardScrollNum()),
        this._setTransitionDuration(),
        this._initProgressBar(),
        this._getCardsDimension(),
        (this._showSpecialHeading = this._testSpecialHeading()),
        (this._visualExtraWidth = this._getVisualExtraWidth()),
        this._setTimelineCentre(),
        this._updateCardLabelDimension(),
        this._setCardLabelCentre()));
  }
  _initLayout() {
    var e = this.querySelector('cs-event-card');
    e && e.shadowRoot
      ? this._reflowContent(null, !0)
      : setTimeout(this._initLayout, 200);
  }
  connectedCallback() {
    super.connectedCallback(),
      setTimeout(() => {
        this._initLayout();
      }),
      this.addEventListener('iron-resize', this._reflowContent),
      this.$.container.addEventListener(
        'touchmove',
        this._handleTouchmoveEvent
      );
    this.setScrollDirection('y', this.$.container);
  }
}

customElements.define('cs-timeline', CsTimeline);
