import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * MixinNamePascelCase - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internaCsScrollToElementMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                isFirstLoad: {
                    type: Boolean,
                    value: !0
                }
            };
        }
        _scrollToElementTop() {
            let e = this;
            let t = 0;
            if (this.isFirstLoad) return void(this.isFirstLoad = !1);
            for (; e;) t += e.offsetTop, e = e.offsetParent; // jshint ignore:line
            window.scrollTo(0, t - 150);
        }
    };

export const CsScrollToElementMixin = dedupingMixin(internaCsScrollToElementMixin);