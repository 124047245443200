import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsBodyScrollMixin } from './mixins/cs-body-scroll-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js';
import { animationFrame } from '@polymer/polymer/lib/utils/async.js';
import { Debouncer } from '@polymer/polymer/lib/utils/debounce.js';

const h = CsMixin,
  v = CsBodyScrollMixin;
let d;
let p;
/**
 * `cs-local-nav` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLocalNav extends h(v(CsElements)) {
  static get properties() {
    return {
      homeText: {
        type: String,
        value: 'Home',
      },
      homeLink: {
        type: String,
        value: '#',
      },
      homeTooltip: {
        type: String,
        value: 'Home',
      },
      logoImage: String,
      logoTooltip: String,
      openDrawerText: {
        type: String,
        value: 'Menu',
      },
      openDrawerTooltip: {
        type: String,
        value: 'Open Menu',
      },
      closeDrawerText: {
        type: String,
        value: 'Close',
      },
      closeDrawerTooltip: {
        type: String,
        value: 'Close Menu',
      },
      theme: {
        observer: '_observeTheme',
        type: String,
        reflectToAttribute: !0,
        value: 'dark',
      },
      shelfType: {
        type: String,
        reflectToAttribute: !0,
        value: 'scrim',
      },
      hideHamburger: {
        type: Boolean,
        reflectToAttribute: !0,
        value: !1,
      },
      hideHamburgerOnDesktop: {
        type: Boolean,
        reflectToAttribute: !0,
        value: !1,
      },
      hideFeaturedProduct: {
        type: Boolean,
        value: !1,
      },
      open: {
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
      },
      mini: {
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
        observer: '_observeMiniTrigger',
      },
      detached: {
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
      },
      shelfSubNavOpen: {
        reflectToAttribute: !0,
        type: Boolean,
      },
      _shelfSubNavs: {
        type: Array,
        value() {
          return this.querySelectorAll('cs-local-nav-shelf-sub-nav');
        },
      },
      _hideCTADropdown: {
        type: Boolean,
        value: !1,
      },
      franchiseOpen: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      showFranchiseNav: {
        type: Boolean,
        value: !1,
      },
      franchiseLinkText: String,
      franchiseLinkUrl: String,
      gameLinkText: String,
      gameLinkUrl: String,
      showBreadcrumbs: {
        type: Boolean,
        value: !1,
      },
      fixed: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      _franchiseTitles: Array,
      loading: {
        type: Boolean,
        readOnly: true,
        notify: true,
        value: false,
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style>
      <style>
        :host {
          font-family: var(--cs-theme-local-nav-shelf-header-font-family);
          font-size: var(--cs-theme-local-nav-shelf-header-font-size-mobile);
          height: 100%;
          letter-spacing: var(
            --cs-theme-local-nav-shelf-header-letter-spacing-mobile
          );
          pointer-events: none;
          position: fixed;
          top: 0;
          transition: top 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
          width: 100%;
          z-index: var(--cs-local-nav-layer);
        }

        @media only screen and (min-width: 1031px) {
          :host {
            font-size: var(
              --cs-theme-local-nav-shelf-header-font-size-tablet-landscape
            );
            letter-spacing: var(
              --cs-theme-local-nav-shelf-header-letter-spacing-tablet-landscape
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          :host {
            font-size: var(--cs-theme-local-nav-shelf-header-font-size-desktop);
            letter-spacing: var(
              --cs-theme-local-nav-shelf-header-letter-spacing-desktop
            );
          }
        }

        :host(.net-nav-onscreen),
        :host(.static-mode) {
          height: calc(100% - 40px);
          top: 40px;
        }

        .cspl-local-nav__shelf::after {
          background-color: #202020;
          content: '';
          height: 100vh;
          left: 0;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          transition: opacity 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
          width: 100%;
        }

        :host([franchise-open]) .cspl-local-nav__shelf::after,
        :host([open]) .cspl-local-nav__shelf::after {
          opacity: 0.5;
          pointer-events: auto;
        }

        .cspl-local-nav__shelf-header {
          align-items: center;
          display: flex;
          height: 56px;
          left: 0;
          pointer-events: auto;
          position: absolute;
          transition: background 0.5s cubic-bezier(0.5, 0.25, 0.015, 1) 0s,
            opacity 0.5s cubic-bezier(0.5, 0.25, 0.015, 1) 0s,
            height 0.5s cubic-bezier(0.5, 0.25, 0.015, 1) 0s;
          width: 100%;
        }

        :host([franchise-open]) .cspl-local-nav__shelf-header,
        :host([open]) .cspl-local-nav__shelf-header {
          opacity: 0;
        }

        :host([theme='dark']) {
          color: var(--cs-theme-local-nav-font-color-dark);
        }

        :host([theme='dark']) .cspl-local-nav__shelf-header {
          background: var(--cs-light-color);
        }

        :host([theme='light']) .cspl-local-nav__shelf-header {
          background: var(--cs-dark-color);
        }

        :host([theme='light']) {
          color: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='brand']) .cspl-local-nav__shelf-header {
          background: var(--cs-theme-color);
        }

        :host([theme='brand']) {
          color: var(--cs-theme-local-nav-font-color-light);
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__shelf-header {
            justify-content: flex-start;
            text-transform: var(--cs-text-case, uppercase);
            transition: border-radius 0.3s step-end;
          }

          :host([detached]) .cspl-local-nav__shelf-header {
            border-radius: 4px;
            left: auto;
            margin: 0 auto;
            position: relative;
            transform: translateY(15px);
            transition: transform 0.3s, width 0.3s;
            width: calc(100% - 60px);
          }

          :host([show-franchise-nav]) .cspl-local-nav__shelf-header {
            padding-left: 17px;
          }

          :host([rtl][show-franchise-nav]) .cspl-local-nav__shelf-header {
            padding-left: 0;
            padding-right: 17px;
          }

          :host([detached][shelf-sub-nav-open]) .cspl-local-nav__shelf-header {
            border-radius: 4px 4px 0 0;
          }

          :host([detached][fixed][shelf-sub-nav-open]),
          :host([fixed]) .cspl-local-nav__shelf-header {
            border-radius: 0;
            transform: translateY(0);
            width: 100%;
          }
        }

        @media only screen and (min-width: 1031px) {
          :host([shelf-type='scrim']) .cspl-local-nav__shelf-header {
            height: 96px;
          }

          :host([theme='dark'][shelf-type='scrim']:not(.cspl-local-nav-mini))
            .cspl-local-nav__shelf-header {
            background: linear-gradient(
              to top,
              transparent 0,
              rgba(255, 255, 255, 0.8) 64px,
              rgba(255, 255, 255, 0.8) 96px
            );
          }

          :host([theme='light'][shelf-type='scrim']:not(.cspl-local-nav-mini))
            .cspl-local-nav__shelf-header {
            background: linear-gradient(
              to top,
              transparent 0,
              rgba(0, 0, 0, 0.8) 64px,
              rgba(0, 0, 0, 0.8) 96px
            );
          }

          :host([theme='brand'][shelf-type='scrim']:not(.cspl-local-nav-mini))
            .cspl-local-nav__shelf-header {
            background: linear-gradient(
              to top,
              transparent 0,
              rgba(0, 0, 0, 0.8) 64px,
              rgba(0, 0, 0, 0.8) 96px
            );
          }

          :host([shelf-type='band']) .cspl-local-nav__shelf-header {
            height: 68px;
          }

          :host(.cspl-local-nav-mini) .cspl-local-nav__shelf-header {
            height: 56px;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([shelf-type='scrim']) .cspl-local-nav__shelf-header {
            height: 120px;
          }

          :host([theme='dark'][shelf-type='scrim']:not(.cspl-local-nav-mini))
            .cspl-local-nav__shelf-header {
            background: linear-gradient(
              to top,
              transparent 0,
              rgba(255, 255, 255, 0.6) 80px,
              rgba(255, 255, 255, 0.7) 120px
            );
          }

          :host([theme='light'][shelf-type='scrim']:not(.cspl-local-nav-mini))
            .cspl-local-nav__shelf-header {
            background: linear-gradient(
              to top,
              transparent 0,
              rgba(0, 0, 0, 0.6) 80px,
              rgba(0, 0, 0, 0.7) 120px
            );
          }

          :host([theme='brand'][shelf-type='scrim']:not(.cspl-local-nav-mini))
            .cspl-local-nav__shelf-header {
            background: linear-gradient(
              to top,
              transparent 0,
              rgba(0, 0, 0, 0.6) 80px,
              rgba(0, 0, 0, 0.7) 120px
            );
          }

          :host(.cspl-local-nav-mini) .cspl-local-nav__shelf-header {
            height: 60px;
          }
        }

        .cspl-local-nav__shelf-home-link {
          align-items: center;
          direction: ltr;
          display: flex;
          font-size: 0;
          height: 100%;
          line-height: 0;
          margin-left: 24px;
          text-indent: -999999px;
        }

        :host([rtl]) .cspl-local-nav__shelf-home-link {
          margin-left: 0;
          margin-right: 24px;
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__shelf-home-link {
            margin-left: 24px;
            margin-right: 48px;
          }

          :host([rtl]) .cspl-local-nav__shelf-home-link {
            margin-left: 48px;
            margin-right: 24px;
          }
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__shelf-home-link {
            margin-left: 28px;
            margin-right: 56px;
          }

          :host([rtl]) .cspl-local-nav__shelf-home-link {
            margin-left: 56px;
            margin-right: 28px;
          }

          :host(:not([rtl])[hide-hamburger-on-desktop])
            .cspl-local-nav__shelf-home-link {
            margin-right: 0;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__shelf-home-link {
            margin-left: 32px;
            margin-right: 64px;
          }

          :host([rtl]) .cspl-local-nav__shelf-home-link {
            margin-left: 64px;
            margin-right: 32px;
          }
        }

        .cspl-local-nav__shelf-logo {
          display: block;
          max-height: 32px;
          transition: max-height 0.5s cubic-bezier(0.5, 0.25, 0.015, 1) 0s;
        }

        :host(.cspl-local-nav-mini) .cspl-local-nav__shelf-logo {
          max-height: 24px;
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__shelf-logo {
            max-height: 40px;
          }

          :host(.cspl-local-nav-mini) .cspl-local-nav__shelf-logo {
            max-height: 32px;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([shelf-type='scrim']) .cspl-local-nav__shelf-logo {
            max-height: 56px;
          }

          :host([shelf-type='band']) .cspl-local-nav__shelf-logo {
            max-height: 48px;
          }

          :host(.cspl-local-nav-mini) .cspl-local-nav__shelf-logo {
            max-height: 40px;
          }
        }

        .cspl-local-nav__open-link {
          align-items: center;
          cursor: pointer;
          direction: ltr;
          display: flex;
          height: 100%;
          margin-right: 24px;
          position: absolute;
          right: 0;
          text-indent: -999999px;
          top: 0;
          transition: opacity var(--cs-interface-expansion);
        }

        :host([rtl]) .cspl-local-nav__open-link {
          direction: rtl;
          left: 0;
          margin-left: 24px;
          margin-right: 0;
          right: auto;
        }

        .cspl-local-nav__open-franchise {
          color: var(--cs-theme-local-nav-font-color-light);
          cursor: pointer;
          margin-right: -15px;
          transform: rotate(-90deg);
          transition: fill 0.7s, transform 0.3s;
          width: 20px;
        }

        :host([rtl]) .cspl-local-nav__open-franchise {
          margin-left: -15px;
          margin-right: 0;
        }

        .cspl-local-nav__open-franchise:hover {
          fill: var(--cs-theme-local-nav-font-color-hover);
          transform: rotate(-90deg) scale(1.4);
        }

        :host([theme='dark']) .cspl-local-nav__open-franchise {
          color: var(--cs-theme-local-nav-font-color-dark);
        }

        :host([wf-loading]) .cspl-local-nav__open-link {
          opacity: 0;
        }

        :host([hide-hamburger]) .cspl-local-nav__open-link {
          display: none;
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__open-link {
            align-items: center;
            color: var(--cs-dark-color);
            flex-direction: row;
            margin-right: 0;
            position: static;
            text-decoration: none;
            text-indent: 0;
            text-indent: initial;
          }

          :host([rtl]) .cspl-local-nav__open-link {
            margin-left: 0;
          }

          :host([theme='dark']) .cspl-local-nav__open-link {
            color: var(--cs-theme-local-nav-font-color-dark);
          }

          :host([theme='dark']) .cspl-local-nav__open-link:hover {
            color: var(--cs-theme-local-nav-font-color-hover);
          }

          :host([theme='light']) .cspl-local-nav__open-link {
            color: var(--cs-theme-local-nav-font-color-light);
          }

          :host([theme='light']) .cspl-local-nav__open-link:hover {
            color: var(--cs-theme-local-nav-font-color-hover);
          }

          :host([theme='brand']) .cspl-local-nav__open-link {
            color: var(--cs-theme-local-nav-font-color-light);
          }

          :host([theme='brand']) .cspl-local-nav__open-link:hover {
            color: var(--cs-theme-local-nav-font-color-hover);
          }
        }

        @media only screen and (min-width: 1031px) {
          :host([hide-hamburger-on-desktop]) .cspl-local-nav__open-link {
            display: none;
          }
        }

        .cspl-local-nav__open-icon {
          height: 1em;
          width: 1.25em;
        }

        :host([theme='dark']) .cspl-local-nav__open-icon {
          fill: var(--cs-theme-local-nav-font-color-dark);
        }

        :host([theme='dark'])
          .cspl-local-nav__open-link:hover
          .cspl-local-nav__open-icon {
          fill: var(--cs-theme-local-nav-font-color-hover);
        }

        :host([theme='light']) .cspl-local-nav__open-icon {
          fill: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='light'])
          .cspl-local-nav__open-link:hover
          .cspl-local-nav__open-icon {
          fill: var(--cs-theme-local-nav-font-color-hover);
        }

        :host([theme='brand']) .cspl-local-nav__open-icon {
          fill: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='brand'])
          .cspl-local-nav__open-link:hover
          .cspl-local-nav__open-icon {
          fill: var(--cs-theme-local-nav-font-color-hover);
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__open-icon {
            margin-left: 8px;
          }

          :host([rtl]) .cspl-local-nav__open-icon {
            margin-left: 0;
            margin-right: 8px;
          }
        }

        .cspl-local-nav__shelf-nav {
          align-items: center;
          height: 100%;
        }

        .cspl-local-nav__featured-links,
        .cspl-local-nav__shelf-nav {
          display: none;
        }

        .cspl-local-nav__cta-dropdown-container {
          height: 100%;
          margin-left: auto;
          padding-right: 10px;
        }

        :host([rtl]) .cspl-local-nav__cta-dropdown-container {
          margin-left: 0;
          margin-right: auto;
          padding-left: 10px;
          padding-right: 0;
        }

        .cspl-local-nav__cta-dropdown-container ::slotted(cs-cta-dropdown) {
          font-family: var(--cs-theme-local-nav-cta-dropdown-font-family);
          font-size: var(--cs-theme-local-nav-cta-dropdown-font-size-mobile);
          letter-spacing: var(
            --cs-theme-local-nav-cta-dropdown-letter-spacing-mobile
          );
        }

        .cspl-local-nav__cta-dropdown-container ::slotted(cs-cta) {
          max-height: 100%;
          padding: 10px 0;
          text-transform: none;
        }

        .cspl-local-nav__cta-dropdown-container ::slotted(:last-of-type) {
          margin-right: 0;
        }

        @media only screen and (max-width: 767px) {
          .cspl-local-nav__cta-dropdown-container {
            display: none;
          }
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__cta-dropdown-container ::slotted(cs-cta-dropdown) {
            font-size: var(
              --cs-theme-local-nav-cta-dropdown-font-size-tablet-landscape
            );
            letter-spacing: var(
              --cs-theme-local-nav-cta-dropdown-letter-spacing-tablet-landscape
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__cta-dropdown-container ::slotted(cs-cta-dropdown) {
            font-size: var(--cs-theme-local-nav-cta-dropdown-font-size-desktop);
            letter-spacing: var(
              --cs-theme-local-nav-cta-dropdown-letter-spacing-desktop
            );
          }
        }

        .cspl-local-nav__featured-links
          ::slotted([slot='local-nav-featured-link']),
        .cspl-local-nav__shelf-nav ::slotted([slot='local-nav-shelf-link']) {
          direction: ltr;
          height: 0;
          left: auto;
          overflow: hidden;
          position: absolute;
          transition: opacity var(--cs-interface-expansion);
          width: 0;
        }

        :host([rtl])
          .cspl-local-nav__featured-links
          ::slotted([slot='local-nav-featured-link']),
        :host([rtl])
          .cspl-local-nav__shelf-nav
          ::slotted([slot='local-nav-shelf-link']) {
          direction: rtl;
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__featured-links,
          .cspl-local-nav__shelf-nav {
            display: flex;
          }

          .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          .cspl-local-nav__shelf-nav ::slotted([slot='local-nav-shelf-link']) {
            align-items: center;
            display: flex;
            margin-left: 32px;
            text-decoration: none;
          }

          :host([rtl])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          :host([rtl])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']) {
            margin-left: 0;
            margin-right: 32px;
          }

          .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']) {
            height: 100%;
            position: static;
            width: auto;
          }

          :host([theme='dark'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          :host([theme='dark'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']) {
            color: var(--cs-theme-local-nav-font-color-dark);
          }

          :host([theme='dark'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']:hover),
          :host([theme='dark'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']:hover) {
            color: var(--cs-theme-local-nav-font-color-hover);
          }

          :host([theme='dark'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link'][current]) {
            color: var(--cs-theme-local-nav-shelf-link-current-dark-color);
          }

          :host([theme='dark'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link'][current]:hover) {
            color: var(
              --cs-theme-local-nav-shelf-link-current-hover-dark-color
            );
          }

          :host([theme='dark'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link'][current]) {
            color: var(
              --cs-theme-local-nav-shelf-featured-link-current-dark-color
            );
          }

          :host([theme='dark'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link'][current]:hover) {
            color: var(
              --cs-theme-local-nav-shelf-featured-link-current-hover-dark-color
            );
          }

          :host([theme='light'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          :host([theme='light'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']) {
            color: var(--cs-theme-local-nav-font-color-light);
          }
          :host([theme='light'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']:hover),
          :host([theme='light'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']:hover) {
            color: var(--cs-theme-local-nav-font-color-hover);
          }
          :host([theme='light'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link'][current]) {
            color: var(--cs-theme-local-nav-shelf-link-current-light-color);
          }
          :host([theme='light'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link'][current]:hover) {
            color: var(
              --cs-theme-local-nav-shelf-link-current-hover-light-color
            );
          }
          :host([theme='light'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link'][current]) {
            color: var(
              --cs-theme-local-nav-shelf-featured-link-current-light-color
            );
          }
          :host([theme='light'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link'][current]:hover) {
            color: var(
              --cs-theme-local-nav-shelf-featured-link-current-hover-light-color
            );
          }

          :host([theme='brand'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          :host([theme='brand'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']) {
            color: var(--cs-theme-local-nav-font-color-light);
          }
          :host([theme='brand'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']:hover),
          :host([theme='brand'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']:hover) {
            color: var(--cs-theme-local-nav-font-color-hover);
          }
          :host([theme='brand'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link'][current]) {
            color: var(--cs-theme-local-nav-shelf-link-current-light-color);
          }
          :host([theme='brand'])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link'][current]:hover) {
            color: var(
              --cs-theme-local-nav-shelf-link-current-hover-light-color
            );
          }
          :host([theme='brand'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link'][current]) {
            color: var(
              --cs-theme-local-nav-shelf-featured-link-current-light-color
            );
          }
          :host([theme='brand'])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link'][current]:hover) {
            color: var(
              --cs-theme-local-nav-shelf-featured-link-current-hover-light-color
            );
          }
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          .cspl-local-nav__shelf-nav ::slotted([slot='local-nav-shelf-link']) {
            height: 100%;
            margin-left: 20px;
            position: static;
            width: auto;
          }

          :host([rtl])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          :host([rtl])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']) {
            margin-left: 0;
            margin-right: 20px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          .cspl-local-nav__shelf-nav ::slotted([slot='local-nav-shelf-link']) {
            margin-left: 34px;
          }

          :host([rtl])
            .cspl-local-nav__featured-links
            ::slotted([slot='local-nav-featured-link']),
          :host([rtl])
            .cspl-local-nav__shelf-nav
            ::slotted([slot='local-nav-shelf-link']) {
            margin-left: 0;
            margin-right: 34px;
          }
        }

        .cspl-local-nav__franchise-drawer {
          background-color: var(--cs-light-color);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
          height: 100%;
          left: -100%;
          overflow: hidden;
          pointer-events: auto;
          position: absolute;
          top: 0;
          transform: translateX(0);
          transition: transform 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
          width: 100%;
        }

        :host([rtl]) .cspl-local-nav__franchise-drawer {
          left: auto;
          right: -100%;
        }

        :host([theme='dark']) .cspl-local-nav__franchise-drawer {
          background-color: var(--cs-light-color);
        }

        :host([theme='light']) .cspl-local-nav__franchise-drawer {
          background-color: var(--cs-dark-color);
        }

        :host([theme='brand']) .cspl-local-nav__franchise-drawer {
          background-color: var(--cs-theme-color);
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__franchise-drawer {
            left: calc(
              -1 * (5 * var(--cs-column-tablet-portrait) + 6 *
                    var(--cs-gutter-tablet-portrait))
            );
            width: calc(
              5 * var(--cs-column-tablet-portrait) + 6 *
                var(--cs-gutter-tablet-portrait)
            );
          }

          :host([rtl]) .cspl-local-nav__franchise-drawer {
            left: auto;
            right: calc(
              -1 * (5 * var(--cs-column-tablet-portrait) + 6 *
                    var(--cs-gutter-tablet-portrait))
            );
          }
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__franchise-drawer {
            left: calc(
              -1 * (4 * var(--cs-column-tablet-landscape) + 5 *
                    var(--cs-gutter-tablet-landscape))
            );
            width: calc(
              4 * var(--cs-column-tablet-landscape) + 5 *
                var(--cs-gutter-tablet-landscape)
            );
          }

          :host([rtl]) .cspl-local-nav__franchise-drawer {
            left: auto;
            right: calc(
              -1 * (4 * var(--cs-column-tablet-landscape) + 5 *
                    var(--cs-gutter-tablet-landscape))
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__franchise-drawer {
            left: calc(
              -1 * (4 * var(--cs-column-desktop) + 5 * var(--cs-gutter-desktop))
            );
            width: calc(
              4 * var(--cs-column-desktop) + 5 * var(--cs-gutter-desktop)
            );
          }

          :host([rtl]) .cspl-local-nav__franchise-drawer {
            left: auto;
            right: calc(
              -1 * (4 * var(--cs-column-desktop) + 5 * var(--cs-gutter-desktop))
            );
          }
        }

        .cspl-local-nav__franchise-drawer-content {
          height: 100%;
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          padding-left: 15px;
          padding-right: 49px;
          position: absolute;
          right: -17px;
          width: 100%;
        }

        :host([rtl]) .cspl-local-nav__franchise-drawer-content {
          left: -17px;
          padding-left: 49px;
          padding-right: 15px;
          right: auto;
        }

        .cspl-local-nav__franchise-drawer-navigation {
          color: #585856;
          display: flex;
          justify-content: space-between;
          margin-bottom: 47px;
          margin-top: 47px;
        }

        :host([rtl]) .cspl-local-nav__franchise-drawer-navigation {
          direction: ltr;
        }

        :host([theme='dark']) .cspl-local-nav__franchise-drawer-navigation {
          color: #a3a3a3;
        }

        .cspl-local-nav__franchise-close-link {
          align-items: center;
          cursor: pointer;
          direction: ltr;
          display: flex;
          font-size: 0;
          line-height: 0;
          text-indent: -999999px;
          width: 16px;
        }

        .cspl-local-nav__franchise-close-link:hover
          .cspl-local-nav__close-icon:hover {
          fill: var(--cs-theme-local-nav-font-color-hover);
        }

        .cspl-local-nav__franchise-drawer-navigation-link {
          color: var(--cs-theme-local-nav-font-color-light);
          font-family: var(--cs-theme-secondary-font-family);
          font-weight: 700;
          text-decoration: none;
          text-transform: var(--cs-text-case, uppercase);
        }

        :host([theme='dark'])
          .cspl-local-nav__franchise-drawer-navigation-link {
          color: var(--cs-theme-local-nav-font-color-dark);
        }

        .cspl-local-nav__franchise-drawer-navigation-link:hover,
        :host([theme='dark'])
          .cspl-local-nav__franchise-drawer-navigation-link:hover,
        :host([theme='light'])
          .cspl-local-nav__franchise-drawer-navigation-link:hover,
        :host([theme='brand'])
          .cspl-local-nav__franchise-drawer-navigation-link:hover {
          color: var(--cs-theme-local-nav-font-color-hover);
        }

        .cspl-local-nav__franchise-drawer-navigation-link--main {
          color: #585856;
        }

        :host([theme='dark'])
          .cspl-local-nav__franchise-drawer-navigation-link--main {
          color: #a3a3a3;
        }

        .cspl-local-nav__franchise-drawer-navigation-link--main:hover,
        :host([theme='dark'])
          .cspl-local-nav__franchise-drawer-navigation-link--main:hover,
        :host([theme='light'])
          .cspl-local-nav__franchise-drawer-navigation-link--main:hover,
        :host([theme='brand'])
          .cspl-local-nav__franchise-drawer-navigation-link--main:hover {
          color: var(--cs-theme-local-nav-font-color-hover);
        }

        .cspl-local-nav__franchise-drawer-navigation-icon {
          transform: rotate(-90deg) scale(0.5) translateX(2px);
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__franchise-drawer-navigation-icon {
            transform: rotate(-90deg) scale(0.67) translateX(2px);
          }
        }

        .cspl-local-nav__drawer {
          background-color: var(--cs-light-color);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
          height: 100%;
          opacity: 0;
          pointer-events: auto;
          position: relative;
          top: -100%;
          transform: translateX(0);
          transition: opacity 0s linear 0.5s,
            transform 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
          width: 100%;
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__drawer {
            left: calc(
              -1 * (5 * var(--cs-column-tablet-portrait) + 6 *
                    var(--cs-gutter-tablet-portrait))
            );
            top: 0;
            width: calc(
              5 * var(--cs-column-tablet-portrait) + 6 *
                var(--cs-gutter-tablet-portrait)
            );
          }

          :host([rtl]) .cspl-local-nav__drawer {
            left: auto;
            right: calc(
              -1 * (5 * var(--cs-column-tablet-portrait) + 6 *
                    var(--cs-gutter-tablet-portrait))
            );
          }
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__drawer {
            left: calc(
              -1 * (4 * var(--cs-column-tablet-landscape) + 5 *
                    var(--cs-gutter-tablet-landscape))
            );
            width: calc(
              4 * var(--cs-column-tablet-landscape) + 5 *
                var(--cs-gutter-tablet-landscape)
            );
          }

          :host([rtl]) .cspl-local-nav__drawer {
            left: auto;
            right: calc(
              -1 * (4 * var(--cs-column-tablet-landscape) + 5 *
                    var(--cs-gutter-tablet-landscape))
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__drawer {
            left: calc(
              -1 * (4 * var(--cs-column-desktop) + 5 * var(--cs-gutter-desktop))
            );
            width: calc(
              4 * var(--cs-column-desktop) + 5 * var(--cs-gutter-desktop)
            );
          }

          :host([rtl]) .cspl-local-nav__drawer {
            left: auto;
            right: calc(
              -1 * (4 * var(--cs-column-desktop) + 5 * var(--cs-gutter-desktop))
            );
          }
        }

        :host([theme='dark']) .cspl-local-nav__drawer {
          background-color: var(--cs-light-color);
        }

        :host([theme='light']) .cspl-local-nav__drawer {
          background-color: var(--cs-dark-color);
        }

        :host([theme='brand']) .cspl-local-nav__drawer {
          background-color: var(--cs-theme-color);
        }

        :host([open]) .cspl-local-nav__drawer {
          opacity: 1;
          transform: translateY(100%);
          transition: opacity 0s linear,
            transform 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
        }

        @media only screen and (min-width: 768px) {
          :host([open]) .cspl-local-nav__drawer {
            top: 0;
            transform: translateX(100%);
          }

          :host([rtl][open]) .cspl-local-nav__drawer {
            transform: translateX(-100%);
          }
        }

        :host([franchise-open]) .cspl-local-nav__franchise-drawer {
          transform: translateX(100%);
        }

        :host([rtl][franchise-open]) .cspl-local-nav__franchise-drawer {
          transform: translateX(-100%);
        }

        @media only screen and (min-width: 768px) {
          :host([detached]) .cspl-local-nav__drawer {
            position: absolute;
            top: 0;
          }
        }

        .cspl-local-nav__drawer-liner {
          align-items: stretch;
          display: flex;
          flex-direction: column;
          height: 100%;
          position: relative;
        }

        .cspl-local-nav__drawer-header {
          align-items: center;
          display: flex;
          flex: 0 0 56px;
          justify-content: space-between;
          width: 100%;
        }

        :host([rtl]) .cspl-local-nav__drawer-header {
          direction: ltr;
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__drawer-header {
            flex: 0 0 96px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__drawer-header {
            flex: 0 0 120px;
          }
        }

        .cspl-local-nav__drawer-home-link {
          align-items: center;
          direction: ltr;
          display: flex;
          font-size: 0;
          height: 100%;
          line-height: 0;
          margin-left: 24px;
          position: relative;
          text-indent: -999999px;
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__drawer-home-link {
            margin-left: 28px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__drawer-home-link {
            margin-left: 32px;
          }
        }

        .cspl-local-nav__drawer-home-logo {
          display: block;
          max-height: 32px;
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__drawer-home-logo {
            max-height: 40px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__drawer-home-logo {
            max-height: 56px;
          }
        }

        .cspl-local-nav__close-link {
          align-items: center;
          cursor: pointer;
          direction: ltr;
          display: flex;
          font-size: 0;
          line-height: 0;
          margin-right: 24px;
          text-indent: -999999px;
          width: 16px;
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__close-link {
            margin-right: 28px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__close-link {
            margin-right: 32px;
          }
        }

        .cspl-local-nav__close-icon {
          height: 16px;
          width: 16px;
        }

        :host([theme='dark']) .cspl-local-nav__close-icon {
          fill: var(--cs-theme-local-nav-font-color-dark);
        }

        :host([theme='dark'])
          .cspl-local-nav__close-link:hover
          .cspl-local-nav__close-icon {
          fill: var(--cs-dark-color);
        }

        :host([theme='light']) .cspl-local-nav__close-icon {
          fill: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='light'])
          .cspl-local-nav__close-link:hover
          .cspl-local-nav__close-icon {
          fill: var(--cs-light-color);
        }

        :host([theme='brand']) .cspl-local-nav__close-icon {
          fill: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='brand'])
          .cspl-local-nav__close-link:hover
          .cspl-local-nav__close-icon {
          fill: var(--cs-light-color);
        }

        .cspl-local-nav__close-icon:hover,
        :host([theme='dark']) .cspl-local-nav__close-icon:hover,
        :host([theme='light']) .cspl-local-nav__close-icon:hover,
        :host([theme='brand']) .cspl-local-nav__close-icon:hover {
          fill: var(--cs-theme-local-nav-font-color-hover);
        }

        .cspl-local-nav__drawer-body {
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;
          justify-content: space-between;
          overflow-y: auto;
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__drawer-body {
            max-height: calc(100% - 96px);
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__drawer-body {
            max-height: calc(100% - 120px);
          }
        }

        .cspl-local-nav__drawer-nav {
          overflow: hidden;
          overflow-y: auto;
          padding: 21px 0 56px 0;
          width: 100%;
        }

        @media only screen and (max-width: 767px) {
          .cspl-local-nav__drawer-nav {
            flex: 0 0 auto;
          }
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__drawer-nav {
            max-height: calc(
              100% -
                (
                  0.5625 *
                    (
                      5 * var(--cs-column-tablet-portrait) + 6 *
                        var(--cs-gutter-tablet-portrait)
                    )
                )
            );
            padding-bottom: 64px;
            padding-top: 29px;
          }
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__drawer-nav {
            max-height: calc(
              100% -
                (
                  0.5625 *
                    (
                      4 * var(--cs-column-tablet-landscape) + 5 *
                        var(--cs-gutter-tablet-landscape)
                    )
                )
            );
            padding-bottom: 72px;
            padding-top: 37px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__drawer-nav {
            max-height: calc(
              100% -
                (
                  0.5625 *
                    (
                      4 * var(--cs-column-desktop) + 5 *
                        var(--cs-gutter-desktop)
                    )
                )
            );
            padding-bottom: 128px;
            padding-top: 93px;
          }
        }

        .cspl-local-nav__item-toggle {
          cursor: pointer;
          display: block;
          padding-bottom: 17px;
          padding-top: 18px;
          position: relative;
          text-transform: var(--cs-text-case, uppercase);
        }

        :host([theme='dark']) .cspl-local-nav__item-toggle {
          color: var(--cs-theme-local-nav-font-color-dark);
        }

        :host([theme='dark']) .cspl-local-nav__item-toggle:hover {
          color: var(--cs-dark-color);
        }

        :host([theme='light']) .cspl-local-nav__item-toggle {
          color: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='light']) .cspl-local-nav__item-toggle:hover {
          color: var(--cs-light-color);
        }

        :host([theme='brand']) .cspl-local-nav__item-toggle {
          color: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='brand']) .cspl-local-nav__item-toggle:hover {
          color: var(--cs-light-color);
        }

        .cspl-local-nav__drawer-nav ::slotted(.cspl-local-nav__drawer-link) {
          align-items: center;
          border-top-style: solid;
          border-top-width: 1px;
          display: flex;
          margin: 0 24px;
          padding-bottom: 17px;
          padding-top: 18px;
          text-decoration: none;
          text-transform: var(--cs-text-case, uppercase);
        }

        :host([theme='dark'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link) {
          border-top-color: #eaeaea;
          color: var(--cs-theme-local-nav-font-color-dark);
        }

        :host([theme='dark'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link:hover) {
          color: var(--cs-theme-local-nav-font-color-hover);
        }

        :host([theme='dark'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link[current]) {
          color: var(--cs-theme-local-nav-drawer-link-current-dark-color);
        }

        :host([theme='dark'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link:hover[current]) {
          color: var(--cs-theme-local-nav-drawer-link-current-hover-dark-color);
        }

        :host([theme='light'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link) {
          border-top-color: #585858;
          color: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='light'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link:hover) {
          color: var(--cs-theme-local-nav-font-color-hover);
        }

        :host([theme='light'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link[current]) {
          color: var(--cs-theme-local-nav-drawer-link-current-light-color);
        }

        :host([theme='light'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link:hover[current]) {
          color: var(
            --cs-theme-local-nav-drawer-link-current-hover-light-color
          );
        }

        :host([theme='brand'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link) {
          border-top-color: #585858;
          color: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='brand'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link:hover) {
          color: var(--cs-theme-local-nav-font-color-hover);
        }

        :host([theme='brand'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link[current]) {
          color: var(--cs-theme-local-nav-drawer-link-current-light-color);
        }

        :host([theme='brand'])
          .cspl-local-nav__drawer-nav
          ::slotted(.cspl-local-nav__drawer-link:hover[current]) {
          color: var(
            --cs-theme-local-nav-drawer-link-current-hover-light-color
          );
        }

        .cspl-local-nav__drawer-nav
          ::slotted
          .cspl-local-nav__drawer-item--first-child {
          border-top-width: 0;
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__drawer-nav ::slotted(.cspl-local-nav__drawer-link) {
            margin: 0 28px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__drawer-nav ::slotted(.cspl-local-nav__drawer-link) {
            margin: 0 32px;
          }
        }

        .cspl-local-nav__drawer-nav ::slotted(cs-local-nav-drawer-sub-tile) {
          display: block;
          margin-bottom: 20px;
        }

        .cspl-local-nav__featured-product {
          flex-basis: calc(
            0.5625 * (6 * var(--cs-column-mobile) + 5 * var(--cs-gutter-mobile))
          );
        }

        @media only screen and (min-width: 768px) {
          .cspl-local-nav__featured-product {
            flex-basis: calc(
              0.5625 *
                (
                  5 * var(--cs-column-tablet-portrait) + 6 *
                    var(--cs-gutter-tablet-portrait)
                )
            );
          }
        }

        @media only screen and (min-width: 1031px) {
          .cspl-local-nav__featured-product {
            flex-basis: calc(
              0.5625 *
                (
                  4 * var(--cs-column-tablet-landscape) + 5 *
                    var(--cs-gutter-tablet-landscape)
                )
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-local-nav__featured-product {
            flex-basis: calc(
              0.5625 *
                (4 * var(--cs-column-desktop) + 5 * var(--cs-gutter-desktop))
            );
          }
        }

        .cspl-local-nav__gamebox-grid {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 32px;
        }

        .cspl-local-nav__gamebox-grid ::slotted([slot='franchise-game-box']) {
          display: inline-block;
          margin-bottom: 32px;
          width: calc(50% - 16px);
        }

        .cspl-local-nav__gamebox-grid ::slotted(:first-child) {
          width: 100%;
        }

        .cspl-local-nav__franchise-drawer-links {
          padding-bottom: 25px;
        }

        .cspl-local-nav__franchise-drawer-links
          ::slotted([slot='franchise-drawer-link']) {
          font-weight: 700;
          margin-bottom: 30px;
          margin-top: 20px;
          text-transform: var(--cs-text-case, uppercase);
          width: 100%;
        }

        :host([theme='light'])
          .cspl-local-nav__franchise-drawer-links
          ::slotted([slot='franchise-drawer-link']) {
          color: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='brand'])
          .cspl-local-nav__franchise-drawer-links
          ::slotted([slot='franchise-drawer-link']) {
          color: var(--cs-theme-local-nav-font-color-light);
        }

        :host([theme='dark'])
          .cspl-local-nav__franchise-drawer-links
          ::slotted([slot='franchise-drawer-link']) {
          color: var(--cs-theme-local-nav-font-color-dark);
        }

        .cspl-local-nav__franchise-drawer-links
          ::slotted([slot='franchise-drawer-link']:hover) {
          color: var(--cs-theme-local-nav-font-color-hover);
        }
        mwc-linear-progress {
          --mdc-theme-primary: var(--cs-theme-secondary-color);
          --mdc-linear-progress-buffer-color: var(--cs-color-light-gray);
        }
      </style>
      <template is="dom-if" if="{{loading}}">
        <mwc-linear-progress indeterminate></mwc-linear-progress>
      </template>
      <div class="cspl-local-nav__shelf" on-click="_closeDrawer">
        <div class="cspl-local-nav__shelf-header">
          <template is="dom-if" if="{{ showFranchiseNav }}">
            <iron-icon
              icon="cs-action-16:ellipsis"
              class="cspl-local-nav__open-franchise"
              on-click="_openFranchiseDrawer"
            ></iron-icon>
          </template>
          <a
            class="cspl-local-nav__shelf-home-link"
            title="{{ homeTooltip }}"
            href="{{ homeLink }}"
            on-click="_goHome"
            >{{ homeText }}
            <img
              class="cspl-local-nav__shelf-logo"
              alt="{{ logoTooltip }}"
              src="{{ logoImage }}"
            />
          </a>
          <span
            on-click="_toggleDrawer"
            class="cspl-local-nav__open-link"
            title="{{ openDrawerTooltip }}"
            >{{ openDrawerText }}
            <svg
              class="cspl-local-nav__open-icon"
              x="0px"
              y="0px"
              viewBox="0 0 20 16"
              style="enable-background:new 0 0 20 16"
            >
              <path
                class="st0"
                d="M1,2h18c0.6,0,1-0.4,1-1s-0.4-1-1-1H1C0.4,0,0,0.4,0,1S0.4,2,1,2z M19,
                                   7H1C0.4,7,0,7.4,0,8s0.4,1,1,1h18 c0.6,0,1-0.4,1-1S19.6,7,19,7z M19,
                                   14H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h18c0.6,0,1-0.4,1-1S19.6,14,19,14z"
              ></path>
            </svg>
          </span>
          <div class="cspl-local-nav__shelf-nav">
            <slot name="local-nav-shelf-link"></slot>
          </div>
          <div class="cspl-local-nav__featured-links">
            <slot name="local-nav-featured-link"></slot>
          </div>
          <div
            class="cspl-local-nav__cta-dropdown-container"
            hidden$="[[ _hideCTADropdown ]]"
          >
            <slot name="cta-dropdown"></slot>
          </div>
        </div>
      </div>
      <div class="cspl-local-nav__drawer">
        <div class="cspl-local-nav__drawer-liner">
          <div class="cspl-local-nav__drawer-header">
            <a
              class="cspl-local-nav__drawer-home-link"
              title="{{ homeTooltip }}"
              href="{{ homeLink }}"
              on-click="_goHome"
              >{{ homeText }}
              <img
                class="cspl-local-nav__drawer-home-logo"
                alt="{{ logoTooltip }}"
                src="{{ logoImage }}"
              />
            </a>
            <span
              on-click="_toggleDrawer"
              class="cspl-local-nav__close-link"
              title="{{ closeDrawerTooltip }}"
              >{{ closeDrawerText }}
              <svg
                class="cspl-local-nav__close-icon"
                x="0px"
                y="0px"
                viewBox="0 0 16 16"
                style="enable-background:new 0 0 16 16"
              >
                <path
                  d="M13.4,11.9l-4-4l3.9-3.9c0.6-0.6,0.7-1.3,0.3-1.7c-0.4-0.4-1.2-0.3-1.7,0.3L8,
                                       6.6l-4-4 C3.5,2.1,2.7,1.9,2.3,2.3C1.9,2.7,2.1,3.5,2.6,4.1l4,4l-3.9,3.9c-0.6,
                                       0.6-0.7,1.3-0.3,1.7c0.4,0.4,1.2,0.3,1.7-0.3L8,9.4l4,4 c0.6,0.6,1.3,0.7,1.7,
                                       0.3C14.1,13.3,13.9,12.5,13.4,11.9z"
                ></path>
              </svg>
            </span>
          </div>
          <div class="cspl-local-nav__drawer-body">
            <div class="cspl-local-nav__drawer-nav">
              <slot name="local-nav-drawer-link"></slot>
            </div>
            <div
              hidden$="[[ hideFeaturedProduct ]]"
              class="cspl-local-nav__featured-product"
            >
              <slot name="container"></slot>
            </div>
          </div>
        </div>
      </div>
      <template is="dom-if" if="{{ showFranchiseNav }}">
        <div class="cspl-local-nav__franchise-drawer">
          <div class="cspl-local-nav__franchise-drawer-content">
            <div class="cspl-local-nav__franchise-drawer-navigation">
              <div>
                <template is="dom-if" if="{{ showBreadcrumbs}}">
                  <a
                    href="[[ franchiseLinkUrl ]]"
                    class="cspl-local-nav__franchise-drawer-navigation-link cspl-local-nav__franchise-drawer-navigation-link--main"
                    >[[ franchiseLinkText ]]</a
                  >
                  <template is="dom-if" if="[[ gameLinkText ]]">
                    <span>
                      <iron-icon
                        class="cspl-local-nav__franchise-drawer-navigation-icon"
                        icon="cs-action-16:chevron"
                      ></iron-icon>
                    </span>
                    <a
                      href="[[ gameLinkUrl ]]"
                      class="cspl-local-nav__franchise-drawer-navigation-link"
                      >[[ gameLinkText ]]</a
                    >
                  </template>
                </template>
              </div>
              <span
                on-click="_closeDrawer"
                class="cspl-local-nav__franchise-close-link"
                title="{{ closeDrawerTooltip }}"
                >{{ closeDrawerText }}
                <svg
                  class="cspl-local-nav__close-icon"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                  style="enable-background:new 0 0 16 16"
                >
                  <path
                    d="M13.4,11.9l-4-4l3.9-3.9c0.6-0.6,0.7-1.3,0.3-1.7c-0.4-0.4-1.2-0.3-1.7,
                                           0.3L8,6.6l-4-4 C3.5,2.1,2.7,1.9,2.3,2.3C1.9,2.7,2.1,3.5,2.6,4.1l4,
                                           4l-3.9,3.9c-0.6,0.6-0.7,1.3-0.3,1.7c0.4,0.4,1.2,0.3,1.7-0.3L8,9.4l4,
                                           4 c0.6,0.6,1.3,0.7,1.7,0.3C14.1,13.3,13.9,12.5,13.4,11.9z"
                  ></path>
                </svg>
              </span>
            </div>
            <div class="cspl-local-nav__gamebox-grid">
              <slot name="franchise-game-box"></slot>
            </div>
            <div class="cspl-local-nav__franchise-drawer-links">
              <slot name="franchise-drawer-link"></slot>
            </div>
          </div>
        </div>
      </template>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super(), (this._handleKeyUp = this._handleKeyUp.bind(this));
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  _openFranchiseDrawer(e) {
    e.stopPropagation(),
      (this.franchiseOpen = !0),
      this._stopBackgroundScrolling();
  }
  _openDrawer() {
    (this.open = !0), this._stopBackgroundScrolling();
  }
  _closeDrawer() {
    (this.open || this.franchiseOpen) &&
      ((this.open = !1),
      (this.franchiseOpen = !1),
      this._addScroll(),
      (p.style.width = 'auto'),
      (p.style.margin = '0'), //customizado
      (p.style.height = 'auto'));
  }
  _stopBackgroundScrolling() {
    this._preventScroll(), (p.style.width = '100%'), (p.style.height = '100%');
  }
  _toggleDrawer(e) {
    e.stopPropagation(), this.open ? this._closeDrawer() : this._openDrawer();
  }
  _propagateTileProperties(e) {
    this.querySelectorAll('cs-tile').forEach(a => {
      var t;
      for (t in e) e.hasOwnProperty(t) && (a[t] = e[t]);
    });
  }
  _resizeShelfHeader() {
    this._debouncer = Debouncer.debounce(
      this._debouncer,
      animationFrame,
      () => {
        var e = window.scrollY >= d;
        e && this.fixed !== this.detached
          ? ((this.fixed = this.detached),
            this.classList.add('cspl-local-nav-mini'),
            this._bindMiniStateToShelfDropdowns(!0))
          : !e &&
            this.fixed &&
            ((this.fixed = !1),
            this.classList.remove('cspl-local-nav-mini'),
            this._bindMiniStateToShelfDropdowns(!1));
      }
    );
  }
  _handleKeyUp(e) {
    e.keyCode === 27 && this._closeDrawer();
  }
  _observeTheme() {
    setTimeout(() => {
      this._propagateTileProperties({
        textColor: this.theme,
      });
    });
  }
  _observeMiniTrigger() {
    var e = this.shadowRoot
      .querySelector('.cspl-local-nav__shelf-header')
      .getBoundingClientRect();
    (d = e.height + e.top),
      this._resizeShelfHeader(),
      this.mini && !this.detached
        ? this.classList.add('cspl-local-nav-mini')
        : window.addEventListener('scroll', this._resizeShelfHeader.bind(this));
  }
  _bindMiniStateToShelfDropdowns(e) {
    this._shelfSubNavs.forEach(a => {
      a.mini = e;
    });
  }
  _bindDetachedStateToShelfDropdowns(e) {
    this._shelfSubNavs.forEach(a => {
      a.detached = e;
    });
  }
  _addFirstChildClass() {
    var e = this.querySelector(
      '.cspl-local-nav__drawer-link, cs-local-nav-drawer-sub-nav'
    );
    e && e.classList.add('cspl-local-nav__drawer-item--first-child');
  }
  _setupLinkGoogleAnalytics(e, a) {
    e.forEach(e => {
      (e.gaEventName = '/ga/nds/local-nav'),
        (e.gaEventPayload = {
          type: a,
          label: e.linkText,
        });
    });
  }
  _setShelfSubNavStatus(e) {
    this.shelfSubNavOpen = e.detail.open;
  }
  connectedCallback() {
    super.connectedCallback(),
      setTimeout(() => {
        this._propagateTileProperties({
          type: 'overlay',
          textColor: this.theme,
        });
      }),
      setTimeout(() => {
        this._hideCTADropdown = !this.querySelectorAll('[slot="cta-dropdown"]')
          .length;
      }),
      (p = document.querySelector('body')),
      this._addFirstChildClass(),
      (this._setShelfSubNavStatus = this._setShelfSubNavStatus.bind(this)),
      this._bindDetachedStateToShelfDropdowns(this.detached),
      window.addEventListener(
        'nds.shelfSubNav.open',
        this._setShelfSubNavStatus
      ),
      window.addEventListener('keyup', this._handleKeyUp);
  }
  ready() {
    super.ready(),
      setTimeout(() => {
        var e = FlattenedNodesObserver.getFlattenedNodes(this).filter(
            e => e.nodeType === Node.ELEMENT_NODE
          ),
          a = [];
        e
          .filter(e => 'cs-local-nav-drawer-sub-nav' === e.localName)
          .forEach(e => {
            Array.from(e.children).forEach(e => {
              'cs-link' === e.localName && a.push(e);
            });
          }),
          this._setupLinkGoogleAnalytics(
            e.filter(e => 'local-nav-shelf-link' === e.getAttribute('slot')),
            'shelf'
          ),
          this._setupLinkGoogleAnalytics(
            e.filter(e => e.classList.contains('cspl-local-nav__drawer-link')),
            'drawer'
          ),
          this._setupLinkGoogleAnalytics(a, 'drawer');
      });
  }
  disconnectedCallBack() {
    super.disconnectedCallback(),
      window.removeEventListener('keyup', this._handleKeyUp),
      window.removeEventListener(
        'nds.shelfSubNav.open',
        this._setShelfSubNavStatus
      );
  }
  _goHome() {
    location.href = this.homeLink;
    // window.history.pushState({}, null, this.homeLink);
    // window.dispatchEvent(new CustomEvent('location-changed'));
  }
}

customElements.define('cs-local-nav', CsLocalNav);

export default { CsLocalNav };
