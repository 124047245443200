import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsDateValidatorMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsDateValidatorMixin = base =>
  /**
   * @polymerMixinClass
   */
  class t extends base {
    static get properties() {
      return {};
    }
    isBetween(e, t, i) {
      return e >= t && e <= i;
    }
    _testDateValues(e, t, i) {
      var a = this.isBetween(e, 1, 31),
        n = this.isBetween(t, 1, 12),
        l = this.isBetween(i, 1900, 2100);
      return a && n && l;
    }
    _formatDateValues(e, t, i) {
      var a = ('0' + e).slice(-2),
        n = ('0' + t).slice(-2);
      return ''.concat(i, '-').concat(n, '-').concat(a);
    }
    _testDate(e, t, i) {
      var a = this._formatDateValues(e, t, i),
        n = new Date(a).toJSON();
      return (n = n ? n.substr(0, 10) : '') === a;
    }
    _validateDateRange(e, t, i, a) {
      if (!this._validateDate(e, t, i) || !a) return !1;
      var n = new Date(),
        l = new Date(i, t - 1, e);
      return n.setHours(0, 0, 0, 0), 'future' === a ? l >= n : l <= n;
    }
    _validateDateFieldValue(e, t) {
      if (!e) return !1;
      var { day: i, month: a, year: n } = e;
      return t
        ? this._validateDateRange(i, a, n, t)
        : this._validateDate(i, a, n);
    }
    _validateDate(e, t, i) {
      return this._testDateValues(e, t, i) && this._testDate(e, t, i);
    }
  };

export const CsDateValidatorMixin = dedupingMixin(
  internalCsDateValidatorMixin
);
