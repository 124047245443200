import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin.js';

/**
 * MixinNamePascelCase - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsPreventTouchScrollMixin = base =>
  /**
   * @polymerMixinClass
   */
  class extends base {
    _preventTouchScroll() {
      var L = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
        e =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : this;
      L &&
        e.addEventListener('touchmove', L => {
          L.preventDefault();
        });
    }
  };

export const CsPreventTouchScrollMixin = dedupingMixin(
  internalCsPreventTouchScrollMixin
);
