/* jshint ignore:start */
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

window.nds = window.nds || {};
const s = CsMixin;
const i = CsGoogleAnalyticsMixin;
const r = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  gplus: 'https://plus.google.com/share?url=',
  linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  reddit: 'http://www.reddit.com/submit?url=',
  twitter: 'https://twitter.com/share?url=',
  vk: 'https://vk.com/share.php?url=',
  whatsapp: 'https://api.whatsapp.com/send?text=',
};
const l = 600;
const c = 480;

/**
 * `cs-social-share` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSocialShare extends s(i(CsElements)) {
  static get properties() {
    return {
      theme: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      channel: {
        type: String,
        value: null,
        reflectToAttribute: !0,
      },
      shareUrl: {
        type: String,
        value: window.location.href,
      },
      backgroundColor: {
        type: String,
        observer: '_setBackgroundColorValue',
        reflectToAttribute: !0,
      },
      iconColor: {
        type: String,
        reflectToAttribute: !0,
      },
      tabIndex: {
        type: String,
        value: '0',
      },
    };
  }

  static get template() {
    return html`<style include="cs-social-button-shared-styles"></style
      ><style>
        :host {
          --copied-text-color: #c2c2c2;
          display: flex;
          flex-shrink: 0;
        }
        :host([theme='light']) .cspl-social-share__button {
          background: var(--cs-dark-color);
        }
        :host([theme='dark']) .cspl-social-share__button {
          background: var(--cs-light-color);
        }
        :host([theme='brand']) .cspl-social-share__button {
          background: var(--cs-theme-color);
        }
        :host([background-color]) .cspl-social-share__button {
          background: var(--background-color);
        }
        :host([theme='light']) .cspl-social-share__icon {
          color: var(--cs-light-color);
        }
        :host([theme='dark']) .cspl-social-share__icon {
          color: var(--cs-dark-color);
        }
        :host([theme='brand']) .cspl-social-share__icon {
          color: #fff;
        }
        :host([icon-color='dark']) .cspl-social-share__icon {
          color: var(--cs-dark-color);
        }
        :host([icon-color='light']) .cspl-social-share__icon {
          color: var(--cs-light-color);
        }
        :host([channel='gplus']) .cspl-social-share__icon {
          padding-top: 1px;
        }</style
      ><a
        class="cspl-social-share__button cspl-social-button"
        tabindex$="[[ tabIndex ]]"
        on-click="_share"
        ><iron-icon
          class="cspl-social-share__icon"
          icon="cs-action-16:[[ channel ]]"
        ></iron-icon
      ></a>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  _setBackgroundColorValue(e) {
    e &&
      this.updateStyles({
        '--background-color': '' + e,
      });
  }
  _share() {
    const e = r[this.channel];
    console.log(this.channel);
    if (
      (this.gaEvent('/ga/social/share', {
        network: this.channel,
      }),
      e)
    ) {
      const t = window.open(
        `${e}${encodeURIComponent(this.shareUrl)}`,
        'share',
        `height=${l},width=${c}`
      );
      return (t.opener = null), t;
    }
    return !1;
  }
  _handleKeyUp(e) {
    let { key: t } = e;
    'Enter' === t && this._share();
  }
  connectedCallback() {
    super.connectedCallback(),
      this.addEventListener('keyup', this._handleKeyUp);
  }
}

customElements.define('cs-social-share', CsSocialShare);
