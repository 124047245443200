import {
    PolymerElement,
    html
} from '@polymer/polymer/polymer-element.js';

/**
 * `app-collapse` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class AppCollapse extends PolymerElement {
    static get properties() {
        return {
            horizontal: {
                type: Boolean
            },
            opened: {
                type: Boolean,
                reflectToAttribute: true
            },
            noAnimation: {
                type: Boolean
            },
        };
    }

    static get template() {
        return html `<style>
        :host {
                display: block;
            }

        :host([opened]) #trigger {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        iron-collapse {
            /* border: 1px solid #dedede;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            @apply --shadow-elevation-2dp; */
        }
        </style>
        <iron-collapse id="collapse" opened="{{opened}}" horizontal="[[horizontal]]" no-animation="[[noAnimation]]" tabindex="0">
            <slot></slot>
        </iron-collapse>
        `;
    }

    /**
     * Instance of the element is created/upgraded. Use: initializing state,
     * set up event listeners, create shadow dom.
     * @constructor
     */
    constructor() {
        super();
    }

    /**
     * Use for one-time configuration of your component after local
     * DOM is initialized.
     */
    ready() {
        super.ready();
    }

    toggle() {
        this.$.collapse.toggle();
    }

    _getText(opened) {
        return opened ? 'Collapse' : 'Expand';
    }

}

customElements.define('app-collapse', AppCollapse);