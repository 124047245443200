import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

const e = window.nds.getSlottedChildren;
/**
 * CsMoreIndicatorParentMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsMoreIndicatorParentMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                _hideMoreIndicators: {
                    type: Boolean,
                    value: !0,
                    computed: "_computeHideMoreIndicators(_moreIndicators)"
                },
                _moreIndicators: {
                    type: Array,
                    value() {
                        return [];
                    }
                }
            };
        }
        _computeHideMoreIndicators(e) {
            return !e.length;
        }
        _getMoreIndicators() {
            this._moreIndicators = e(this, ["more-indicator"]);
        }
        connectedCallback() {
            super.connectedCallback(), this._getMoreIndicators(), this.shadowRoot.querySelector("slot[name=\"more-indicator\"]").addEventListener("slotchange", this._getMoreIndicators); // jshint ignore:line
        }
    };

export const CsMoreIndicatorParentMixin = dedupingMixin(internalCsMoreIndicatorParentMixin);