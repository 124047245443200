/* jshint ignore:start */
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';

/**
 * `cs-tab` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsTab extends CsElements {
  static get properties() {
    return {
      disabled: {
        type: Boolean,
        reflectToAttribute: !0,
        value: !1,
      },
      url: String,
    };
  }

  static get template() {
    return html` <style
        include="cs-shared-styles-typography-paragraphs"
      ></style>
      <style include="cs-shared-styles-base-common"></style>
      <style>
        :host {
          color: inherit;
          cursor: pointer;
          height: 50px;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          text-transform: var(--cs-theme-tabs-tab-text-transform);
        }
        @media (min-width: 768px) {
          :host {
            height: 60px;
          }
        }
        :host(:focus) {
          outline: none;
        }
        :host([disabled]) {
          color: #eaeaea;
          cursor: not-allowed;
        } /* stylelint-disable order/properties-alphabetical-order */ /* border-color needs to come after border-left, border-right */
        .content {
          align-items: center;
          border-left: 1px solid;
          border-right: 1px solid;
          border-color: inherit;
          display: flex;
          justify-content: center;
          margin: 10px 0;
          padding: 0 15px;
          position: relative;
          white-space: nowrap;
        } /* stylelint-enable */
        :host(:first-of-type) .content {
          border-left: none;
        }
        :host(:last-of-type) .content {
          border-right: none;
        }
        @media (min-width: 768px) {
          .content {
            margin: 15px 0;
          }
        }
        @media (min-width: 768px) {
          .content::after {
            content: '';
          }
        }
        .content ::slotted(a) {
          border: inherit;
          color: inherit;
          cursor: inherit;
          font: inherit;
          outline: inherit;
          text-decoration: inherit;
        }
        .indicator {
          background-color: #fff;
          display: block;
          height: 2px;
          margin: 0 auto;
          transition: width 0.1s ease-out;
          width: 0;
        } /* dark variant */
        :host([theme='dark']) .indicator {
          background-color: var(--cs-theme-color);
        } /* custom variant */
        :host([theme='custom']) .indicator {
          background-color: var(--cs-theme-tabs-indicator-color);
        }
        :host(.iron-selected) .indicator {
          width: 80%;
        }
      </style>
      <div class="content b2"><slot></slot></div>
      <div class="indicator"></div>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _onClick(t) {
    var { _anchor: e, disabled: n } = this;
    n
      ? t.preventDefault()
      : (e && t.target !== e && e.click(),
        (this.gaEventName = '/ga/navigation/tab'),
        (this.gaEventPayload = {
          title: this.url,
        }),
        this.gaEvent(this.gaEventName, this.gaEventPayload),
        this._changeLocation(this.url));
  }
  _changeLocation(t) {
    t &&
      0 !== t.length &&
      (t.startsWith('#')
        ? ((window.location.hash = t),
          this.dispatchEvent(
            new CustomEvent('tab-change', {
              bubbles: !0,
              composed: !0,
              detail: {
                tabId: t.split('#')[1],
              },
            })
          ))
        : (window.location = t));
  }
  connectedCallback() {
    super.connectedCallback(),
      this.setAttribute('role', 'tab'),
      this.addEventListener('click', this._onClick),
      setTimeout(() => {
        (this._anchor = this.querySelector('a')),
          this._anchor && this._anchor.setAttribute('tabindex', -1);
      });
  }
}

customElements.define('cs-tab', CsTab);
