import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsCurrentLinkMixin } from "./mixins/cs-current-link-mixin.js";
import { CsGoogleAnalyticsMixin } from "./mixins/cs-google-analytics-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";

const t = CsMixin,
	a = CsCurrentLinkMixin,
	r = CsGoogleAnalyticsMixin;

/**
 * `cs-local-link` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLocalLink extends t(a(r(PolymerElement))) {
	static get properties() {
		return {
			linkUrl: String,
			linkTitle: String,
			linkText: {
				type: String,
				observer: "_observeLinkText",
			},
			textColor: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-links"></style>
			<style>
				:host {
					display: block;
					margin: 6px 0 5px;
					width: 50%;
				}

				:host(:last-child) {
					margin-right: 0;
				}

				@media only screen and (min-width: 768px) {
					:host {
						margin-right: 32px;
						margin-top: 8px;
						width: auto;
					}
				}

				@media only screen and (min-width: 1024px) {
					:host {
						margin-right: 40px;
					}
				}

				@media only screen and (min-width: 1456px) {
					:host {
						font-size: 18px;
						margin-right: 48px;
					}
				}

				a {
					font-family: var(--cs-theme-primary-font-family);
					font-size: var(--cs-theme-b2-font-size-mobile);
					line-height: var(--cs-theme-b2-line-height-mobile);
					text-decoration: none;
				}

				a {
					color: #161616;
				}

				:host([text-color="light"]) a {
					color: #a3a3a3;
				}

				:host([text-color="brand"]) a {
					color: var(--cs-light-color);
				}

				:host([text-color="light"]) a:hover,
				a:hover {
					color: #f7f7f7;
					text-decoration: none;
				}
			</style>
			<a href$="[[ linkUrl ]]" title$="[[ linkTitle ]]" inner-h-t-m-l="[[ linkText ]]"></a> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_observeLinkText(e) {
		(this.gaEventName = "/ga/navigation/menu"),
			(this.gaEventPayload = {
				position: "footer",
				level: "parent",
				label: e,
			}); // jshint ignore:line
	}
}

customElements.define("cs-local-link", CsLocalLink);
