import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsModalInvokerMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsModalInvokerMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                modalId: String
            };
        }
        _handleInvokerTap(e) {
            e.preventDefault(), document.dispatchEvent(new CustomEvent("nds.modal.open", {
                bubbles: !0,
                composed: !0,
                detail: {
                    modalId: this.modalId
                }
            })); // jshint ignore:line
        }
        connectedCallback() {
            super.connectedCallback(), this.modalId && this.addEventListener("click", this._handleInvokerTap); // jshint ignore:line
        }
    };

export const CsModalInvokerMixin = dedupingMixin(internalCsModalInvokerMixin);