import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";
import { afterNextRender } from "@polymer/polymer/lib/utils/render-status.js";

/**
 * `cs-order-items` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsOrderItems extends PolymerElement {
	static get properties() {
		return {
			data: Object,
		};
	}

	static get template() {
		return html`
			<style>
				:host {
					display: block;
				}
				.card {
					@apply --layout-horizontal;
					position: relative;
					margin: 5px 0;
					padding: 8px 0;
				}

				.destak {
					color: var(--cs-theme-color);
				}

				#collapse {
					border-bottom: 1px solid var(--cs-color-light-gray);
				}

				.column input-row {
					line-height: 24px;
				}

				shop-image {
					width: 80px;
					height: 80px;
				}
				shop-select > select {
					font-size: 16px;
					padding-left: 40px;
				}
				shop-select > shop-md-decorator {
					font-size: 12px;
					border: none;
				}
				.name {
					@apply --layout-flex-auto;
					line-height: 20px;
					font-weight: 500;
					float: left;
					width: calc(100% - 438px);
					margin-top: 8px;
					margin-right: 30px;
				}
				.name a {
					display: inline-block;
					max-width: 100%;
					text-decoration: none;
					color: var(--app-primary-color);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.price,
				.size {
					display: inline-block;
					white-space: nowrap;
				}
				.size {
					min-width: 70px;
					width: 144px;
				}
				.size > span {
					margin-left: 10px;
				}
				.price {
					text-align: right;
					min-width: 70px;
					width: 100px;
				}
				.quantity {
					min-width: 80px;
					width: 100px;
				}
				.delete-button {
					width: 34px;
					height: 34px;
					color: var(--app-secondary-color);
					position: absolute;
					top: 18px;
					right: 0;
				}
				.value {
					font-weight: 300;
				}
				.flex {
					@apply --layout-horizontal;
					@apply --layout-flex-auto;
					margin-left: 24px;
				}
				.detail {
					@apply --layout-horizontal;
					@apply --layout-center;
					margin-top: 8px;
					margin-right: 30px;
					height: 20px;
				}
				.recurrency {
					border-left: 5px solid var(--cs-theme-color);
				}
				.no-recurrency {
					border-left: 5px solid var(--cs-dark-color);
				}

				@media (max-width: 767px) {
					.flex {
						@apply --layout-vertical;
						margin-left: 10px;
					}
					.limited {
						max-width: 200px;
					}
					.name {
						margin-top: 16px;
						margin-right: 0;
						width: calc(100% - 40px);
					}
					.detail {
						@apply --layout-self-end;
						margin: 0 10px 0 0;
					}
					.quantity,
					.size,
					.price {
						padding-right: 30px;
						text-align: right;
						width: auto;
					}
					.delete-button {
						top: 8px;
					}
				}
				@media (max-width: 360px) {
					.name {
						margin-top: 0;
					}
					.total {
						display: none;
					}
					.limited {
						max-width: 200px;
					}
					.detail {
						@apply --layout-vertical;
						@apply --layout-start;
						@apply --layout-self-start;
						height: auto;
						margin-top: 0;
					}
					.delete-button {
						top: -6px;
					}
					shop-select > select {
						padding: 2px 24px 2px 40px;
					}
					.quantity,
					.size,
					.price {
						text-align: right;
						width: auto;
					}
				}
			</style>
			<div on-click="toggle">
				<div class="card">
					<shop-image id="image" src="[[data.image]]" alt="[[data.product.name]]"></shop-image>
					<div class="flex">
						<div class="name">
							<div class="column input-row">
								<div class="title">[[data.parents.name]]</div>
								<div class="title limited">[[data.quantity]] - [[data.name]]</div>
								<div class="value destak">[[data.recurrency]]</div>
								<div class="value" hidden$="{{!data.product.description}}">Detalhes <iron-icon icon="icons:expand-more"></iron-icon></div>
							</div>
						</div>
						<div class="detail">
							<div class="price total">[[numberFormat(data.salePrice)]]</div>
						</div>
					</div>
				</div>
			</div>
			<app-collapse id="collapse">
				<div class="content" inner-h-t-m-l="{{data.product.description}}"></div>
			</app-collapse>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
		afterNextRender(this, function () {
			if (this.data != undefined && this.data.recurrency != "") {
				this.$.image.setAttribute("class", "recurrency");
			} else {
				this.$.image.setAttribute("class", "no-recurrency");
			}
		});
	}

	numberFormat(number) {
		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
		}).format(number);
	}

	toggle() {
		this.$.collapse.toggle();
	}
}

customElements.define("cs-order-items", CsOrderItems);
