import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsControlPlayerMixin } from './mixins/cs-control-player-mixin.js';
import { CsHasImageMixin } from './mixins/cs-has-image-mixin.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsIframeMixin } from './mixins/cs-iframe-mixin.js';

window.nds = window.nds || {};

const r = CsMixin;
const l = CsDeviceMixin;
const c = CsControlPlayerMixin;
const d = CsHasImageMixin;
const u = CsGoogleAnalyticsMixin;
const p = CsIframeMixin;

/**
 * `cs-youtube` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsYoutube extends r(l(c(d(u(p(CsElements)))))) {
  static get properties() {
    return {
      _eventData: {
        computed: '_getEventData(videoId)',
        type: Object,
      },
      _playerUId: {
        computed: '_getPlayerUId(videoId)',
        type: String,
      },
      _player: Object,
      _playerVars: {
        computed:
          '_getPlayerVars(' +
          [
            'autoplay',
            'controls',
            'loop',
            'rel',
            'annotations',
            'playlist',
            'videoId',
            'allowfullscreen',
            'hideInfo',
            'playsinline',
          ].join(',') +
          ')',
        type: String,
      },
      _isYtApiReadyTimeoutId: Number,
      _isYtApiReadyTimeoutDelay: {
        type: Number,
        value: 100,
      },
      _ytApiUrl: {
        type: String,
        value: 'https://www.youtube.com/iframe_api',
      },
      _videoTrackingTimeoutId: Number,
      _videoTrackingTimeoutDelay: {
        type: Number,
        value: 1e3,
      },
      _videoWasPaused: Boolean,
      _playIntent: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      theme: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      iconTheme: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      isAndroid: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      showLoading: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      controls: {
        type: Number,
        value: 1,
      },
      loop: {
        type: Number,
        value: 0,
      },
      playlist: String,
      rel: {
        type: Number,
        value: 0,
      },
      annotations: {
        type: Number,
        value: 1,
      },
      videoId: String,
      hideInfo: {
        type: Number,
        value: 0,
      },
      playsinline: {
        type: Number,
        value: 0,
      },
      widget: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      buttonColor: {
        type: String,
        value: 'light',
      },
      defaultAgeGate: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      ageGate: {
        type: Boolean,
        value: !1,
      },
      ageGateAlignment: {
        type: String,
        value: 'center-left',
        reflectToAttribute: !0,
      },
      ageGateMessage: {
        type: String,
        value: 'This video is age-restricted and only available on YouTube',
      },
      moreLinkText: {
        type: String,
        value: 'Learn More',
      },
      moreLinkUrl: String,
      watchLinkText: {
        type: String,
        value: 'Watch On YouTube',
      },
      _videoUrl: {
        type: String,
        computed: '_computeVideoUrl(videoId)',
      },
    };
  }

  static get template() {
    return html`<style include="cs-media-player-styles"></style>
    <style include="cs-youtube-styles"></style>
    <style>
      :host {
        max-height: 100vh
      }

      :host picture {
        display: block
      }

      :host([age-gate]) {
        max-height: unset
      }

      :host([is-touch][age-gate]) .cspl-media-player__cover {
        display: flex
      }

      iframe {
        height: 100%;
        left: 0;
        max-height: 100vh;
        position: absolute !important;
        top: 0;
        width: 100%
      }

      .cspl-media-player__video-overlay {
        background: #313131;
        border-radius: 50%;
        display: none;
        height: 72px;
        left: 50%;
        margin-left: -36px;
        margin-top: -48px;
        position: absolute;
        top: 50%;
        width: 72px;
        z-index: 10
      }

      .cspl-media-player__video-overlay .cspl-media-player__cover-icon-spinner,
      :host([is-android][show-loading]) .cspl-media-player__video-overlay {
        display: block
      }

      .cspl-media-player__age-gate-container {
        align-items: center;
        background-color: var(--cs-theme-age-gate-background-image-color-dark);
        box-sizing: border-box;
        color: var(--cs-theme-age-gate-subheading-color-light);
        display: flex;
        justify-content: center;
        left: 0;
        min-height: 100%;
        overflow: hidden;
        padding: 40px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10
      }

      @media screen and (max-width:767px) {
        :host([age-gate]) .cspl-media-player__ratio {
          display: flex;
          padding: unset
        }

        :host([age-gate]) .cspl-media-player__ratio::before {
          content: " ";
          float: left;
          padding-top: 56.25%
        }

        :host([age-gate]) .cspl-media-player__age-gate-container {
          position: unset
        }
      }

      :host([age-gate][widget]) cs-cta[type=standard] {
        margin-bottom: 16px
      }

      @media only screen and (min-width:768px) {
        :host([age-gate-alignment=center][age-gate][widget][is-desktop]) .cspl-media-player__ratio {
          padding-top: 208px
        }

        :host([age-gate-alignment=center][age-gate][widget][is-desktop]) .cspl-media-player__age-gate-information-more-link {
          margin-bottom: 15px
        }
      }

      :host([widget]) .cspl-media-player__age-gate-container {
        padding: 10px
      }

      :host([theme=brand]) .cspl-media-player__age-gate-container,
      :host([theme=dark]) .cspl-media-player__age-gate-container {
        background-color: var(--cs-theme-age-gate-background-image-color-light);
        color: var(--cs-theme-age-gate-subheading-color-dark)
      }

      :host([_has-cover-image]:not([default-age-gate])) .cspl-media-player__age-gate-container {
        background-color: unset
      }

      .cspl-media-player__age-gate-content {
        display: flex
      }

      :host([age-gate-alignment=center]) .cspl-media-player__age-gate-content {
        align-items: center;
        flex-direction: column
      }

      :host([age-gate-alignment=center]) .cspl-media-player__age-gate-content-left,
      :host([age-gate-alignment=center][age-gate][widget]) .cspl-media-player__age-gate-content-left {
        margin-bottom: 12px
      }

      :host([age-gate-alignment=center]) .cspl-media-player__age-gate-content-right {
        text-align: center
      }

      :host([age-gate-alignment=center-right]) .cspl-media-player__age-gate-content-right {
        text-align: right
      }

      :host([rtl][age-gate-alignment=center-right]) .cspl-media-player__age-gate-content-right {
        text-align: left
      }

      :host([age-gate-alignment=center-right]) .cspl-media-player__age-gate-content {
        flex-direction: row-reverse
      }

      .cspl-media-player__age-gate-information {
        align-items: center;
        display: flex
      }

      .cspl-media-player__age-gate-information-icon {
        fill: var(--cs-theme-age-gate-info-icon-color-light);
        stroke: var(--cs-theme-age-gate-info-icon-color-light)
      }

      :host([icon-theme=dark]) .cspl-media-player__age-gate-information-icon {
        fill: var(--cs-theme-age-gate-info-icon-color-dark);
        stroke: var(--cs-theme-age-gate-info-icon-color-dark)
      }

      :host([icon-theme=brand]) .cspl-media-player__age-gate-information-icon {
        fill: var(--cs-theme-age-gate-info-icon-color-brand);
        stroke: var(--cs-theme-age-gate-info-icon-color-brand)
      }

      .cspl-media-player__age-gate-information-icon,
      :host([rtl][age-gate-alignment=center-right]) .cspl-media-player__age-gate-information-icon {
        height: 42px;
        margin-left: unset;
        margin-right: 12px;
        width: 42px
      }

      :host([age-gate-alignment=center-right]) .cspl-media-player__age-gate-information-icon,
      :host([rtl]) .cspl-media-player__age-gate-information-icon {
        margin-left: 12px;
        margin-right: unset
      }

      .cspl-media-player__age-gate-information-more-link {
        width: max-content
      }

      .cspl-media-player__age-gate-information-more-link,
      .cspl-media-player__age-gate-information-text-container {
        font-family: var(--cs-theme-secondary-font-family);
        font-size: var(--cs-theme-l2-font-size-mobile);
        font-weight: var(--cs-theme-l2-font-weight);
        line-height: var(--cs-theme-l2-line-height-mobile);
        max-width: 231px
      }

      .cspl-media-player__age-gate-information-text-container div {
        display: inline-flex
      }

      .cspl-media-player__age-gate-information-more-link {
        margin-bottom: 20px
      }

      .cspl-media-player__video-logo-icon,
      :host([widget]) .cspl-media-player__video-logo-icon {
        bottom: 8px;
        height: 24px;
        position: absolute;
        right: 14px;
        width: 24px;
        z-index: 10
      }

      :host([rtl]) .cspl-media-player__video-logo-icon {
        left: 14px;
        right: unset
      }

      .cspl-media-player__age-gate-video-watch-link {
        font-size: var(--cs-theme-cta-standard-small-font-size-mobile);
        line-height: var(--cs-theme-cta-standard-small-line-height-mobile)
      }

      @media only screen and (min-width:768px) {
        .cspl-media-player__age-gate-video-watch-link {
          font-size: var(--cs-theme-cta-standard-small-font-size-tablet);
          line-height: var(--cs-theme-cta-standard-small-line-height-tablet)
        }
      }

      @media only screen and (min-width:1456px) {
        .cspl-media-player__age-gate-video-watch-link {
          font-size: var(--cs-theme-cta-standard-small-font-size-desktop);
          line-height: var(--cs-theme-cta-standard-small-line-height-desktop)
        }
      }

      :host([age-gate-alignment=center]) .cspl-media-player__age-gate-information-icon {
        margin-left: 0;
        margin-right: 0
      }

      @media (min-width:768px) {
        :host([age-gate-alignment=center]) .cspl-media-player__age-gate-content-left {
          margin-bottom: 42px
        }

        .cspl-media-player__age-gate-information-icon,
        :host([rtl][age-gate-alignment=center-right]) .cspl-media-player__age-gate-information-icon {
          margin-left: unset;
          margin-right: 32px
        }

        :host([age-gate-alignment=center-right]) .cspl-media-player__age-gate-information-icon,
        :host([rtl]) .cspl-media-player__age-gate-information-icon {
          margin-left: 32px;
          margin-right: unset
        }

        .cspl-media-player__video-logo-icon {
          bottom: 28px;
          cursor: pointer;
          height: 74px;
          right: 38px;
          width: 74px
        }

        :host([rtl]) .cspl-media-player__video-logo-icon {
          left: 38px;
          right: unset
        }

        .cspl-media-player__age-gate-information-more-link,
        .cspl-media-player__age-gate-information-text-container {
          font-size: var(--cs-theme-l2-font-size-tablet-landscape);
          line-height: var(--cs-theme-l2-line-height-tablet-landscape);
          max-width: 527px
        }

        .cspl-media-player__age-gate-information-text-container div {
          display: block
        }
      }

      @media (min-width:1024px) {

        .cspl-media-player__age-gate-information-more-link,
        .cspl-media-player__age-gate-information-text-container {
          font-size: var(--cs-theme-l2-font-size-desktop);
          line-height: var(--cs-theme-l2-line-height-desktop)
        }
      }
    </style>
    <div class="cspl-media-player__ratio">
      <template is="dom-if" if="[[ ageGate ]]">
        <div class="cspl-media-player__age-gate-container">
          <div class="cspl-media-player__age-gate-content">
            <div class="cspl-media-player__age-gate-content-left">
              <iron-icon class="cspl-media-player__age-gate-information-icon" icon="cs-action-20:circle-i-dashed"></iron-icon>
            </div>
            <div class="cspl-media-player__age-gate-content-right">
              <div class="cspl-media-player__age-gate-information">
                <div class="cspl-media-player__age-gate-information-text-container">
                  <div>[[ ageGateMessage ]]</div>
                  <div>
                    <cs-cta class="cspl-media-player__age-gate-information-more-link" type="text" target="_blank" text-color="[[ _setAgeGateTextColor() ]]" link-text="[[ moreLinkText ]]" link-url="[[ moreLinkUrl ]]"></cs-cta>
                  </div>
                </div>
              </div>
              <cs-cta class="cspl-media-player__age-gate-video-watch-link" type="standard" target="_blank" text-color="[[ _setAgeGateTextColor() ]]" link-text="[[ watchLinkText ]]" link-url="[[ _videoUrl ]]"></cs-cta>
            </div>
          </div>
          <a href="https://www.youtube.com">
            <iron-icon class="cspl-media-player__video-logo-icon" icon="cs-action-192:youtube-[[ theme ]]"></iron-icon>
          </a>
        </div>
      </template>
      <template is="dom-if" if="[[ _hasCoverImage ]]">
        <div class="cspl-media-player__cover" on-click="play">
          <cs-lazy-picture>
            <picture>
              <source media="(max-width: 320px)" data-srcset$="[[ coverImageSmallest ]]">
              <source media="(max-width: 767px)" data-srcset$="[[ coverImageSmall ]]">
              <source media="(max-width: 1023px)" data-srcset$="[[ coverImageMedium ]]">
              <source media="(max-width: 1455px)" data-srcset$="[[ coverImageLarge ]]">
              <source media="(min-width: 1456px)" data-srcset$="[[ coverImageLargest ]]">
              <img class="cspl-media-player__cover-image" data-src$="[[ coverImageSmallest ]]" alt$="[[ coverImageTooltip ]]">
            </picture>
          </cs-lazy-picture>
          <template is="dom-if" if="[[ !ageGate ]]">
            <div class="cspl-media-player__cover-icon">
              <iron-icon class="cspl-media-player__cover-icon-play" icon$="cs-action-192:youtube-[[ buttonColor ]]" role="button" tabindex="0" on-keydown="_handlePlayKeyDown"></iron-icon>
              <iron-icon class="cspl-media-player__cover-icon-spinner" icon="cs-action-24:loading"></iron-icon>
            </div>
          </template>
        </div>
      </template>
      <div class="cspl-media-player__video-container cspl-media-player__wrapper" hidden$="[[ ageGate ]]">
        <div id$="[[ _playerUId ]]"></div>
        <div class="cspl-media-player__cover" on-click="play">
          <cs-lazy-picture>
            <picture>
              <source media="(max-width: 320px)" data-srcset$="[[ coverImageSmallest ]]">
              <source media="(max-width: 767px)" data-srcset$="[[ coverImageSmall ]]">
              <source media="(max-width: 1023px)" data-srcset$="[[ coverImageMedium ]]">
              <source media="(max-width: 1455px)" data-srcset$="[[ coverImageLarge ]]">
              <source media="(min-width: 1456px)" data-srcset$="[[ coverImageLargest ]]">
              <img class="cspl-media-player__cover-image" data-src$="[[ coverImageSmallest ]]" alt$="[[ coverImageTooltip ]]">
            </picture>
          </cs-lazy-picture>
          <template is="dom-if" if="[[ !ageGate ]]">
            <div class="cspl-media-player__cover-icon">
              <iron-icon class="cspl-media-player__cover-icon-play" icon$="cs-action-192:youtube-[[ buttonColor ]]" role="button" tabindex="0" on-keydown="_handlePlayKeyDown"></iron-icon>
              <iron-icon class="cspl-media-player__cover-icon-spinner" icon="cs-action-24:loading"></iron-icon>
            </div>
          </template>
        </div>
      </div>
    </div>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _setAgeGateTextColor() {
    return 'brand' === this.theme ? 'dark' : this.theme;
  }
  _getPlayerUId(e) {
    return `yt-${e}-${Math.floor(9e3 * Math.random()) + 1e3}`;
  }
  _buildYTPlayer(e) {
    if (!this._player || 'function' != typeof this._player.playVideo) {
      const t = this.shadowRoot.querySelector('#' + this._playerUId);
      this._player = new e.Player(t, {
        height: '100%',
        width: '100%',
        videoId: this.videoId,
        playerVars: this._playerVars,
        events: {
          onReady: this._setupHandleReady.bind(this),
          onStateChange: this._handleVendorEvents.bind(this),
        },
      });
    }
  }
  _setupHandleReady(e) {
    (this._player = e.target),
      this._player.setLoop(1 === this.loop),
      this._handleVendorEvents({
        data: 'ready',
      });
  }
  _isYTLoaded() {
    return 'undefined' != typeof YT && YT.Player;
  }
  _isYTLoading() {
    return 'undefined' != typeof YT && YT.loading;
  }
  _initYTLoad() {
    this._isYTLoaded()
      ? this._buildYTPlayer(YT)
      : this._isYTLoading()
      ? this._isYTApiReady()
      : this._loadYTApi(),
      this.autoplay && (this._playIntent = !0);
  }
  _loadYTApi() {
    if (!window.nds.EaYoutubeAPILoading) {
      const e = document.createElement('script'),
        t = document.getElementsByTagName('script')[0];
      (window.nds.EaYoutubeAPILoading = 1),
        (e.src = this._ytApiUrl),
        t.parentNode.insertBefore(e, t);
    }
    this._isYTApiReady();
  }
  _isYTApiReady() {
    clearTimeout(this._isYtApiReadyTimeoutId),
      (this._isYtApiReadyTimeoutId = setTimeout(() => {
        this._isYTLoaded() ? this._buildYTPlayer(YT) : this._isYTApiReady();
      }, this._isYtApiReadyTimeoutDelay));
  }
  _getPlayerVars(e, t, a, i, o, n, r, l, s, d) {
    const p = {
      autoplay: e,
      controls: t,
      html5: 1,
      loop: a,
      origin: '*',
      rel: i,
      iv_load_policy: o,
      fs: l ? 1 : 0,
      showinfo: s ? 0 : 1,
      playsinline: d,
    };
    return (
      n &&
        1 === n.split(',').length &&
        ((p.list = n), (p.listType = 'playlist')),
      n && n.split(',').length > 1 && (p.playlist = n),
      !n && a && (p.playlist = r),
      p
    );
  }
  _getEventData(e) {
    return {
      video: e,
    };
  }
  _handleVendorEvents(e) {
    let t = '',
      a = '';
    switch (e.data) {
      case 5:
        (a = this._eventNames.READY), (t = this._eventNames.STOPPED);
        break;
      case -1:
        this.autoplay ? (this._playIntent = !1) : (this.showLoading = !0);
        break;
      case 0:
        (a = this._eventNames.FINISHED),
          (t = this._eventNames.FINISHED),
          (this._videoWasPaused = !1),
          clearTimeout(this._videoTrackingTimeoutId);
        break;
      case 1:
        (t = a = this._eventNames.PLAYING),
          (this._playIntent = !1),
          this._initTracking(),
          (this.showLoading = !1);
        break;
      case 2:
        (t = a = this._eventNames.PAUSED),
          (this._videoWasPaused = !0),
          clearTimeout(this._videoTrackingTimeoutId);
        break;
      case 'ready':
        (t = a = this._eventNames.READY),
          this.autoplay &&
            ((this.showLoading = !0),
            setTimeout(() => {
              this.showLoading = !1;
            }, 500));
    }
    a &&
      t &&
      a !== this.status &&
      (this._setStatus(a),
      this.dispatchEvent(
        new CustomEvent(t, {
          bubbles: !0,
          composed: !0,
          detail: this._eventData,
        })
      ),
      this._playIntent && 'ready' === a && this.play());
  }
  _initTracking() {
    if (this._videoWasPaused) {
      const e = this._videoTrackingTimeoutDelay;
      this._trackVideoProgress(e - ((1e3 * this._player.getCurrentTime()) % e));
    } else
      this.gaEvent('/ga/video/start', {
        name: this.videoId,
        provider: 'YT',
      }),
        (this._videoTrackingTimeoutDelay = (this.getDuration() / 10) * 1e3),
        this._trackVideoProgress();
  }
  _trackVideoProgress() {
    let e =
      arguments.length > 0 && void 0 !== arguments[0]
        ? arguments[0]
        : this._videoTrackingTimeoutDelay;
    clearTimeout(this._videoTrackingTimeoutId),
      (this._videoTrackingTimeoutId = setTimeout(() => {
        const e = (this._player.getCurrentTime() / this.getDuration()) * 100,
          t = 10 * Math.round(e / 10);
        this.gaEvent('/ga/video/range', {
          name: this.videoId,
          range: t,
          provider: 'YT',
        }),
          this._trackVideoProgress();
      }, e));
  }
  getDuration() {
    return this._player.getDuration();
  }
  play() {
    this.ageGate ||
      ('loading' !== this.status && this._player.playVideo(),
      (this._playIntent = !0));
  }
  pause() {
    this._player &&
      'function' == typeof this._player.getPlayerState &&
      this._player.getPlayerState() !== YT.PlayerState.PAUSED &&
      (this._player.pauseVideo(), (this._playIntent = !1));
  }
  stop() {
    this._player.stopVideo();
  }
  _lazyLoadYoutube() {
    const e = this.shadowRoot.querySelector('.cspl-media-player__ratio'),
      t = new IntersectionObserver(
        e => {
          e.forEach(e => {
            if (e.isIntersecting || e.intersectionRatio > 0) {
              this._initYTLoad();
              const a = this.shadowRoot.querySelector('picture img');
              a && a.removeAttribute('loading'), t.unobserve(e.target);
            }
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        }
      );
    t.observe(e);
  }
  _computeVideoUrl(e) {
    return 'https://www.youtube.com/watch?v=' + e;
  }
  connectedCallback() {
    super.connectedCallback(),
      (this.isAndroid = /Android/g.test(navigator.userAgent)),
      (this._videoOverlay = this.shadowRoot.querySelector(
        '.cspl-media-player__video-overlay'
      )),
      'function' == typeof IntersectionObserver
        ? this._lazyLoadYoutube()
        : this._initYTLoad();
  }
  disconnectedCallback() {
    super.disconnectedCallback(),
      clearTimeout(this._isYtApiReadyTimeoutId),
      clearTimeout(this._videoTrackingTimeoutId);
  }
}

customElements.define('cs-youtube', CsYoutube);
