/* jshint ignore:start */
import { html } from "@polymer/polymer/polymer-element.js";
import { CsElements } from "./cs-elements.js";

/**
 * `cs-cta-tile` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsCtaTile extends CsElements {
	static get properties() {
		return {
			backgroundColor: {
				type: String,
				reflectToAttribute: !0,
				value: "brand",
			},
			type: {
				type: String,
				reflectToAttribute: !0,
				value: "filled",
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style>
				:host {
					border-radius: 3%;
					display: block;
					padding-bottom: 100%;
					position: relative;
					width: 100%;
				}
				:host([type="filled"][background-color="brand"]) {
					background-color: var(--cs-theme-color);
				}
				:host([type="filled"][background-color="dark"]) {
					background-color: var(--cs-dark-color);
				}
				:host([type="filled"][background-color="light"]) {
					background-color: var(--cs-light-color);
				}
				:host([type="stroke"])::before {
					border-radius: 3%;
					border-style: solid;
					border-width: 2px;
					content: "";
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
				:host([type="stroke"][background-color="brand"])::before {
					border-color: var(--cs-theme-color);
				}
				:host([type="stroke"][background-color="dark"])::before {
					border-color: var(--cs-dark-color);
				}
				:host([type="stroke"][background-color="light"])::before {
					border-color: var(--cs-light-color);
				}
				:host ::slotted([slot="cta"]) {
					display: flex;
					height: 100%;
					left: 0;
					padding: 5px;
					position: absolute;
					top: 0;
					width: 100%;
				}
				@media screen and (min-width: 768px) {
					:host ::slotted([slot="cta"]) {
						padding: 8px;
					}
				}
				@media screen and (min-width: 1024px) {
					:host ::slotted([slot="cta"]) {
						padding: 25px;
					}
				}
				@media screen and (min-width: 1456px) {
					:host ::slotted([slot="cta"]) {
						padding: 30px;
					}
				}
			</style>
			<slot name="cta"></slot>`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
}

customElements.define("cs-cta-tile", CsCtaTile);
