/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';

/**
 * `cs-icon-container` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsIconContainer extends PolymerElement {
  static get properties() {
    return {
      icon: {
        type: String,
        value: '',
        reflectToAttribute: !0,
      },
      color: {
        type: String,
        reflectToAttribute: !0,
        value: '',
      },
      backgroundColor: {
        type: String,
        value: 'none',
        reflectToAttribute: !0,
      },
      hovered: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      type: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`<style>
        :host {
          align-self: stretch;
          color: inherit;
          display: block;
          margin-right: 24px;
          position: relative;
          z-index: 1;
        }

        :host(:not([icon])),
        :host([icon='']) {
          display: none;
        }

        :host([background-color='none']) {
          margin-right: 10px;
        }

        .cspl-cta-dropdown__icon-background {
          backface-visibility: hidden;
          border-radius: 50%;
          height: 40px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          transition: transform var(--cs-transition-duration)
            var(--cs-transition-easing);
          width: 40px;
          z-index: -1;
        }

        @media only screen and (min-width: 768px) {
          .cspl-cta-dropdown__icon-background {
            height: 48px;
            width: 48px;
          }
        }

        :host([background-color='none']) .cspl-cta-dropdown__icon-background {
          display: none;
        }

        :host([hovered]) .cspl-cta-dropdown__icon-background {
          transform: translate3d(-50%, -50%, 0) scale(1.2);
        }

        @media only screen and (min-width: 768px) {
          :host([hovered]) .cspl-cta-dropdown__icon-background {
            transform: translate3d(-50%, -50%, 0) scale(1.167);
          }
        }

        .cspl-cta-dropdown__icon {
          color: inherit;
          display: block;
          height: 22px;
          position: relative;
          width: 22px;
        }

        @media only screen and (min-width: 768px) {
          .cspl-cta-dropdown__icon {
            height: 28px;
            width: 28px;
          }
        }

        :host([color='light']) .cspl-cta-dropdown__icon {
          color: var(--cs-light-color);
          fill: var(--cs-light-color);
        }

        :host([color='dark']) .cspl-cta-dropdown__icon {
          color: var(--cs-dark-color);
          fill: var(--cs-dark-color);
        }

        :host([color='brand']) .cspl-cta-dropdown__icon {
          color: var(--cs-theme-color);
          fill: var(--cs-theme-color);
        }

        :host([background-color='light']) .cspl-cta-dropdown__icon-background {
          background-color: var(--cs-light-color);
        }

        :host([background-color='dark']) .cspl-cta-dropdown__icon-background {
          background-color: var(--cs-dark-color);
        }

        :host([background-color='brand']) .cspl-cta-dropdown__icon-background {
          background-color: var(--cs-theme-color);
        }

        :host([background-color='cs-access-brand'])
          .cspl-cta-dropdown__icon-background {
          background-color: var(--cs-access-brand-color);
        }

        :host([background-color='origin-brand'])
          .cspl-cta-dropdown__icon-background {
          background-color: var(--origin-brand-color);
        }

        :host([background-color='xbox-brand'])
          .cspl-cta-dropdown__icon-background {
          background-color: var(--xbox-brand-color);
        }

        :host([background-color='playstation-brand'])
          .cspl-cta-dropdown__icon-background {
          background-color: var(--playstation-brand-color);
        }

        :host([background-color='nintendo-brand'])
          .cspl-cta-dropdown__icon-background {
          background-color: var(--nintendo-brand-color);
        }
      </style>
      <span class="cspl-cta-dropdown__icon-background"></span>
      <template is="dom-if" if="[[ !_hasMwcIcon(type) ]]">
        <iron-icon
          icon="[[ _getIconName(icon) ]]"
          class="cspl-cta-dropdown__icon"
        ></iron-icon>
      </template>
      <template is="dom-if" if="[[ _hasMwcIcon(type) ]]">
        <mwc-icon class="cspl-cta-dropdown__icon" style="font-size:28px;"
          >[[ icon ]]</mwc-icon
        >
      </template>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _getIconName(e) {
    return `cs-action-32:${e}`;
  }
  _hasMwcIcon(value) {
    return value === 'mwc-icon';
  }
}

customElements.define('cs-icon-container', CsIconContainer);
