import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsHasImageMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsHasImageMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        _hasImage(...e) {
            return e.every((e) => e && e.trim().length);
        }
    };

export const CsHasImageMixin = dedupingMixin(internalCsHasImageMixin);