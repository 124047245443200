import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        with(...e) {
            return e.reduce((e, t) => t(e), this.base);
        }
    };

// deduplicate and export it
export const CsMixin = dedupingMixin(internalCsMixin);
