/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-local-links` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLocalLinks extends PolymerElement {
	static get properties() {
		return {
			textColor: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			titleText: {
				type: String,
				value: "",
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-typography-labels"></style>
			<style>
				:host {
					display: block;
				}

				.local-links-title {
					color: #161616;
					margin-bottom: 7px;
					position: relative;
				}

				:host([text-color="light"]) .local-links-title {
					color: #f7f7f7;
				}

				.local-links {
					display: flex;
					flex-flow: column wrap;
					margin-bottom: 9px;
					padding: 0;
				}

				@media only screen and (min-width: 321px) {
					.local-links {
						flex-flow: row wrap;
					}
				}

				@media only screen and (min-width: 768px) {
					.local-links {
						margin: 0;
					}
				}

				@media only screen and (min-width: 1024px) {
					.local-links {
						margin-top: 11px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.local-links {
						margin-top: 12px;
					}
				}
			</style>
			<h3 class="local-links-title l2" inner-h-t-m-l="[[ titleText ]]" hidden$="[[ _hideTitle(titleText) ]]"></h3>
			<div class="local-links">
				<slot name="local-link"></slot>
			</div>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_hideTitle(e) {
		return "" === e.trim();
	}
}

customElements.define("cs-local-links", CsLocalLinks);
