/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsCatalogMixin } from './mixins/cs-catalog-mixin.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

var e = window.nds;
e.getSlottedChildren = (e, t = ['']) => {
  const a = e.shadowRoot;
  return a
    ? t.reduce((e, t) => {
        const r = a.querySelector(t.length ? `slot[name="${t}"]` : 'slot');
        if (!r) return e;
        const n = r
          .assignedNodes({
            flatten: !0,
          })
          .filter(e => e.nodeType === Node.ELEMENT_NODE);
        return [...e, ...n];
      }, [])
    : [];
};
const n = CsMixin,
  s = CsCatalogMixin,
  d = CsDeviceMixin,
  L = e.getSlottedChildren;
/**
 * `cs-table` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsTable extends n(d(s(PolymerElement))) {
  static get properties() {
    return {
      titleText: {
        type: String,
        value: '',
      },
      headerTheme: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      rowTheme: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      _columns: {
        type: Array,
        value() {
          return this._getColumnValues();
        },
      },
      _displayedRows: Array,
      _allRows: Array,
      spacingTop: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      spacingBottom: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-typography-labels"></style>
      <style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-shared-styles-typography-headings"></style>
      <style include="cs-shared-styles-common-spacing"></style>
      <style include="cs-shared-styles-common-catalog"></style>
      <style include="cs-shared-styles-base-links"></style>
      <style include="cs-shared-styles-base-badge"></style>
      <style>
        :host {
          display: block;
        }
        .cspl-table {
          border-collapse: collapse;
          border-spacing: 0;
          display: table;
          position: relative;
          width: 100%;
          word-break: normal;
        }
        :host([row-theme='dark']) .cspl-table {
          color: var(--cs-dark-color);
        }
        :host([row-theme='light']) .cspl-table {
          color: var(--cs-light-color);
        }
        .cspl-table__thead {
          display: table-header-group;
          vertical-align: middle;
        }
        .cspl-table__head {
          display: table-cell;
          padding: 0;
          position: relative;
          text-align: left;
          vertical-align: middle;
          z-index: 1;
        }
        .cspl-table__head-unsortable {
          padding: 12px 16px 12px 20px;
        }
        :host([rtl]) .cspl-table__head-unsortable {
          padding-left: 16px;
          padding-right: 20px;
        }
        .cspl-table__head-unsortable:first-child {
          padding-left: 16px;
        }
        :host([rtl]) .cspl-table__head-unsortable:first-child {
          padding-left: 20px;
          padding-right: 16px;
        }
        @media only screen and (min-width: 768px) {
          .cspl-table__head-unsortable {
            padding: 12px 24px 12px 28px;
          }
          :host([rtl]) .cspl-table__head-unsortable {
            padding-left: 24px;
            padding-right: 28px;
          }
          .cspl-table__head-unsortable:first-child {
            padding: 24px;
          }
        }
        .cspl-table-simple .cspl-table__head {
          text-transform: var(--cs-text-case, uppercase);
        }
        .cspl-table__head-dark-brand::before,
        .cspl-table__head-dark::before,
        .cspl-table__head-light-brand::before,
        .cspl-table__head-light::before {
          border-radius: 3px;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: calc(100% - 4px);
          z-index: -1;
        }
        .cspl-table__head-light {
          color: var(--cs-light-color);
        }
        .cspl-table__head-light::before {
          background-color: #585858;
        }
        .cspl-table__head-dark {
          color: var(--cs-dark-color);
        }
        .cspl-table__head-dark::before {
          background-color: #c2c2c2;
        }
        .cspl-table__head-dark-brand {
          color: var(--cs-dark-color);
        }
        .cspl-table__head-dark-brand::before {
          background-color: var(--cs-theme-color);
        }
        .cspl-table__head-light-brand {
          color: var(--cs-light-color);
        }
        .cspl-table__head-light-brand::before {
          background-color: var(--cs-theme-color);
        }
        .cspl-table__link {
          align-items: flex-end;
          color: inherit;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          text-decoration: none;
        }
        .cspl-table__head-sortable .cspl-table__link {
          padding: 12px 16px 12px 20px;
        }
        :host([rtl]) .cspl-table__head-sortable .cspl-table__link {
          padding-left: 16px;
          padding-right: 20px;
        }
        .cspl-table__head-sortable:first-child .cspl-table__link {
          padding-left: 16px;
        }
        :host([rtl]) .cspl-table__head-sortable:first-child .cspl-table__link {
          padding-left: 20px;
          padding-right: 16px;
        }
        @media only screen and (min-width: 768px) {
          .cspl-table__head-sortable .cspl-table__link {
            padding: 12px 24px 12px 28px;
          }
          :host([rtl]) .cspl-table__head-sortable .cspl-table__link {
            padding-left: 24px;
            padding-right: 28px;
          }
          .cspl-table__head-sortable:first-child .cspl-table__link {
            padding-left: 24px;
          }
          :host([rtl])
            .cspl-table__head-sortable:first-child
            .cspl-table__link {
            padding-left: 28px;
            padding-right: 24px;
          }
        }
        .cspl-table__tbody {
          display: table-row-group;
          vertical-align: middle;
        }
        .cspl-table__row {
          display: table-row;
        }
        .cspl-table__chevron {
          fill: var(--cs-light-color);
          height: 16px;
          margin-bottom: 2px;
          margin-left: 16px;
          opacity: 0;
          transition: opacity 0.4s, transform 0.4s;
          width: 16px;
        }
        :host([rtl]) .cspl-table__chevron {
          margin-left: 0;
          margin-right: 16px;
        }
        .cspl-table__head-sorted .cspl-table__chevron {
          opacity: 1;
        }
        .cspl-table__head-asc .cspl-table__chevron {
          transform: rotate(-180deg);
        }
        .cspl-table__head-light .cspl-table__chevron {
          fill: var(--cs-dark-color);
        }
        .cspl-table__data {
          border-bottom-style: solid;
          border-bottom-width: 1px;
          display: table-cell;
          padding: 32px 16px 16px 20px;
          vertical-align: middle;
        }
        :host([rtl]) .cspl-table__data {
          padding-left: 16px;
          padding-right: 20px;
        }
        .cspl-table__data:first-child {
          padding-left: 16px;
        }
        :host([rtl]) .cspl-table__data:first-child {
          padding-left: 20px;
          padding-right: 16px;
        }
        :host([is-ios]) .cspl-table__data {
          -webkit-text-size-adjust: 100%;
        }
        @media only screen and (min-width: 768px) {
          .cspl-table__data {
            padding: 32px 24px 16px 28px;
          }
          :host([rtl]) .cspl-table__data {
            padding-left: 24px;
            padding-right: 28px;
          }
          .cspl-table__data:first-child {
            padding-left: 24px;
          }
          :host([rtl]) .cspl-table__data:first-child {
            padding-left: 28px;
            padding-right: 24px;
          }
        }
        :host([row-theme='dark']) .cspl-table-simple .cspl-table__data {
          border-bottom-color: #eaeaea;
        }
        :host([row-theme='light']) .cspl-table-simple .cspl-table__data {
          border-bottom-color: #585858;
        }
        @media only screen and (max-width: 1119px) {
          .cspl-table {
            display: block;
            max-width: 100%;
            overflow-x: auto;
            white-space: nowrap;
          }
        }
      </style>
      <iron-ajax
        auto=""
        url="[[ filterUrl ]]"
        handle-as="text"
        on-response="_handleFilterAjaxResponse"
        on-error="_handleFilterAjaxError"
      ></iron-ajax>
      <iron-ajax id="eaCatalogAjax" handle-as="text"></iron-ajax>
      <slot name="filter"></slot>
      <h2 hidden="[[ !titleText ]]" class$="[[ _getTitleClass(rowTheme) ]]">
        [[ titleText ]]
      </h2>
      <div class="cspl-table cspl-table-simple">
        <div class="cspl-table__thead">
          <div class="cspl-table__row">
            <template is="dom-repeat" items="[[ _columns ]]" as="column">
              <div
                class$="[[ _getHeadClass(column.sortable, headerTheme) ]]"
                data-column="[[ column ]]"
                data-sortable$="[[ column.sortable ]]"
                data-type$="[[ column.type ]]"
                on-tap="_handleSort"
                scope="col"
                title="[[ column.description ]]"
              >
                <template is="dom-if" if="[[ column.sortable ]]">
                  <span class="cspl-table__link"
                    >[[ column.label ]]
                    <iron-icon
                      icon="cs-action-16:chevron"
                      class="cspl-table__chevron"
                    ></iron-icon>
                  </span>
                </template>
                <template is="dom-if" if="[[ !column.sortable ]]"
                  >[[ column.label ]]</template
                >
              </div>
            </template>
          </div>
        </div>
        <div class="cspl-table__tbody cspl-catalog__items">
          <template is="dom-repeat" items="[[ _displayedRows ]]" as="row">
            <div class="cspl-table__row">
              <template is="dom-repeat" items="[[ _columns ]]" as="column">
                <div class$="[[ _getDataClass(column) ]]" scope="row">
                  <template
                    is="dom-if"
                    if="[[ !_hasEmptyValue(row, column.key, column.type) ]]"
                  >
                    <template
                      if="[[ _testEquality(column.type, 'boolean') ]]"
                      is="dom-if"
                    >
                      <iron-icon
                        icon="[[ _getRowValue(row, column.key, column.type) ]]"
                        scope="row"
                      ></iron-icon>
                    </template>
                    <template
                      if="[[ !_testEquality(column.type, 'boolean') ]]"
                      is="dom-if"
                    >
                      <span
                        inner-h-t-m-l="[[ _getRowValue(row, column.key, column.type) ]]"
                      ></span>
                    </template>
                  </template>
                  <template
                    is="dom-if"
                    if="[[ _hasEmptyValue(row, column.key, column.type) ]]"
                  >
                    <iron-icon icon="cs-nav:minus"></iron-icon>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div id="processing" class="cspl-processing-icon">
        <iron-icon icon="cs-action-24:loading"></iron-icon>
      </div>
      <slot id="pagination" name="pagination"></slot>
      <slot id="loadMore" name="load-more"></slot>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  _getTitleClass(e) {
    return ''.concat('d4', ' ').concat('d4', '-').concat(e);
  }
  _getHeadClass(e, t) {
    var a = ''
      .concat('cspl-table__head', ' ')
      .concat('cspl-table__head', '-')
      .concat(t, ' l2');
    return (a = e
      ? ''.concat(a, ' ').concat('cspl-table__head', '-sortable')
      : ''.concat(a, ' ').concat('cspl-table__head', '-unsortable'));
  }
  _getDataClass(e) {
    var t = 'cspl-table__data';
    return (t =
      'bold' === e.treatment ? ''.concat(t, ' l2') : ''.concat(t, ' b2'));
  }
  _hasEmptyValue(e, t, a) {
    return 'date' === a ? !e[t].label : !e[t];
  }
  _getColumnValues() {
    var e = [];
    return (
      this.querySelectorAll('cs-table-header > cs-table-header-cell').forEach(
        function (t) {
          var a = {
            isSorted: t.getAttribute('isSorted'),
            key: t.getAttribute('key'),
            label: t.textContent,
            description: t.getAttribute('description'),
            sortable: t.hasAttribute('sortable'),
            sortDirection: t.hasAttribute('sortDirection'),
            type: t.getAttribute('type'),
            treatment: t.getAttribute('treatment'),
          };
          e.push(a);
        }
      ),
      e
    );
  }
  _getRowValues() {
    var e = [],
      t = this.querySelectorAll('cs-table-body > cs-table-body-row'),
      a = this._columns;
    return (
      t.forEach(function (t) {
        var l = {};
        t
          .querySelectorAll('cs-table-body-cell')
          .forEach(this._processRowValue.bind(this, a, l)),
          e.push(l);
      }, this),
      e
    );
  }
  _getRowValue(e, t, a) {
    return 'date' === a ? e[t].label : e[t];
  }
  _processDateFromString(e) {
    var t = new Date(e.textContent),
      a = !isNaN(Date.parse(e.textContent));
    return {
      label: a ? t.toLocaleDateString() : '',
      value: a ? t : new Date(null),
    };
  }
  _processBooleanFromString(e) {
    return JSON.parse(e.textContent)
      ? 'cs-state-16:checked'
      : 'cs-action-16:close';
  }

  stringToHTML(str) {
    var dom = document.createElement('div');
    dom.innerHTML = str;
    this.querySelectorAll('#box1').appendChild(dom);
    return dom;
  }

  _processRowValue(e, t, a, l) {
    var i = e[l];
    if (i) {
      var o = a.getAttribute('key');
      switch (i.type) {
        case 'date':
          t[o] = this._processDateFromString(a);
          break;
        case 'boolean':
          t[o] = this._processBooleanFromString(a);
          break;
        case 'string':
          t[o] = a.innerHTML;
          break;
        default:
          t[o] = a.textContent;
      }
    }
  }
  _testEquality(e, t) {
    return e === t;
  }
  _handleSort(e) {
    var t = e.currentTarget,
      a = e.model.column,
      l = Array.prototype.slice.call(
        t.parentNode.getElementsByClassName('cspl-table__head')
      ),
      i = {
        column: a.key,
        direction: '',
        type: a.type,
      };
    if (a.sortable) {
      for (var o, r = l.indexOf(t), n = l.length - 1; n > -1; --n)
        (o = l[n]).classList.remove(
          ''.concat('cspl-table__head-', 'asc'),
          ''.concat('cspl-table__head-', 'dsc')
        ),
          n === r
            ? o.classList.add('cspl-table__head-sorted')
            : o.classList.remove('cspl-table__head-sorted');
      a.sortDirection && 'asc' === a.sortDirection
        ? (a.sortDirection = 'dsc')
        : (a.sortDirection = 'asc'),
        t.classList.add(''.concat('cspl-table__head-').concat(a.sortDirection)),
        (i.direction = a.sortDirection),
        this._allRows.sort(this._customSort.bind(i));
      var s = this._getPaginationItem();
      s
        ? this._showPage(s.pageSize, s.currentPage)
        : (this._displayedRows = this._allRows.slice()),
        this.dispatchEvent(
          new CustomEvent('sort', {
            detail: {
              columnModel: a,
              sort: i,
            },
          })
        );
    }
  }
  _customSort(e, t) {
    var a;
    switch (this.type) {
      case 'date':
        a = +e[this.column].value > +t[this.column].value ? 1 : -1;
        break;
      case 'float':
      case 'integer':
        a = +e[this.column] > +[t[this.column]] ? 1 : -1;
        break;
      default:
        a = e[this.column].localeCompare(t[this.column]);
    }
    return 'asc' === this.direction ? a : -a;
  }
  get _catalogItems() {
    return this._getRowValues();
  }
  get _catalogItemsContainer() {
    return this.querySelector('cs-table-body');
  }
  _showRows(e, t) {
    this._allRows && (this._displayedRows = this._allRows.slice(e, t));
  }
  _processCatalogItems() {
    this._allRows = this._displayedRows = this._catalogItems;
  }
  _getPaginationItem() {
    var e = L(this, ['pagination']);
    if (e.length) return e[0];
  }
  connectedCallback() {
    super.connectedCallback(),
      requestAnimationFrame(() => {
        (this._columns = this._getColumnValues()),
          this._processCatalogItems(),
          this.dispatchEvent(
            new CustomEvent('nds.catalog.itemsReady', {
              bubbles: !0,
              composed: !0,
              detail: {
                items: this._catalogItems,
              },
            })
          ),
          this.pagination &&
            this._showPage(
              this.pagination.pageSize,
              this.pagination.currentPage
            );
      });
  }
}

customElements.define('cs-table', CsTable);
