/* jshint ignore:start */
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsMixin } from './mixins/cs-mixin.js';

const r = CsMixin;

/**
 * `cs-load-more` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLoadMore extends r(CsElements) {
  static get properties() {
    return {
      end: {
        type: Number,
        value: 0,
      },
      quantity: {
        type: Number,
        value: 10,
      },
      theme: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      totalCount: {
        type: Number,
        value: 0,
      },
      hidden: {
        type: Boolean,
        reflectToAttribute: !0,
        computed: '_computeHidden(end, totalCount)',
      },
      labelText: String,
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-common-catalog-spacing"></style>
      <style>
        :host {
          align-items: center;
          border-radius: 3px;
          border-style: solid;
          border-width: 2px;
          display: flex;
          height: 98px;
          justify-content: center;
          text-decoration: none;
        }
        :host(:hover) {
          cursor: pointer;
        }
        :host(:active) {
          border-width: 3px;
        }
        :host([theme='dark']) {
          border-color: #eaeaea;
          color: #a3a3a3;
        }
        :host([theme='dark']:hover) {
          border-color: #a3a3a3;
          color: var(--cs-dark-color);
        }
        :host([theme='light']) {
          border-color: #585858;
          color: #c2c2c2;
        }
        :host([theme='light']:hover) {
          border-color: #c2c2c2;
          color: var(--cs-light-color);
        }
        @media only screen and (min-width: 1024px) {
          :host {
            height: 120px;
          }
        }
        .cspl-load-more__icon {
          display: block;
          fill: #a3a3a3;
          height: 40px;
          margin: 0 auto;
          transition: height 0.1s, margin 0.1s, width 0.1s;
          width: 40px;
        }
        :host(:hover) .cspl-load-more__icon {
          fill: var(--cs-dark-color);
          height: 44px;
          margin: -2px auto;
          width: 44px;
        }
        :host([theme='dark']) .cspl-load-more__icon {
          fill: #a3a3a3;
        }
        :host([theme='dark']:hover) .cspl-load-more__icon {
          fill: #161616;
        }
        :host([theme='light']) .cspl-load-more__icon {
          fill: #c2c2c2;
        }
        :host([theme='light']:hover) .cspl-load-more__icon {
          fill: var(--cs-light-color);
        }
        .cspl-load-more__label {
          cursor: pointer;
          display: block;
          font-family: var(--cs-theme-secondary-font-family);
          font-size: 16px;
          -webkit-font-smoothing: antialiased;
          font-weight: 700;
          line-height: 12px;
          margin-bottom: 0;
          margin-top: 16px;
          text-align: center;
          text-transform: var(--cs-text-case, uppercase);
        }
        @media only screen and (min-width: 1024px) {
          .cspl-load-more__label {
            font-size: 20px;
            line-height: 24px;
          }
        }
      </style>
      <div>
        <iron-icon icon="cs-action-54:load" class="cspl-load-more__icon">
        </iron-icon>
        <label class="cspl-load-more__label">[[ labelText ]]</label>
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  _loadMoreTap(e) {
    e.preventDefault(),
      this.end <= this.totalCount &&
        ((this.end = this.end + this.quantity),
        this.dispatchEvent(
          new CustomEvent('loadmore', {
            bubbles: !0,
            composed: !0,
            detail: {
              end: this.end,
              quantity: this.quantity,
            },
          })
        )),
      this._checkAndSetHidden();
  }
  _checkAndSetHidden() {
    this.hidden = this.end >= this.totalCount;
  }
  _computeHidden(e, t) {
    return e >= t;
  }
  connectedCallback() {
    super.connectedCallback();
    // this.end = this.end + this.quantity;
    // this._checkAndSetHidden();
    // this.dispatchEvent(
    //   new CustomEvent('loadmoreinit', {
    //     bubbles: !0,
    //     composed: !0,
    //     detail: {
    //       end: this.end,
    //       quantity: this.quantity,
    //     },
    //   })
    // );
    // Object(a.b)(this, 'tap', this._loadMoreTap);
  }
}

customElements.define('cs-load-more', CsLoadMore);
