/* jshint ignore:start */
import { GestureEventListeners } from '@polymer/polymer/lib/mixins/gesture-event-listeners.js';
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js';
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsMixin } from './mixins/cs-mixin.js';

const t = CsMixin;

/**
 * `cs-text` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsText extends t(GestureEventListeners(CsElements)) {
  static get properties() {
    return {
      textColor: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      _readMoreText: String,
      _readLessText: String,
      opened: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      desiredLongTextHeight: {
        type: Number,
        value: 0,
      },
      _textLong: {
        type: Array,
        value: () => [],
      },
      _textLongObserver: Object,
    };
  }

  static get template() {
    return html`
    <style include="cs-shared-styles-base-common"></style>
    <style include="cs-shared-styles-base-links"></style>
    <style include="cs-shared-styles-base-headings"></style>
    <style include="cs-shared-styles-typography-headings"></style>
    <style include="cs-shared-styles-base-common"></style>
    <style include="cs-shared-styles-common-spacing"></style>
    <style>
      :host([text-color='dark']) {
        color: var(--cs-dark-color);
      }

      :host([text-color='light']) {
        color: var(--cs-light-color);
      }

      :host([text-color='brand']) {
        color: var(--cs-theme-color);
      }

      :host([text-color='theme-secondary']) {
        color: var(--cs-theme-secondary-color);
      }

      .cspl-text__toggle-link {
        cursor: pointer;
      }

      :host ::slotted(cs-text-long) {
        display: block;
        max-height: 0;
        overflow: hidden;
      }

      :host([opened]) ::slotted(cs-text-long) {
        max-height: none;
      }
    </style>
    <slot></slot>
    <slot id="textLongSlot" name="text-long"></slot>
    <template is="dom-if" if="[[ _computeHideTextLong(_textLong) ]]">
      <a class="cspl-text__toggle-link" on-tap="_toggleOpened" hidden$="[[ opened ]]"> [[ _readMoreText ]] </a>
      <a class="cspl-text__toggle-link" on-tap="_toggleOpened" hidden$="[[ !opened ]]">[[ _readLessText ]] </a>
    </template>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _toggleOpened() {
    this.opened = !this.opened;
  }
  _computeHideTextLong(e) {
    return !e.length;
  }
  _observeTextLong(e) {
    (this._textLong = e.addedNodes[0]),
      (this._readMoreText =
        this._textLong.getAttribute('read-more-text') || ''),
      (this._readLessText =
        this._textLong.getAttribute('read-less-text') || ''),
      this._textLongObserver.disconnect();
  }
  connectedCallback() {
    super.connectedCallback(),
      (this._textLongObserver = new FlattenedNodesObserver(
        this.$.textLongSlot,
        this._observeTextLong.bind(this)
      )); // jshint ignore:line
  }
}

customElements.define('cs-text', CsText);
