import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import './shared-styles/cs-mwc-textfield.js';

/**
 * `money-input` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
 class MoneyInput extends PolymerElement {
  static get properties() {
    return {
      /** Internal var to store formatted number */
      _formattedValue: {
        type: String,
        value: undefined,
      },

      /** Disable field */
      disabled: {
        type: Boolean,
        value: false,
      },

      /** Sets field as readonly */
      readonly: {
        type: Boolean,
        value: false,
      },

      /** Sets field as required */
      required: {
        type: Boolean,
        value: false,
      },

      /** Field name */
      name: {
        type: String,
        value: 'amount',
      },

      /** Field label */
      label: {
        type: String,
        value: '',
      },

      /** Disables the floating label */
      noLabelFloat: {
        type: Boolean,
        value: false,
      },

      // Always float the label
      alwaysFloatLabel: {
        type: Boolean,
        value: false,
      },

      /** Presets field value notifies new values */
      value: {
        type: Number,
        notify: true,
      },

      /** Currency used as field prefix */
      currency: {
        type: String,
        value: '$',
      },

      /** Max value accepted by field */
      maxValue: {
        type: Number,
        value: 1000000,
      },

      /** Min value accepted by field */
      minValue: {
        type: Number,
        value: 0,
      },

      /** Decimal precision -> Use "0" for integer amount */
      precision: {
        type: Number,
        value: 2,
      },

      /** Error message for values higher than min value */
      minValueErrorMessage: {
        type: String,
        value: 'Value must be higher than',
      },

      /** Error message for values higher than max value */
      maxValueErrorMessage: {
        type: String,
        value: 'Value must be lower than',
      },

      /** Symbol used to separate number blocks */
      unitSymbol: {
        type: String,
        value: '.',
      },

      /** Symbol used to separate decimal from integer part */
      decimalSymbol: {
        type: String,
        value: ',',
      },

      /** Placeholder to fill the input field while blank **/
      placeholder: {
        type: String,
        value: '',
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-mwc-textfield"></style>
      <style>
        :host {
          display: flex;
        }
        .prefix {
          padding-right: 10px;
        }

        .mdc-text-field {
          width: 100%;
        }

        #input {
          width: var(--money-input-width);
          padding-right: max(16px, var(--mdc-shape-small, 4px));
          padding-left: max(16px, var(--mdc-shape-small, 4px));
          --paper-input-container-label: {
            left: 4px;
            background: #fff;
            z-index: 1;
            max-width: 50%;
          }
          /* Reset some defaults */
          --paper-input-container: {
            padding: 0;
          }
          --paper-input-container-underline: {
            display: none;
            height: 0;
          }
          --paper-input-container-underline-focus: {
            display: none;
          }
        }
      </style>
      <paper-input
        id="input"
        label="[[label]]"
        no-label-float="[[noLabelFloat]]"
        always-float-label="[[alwaysFloatLabel]]"
        required$="[[required]]"
        value="{{_formattedValue}}"
        name$="[[name]]"
        class="text-field-outlined-trailing"
        allowed-pattern="[0-9.,-]"
        disabled$="[[disabled]]"
        readonly$="[[readonly]]"
        placeholder$="[[placeholder]]"
      >
        <div prefix class="prefix">[[currency]]</div>
      </paper-input>
    `;
  }

  static get observers() {
    return [
      '_setValue(_formattedValue)',
      '_onValueChanged(_formattedValue)',
      '_valueChange(value)',
    ];
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
    this.renderElement();
  }

  /** You can use this to re-render the input */
  renderElement() {
    this.$.input.invalid = false;
    if (this.value && !this._formattedValue) {
      var signal = this._getSignal('' + this.value);
      if (('' + this.value).length >= this.precision) {
        this._formattedValue = signal + this.value;
      } else {
        this._formattedValue =
          signal + this.value + this._generateNecessarySizeOfZerosChar();
      }
    }
    this._setValue = function (f) {
      if (this._formattedValue) {
        var signal = this._getSignal(this._formattedValue);
        var number =
          parseFloat(this._removeUI(this._formattedValue)) /
          Math.pow(10, this.precision);
        this.set('value', signal + number.toFixed(this.precision));
      }
    };
  }

  /** Clean completely the input value, this method will set the value to undefined */
  clean() {
    this.value = undefined;
    this._formattedValue = '';
  }

  _valueChange() {
    if (this._formattedValue != this.value) {
      if (!isNaN(parseFloat(this.value)) && isFinite(this.value)) {
        var signal = this._getSignal('' + this.value);
        if (('' + this.value).length >= this.precision) {
          this._formattedValue = signal + this.value;
        } else {
          this._formattedValue =
            signal + this.value + this._generateNecessarySizeOfZerosChar();
        }
      } else {
        this.clean();
      }
    }
  }

  _setValue() {
    //stub
    // if (this.value == undefined) {
    // 	this.value = 0;
    // }
  }

  _getDecimal(string) {
    return string.match(new RegExp('(\\d{0,' + this.precision + '})$'))[0];
  }

  _parse(string) {
    var dec = this._getDecimal(string);
    var reg = new RegExp(dec + '$');
    var result = string.replace(reg, '');
    result =
      (result.length <= 1 ? result : this._markAmount(result)) +
      this.decimalSymbol +
      dec;

    return result;
  }

  _markAmount(string) {
    return string
      .replace(/(0*)(?=[^0]*)/, '')
      .split(/(?=(?:...)*$)/)
      .join(this.unitSymbol);
  }

  _onValueChanged() {
    this.validate();

    if (
      this._formattedValue === '' ||
      (this._formattedValue === '0' && this.precision == 0)
    ) {
      this.value = this._formattedValue === '' ? undefined : 0;
      return;
    }

    if (this.precision == 0) {
      this._formattedValue = this._markAmount(
        this._removeUI(this._formattedValue)
      );
      return;
    }
    var signal = this._getSignal(this._formattedValue);

    if (this._removeUI(this._formattedValue).length < this.precision + 1) {
      var dec = this._getDecimal(this._removeUI(this._formattedValue));
      var group;
      var decNoZeros = dec.replace(/(0*)(?=[^0]*)/, '');

      //check if needs to add 0 in the end, beginning or both
      group = dec.match(new RegExp('(0*)(?=' + decNoZeros + ')'));

      if (
        group.filter(function (e, i) {
          if (e.length > 0) {
            return e;
          }
        }).length > 0
      ) {
        if (group[0].length + decNoZeros.length == this.precision) {
          this._formattedValue =
            signal + '0' + this._getDecimal(this._formattedValue);
          return;
        } else if (group[0].length + decNoZeros.length < this.precision) {
          this._formattedValue =
            signal +
            group[0] +
            decNoZeros +
            this._generateNecessarySizeOfZerosChar(
              group[0].length + decNoZeros.length
            );
          return;
        }
      } else {
        this._formattedValue =
          signal +
          this._generateNecessarySizeOfZerosChar(1 - decNoZeros.length) +
          decNoZeros;
      }
      return;
    }

    this._formattedValue =
      signal + this._parse(this._removeUI(this._formattedValue));
  }

  _removeUI(string) {
    return string.replace(/[^\d]*/g, '');
  }

  _getSignal(value) {
    return value.indexOf('-') >= 0 ? '-' : '';
  }

  _generateNecessarySizeOfZerosChar(existingSize) {
    existingSize = existingSize || 0;
    return Array(this.precision - Math.abs(existingSize) + 1).join('0');
  }

  _validate(value) {
    var signal = this._getSignal(value);
    var n =
      parseFloat(signal + this._removeUI(value)) / Math.pow(10, this.precision);

    if (n > this.maxValue) {
      this.$.input.invalid = true;
      this.$.input.errorMessage =
        this.maxValueErrorMessage + ' ' + this.maxValue;
      return false;
    } else if (n < this.minValue) {
      this.$.input.invalid = true;
      this.$.input.errorMessage =
        this.minValueErrorMessage + ' ' + this.minValue;
      return false;
    } else {
      this.$.input.invalid = false;
      this.$.input.errorMessage = '';
      return true;
    }
  }

  /** Return validation based on required, max and min configs */
  validate() {
    return this.$.input.validate() && this._validate(this.$.input.value);
  }
}

customElements.define('money-input', MoneyInput);
