import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';

window.nds.data = window.nds.data || {};
var e = window.nds;
/**
 * `cs-submit` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSubmit extends CsElements {
  static get properties() {
    return {
      textColor: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      fillColor: {
        type: String,
        value: 'brand',
        reflectToAttribute: !0,
      },
      disabled: {
        type: Boolean,
        reflectToAttribute: !0,
      },
      processing: {
        type: Boolean,
        reflectToAttribute: !0,
      },
      valueText: String,
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style>
        :host {
          display: inline-flex;
          flex-direction: column;
          margin-bottom: 8px;
          margin-right: 24px;
          margin-top: 0;
          position: relative;
          transition: opacity var(--cs-interface-expansion);
          z-index: 0;
        }
        :host([rtl]) {
          margin-left: 24px;
          margin-right: 0;
        }
        :host([wf-loading]) {
          opacity: 0;
        }
        :host::before {
          border-radius: 3px;
          border-style: solid;
          border-width: 2px;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: bottom var(--cs-interface-expansion),
            left var(--cs-interface-expansion),
            right var(--cs-interface-expansion),
            top var(--cs-interface-expansion);
          z-index: -1;
        }
        :host([fill-color='none']) {
          background-color: var(--cs-theme-color);
          border-color: var(--cs-theme-color);
        }
        :host([fill-color='secondary-color']) {
          background-color: var(--cs-theme-secondary-color);
        }
        :host([fill-color='secondary-color'][text-color='dark'])::before {
          background-color: transparent;
          border-color: var(--cs-theme-secondary-color);
        }
        :host([fill-color='theme-color']) {
          background-color: var(--cs-theme-color);
        }
        :host([fill-color='theme-color'][text-color='light'])::before {
          background-color: transparent;
          border-color: var(--cs-theme-color);
        }
        :host([fill-color='access-brand']) {
          background-color: var(--cs-color-blue);
        }
        :host([fill-color='access-brand'][text-color='light'])::before {
          background-color: transparent;
          border-color: var(--cs-color-blue);
        }
        :host([fill-color='none'][text-color='dark'])::before {
          background-color: transparent;
          border-color: var(--cs-dark-color);
        }
        :host([fill-color='none'][text-color='light'])::before {
          background-color: transparent;
          border-color: var(--cs-light-color);
        }
        :host([disabled]),
        :host([processing]) {
          overflow: hidden;
          pointer-events: none;
        }
        :host(:hover)::before {
          bottom: -3px;
          left: -3px;
          right: -3px;
          top: -3px;
        }
        :host(:active)::before {
          border-width: 3px;
          bottom: -4px;
          left: -4px;
          right: -4px;
          top: -4px;
        }
        .cspl-submit__button {
          background-color: transparent;
          border: 0;
          cursor: pointer;
          display: block;
          font-family: var(--cs-theme-primary-font-family);
          font-size: 16px;
          line-height: 48px;
          min-width: 136px;
          opacity: 1;
          padding-left: 24px;
          padding-right: 24px;
          position: relative;
          transition: opacity var(--cs-interface-expansion);
        }
        @media only screen and (min-width: 1456px) {
          .cspl-submit__button {
            font-size: 20px;
            line-height: 60px;
          }
        }
        .cspl-submit__button:focus {
          outline: 0;
        }
        :host([text-color='dark']) .cspl-submit__button {
          color: var(--cs-dark-color);
        }
        :host([text-color='light']) .cspl-submit__button {
          color: var(--cs-light-color);
        }
        :host([processing]) .cspl-submit__button {
          opacity: 0;
        }
        .cspl-submit__processing-icon {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          transition: opacity var(--cs-interface-expansion);
          width: 100%;
        }
        :host([text-color='dark']) .cspl-submit__processing-icon {
          color: var(--cs-dark-color);
        }
        :host([text-color='light']) .cspl-submit__processing-icon {
          color: var(--cs-light-color);
        }
        :host([processing]) .cspl-submit__processing-icon {
          opacity: 1;
        }
        .cspl-submit__processing-icon iron-icon {
          animation: infinite-rotate 1.5s linear infinite;
        }
        @keyframes infinite-rotate {
          from {
            transform: rotate(0);
          }
          to {
            transform: rotate(360deg);
          }
        }
      </style>
      <input
        class="cspl-submit__button"
        type="submit"
        value="[[ valueText ]]"
      />
      <div class="cspl-submit__processing-icon">
        <iron-icon icon="cs-action-24:loading"></iron-icon>
      </div>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _handleOnClick() {
    this.dispatchEvent(
      new CustomEvent('submit', {
        bubbles: !0,
        composed: !0,
      })
    );
  }
  connectedCallback() {
    super.connectedCallback(),
      this.addEventListener('click', this._handleOnClick);
  }
}

customElements.define('cs-submit', CsSubmit);
