import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

const e = window.nds.getSlottedChildren;
/**
 * CsCategoryTagParentMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsCategoryTagParentMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                hideCategoryTags: {
                    type: Boolean,
                    value: !1
                },
                _hideCategoryTags: {
                    type: Boolean,
                    value: !0,
                    computed: "_computeHideCategoryTags(_categoryTags, hideCategoryTags)"
                },
                _categoryTags: {
                    type: Array,
                    value() {
                        return [];
                    }
                }
            };
        }
        _computeHideCategoryTags(e, t) {
            return !e.length || t;
        }
        _getCategoryTags() {
            this._categoryTags = e(this, ["category-tag"]);
        }
        connectedCallback() {
            super.connectedCallback(), this._getCategoryTags(), this.shadowRoot.querySelector("slot[name=\"category-tag\"]").addEventListener("slotchange", this._getCategoryTags.bind(this)); // jshint ignore:line
        }
    };

export const CsCategoryTagParentMixin = dedupingMixin(internalCsCategoryTagParentMixin);