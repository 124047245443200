import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsInputElementInteractionsMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsInputElementInteractionsMixin = base =>
  /**
   * @polymerMixinClass
   */
  class t extends base {
    static get properties() {
      return {
        current: {
          type: Boolean,
          value: !1,
          reflectToAttribute: !0,
        },
        _empty: {
          type: Boolean,
          value: !0,
          reflectToAttribute: !0,
        },
        backgroundColor: {
          type: String,
          reflectToAttribute: !0,
        },
        error: {
          type: Boolean,
          value: !1,
          reflectToAttribute: !0,
        },
        helpActive: {
          type: Boolean,
          value: !1,
          reflectToAttribute: !0,
        },
      };
    }
    _handleEnter() {
      this.helpActive = !0;
    }
    _handleLeave() {
      this.helpActive = !1;
    }
    connectedCallback() {
      super.connectedCallback(),
        this.addEventListener('mouseenter', this._handleEnter),
        this.addEventListener('mouseleave', this._handleLeave);
    }
  };

export const CsInputElementInteractionsMixin = dedupingMixin(internalCsInputElementInteractionsMixin);
