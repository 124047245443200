/* eslint-disable no-sequences */
/* jshint ignore:start */
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsCtaParentMixin } from './mixins/cs-cta-parent-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

const t = CsMixin;
const a = CsCtaParentMixin;

/**
 * `cs-elements` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
export class CsElements extends t(a(PolymerElement)) {
  static get properties() {
    return {
      unresolved: {
        type: Boolean,
        reflectToAttribute: !0,
      },
      wfLoading: {
        type: Boolean,
        value: !0,
        reflectToAttribute: !0,
      },
      rtl: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
    };
  }

  _wfLoaded() {
    // eslint-disable-next-line no-unused-expressions
    (this.wfLoading = !1), this._removeEventListeners();
  }

  _removeEventListeners() {
    document.removeEventListener('nds.typeKit.active', this._wfLoaded);
  }

  constructor() {
    // eslint-disable-next-line no-unused-expressions
    super(), (this._wfLoaded = this._wfLoaded.bind(this));
  }

  connectedCallback() {
    // eslint-disable-next-line no-unused-expressions
    super.connectedCallback(),
      document.addEventListener('nds.typeKit.active', this._wfLoaded),
      this.dispatchEvent(
        new CustomEvent('nds.typeKit.check', {
          bubbles: !0,
          composed: !0,
        })
      ),
      document.documentElement.hasAttribute('wf-loading') || this._wfLoaded(),
      (this.rtl = document.dir === 'rtl');
  }

  disconnectedCallback() {
    // eslint-disable-next-line no-unused-expressions
    super.disconnectedCallback(), this._removeEventListeners();
  }

  ready() {
    // eslint-disable-next-line no-unused-expressions
    super.ready(), (this.unresolved = !1);
  }
}

customElements.define('cs-elements', CsElements);
