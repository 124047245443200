import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import { GestureEventListeners } from '@polymer/polymer/lib/mixins/gesture-event-listeners.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

const n = CsMixin,
  c = CsDeviceMixin;
/**
 * `cs-stream` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsStream extends GestureEventListeners(n(c(PolymerElement))) {
  static get properties() {
    return {
      uiColor: {
        type: String,
        value: 'light',
        observer: '_updateUIColor',
      },
      paddleDefaultColor: String,
      progressIndicatorDefaultColor: String,
      progressIndicatorActiveColor: String,
      paddleDisableProgressBarDefaultColor: String,
      tileType: {
        type: String,
        value: 'vertical',
      },
      tileTextAlign: {
        type: String,
        value: 'left',
      },
      tileTextColor: {
        type: String,
        value: 'dark',
      },
      tileColor: {
        type: String,
        value: 'light',
      },
      tileMediaShape: {
        type: String,
        value: 'rectangle',
      },
      tileScrimColor: {
        type: String,
        value: 'none',
      },
      tileScrimOpacity: {
        type: String,
        value: 'medium',
      },
      hideMedia: {
        type: Boolean,
        value: !1,
      },
      hideEyebrow: {
        type: Boolean,
        value: !1,
      },
      hideTitle: {
        type: Boolean,
        value: !1,
      },
      hideCopy: {
        type: Boolean,
        value: !1,
      },
      hideCtas: {
        type: Boolean,
        value: !1,
      },
      hideTags: {
        type: Boolean,
        value: !1,
      },
      hideBrandStroke: {
        type: Boolean,
        value: !1,
      },
      hideByline: {
        type: Boolean,
        value: !1,
      },
      spacingTop: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      spacingBottom: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      _viewableItems: {
        type: Number,
        computed:
          '_computeViewableItems(_viewableWidth, _itemWidth, _itemDimensions)',
      },
      _maxScrollDistance: {
        type: Number,
        computed:
          '_computeMaxScrollDistance(_viewableWidth, _containerWidth, _itemWidth)',
      },
      filtering: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      filterKey: {
        type: String,
        value: 'all',
      },
      tracking: {
        type: Boolean,
        value: !1,
      },
      notStreamable: {
        type: Boolean,
        reflectToAttribute: !0,
        readOnly: !0,
        value: !1,
        computed: '_computeNotStreamable(_viewableWidth, _containerWidth)',
      },
      _items: {
        type: Array,
        value: () => [],
      },
      _filteredItems: {
        type: Array,
        computed: '_computeFilteredItems(_items, filterKey)',
      },
      _forwardDisabled: {
        type: Boolean,
        value: !1,
        computed: '_computeForwardDisabled(_streamPositionX)',
      },
      _backDisabled: {
        type: Boolean,
        value: !1,
        computed: '_computeBackDisabled(_streamPositionX)',
      },
      _itemWidth: {
        type: Number,
        computed: '_computeItemWidth(_itemDimensions)',
      },
      _containerWidth: {
        type: Number,
        computed: '_computeContainerWidth(_filteredItems, _itemWidth)',
      },
      _viewableWidth: Number,
      _debounceDelay: {
        type: Number,
        value: 20,
      },
      minTrackTime: {
        type: Number,
        value: 0.2,
      },
      midTrackTime: {
        type: Number,
        value: 0.35,
      },
      minTrackDistance: Number,
      midTrackDistance: Number,
      _canScrollHorizontal: {
        type: Boolean,
        value: !1,
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-common-spacing"></style>
      <style>
        :host {
          overflow: hidden;
          position: relative;
          --cs-theme-stream-ui-paddle-default: #f3f3f3;
          --cs-theme-stream-ui-paddle-progresss-color: #4f4f4f;
          --cs-theme-stream-ui-paddle-disabled: var(
            --cs-theme-stream-ui-paddle-progresss-color
          );
          --cs-theme-stream-ui-progress-bar-stroke-default: var(
            --cs-theme-stream-ui-paddle-progresss-color
          );
          --cs-theme-stream-ui-progress-bar-stroke-hover: var(
            --cs-theme-stream-ui-paddle-progresss-color
          );
          --cs-theme-stream-ui-progress-bar-fill-hover: var(
            --cs-theme-stream-ui-paddle-progresss-color
          );
          --cs-theme-stream-ui-progress-indicator-default: #878787;
          --cs-theme-stream-ui-progress-indicator-hover: #bfbfbf;
        }
        .cspl-stream__grid {
          display: flex;
        }
        :host([not-streamable]) .cspl-stream__grid {
          justify-content: center;
          margin: 0 auto;
        }
        @media only screen and (min-width: 320px) {
          .cspl-stream__grid {
            width: calc(
              100% - (var(--cs-rail-mobile) * 2) + var(--cs-gutter-mobile)
            );
          }
        }
        @media only screen and (min-width: 768px) {
          .cspl-stream__grid {
            width: calc(
              100% - (var(--cs-rail-tablet-portrait) * 2) +
                var(--cs-gutter-tablet-portrait)
            );
          }
        }
        @media only screen and (min-width: 1024px) {
          .cspl-stream__grid {
            width: calc(
              100% - (var(--cs-rail-tablet-landscape) * 2) +
                var(--cs-gutter-tablet-landscape)
            );
          }
        }
        @media only screen and (min-width: 1456px) {
          .cspl-stream__grid {
            width: calc(
              100% - (var(--cs-rail-desktop) * 2) + var(--cs-gutter-desktop)
            );
          }
        }
        cs-containers {
          display: flex;
          min-width: 0;
          transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        }
        @media only screen and (min-width: 768px) {
          cs-containers {
            min-width: unset;
          }
        }
        cs-containers[no-animation] {
          transition: none;
        }
        cs-container {
          flex: 0 0 auto;
          margin: 0 calc(var(--cs-gutter-mobile) / 2);
          max-width: calc(100% - var(--cs-gutter-mobile));
          width: calc(
            (6 * var(--cs-column-mobile)) + (5 * var(--cs-gutter-mobile))
          );
          will-change: transform;
        }
        @media only screen and (min-width: 768px) {
          cs-container {
            margin: 0 calc(var(--cs-gutter-tablet-portrait) / 2);
            max-width: unset;
            width: calc(
              (6 * var(--cs-column-tablet-portrait)) +
                (5 * var(--cs-gutter-tablet-portrait))
            );
          }
        }
        @media only screen and (min-width: 1024px) {
          cs-container {
            margin: 0 calc(var(--cs-gutter-tablet-landscape) / 2);
            width: calc(
              (4 * var(--cs-column-tablet-landscape)) +
                (3 * var(--cs-gutter-tablet-landscape))
            );
          }
        }
        @media only screen and (min-width: 1456px) {
          cs-container {
            margin: 0 calc(var(--cs-gutter-desktop) / 2);
            width: calc(
              (4 * var(--cs-column-desktop)) + (3 * var(--cs-gutter-desktop))
            );
          }
        }
        cs-container[filtered] {
          opacity: 0;
          pointer-events: none;
          position: absolute;
        }
        .cspl-stream__mask {
          overflow: hidden;
          position: relative;
        }
        .cspl-stream__mask-offset {
          transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          will-change: opacity;
        }
        @media only screen and (max-width: 767px) {
          .cspl-stream__mask-offset {
            display: flex;
            justify-content: center;
          }
        }
        :host([not-streamable]) .cspl-stream__mask-offset {
          transform: none;
        }
        :host([filtering]) .cspl-stream__mask-offset {
          opacity: 0;
        }
        .cspl-stream__controls {
          align-items: center;
          color: var(--cs-light-color);
          display: flex;
          height: 40px;
          justify-content: center;
          margin-bottom: 20px;
          margin-top: 8px;
          pointer-events: inherit;
          transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        }
        @media only screen and (min-width: 768px) {
          .cspl-stream__controls {
            margin-top: 32px;
          }
        }
        :host([not-streamable]) .cspl-stream__controls {
          display: none;
        }
        .cspl-stream__control {
          position: absolute;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        }
        .cspl-stream__control iron-icon {
          color: var(--cs-theme-stream-ui-paddle-default);
          height: 40px;
          width: 40px;
        }
        @media only screen and (min-width: 768px) {
          .cspl-stream__control:not([is-disabled]):hover {
            cursor: pointer;
            transform: scale(1.125);
            transition: transform 0.18s ease-out;
          }
        }
        .cspl-stream__control[is-disabled] iron-icon {
          color: var(--cs-theme-stream-ui-paddle-disabled);
        }
        .cspl-stream__control-forward {
          right: calc(50% - 130px);
        }
        .cspl-stream__control-back {
          left: calc(50% - 130px);
        }
        .cspl-stream__control-back iron-icon {
          transform: rotate(180deg);
        }
        @media only screen and (min-width: 768px) {
          .cspl-stream__control-back {
            left: calc(50% - 160px);
          }
          .cspl-stream__control-forward {
            right: calc(50% - 160px);
          }
        }
        .cspl-stream__control-progress-bar {
          background-color: transparent;
          border: 2px solid
            var(--cs-theme-stream-ui-progress-bar-stroke-default);
          border-radius: 45px;
          height: 16px;
          position: relative;
          width: 140px;
        }
        @media only screen and (max-width: 767px) {
          .cspl-stream__control-progress-bar {
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }
        @media only screen and (min-width: 768px) {
          .cspl-stream__control-progress-bar {
            width: 200px;
          }
          .cspl-stream__control-progress-bar:hover {
            background-color: var(--cs-theme-stream-ui-progress-bar-fill-hover);
            border-color: var(--cs-theme-stream-ui-progress-bar-stroke-hover);
          }
        }
        .cspl-stream__control-progress-indicator {
          background-color: var(
            --cs-theme-stream-ui-progress-indicator-default
          );
          border-radius: 45px;
          height: calc(100% + 4px);
          margin: -2px;
          position: absolute;
          transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
          width: 50px;
          will-change: transform;
        }
        @media only screen and (max-width: 767px) {
          .cspl-stream__control-progress-indicator {
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }
        @media only screen and (min-width: 768px) {
          .cspl-stream__control-progress-indicator {
            width: 70px;
          }
          .cspl-stream__control-progress-indicator:hover {
            background-color: var(
              --cs-theme-stream-ui-progress-indicator-hover
            );
          }
        }
        .cspl-stream__control-progress-indicator[no-animation] {
          transition: none;
        }
        .cspl-stream__simple-filter {
          margin-bottom: var(--cs-medium-component-space-mobile);
        }
        @media only screen and (min-width: 768px) {
          .cspl-stream__simple-filter {
            margin-bottom: var(--cs-medium-component-space-tablet-portrait);
          }
        }
        @media only screen and (min-width: 1024px) {
          .cspl-stream__simple-filter {
            margin-bottom: var(--cs-medium-component-space-tablet-landscape);
          }
        }
        @media only screen and (min-width: 1456px) {
          .cspl-stream__simple-filter {
            margin-bottom: var(--cs-medium-component-space-desktop);
          }
        }
      </style>
      <div class="cspl-stream__simple-filter">
        <slot name="simple-filter"></slot>
      </div>
      <div class="cspl-stream__mask">
        <div
          id="maskOffset"
          class="cspl-stream__mask-offset"
          on-track="_handleTrackEvent"
        >
          <div class="cspl-stream__grid" id="grid">
            <cs-containers id="containers"></cs-containers>
          </div>
        </div>
        <div id="controls" class="cspl-stream__controls">
          <div
            is-disabled$="[[ _backDisabled ]]"
            class="cspl-stream__control cspl-stream__control-back"
          >
            <iron-icon
              icon="cs-action-40:paddle"
              on-click="_onBackTap"
            ></iron-icon>
          </div>
          <div
            id="streamProgressBar"
            class="cspl-stream__control-progress-bar"
            on-click="_setProgressBarPosition"
          >
            <div
              id="progressIndicator"
              class="cspl-stream__control-progress-indicator"
              on-track="_handleProgressIndicatorTrack"
            ></div>
          </div>
          <div
            is-disabled$="[[ _forwardDisabled ]]"
            class="cspl-stream__control cspl-stream__control-forward"
          >
            <iron-icon
              icon="cs-action-40:paddle"
              on-click="_onForwardTap"
            ></iron-icon>
          </div>
        </div>
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super(),
      (this._handleMouseDown = this._handleMouseDown.bind(this)),
      (this._handleMouseUp = this._handleMouseUp.bind(this)),
      (this._handleTouchmoveEvent = this._handleTouchmoveEvent.bind(this));
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  _computeNotStreamable(e, L) {
    return L && e > L;
  }
  _computeForwardDisabled(e) {
    return this.rtl ? e <= 0 : e <= -1 * this._maxScrollDistance;
  }
  _computeBackDisabled(e) {
    return this.rtl ? e >= this._maxScrollDistance : e >= 0;
  }
  _computeViewableItems(e, L, t) {
    if (e && L && t) {
      var a = Math.floor(e / L);
      return (
        e - L * a - t.spacing / 2 > t.width && a++,
        a > this._totalItems && (a = this._totalItems),
        a || 1
      );
    }
    return 1;
  }
  _computeMaxScrollDistance(e, L, t) {
    if (L > e) {
      var a = 0;
      return (
        this._viewportWidth < 768 && t && (a = this._viewportWidth - t),
        L - e + a
      );
    }
    return 0;
  }
  _computeItemWidth(e) {
    var { width: L, spacing: t } = e;
    return L + t;
  }
  _computeContainerWidth(e, L) {
    return L * e.length;
  }
  _computeFilteredItems(e, L) {
    return e.filter(e => {
      return e.getAttribute('filter-key') === L || 'all' === L;
    });
  }
  _updateUIColor(e) {
    'dark' === e &&
      ((this.paddleDefaultColor = '#161616'),
      (this.progressIndicatorDefaultColor = '#878787'),
      (this.progressIndicatorActiveColor = '#4f4f4f'),
      (this.paddleDisableProgressBarDefaultColor = '#bfbfbf'));
    var L = Object.assign(
      {},
      this.paddleDefaultColor && {
        '--cs-theme-stream-ui-paddle-default': this.paddleDefaultColor,
      },
      this.progressIndicatorDefaultColor && {
        '--cs-theme-stream-ui-progress-indicator-default': this
          .progressIndicatorDefaultColor,
      },
      this.progressIndicatorActiveColor && {
        '--cs-theme-stream-ui-progress-indicator-hover': this
          .progressIndicatorActiveColor,
      },
      this.paddleDisableProgressBarDefaultColor && {
        '--cs-theme-stream-ui-paddle-progresss-color': this
          .paddleDisableProgressBarDefaultColor,
      }
    );
    this.updateStyles(L);
  }
  _setTileDisplayOptions() {
    [...this.shadowRoot.querySelectorAll('[slot="tile"]')].forEach(e => {
      (e.type = this.tileType),
        (e.textAlign = this.tileTextAlign),
        (e.textColor = this.tileTextColor),
        (e.color = this.tileColor),
        (e.mediaShape = this.tileMediaShape),
        (e.scrimColor = this.tileScrimColor),
        (e.scrimOpacity = this.tileScrimOpacity),
        (e.hideMedia = this.hideMedia),
        (e.hideEyebrow = this.hideEyebrow),
        (e.hideTitle = this.hideTitle),
        (e.hideCopy = this.hideCopy),
        (e.hideCtas = this.hideCtas),
        (e.hideTags = this.hideTags),
        (e.hideBrandStroke = this.hideBrandStroke),
        (e.hideByline = this.hideByline),
        (e.mediaSize = this.mediaSize),
        (e.context = 'cs-stream');
    });
  }
  _getItemDimensions(e) {
    var { marginLeft: L, marginRight: t, width: a } = window.getComputedStyle(
      e
    );
    return {
      width: parseFloat(a),
      spacing: parseFloat(L) + parseFloat(t),
    };
  }
  _onBackTap() {
    this._scroll(1);
  }
  _onForwardTap() {
    this._scroll(-1);
  }
  _setProgressBarPosition(e) {
    if (!this.isPhone && !this._isDragging) {
      var L = 0,
        t = e.clientX - this._progressInitialX,
        a = this._progressBarPositionX + this._progressBarWidth / 2;
      this.rtl &&
        ((t = this._progressWidth - t),
        (a = -1 * this._progressBarPositionX + this._progressBarWidth / 2)),
        t > a ? (L = -1) : t < a && (L = 1),
        this.rtl && (L *= -1),
        this.debounce(
          'scrollDebounceJob',
          () => {
            var e = Math.abs(t - a),
              i = (e / this._maxProgressClientX) * this._maxScrollDistance;
            this._scrollTo(...this._getPosition(L, i, e));
          },
          this._debounceDelay
        );
    }
  }
  _checkPosition(e, L) {
    var t = e,
      a = L,
      i = this._maxProgressClientX,
      r = 0,
      s = 0,
      o = -1 * this._maxScrollDistance;
    return (
      this.rtl &&
        ((i = 0),
        (r = -1 * this._maxProgressClientX),
        (s = this._maxScrollDistance),
        (o = 0)),
      a < r ? (a = r) : a > i && (a = i),
      t < o ? (t = o) : t > s && (t = s),
      [t, a]
    );
  }
  _getPosition(e, L, t) {
    var a = this._streamPositionX + L * e,
      i = this._progressBarPositionX - t * e;
    return this._checkPosition(a, i);
  }
  _scroll(e) {
    (-1 === e && this._forwardDisabled) ||
      (1 === e && this._backDisabled) ||
      this.debounce(
        'scrollDebounceJob',
        () => {
          var L = this._itemWidth * this._viewableItems,
            t = (L / this._maxScrollDistance) * this._maxProgressClientX;
          this._scrollTo(...this._getPosition(e, L, t));
        },
        this._debounceDelay
      );
  }
  _scrollTo(e, L) {
    var t = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
    this.toggleAttribute('no-animation', t, this.$.containers),
      this.toggleAttribute('no-animation', t, this.$.progressIndicator),
      this._setPositionX(e, L);
  }
  _setPositionX(e, L) {
    (this._streamPositionX = e),
      (this._progressBarPositionX = L),
      this.transform('translateX('.concat(e, 'px)'), this.$.containers),
      this.transform('translateX('.concat(L, 'px)'), this.$.progressIndicator);
  }
  _reflowContent() {
    var e = !(arguments.length > 0 && void 0 !== arguments[0]) || arguments[0];
    (this._viewableWidth = this.$.maskOffset.clientWidth),
      (this._viewportWidth === window.innerWidth && e) ||
        !this._items.length ||
        (e && (this._streamPositionX = null),
        (this._viewportWidth = window.innerWidth),
        (this._itemDimensions = this._getItemDimensions(this._items[0])),
        this._initProgressBar(),
        this._setPositionX(0, 0));
  }
  _applyCurrentFilterOnStream() {
    this._items.forEach(e => {
      var L = this._filteredItems.includes(e);
      this.toggleAttribute('filtered', !L, e);
    }),
      this._reflowContent(!1),
      requestAnimationFrame(() => {
        this.filtering = !1;
      });
  }
  _setStreamFilter(e) {
    e !== this.filterKey &&
      ((this.filterKey = e),
      (this.filtering = !0),
      window.setTimeout(() => {
        this._applyCurrentFilterOnStream();
      }, 250));
  }
  _handleFilterEvent(e) {
    var L = e.detail.filterKey;
    this._setStreamFilter(L.toLowerCase());
  }
  _handleTrackEvent(e) {
    this.notStreamable || this._setTrackAction(e.detail);
  }
  _handleTouchmoveEvent(e) {
    this._canScrollHorizontal && e.cancelable && e.preventDefault();
  }
  _setTrackAction(e) {
    switch (e.state) {
      case 'start':
        (this._startTrackStreamX = this._streamPositionX),
          (this._stratTrackProgressBarX = this._progressBarPositionX),
          (this._startTrackX = e.x),
          (this._startTrackY = e.y),
          (this._startTrackTime = Date.now());
        var L = Math.abs(e.dx),
          t = Math.abs(e.dy);
        (this._controlIconDisabled =
          this._forwardDisabled || this._backDisabled),
          (this._canScrollHorizontal = !(
            Math.abs(Math.atan2(t, L)) >=
            0.25 * Math.PI
          ));
        break;
      case 'track':
        if (!1 === this._canScrollHorizontal) return;
        var a = Math.abs(e.ddx);
        if (Math.abs(e.ddy) > a) return;
        var i = 0;
        e.ddx > 0 ? (i = 1) : e.ddx < 0 && (i = -1);
        var r = a,
          s = (r / this._maxScrollDistance) * this._maxProgressClientX;
        this._scrollTo(...this._getPosition(i, r, s), !0);
        break;
      case 'end':
        this._handleStreamEndTrack(e);
    }
  }
  _handleStreamEndTrack(e) {
    if (this._canScrollHorizontal) {
      var L = Math.abs(e.x - this._startTrackX);
      if (!(Math.abs(e.y - this._startTrackY) > L)) {
        var t = e.x - this._startTrackX < 0 ? -1 : 1,
          a = this.minTrackDistance || this._itemWidth,
          i = this.midTrackDistance || 3 * this._itemWidth,
          r = (Date.now() - this._startTrackTime) / 1e3,
          s = a / this.midTrackTime,
          o = i / this.midTrackTime,
          l = L / r,
          n =
            this._viewableWidth -
            this._itemWidth * this._viewableItems -
            this._itemDimensions.spacing / 2,
          c = 0,
          d = this._itemDimensions.width / 4;
        n < 0 && (n = 0),
          this._viewportWidth >= 768
            ? (((this._controlIconDisabled || -1 === t) && L + n >= d) ||
                (1 === t && L >= d)) &&
              (c = 1)
            : ((n =
                (this._viewableWidth - this._itemWidth * this._viewableItems) /
                  2 -
                this._itemDimensions.spacing) < 0 && (n = 0),
              L + n >= d && (c = 1)),
          c > 0 &&
            (L <= a
              ? (c = l < o ? 1 : 3)
              : L <= i
              ? (c = l <= s ? 1 : l >= o ? 5 : 3)
              : L > i && (c = l <= s ? 2 : l <= o ? 5 : 7));
        var h = this._viewableItems;
        this._viewableWidth - this._itemWidth * this._viewableItems >=
          this._itemWidth / 2 && h++;
        var C = this._itemWidth * c,
          p = (this._maxProgressClientX / (this._totalItems - h)) * c;
        this.debounce(
          'scrollDebounceJob',
          () => {
            var e =
                Math.round(
                  (this._startTrackStreamX + C * t) / this._itemWidth
                ) * this._itemWidth,
              L = this._stratTrackProgressBarX - p * t;
            this._scrollTo(...this._checkPosition(e, L));
          },
          this._debounceDelay
        );
      }
    }
  }
  _handleProgressIndicatorTrack(e) {
    if (!this.isPhone) {
      var L = e.detail;
      switch (L.state) {
        case 'start':
          this._isDragging = !0;
          break;
        case 'track':
          if (!L.ddx) return;
          var t = 0;
          L.ddx > 0 ? (t = -1) : L.ddx < 0 && (t = 1);
          var a = Math.abs(L.ddx),
            i = (a / this._maxProgressClientX) * this._maxScrollDistance;
          this._scrollTo(...this._getPosition(t, i, a), !0);
          break;
        case 'end':
          this._trackDisabled = !0;
      }
    }
  }
  _setup() {
    var e = document.createDocumentFragment();
    (this._items = [...this.querySelectorAll('[slot="container"]')]),
      this._items.forEach(L => e.appendChild(L)),
      this.$.containers.appendChild(e),
      (this._totalItems = this.$.containers.children.length),
      setTimeout(() => {
        this._setTileDisplayOptions(), this._reflowContent();
      });
  }
  _initReflow() {
    setTimeout(() => {
      this._items &&
        this._itemDimensions &&
        !this._itemDimensions.width &&
        (this._reflowContent(), this._initReflow());
    }, 500);
  }
  _initProgressBar() {
    this.notStreamable ||
      (this._viewportWidth < 768
        ? ((this._progressWidth = 136), (this._progressBarWidth = 46))
        : ((this._progressWidth = 196), (this._progressBarWidth = 66)),
      (this._progressInitialX = this.$.streamProgressBar.offsetLeft + 2),
      (this._maxProgressClientX =
        this._progressWidth - this._progressBarWidth));
  }
  _handleMouseDown(e) {
    this._mousePosition = e.clientX;
  }
  _handleMouseUp(e) {
    this._mousePosition === e.clientX
      ? (this._isDragging = !1)
      : (this._isDragging = !0);
  }
  connectedCallback() {
    super.connectedCallback(),
      this._initProgressBar(),
      setTimeout(() => {
        this._setup(), this._initReflow();
      }),
      this.addEventListener('iron-resize', this._reflowContent),
      this.addEventListener('filter', this._handleFilterEvent),
      this.$.streamProgressBar.addEventListener('mouseup', this._handleMouseUp),
      this.$.streamProgressBar.addEventListener(
        'mousedown',
        this._handleMouseDown
      ),
      this.$.maskOffset.addEventListener(
        'touchmove',
        this._handleTouchmoveEvent
      ),
      this.setScrollDirection('y', this.$.maskOffset);
  }
}

customElements.define('cs-stream', CsStream);
