/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsGoogleAnalyticsMixin } from "./mixins/cs-google-analytics-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";

const t = CsMixin,
	a = CsGoogleAnalyticsMixin;

/**
 * `cs-local-nav-shelf-sub-nav-section-link` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLocalNavShelfSubNavSectionLink extends t(a(PolymerElement)) {
	static get properties() {
		return {
			theme: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			linkText: String,
			linkHref: String,
			linkTarget: String,
			linkImage: String,
			open: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style>
				:host {
					display: block;
					font-family: var(--cs-theme-local-nav-shelf-font-family);
					font-size: var(--cs-theme-local-nav-shelf-sub-nav-link-font-size-mobile);
					line-height: 1em;
					margin: 20px 0 20px 0;
					opacity: 0;
					transition: 0.4s cubic-bezier(0.5, 0.25, 0.015, 1);
					transition-delay: 0.1s;
					transition-property: transform, opacity;
				}

				@media only screen and (min-width: 1024px) {
					:host {
						font-size: var(--cs-theme-local-nav-shelf-sub-nav-link-font-size-tablet-landscape);
					}
				}

				@media only screen and (min-width: 1456px) {
					:host {
						font-size: var(--cs-theme-local-nav-shelf-sub-nav-link-font-size-desktop);
					}
				}

				:host([open]) {
					opacity: 1;
					transform: translateY(5px);
				}

				.cspl-local-nav__shelf-sub-nav-section-link {
					color: var(--cs-theme-local-nav-font-color-dark);
					font-weight: 300;
					margin: 5px 0;
					text-decoration: none;
				}

				:host([theme="light"]) .cspl-local-nav__shelf-sub-nav-section-link {
					color: var(--cs-theme-local-nav-font-color-light);
				}

				.cspl-local-nav__shelf-sub-nav-section-link:hover,
				:host([theme="light"]) .cspl-local-nav__shelf-sub-nav-section-link:hover {
					color: var(--cs-theme-local-nav-font-color-hover);
				}
			</style>
			<a class="cspl-local-nav__shelf-sub-nav-section-link" href="[[ linkHref ]]" target="[[ linkTarget ]]" on-mouseover="_onMouseOver" on-mouseout="_onMouseOut"
				>[[ linkText ]]</a
			>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_onMouseOver() {
		this.dispatchEvent(
			new CustomEvent("nds.shelfSubNavSectionLink.hover", {
				bubbles: !0,
				composed: !0,
				detail: this.linkImage,
			})
		);
	}
	_onMouseOut() {
		this.dispatchEvent(
			new CustomEvent("nds.shelfSubNavSectionLink.hover", {
				bubbles: !0,
				composed: !0,
				detail: this._images,
			})
		);
	}
	_setGAEvent(e = "", t) {
		// jshint ignore:line
		(this.gaEventName = "/ga/nds/local-nav"),
			(this.gaEventPayload = {
				label: e.trim(),
				type: "shelf",
				url: t,
			}); // jshint ignore:line
	} // jshint ignore:line
	connectedCallback() {
		super.connectedCallback(),
			this._setGAEvent(this.linkText, this.linkHref),
			setTimeout(() => {
				this.dispatchEvent(
					new CustomEvent("nds.shelfSubNavSectionLink.image", {
						bubbles: !0,
						composed: !0,
						detail: this.linkImage,
					})
				);
			}, 0); // jshint ignore:line
	}
}

customElements.define("cs-local-nav-shelf-sub-nav-section-link", CsLocalNavShelfSubNavSectionLink);
