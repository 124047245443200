/* jshint ignore:start */
import { GestureEventListeners } from '@polymer/polymer/lib/mixins/gesture-event-listeners.js';
import * as Gestures from '@polymer/polymer/lib/utils/gestures.js';
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

const t = CsMixin,
  a = CsDeviceMixin,
  r = CsGoogleAnalyticsMixin;

/**
 * `cs-game-box` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsGameBox extends t(a(r(GestureEventListeners(PolymerElement)))) {
  static get properties() {
    return {
      _sideToggleIcon: {
        computed: '_toggleSideIcon(open)',
        type: String,
        value: '',
      },
      backgroundImage: String,
      backgroundImageAltText: {
        type: String,
        value: ' ',
      },
      logoUrl: {
        type: String,
        value: '',
      },
      mainLinkTarget: {
        type: String,
        value: '_self',
      },
      mainLinkUrl: String,
      mainLinkTitle: String,
      open: {
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
      },
      saleText: {
        type: String,
        value: '',
      },
      showBackAlt: String,
      showFrontAlt: {
        type: String,
        value: '',
      },
      title: {
        type: String,
        value: ' ',
      },
      context: {
        type: String,
        value: '',
      },
      scrimColor: {
        type: String,
        reflectToAttribute: !0,
        value: 'none',
      },
      scrimOpacity: {
        type: String,
        reflectToAttribute: !0,
        value: 'medium',
      },
      backgroundLinkActive: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      _hasBackgroundClip: {
        type: Boolean,
        value: !1,
      },
      type: {
        type: String,
        value: 'standard',
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-shared-styles-typography-labels"></style>
      <style include="cs-shared-styles-typography-headings"></style>
      <style include="cs-shared-styles-base-headings"></style>
      <style include="cs-cta-shared-styles"></style>
      <style include="cs-shared-styles-base-common"></style>
      <style>
        :host {
          color: var(--cs-light-color);
          display: block;
          height: 0;
          overflow: hidden;
          padding-top: 100%;
          position: relative;
          width: 100%;
        }

        :host(:hover),
        :host([open]) {
          box-shadow: 0 14px 20px 0 rgba(80, 80, 80, 0.24);
        }

        :host([type='simple']:hover),
        :host([type='simple'][open]) {
          box-shadow: none;
        }

        .cspl-game-box__background-link {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        .cspl-game-box__background-link::after {
          bottom: 0;
          content: '';
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: opacity 0.4s ease;
        }

        :host([scrim-color='dark']) .cspl-game-box__background-link::after,
        :host([scrim-color='light']) .cspl-game-box__background-link::after {
          opacity: 0.3;
        }

        :host([scrim-color='dark']) .cspl-game-box__background-link::after {
          background: #000;
        }

        :host([scrim-color='light']) .cspl-game-box__background-link::after {
          background: #fff;
        }

        :host([scrim-opacity='low']) .cspl-game-box__background-link::after {
          opacity: 0.15;
        }

        :host([scrim-opacity='high']) .cspl-game-box__background-link::after {
          opacity: 0.45;
        }

        :host(:not([is-touch]):hover) .cspl-game-box__background-link::after,
        :host([open]) .cspl-game-box__background-link::after {
          background: linear-gradient(
            -83deg,
            var(--cs-theme-light-gradient),
            var(--cs-theme-dark-gradient)
          );
          opacity: 0.9;
        }

        :host([type='simple']:not([is-touch]):hover)
          .cspl-game-box__background-link::after,
        :host([type='simple'][open]) .cspl-game-box__background-link::after {
          display: none;
        }

        .cspl-game-box__background-image {
          display: block;
        }

        .cspl-game-box__background-image,
        .cspl-game-box__background-link cs-media {
          height: 100%;
          left: 0;
          margin: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        .cspl-game-box__background-link cs-media,
        :host(:not([is-touch]):hover)
          .cspl-game-box__content[has-background-clip]
          .cspl-game-box__background-image,
        :host([open])
          .cspl-game-box__content[has-background-clip]
          .cspl-game-box__background-image {
          display: none;
        }

        :host(:not([is-touch]):hover) .cspl-game-box__background-link cs-media,
        :host([open]) .cspl-game-box__background-link cs-media {
          display: block;
          height: 100%;
        }

        .cspl-game-box__content {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          left: 0;
          overflow: hidden;
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100%;
        }

        .cspl-game-box__special-offer:not([hidden]) {
          background-color: var(--cs-theme-light-gradient);
          border-radius: 0 0 0.2em 0.2em;
          font-weight: 700;
          left: 50%;
          line-height: 1;
          margin: 0;
          max-height: 3em;
          padding: 11px 20px;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          top: 0;
          transform: translateX(-50%);
          transition: max-height 0.4s ease, opacity 0.4s ease, padding 0.4s ease;
        }

        @media only screen and (min-width: 1024px) {
          .cspl-game-box__special-offer:not([hidden]) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        :host(:not([is-touch]):hover) .cspl-game-box__special-offer,
        :host([open]) .cspl-game-box__special-offer {
          max-height: 0;
          opacity: 0;
          padding: 0;
        }

        :host([type='simple']) .cspl-game-box__special-offer {
          display: none;
        }

        .cspl-game-box__logo {
          filter: drop-shadow(8px 7px 23px #000);
          font-size: 1.6rem;
          font-weight: 700;
          left: 50%;
          max-height: 20%;
          pointer-events: none;
          position: absolute;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          transition: 0.4s ease;
          transition-property: transform;
        }

        :host([type='simple']) .cspl-game-box__logo {
          transition: transform 0.3s;
        }

        :host(:not([is-touch]):hover) .cspl-game-box__logo,
        :host([open]) .cspl-game-box__logo {
          transform: translate3d(-50%, calc(-100% - 32px), 0);
        }

        :host([type='simple']:not([is-touch]):hover) .cspl-game-box__logo,
        :host([type='simple'][open]) .cspl-game-box__logo {
          transform: scale(1.1) translate3d(-45%, -40%, 0);
        }

        .cspl-game-box__features,
        .cspl-game-box__logo {
          max-width: calc(
            (3 * var(--cs-column-mobile)) + (2 * var(--cs-gutter-mobile))
          );
        }

        @media only screen and (min-width: 768px) {
          .cspl-game-box__features,
          .cspl-game-box__logo {
            max-width: calc(
              (2 * var(--cs-column-tablet-portrait)) +
                (1 * var(--cs-gutter-tablet-portrait))
            );
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-game-box__features,
          .cspl-game-box__logo {
            max-width: calc(
              (2 * var(--cs-column-tablet-landscape)) +
                (1 * var(--cs-gutter-tablet-landscape))
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-game-box__features,
          .cspl-game-box__logo {
            max-width: calc(
              (2 * var(--cs-column-desktop)) + (1 * var(--cs-gutter-desktop))
            );
          }
        }

        .cspl-game-box__features {
          left: 50%;
          opacity: 0;
          overflow: hidden;
          pointer-events: none;
          position: absolute;
          text-align: center;
          top: 50%;
          transform: translate3d(-50%, -12px, 0);
        }

        :host(:not([is-touch]):hover) .cspl-game-box__features,
        :host([open]) .cspl-game-box__features {
          display: block;
          opacity: 1;
          overflow: visible;
          pointer-events: auto;
        }

        :host([type='simple']:not([is-touch]):hover) .cspl-game-box__features,
        :host([type='simple'][open]) .cspl-game-box__features {
          display: none;
        }

        .cspl-link {
          align-items: center;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          color: #a3a3a3;
          display: flex;
          flex-direction: column;
          height: 40px;
          justify-content: center;
          text-decoration: none;
          transition: 0.4s ease;
          transition-property: transform;
          white-space: nowrap;
        }

        .cspl-link:nth-of-type(2) {
          transform: translate3d(0, -40px, 0);
        }

        .cspl-link:nth-of-type(3) {
          transform: translate3d(0, -80px, 0);
        }

        :host(:not([is-touch]):hover) .cspl-link,
        :host([open]) .cspl-link {
          transform: translate3d(0, 0, 0);
        }

        .cspl-link:hover,
        :host([background-link-active]) .cspl-link--main {
          color: var(--cs-light-color);
        }

        :host(:not([is-touch]):hover) .b2.cspl-link--first,
        :host([open]) .b2.cspl-link--first {
          margin-top: 0;
        }

        :host(:not([is-touch]):hover) .b2.cspl-link--last,
        :host([open]) .b2.cspl-link--last {
          border-bottom: none;
          margin-bottom: 0;
        }

        .cspl-game-box__side-toggle {
          cursor: pointer;
          display: flex;
          margin: 0 6px;
          padding: 10px;
          position: absolute;
          right: 0;
          top: 0;
        }

        :host([type='simple']) .cspl-game-box__side-toggle {
          display: none;
        }

        .cspl-game-box__side-toggle > iron-icon {
          height: 20px;
          width: 20px;
        }

        .cspl-game-box__ctas {
          align-items: flex-end;
          bottom: 8px;
          flex-direction: column;
          pointer-events: auto;
          position: absolute;
          right: 16px;
        }

        :host([type='simple']) .cspl-game-box__ctas {
          display: none;
        }

        .cspl-game-box__ctas cs-cta::before {
          content: none !important;
          display: none !important;
        }

        .cspl-game-box__ctas cs-cta {
          margin-right: 0;
        }
      </style>
      <a
        class="cspl-game-box__background-link"
        href="[[ mainLinkUrl ]]"
        title="[[ mainLinkTitle ]]"
        id="backgroundLink"
        on-tap="_onTap"
        on-mouseenter="_onMouseEnter"
        on-mouseleave="_onMouseLeave"
        target="[[ mainLinkTarget ]]"
      >
        <template is="dom-if" if="[[ backgroundImage ]]">
          <cs-lazy-image>
            <img
              data-src$="[[ backgroundImage ]]"
              alt="[[ backgroundImageAltText ]]"
              class="cspl-game-box__background-image"
            />
          </cs-lazy-image>
        </template>
      </a>
      <div
        class="cspl-game-box__content"
        has-background-clip$="[[ _hasBackgroundClip ]]"
      >
        <span class="l2 cspl-game-box__special-offer" hidden$="[[ !saleText ]]"
          >[[ saleText ]]</span
        >
        <cs-lazy-image>
          <span
            class="cspl-game-box__logo"
            hidden$="[[ !_computeHideTextLong(logoUrl) ]]"
            >[[ title ]]</span
          >
          <img
            alt="[[ title ]]"
            data-src$="[[ logoUrl ]]"
            class="cspl-game-box__logo"
            hidden$="[[ !logoUrl ]]"
          />
        </cs-lazy-image>
        <div class="cspl-game-box__features" id="featureLinks"></div>
      </div>
      <span
        aria-label$="[[ _getToggleAltText(open, showFrontAlt, showBackAlt) ]]"
        class="cspl-game-box__side-toggle"
        hidden$="[[ !touch ]]"
        on-tap="_toggleSide"
      >
        <iron-icon icon="[[ _sideToggleIcon ]]"></iron-icon>
      </span>
      <div class="cspl-game-box__ctas ctas" id="gameBoxCtas"></div> `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  _addFeatureLinkClasses() {
    const e = 'cspl-link';
    const t = document.createDocumentFragment();
    const a = [...this.querySelectorAll(`.${e}`)];
    a.forEach((a, r, n) => {
      a.classList.add('b2', 'b2-light', `${e}-${r}`),
        (a.context = this.context),
        0 === r && a.classList.add(`${e}--first`),
        r === n.length - 1 && a.classList.add(`${e}--last`),
        this.mainLinkUrl &&
          this.mainLinkUrl === a.getAttribute('href') &&
          a.classList.add(`${e}--main`),
        Gestures.addListener(a, 'tap', () => {
          this.gaEvent('/ga/nds/gamebox', {
            context: this.context,
            text: a.innerHTML.trim(),
            url: a.href,
          });
        }),
        t.appendChild(a); // jshint ignore:line
    }),
      this.$.featureLinks.appendChild(t); // jshint ignore:line
  }
  _getToggleAltText(e, t, a) {
    return e ? t : a;
  }
  _toggleSideIcon(e) {
    return this.isTouch
      ? e
        ? 'cs-action-16:close'
        : 'cs-action-16:ellipsis'
      : null;
  }
  _toggleSide() {
    this.open = !this.open;
  }
  _onTap() {
    this.gaEvent('/ga/nds/game-box', {
      context: this.context,
      text: this.mainLinkTitle,
      url: this.mainLinkUrl,
    });
  }
  _onMouseEnter() {
    this.backgroundLinkActive = !0;
  }
  _onMouseLeave() {
    this.backgroundLinkActive = !1;
  }
  _computeHideTextLong(e) {
    return !e.length;
  }
  _buildGraphicCtas() {
    const e = document.createDocumentFragment();
    const t = [...this.querySelectorAll('cs-cta')];
    t.forEach(t => {
      (t.context = this.context), e.appendChild(t); // jshint ignore:line
    }),
      this.$.gameBoxCtas.appendChild(e); // jshint ignore:line
  }
  _appendMedia() {
    const e = this.querySelector('cs-media');
    e && ((this._hasBackgroundClip = !0), this.$.backgroundLink.appendChild(e)); // jshint ignore:line
  }
  connectedCallback() {
    super.connectedCallback();
    this._buildGraphicCtas();
    this._appendMedia();
    this._addFeatureLinkClasses();
  }
}

customElements.define('cs-game-box', CsGameBox);
