import { dedupingMixin } from "@polymer/polymer/lib/utils/mixin.js";

const e = window.nds.getSlottedChildren;
/**
 * CsCopyParentMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsCopyParentMixin = base =>
	/**
	 * @polymerMixinClass
	 */
	class extends base {
		static get properties() {
			return {
				hideCopy: {
					type: Boolean,
					value: !1
				},
				_hideCopy: {
					type: Boolean,
					value: !0,
					computed: "_computeHideCopy(_copy, hideCopy)"
				},
				_copy: {
					type: Array,
					value() {
						return [];
					}
				}
			};
		}
		_computeHideCopy(e, t) {
			return !e.length || t;
		}
		_getCopy() {
			this._copy = e(this, ["copy"]);
		}
		connectedCallback() {
			super.connectedCallback(), this._getCopy(), this.shadowRoot.querySelector('slot[name="copy"]').addEventListener("slotchange", this._getCopy.bind(this)); // jshint ignore:line
		}
	};

export const CsCopyParentMixin = dedupingMixin(internalCsCopyParentMixin);
