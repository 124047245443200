import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';
import {
    CsMixin
} from './cs-mixin.js';
import {
    CsScrollToElementMixin
} from './cs-scroll-to-element-mixin.js';


window.nds = window.nds || {};
var e = window.nds;
e.getAncestorByAttribute = (e, t) => {
  for (let a = e; a.parentElement; ) {
    if (a.hasAttribute(t)) return a;
    a = a.parentElement;
  }
  return null;
}
e.getSlottedChildren = (e, t = ['']) => {
  const a = e.shadowRoot;
  return a
    ? t.reduce((e, t) => {
        const r = a.querySelector(t.length ? `slot[name="${t}"]` : 'slot');
        if (!r) return e;
        const n = r
          .assignedNodes({
            flatten: !0,
          })
          .filter(e => e.nodeType === Node.ELEMENT_NODE);
        return [...e, ...n];
      }, [])
    : [];
}
const t = CsMixin,
    a = CsScrollToElementMixin,
    r = e.getSlottedChildren,
    n = e.getAncestorByAttribute;

/**
 * CsCatalogMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsCatalogMixin = base =>
  /**
   * @polymerMixinClass
   */
  class extends t(base, a) {
    static get properties() {
      return {
        pagination: Object,
        loadMore: Object,
        filter: Object,
        filterUrl: String,
        _filterPath: String,
        totalCount: {
          type: Number,
        },
        ajaxUrl: {
          type: String,
        },
        processing: {
          type: Boolean,
          value: !1,
          reflectToAttribute: !0,
        },
        processingLayout: {
          type: String,
          value: null,
          reflectToAttribute: !0,
        },
        processingIconColor: {
          type: String,
          value: 'light',
          reflectToAttribute: !0,
        },
      };
    }
    get filter() {
      return this.querySelector('cs-filter');
    }
    get loadMore() {
      const e = r(this, ['load-more']);
      return e.length ? e[0] : null;
    }
    get pagination() {
      const e = r(this, ['pagination']);
      return e.length ? e[0] : null;
    }
    get _catalogItemsContainer() {
      return this.$.catalogContainer;
    }
    get _catalogItems() {
      return r(this, ['container']);
    }
    _showPage(e, t) {
      const a = e * t - e;
      setTimeout(this._showRows.bind(this, a, a + e));
    }
    _showRows(e, t) {
      [...this._catalogItems].forEach((a, r) => {
        r >= e && r < t
          ? a.removeAttribute('hidden')
          : a.setAttribute('hidden', !0); // jshint ignore:line
      });
    }
    _scrollToElementTop() {
      let e = this;
      let t = 0;
      if (this.isFirstLoad) return void (this.isFirstLoad = !1);
      for (; e; ) (t += e.offsetTop), (e = e.offsetParent); // jshint ignore:line
      window.scrollTo(0, t - 150);
    }
    _processCatalogItems() {}
    _setupPagination() {
      this.pagination &&
        ((this.processingLayout = 'overlay'),
        this._showPage(this.pagination.pageSize, this.pagination.currentPage),
        (this.pagination.totalCount = this.totalCount),
        this.pagination.addEventListener(
          'pagechange',
          this._onPageChange.bind(this)
        ),
        this._scrollToElementTop()); // jshint ignore:line
    }
    _onPageChange(e) {
      this._scrollToElementTop(),
        this.ajaxUrl
          ? this._getPaginationItems(e)
          : this._showPage(e.detail.pageSize, e.detail.currentPage); // jshint ignore:line
    }
    _setupLoadMore() {
      this.loadMore &&
        ((this.processingLayout = 'inline'),
        this.loadMore.addEventListener('loadmore', this._onLoadMore.bind(this)),
        (this.loadMore.totalCount = this.totalCount),
        this._showRows(0, this.loadMore.end)); // jshint ignore:line
    }
    _onLoadMore(e) {
      this.ajaxUrl
        ? this._getLoadMoreItems(e)
        : this._showRows(0, e.detail.end); // jshint ignore:line
    }
    _setupFilters() {
      this.filter &&
        (this.filter.addEventListener(
          'nds.filter.filterUpdated',
          this._onFilter.bind(this)
        ),
        this.filter.initializeFilter()); // jshint ignore:line
    }
    _onFilter(e) {
      if (this.ajaxUrl) {
        this._filterPath = e.detail ? e.detail.filterPath : '';
        const t = `${this.ajaxUrl}${this._filterPath}`;
        this.filterUrl !== t &&
          ((this.filterUrl = t),
          history.pushState(null, 'Filtered Data', `?${this._filterPath}`),
          (this.processing = !0)); // jshint ignore:line
      }
    }
    _getPaginationItems(e) {
      (this.ajax.url = this._filterPath
        ? `${this.ajaxUrl}/page/${e.detail.currentPage}${this._filterPath}`
        : `${this.ajaxUrl}/page/${e.detail.currentPage}`),
        (this._handlePaginationAjaxError = this._handlePaginationAjaxError.bind(
          this
        )),
        (this._handlePaginationAjaxResponse = this._handlePaginationAjaxResponse.bind(
          this
        )),
        this.ajax.addEventListener('error', this._handlePaginationAjaxError),
        this.ajax.addEventListener(
          'response',
          this._handlePaginationAjaxResponse
        ),
        (this.processing = !0),
        this.ajax.generateRequest(); // jshint ignore:line
    }
    _handlePaginationAjaxError() {
      (this.processing = !1),
        this.ajax.removeEventListener('error', this._handlePaginationAjaxError); // jshint ignore:line
    }
    _handlePaginationAjaxResponse(e) {
      const t = e.detail.response;
      (this._catalogItemsContainer.innerHTML = t),
        this._processCatalogItems(),
        (this.processing = !1),
        this.ajax.removeEventListener(
          'response',
          this._handlePaginationAjaxResponse
        ); // jshint ignore:line
    }
    _getLoadMoreItems(e) {
      const t = Math.ceil(e.detail.end / e.detail.quantity);
      (this.ajax.url = this._filterPath
        ? `${this.ajaxUrl}/page/${t}${this._filterPath}`
        : `${this.ajaxUrl}/page/${t}`),
        (this._handleLoadMoreAjaxError = this._handleLoadMoreAjaxError.bind(
          this
        )),
        (this._handleLoadMoreAjaxResponse = this._handleLoadMoreAjaxResponse.bind(
          this
        )),
        this.ajax.addEventListener('error', this._handleLoadMoreAjaxError),
        this.ajax.addEventListener(
          'response',
          this._handleLoadMoreAjaxResponse
        ),
        (this.processing = !0),
        this.ajax.generateRequest(); // jshint ignore:line
    }
    _handleLoadMoreAjaxError() {
      (this.processing = !1),
        this.ajax.removeEventListener('error', this._handleLoadMoreAjaxError); // jshint ignore:line
    }
    _handleLoadMoreAjaxResponse(e) {
      const t = document
        .createRange()
        .createContextualFragment(e.detail.response);
      this._catalogItemsContainer.appendChild(t),
        this._processCatalogItems(),
        (this.processing = !1),
        this.ajax.removeEventListener(
          'response',
          this._handleLoadMoreAjaxResponse
        ); // jshint ignore:line
    }
    _handleFilterAjaxError() {
      this.processing = !1;
    }
    _handleFilterAjaxResponse(e, t) {
      const a=t.response;
      const r = t.xhr.getResponseHeader('x-total-count');
      this.pagination
        ? ((this.pagination.totalCount = r), (this.pagination.currentPage = 1))
        : this.loadMore &&
          ((this.loadMore.end = this.loadMore.quantity),
          (this.loadMore.totalCount = r),
          this._showRows(0, this.loadMore.end)),
        (this._catalogItemsContainer.innerHTML = a),
        this._processCatalogItems(),
        this._scrollToElementTop(),
        (this.processing = !1); // jshint ignore:line
    }
    _getIconColorFromParent() {
      const e = n(this, 'background-color');
      e && 'light' === e.backgroundColor && (this.processingIconColor = 'dark'); // jshint ignore:line
    }
    connectedCallback() {
      super.connectedCallback(),
        (this.ajax = this.$.csCatalogAjax),
        this.addEventListener('nds.catalog.itemsReady', e => {
          this.totalCount || (this.totalCount = e.detail.items.length),
            this._getIconColorFromParent(),
            this._setupPagination(),
            this._setupLoadMore(),
            this._setupFilters(); // jshint ignore:line
        }); // jshint ignore:line
    }
  };

export const CsCatalogMixin = dedupingMixin(internalCsCatalogMixin);
