import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';

/**
 * `cs-form-item` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsFormItem extends PolymerElement {
  static get properties() {
    return {};
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
    <style include="cs-shared-styles-base-links"></style>
    <style include="cs-shared-styles-typography-headings"></style>
    <style include="cs-shared-styles-typography-paragraphs"></style>
    <style include="cs-shared-styles-common-spacing"></style>
    <style include="cs-cta-shared-styles"></style>
      <style>
        :host,
        *,
        *::after,
        *::before {
          box-sizing: border-box;
        }

        :host {
          color: var(--cs-dark-color);
          font-family: var(--cs-theme-primary-font-family);
          font-size: var(--cs-theme-body-font-size-mobile);
          -webkit-font-smoothing: antialiased;
          line-height: var(--cs-theme-body-line-height-mobile);
        }

        :host([text-color='light']) {
          color: var(--cs-light-color);
        }

        @media only screen and (min-width: 1024px) {
          :host {
            font-size: var(--cs-theme-body-font-size-tablet-landscape);
            line-height: var(--cs-theme-body-line-height-tablet-landscape);
          }
        }

        @media only screen and (min-width: 1456px) {
          :host {
            font-size: var(--cs-theme-body-font-size-desktop);
            line-height: var(--cs-theme-body-line-height-desktop);
          }
        }

        [hidden] {
          display: none !important;
        }

        :host {
          display: block;
          width: 100%;
        }

        :host(:not(:first-child)) {
          margin-top: 16px;
        }

        @media only screen and (min-width: 768px) {
          :host(:not(:first-child)) {
            margin-top: 0;
          }
        }

        :host ::slotted(cs-select) {
          width: 100%;
        }
      </style>
      <slot></slot>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
}

customElements.define('cs-form-item', CsFormItem);
