/* jshint ignore:start */
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements';
import { CsAgeCheckMixin } from './mixins/cs-age-check-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

var e = window.nds;

e.getSlottedChildren = (e, t = ['']) => {
  const a = e.shadowRoot;
  return a
    ? t.reduce((e, t) => {
        const r = a.querySelector(t.length ? `slot[name="${t}"]` : 'slot');
        if (!r) return e;
        const n = r
          .assignedNodes({
            flatten: !0,
          })
          .filter(e => e.nodeType === Node.ELEMENT_NODE);
        return [...e, ...n];
      }, [])
    : [];
};
const l = CsMixin,
  r = CsAgeCheckMixin,
  d = e.getSlottedChildren;

/**
 * `cs-media` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsMedia extends l(r(CsElements)) {
  static get observers() {
    return ['_allowFullscreen(_media, allowfullscreen)'];
  }
  static get properties() {
    return {
      titleText: String,
      hideTitle: {
        type: Boolean,
        value: !1,
      },
      captionText: String,
      hideCaption: {
        type: Boolean,
        value: !1,
      },
      textColor: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      spacingTop: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      spacingBottom: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      allowfullscreen: {
        type: Boolean,
        value: !0,
      },
      _media: {
        type: Array,
        value: () => [],
      },
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-shared-styles-common-spacing"></style>
      <style include="cs-age-gate-shared-styles"></style>
      <style>
        :host([slot='media']) {
          display: flex;
          flex-direction: column;
          margin-left: 0;
          margin-right: 0;
          overflow: hidden;
          width: 100%;
        }
        .cspl-media__figure {
          margin: 0;
        }
        .cspl-media__asset {
          position: relative;
        }
        .cspl-media__copy {
          flex-shrink: 0;
          margin-top: 24px;
          width: 100%;
        }
        @media only screen and (min-width: 768px) {
          .cspl-media__copy {
            width: calc(
              (8 * var(--cs-column-tablet-portrait)) +
                (7 * var(--cs-gutter-tablet-portrait))
            );
          }
        }
        @media only screen and (min-width: 1024px) {
          .cspl-media__copy {
            margin-top: 28px;
            width: calc(
              (7 * var(--cs-column-tablet-landscape)) +
                (6 * var(--cs-gutter-tablet-landscape))
            );
          }
        }
        @media only screen and (min-width: 1456px) {
          .cspl-media__copy {
            margin-top: 32px;
            width: calc(
              (6 * var(--cs-column-desktop)) + (5 * var(--cs-gutter-desktop))
            );
          }
        }
        .cspl-media__title {
          margin: 0;
        }
        .cspl-media__caption {
          margin: 0;
        }
        .cspl-media__age-gate-cover {
          z-index: 4;
        }
        :host([age-check-verified]) .cspl-media__age-gate-cover {
          display: none;
        }
      </style>
      <figure class="cspl-media__figure">
        <div class="cspl-media__asset">
          <slot name="image"></slot><slot name="soundcloud"></slot
          ><slot name="twitch"></slot><slot name="vimeo"></slot
          ><slot name="youtube"></slot><slot name="html5-video"></slot>
          <div
            class="cspl-media__age-gate-cover"
            hidden$="[[ isAgeGateCoverHidden(ageCheck) ]]"
            on-tap="launchAgeGateEvent"
          ></div>
        </div>
        <figcaption
          class="cspl-media__copy"
          hidden$="[[ _hideCopy(hideTitle, titleText, hideCaption, captionText) ]]"
        >
          <h2
            class$="[[ _getTitleClass(textColor) ]]"
            hidden$="[[ _hideTitle(hideTitle, titleText) ]]"
          >
            [[ titleText ]]
          </h2>
          <p
            class$="[[ _getCaptionClass(textColor) ]]"
            hidden$="[[ _hideCaption(hideCaption, captionText) ]]"
          >
            [[ captionText ]]
          </p>
        </figcaption>
      </figure> `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _getTitleClass(e) {
    return 'cspl-media__title b2 b2-'.concat(e);
  }
  _hideTitle(e, t) {
    return !t || e;
  }
  _getCaptionClass(e) {
    return 'cspl-media__caption b3 b3-'.concat(e);
  }
  hasCopy() {
    return !this._hideCopy(
      this.hideTitle,
      this.titleText,
      this.hideCaption,
      this.captionText
    );
  }
  _hideCaption(e, t) {
    return !t || e;
  }
  _hideCopy(e, t, a, i) {
    return this._hideTitle(e, t) && this._hideCaption(a, i);
  }
  getCaptionHeight() {
    return (
      this.getBoundingClientRect().height -
      this.shadowRoot
        .querySelector('.cspl-media__asset')
        .getBoundingClientRect().height
    );
  }
  _allowFullscreen(e, t) {
    e && (e.allowfullscreen = t);
  }
  _getMedia() {
    this._media = d(this, [
      'image',
      'soundcloud',
      'twitch',
      'vimeo',
      'youtube',
      'html5-video',
    ]);
  }
  connectedCallback() {
    super.connectedCallback(),
      this.shadowRoot.querySelectorAll('slot').forEach(e => {
        e.addEventListener('slotchange', this._getMedia.bind(this));
      });
  }
}

customElements.define('cs-media', CsMedia);
