/* jshint ignore:start */
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin';
import { CsMixin } from './mixins/cs-mixin';

const o = CsMixin;
const r = CsGoogleAnalyticsMixin;
const l = 600;
const c = 480;

/**
 * `cs-social-share-twitter` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSocialShareTwitter extends o(r(CsElements)) {
  static get properties() {
    return {
      theme: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      text: String,
      hashtags: String,
      via: String,
      shareUrl: {
        type: String,
        value: () => window.location.href,
      },
      tabIndex: {
        type: String,
        value: '0',
      },
    };
  }

  static get template() {
    return html`<style include="cs-social-button-shared-styles"></style>
      <style>
        :host {
          display: flex;
          flex-shrink: 0;
        }

        :host([theme='light']) .cspl-social-share-twitter__button {
          background: var(--cs-dark-color);
        }

        :host([theme='dark']) .cspl-social-share-twitter__button {
          background: var(--cs-light-color);
        }

        :host([theme='brand']) .cspl-social-share-twitter__button {
          background: var(--cs-theme-color);
        }

        :host([theme='light']) .cspl-social-share-twitter__icon {
          color: var(--cs-light-color);
        }

        :host([theme='dark']) .cspl-social-share-twitter__icon {
          color: var(--cs-dark-color);
        }

        :host([theme='brand']) .cspl-social-share-twitter__icon {
          color: #fff;
        }
      </style>
      <a
        class="cspl-social-share-twitter__button cspl-social-button"
        tabindex$="[[ tabIndex ]]"
        on-click="_share"
      >
        <iron-icon
          class="cspl-social-share-twitter__icon"
          icon="cs-action-16:twitter"
        ></iron-icon>
      </a>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _buildShareUrl() {
    let e =
      'https://twitter.com/share?url=' + encodeURIComponent(this.shareUrl);
    return (
      this.text && (e = `${e}&text=${this.text}`),
      this.hashtags && (e = `${e}&hashtags=${this.hashtags}`),
      this.via && (e = `${e}&via=${this.via}`),
      e
    );
  }
  _share() {
    const e = this._buildShareUrl();
    if (
      ((this.gaEventName = '/ga/social/share'),
      (this.gaEventPayload = {
        network: 'twitter',
      }),
      e)
    ) {
      const t = window.open(e, 'share', `height=${l},width=${c}`);
      return (t.opener = null), t;
    }
    return !1;
  }
  _handleKeyUp(e) {
    let { key: t } = e;
    'Enter' === t && this._share();
  }
  connectedCallback() {
    super.connectedCallback(),
      this.addEventListener('keyup', this._handleKeyUp);
  }
}

customElements.define('cs-social-share-twitter', CsSocialShareTwitter);
