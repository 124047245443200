import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';

/**
 * `cs-scrolling` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsScrolling extends PolymerElement {
  static get properties() {
    return {
      selected: {
        type: Object,
        observer: '_selectedChanged',
      },
    };
  }

  static get template() {
    return html`
      <style>
        :host {
          display: block;
          position: relative;
        }

        #items.cs-horizontal-list {
          margin-bottom: var(4px, 24px);
          transition-duration: 0.15s;
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
          will-change: transform;
          display: inline-block;
          white-space: nowrap;
        }

        #scroll-container.cs-horizontal-list {
          overflow-x: hidden;
        }

        /* div> ::slotted(:not([selected])) {
                display: none;
            } */

        .button {
          position: absolute;
          top: calc(25% - 20px);
          background: #fff;
          color: var(--cs-theme-grey-hard);
          opacity: 0.9;
        }

        .button:hover,
        .button:focus {
          opacity: 1;
        }

        #prevBtn {
          left: -16px;
        }

        #nextBtn {
          right: -16px;
        }

        .button[disabled] {
          opacity: 0.4;
        }

        .at-start {
          transform: translateX(0px);
        }

        .at-end {
          transform: translateX(-856px);
        }

        .visible {
          display: block !important;
        }

        .unvisible {
          display: none !important;
        }
      </style>

      <div class="horizontal-list">
        <div id="scroll-container" class="style-scope cs-horizontal-list">
          <div id="items" class="style-scope cs-horizontal-list">
            <slot></slot>
          </div>
          <paper-fab
            id="prevBtn"
            mini
            icon="chevron-left"
            on-click="previous"
          ></paper-fab>
          <paper-fab
            id="nextBtn"
            mini
            icon="chevron-right"
            class="unvisible"
            on-click="next"
          ></paper-fab>
        </div>
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  connectedCallback() {
    super.connectedCallback();
    this.shadowRoot.addEventListener(
      'slotchange',
      this._resetSelected.bind(this)
    );
    this._resetSelected();
  }

  _resetSelected() {
    this.$.items.setAttribute(
      'class',
      'style-scope cs-horizontal-list at-start'
    );
    this.$.prevBtn.setAttribute('class', 'button unvisible');
    if (
      this.shadowRoot
        // If you have more than one slot, you can use a
        // different selector to identify the slot you're interested in.
        .querySelector('slot')
        .assignedNodes({ flatten: true })
        .filter(n => n.nodeType === Node.ELEMENT_NODE).length > 4
    ) {
      this.$.nextBtn.removeAttribute('class');
      this.$.nextBtn.setAttribute('class', 'button visible');
    }
  }

  _selectedChanged(selected, oldSelected) {
    if (oldSelected) oldSelected.removeAttribute('class');
    if (selected) {
      if (selected)
        selected.setAttribute('class', 'style-scope cs-horizontal-list at-end');
    } else {
      if (selected)
        selected.setAttribute(
          'class',
          'style-scope cs-horizontal-list at-start'
        );
    }
  }

  previous() {
    const elem = this.$.items;
    if (elem) {
      elem.setAttribute('class', 'style-scope cs-horizontal-list at-start');
      this.$.prevBtn.setAttribute('class', 'button unvisible');
      this.$.nextBtn.setAttribute('class', 'button visible');
    }
  }

  next() {
    const elem = this.$.items;
    if (elem) {
      elem.setAttribute('class', 'style-scope cs-horizontal-list at-end');
      this.$.prevBtn.setAttribute('class', 'button visible');
      this.$.nextBtn.setAttribute('class', 'button unvisible');
    }
  }
}

customElements.define('cs-scrolling', CsScrolling);
