import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * MixinNamePascelCase - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsControlPlayerMixin = base =>
  /**
   * @polymerMixinClass
   */
  class extends base {
    static get properties() {
      return {
        _eventNames: {
          type: Object,
          value: {
            FINISHED: 'finished',
            PAUSED: 'paused',
            PLAYING: 'playing',
            READY: 'ready',
            STOPPED: 'stopped',
          },
        },
        autoplay: {
          type: Number,
          value: 0,
        },
        buttonColor: {
          reflectToAttribute: !0,
          type: String,
          value: 'dark',
        },
        coverImageSmallest: String,
        coverImageSmall: String,
        coverImageMedium: String,
        coverImageLarge: String,
        coverImageLargest: String,
        coverImageTooltip: {
          type: String,
          value: ' ',
        },
        status: {
          readOnly: !0,
          reflectToAttribute: !0,
          type: String,
          value: 'loading',
        },
        hidePlayIcon: {
          type: Boolean,
          value: !1,
          reflectToAttribute: !0,
        },
      };
    }
    _setStatus(e) {
      return 'loading' !== this.status && ((this.status = e), this.status);
    }
    play() {
      this._player.play();
    }
    pause() {
      this._player.pause();
    }
    stop() {
      'function' == typeof this._player.stop
        ? this._player.stop()
        : (this.pause(),
          this._setStatus(this._eventNames.READY),
          this.dispatchEvent(
            new CustomEvent(this._eventNames.STOPPED, this._eventData)
          ));
    }
  };

export const CsControlPlayerMixin = dedupingMixin(internalCsControlPlayerMixin);
