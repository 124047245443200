import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin.js';
import { html } from '@polymer/polymer/polymer-element.js';

const { getSlottedChildren: o } = window.nds;

/**
 * CsQdcZeroResultMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsQdcZeroResultMixin = (base) =>
  /**
   * @polymerMixinClass
   */

  class t extends base {
    static get qdcZeroResultSlotTemplate() {
      return html`
        <style>
          .eapl-qdc-zero-message__wrapper {
            display: none;
          }

          [slot='qdcZeroResultTitle'],
          [slot='qdcZeroResultSubTitle'],
          [slot='qdcZeroResultCta'] {
            display: none;
          }
        </style>
        <div class="eapl-qdc-zero-message__wrapper">
          <slot id="qdcZeroResultTitle" name="qdcZeroResultTitle"></slot>
          <slot id="qdcZeroResultSubTitle" name="qdcZeroResultSubTitle"></slot>
          <slot id="qdcZeroResultCta" name="qdcZeroResultCta"></slot>
        </div>
      `;
    }
    _getStyle() {
      return html`
        <style>
          :host {
            display: block;
          }

          .eapl-qdc-zero-result__wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 72px auto;
            text-align: center;
          }

          :host([theme='light']) .eapl-qdc-zero-result__wrapper {
            color: var(--ea-light-color);
          }

          :host([wf-loading]) [slot='qdcZeroResultTitle'],
          :host([wf-loading]) [slot='qdcZeroResultSubTitle'] {
            opacity: 0;
          }

          [slot='qdcZeroResultTitle'] {
            font-family: var(--ea-theme-header-font-family);
            font-size: var(--ea-qdc-zero-result-title-font-size-mobile, 22px);
            font-weight: bold;
            line-height: 1.1;
            margin-bottom: 12px;
          }

          [slot='qdcZeroResultSubTitle'] {
            font-family: var(--ea-theme-body-font-family);
            font-size: var(
              --ea-qdc-zero-result-subtitle-font-size-mobile,
              16px
            );
            line-height: 1.2;
            margin-bottom: 24px;
          }

          @media only screen and (min-width: 321px) {
            [slot='qdcZeroResultTitle'] {
              margin-bottom: 16px;
            }
          }

          @media only screen and (min-width: 768px) {
            .eapl-qdc-zero-result__wrapper {
              margin: 96px auto;
            }

            [slot='qdcZeroResultTitle'] {
              font-size: var(
                --ea-qdc-zero-result-title-font-size-tablet-landscape,
                32px
              );
            }

            [slot='qdcZeroResultSubTitle'] {
              font-size: var(
                --ea-qdc-zero-result-subtitle-font-size-tablet-landscape,
                18px
              );
            }
          }

          @media only screen and (min-width: 1024px) {
            .eapl-qdc-zero-result__wrapper {
              margin: 112px auto;
            }

            [slot='qdcZeroResultTitle'] {
              font-size: var(
                --ea-qdc-zero-result-title-font-size-desktop,
                32px
              );
            }

            [slot='qdcZeroResultSubTitle'] {
              font-size: var(
                --ea-qdc-zero-result-subtitle-font-size-desktop,
                18px
              );
            }
          }

          [slot='qdcZeroResultCta'] {
            margin-bottom: 0;
          }

          .eapl-qdc-zero-result__empty {
            margin: 0;
          }
        </style>
      `;
    }

    _adjustQdcZeroResultSlot(t) {
      const e = o(this, [
        'qdcZeroResultTitle',
        'qdcZeroResultSubTitle',
        'qdcZeroResultCta',
      ]);
      if (t.length || !e[0]) return;
      const i = document.querySelector(
          '#ea-shared-styles-common-spacing template'
        ),
        a = e[0].getAttribute('no-data-text-color'),
        n = this.parentNode,
        s = document.createElement('ea-element'),
        l = document.createElement('div'),
        r = e.filter(t => '' !== t.innerHTML);
      l.classList.add('eapl-qdc-zero-result__wrapper'),
        l.append(...r),
        a && s.setAttribute('theme', a),
        s
          .attachShadow({
            mode: 'open',
          })
          .append(
            this._getStyle().content.cloneNode(!0),
            i.content.cloneNode(!0),
            l
          ),
        0 === r.length && l.classList.add('eapl-qdc-zero-result__empty'),
        s.setAttribute('spacing-bottom', this.spacingBottom),
        s.setAttribute('spacing-top', this.spacingTop),
        n.insertBefore(s, this),
        n.removeChild(this);
    }
  };

export const CsQdcZeroResultMixin = dedupingMixin(internalCsQdcZeroResultMixin);
