/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsHasImageMixin } from "./mixins/cs-has-image-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";

var e = window.nds;
e.getSlottedChildren = function (e) {
	var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [""],
		{ shadowRoot: a } = e;
	return a
		? t.reduce((e, t) => {
				var l = a.querySelector(t.length ? 'slot[name="'.concat(t, '"]') : "slot");
				if (!l) return e;
				var o = l
					.assignedNodes({
						flatten: !0,
					})
					.filter((e) => e.nodeType === Node.ELEMENT_NODE);
				return [...e, ...o];
		  }, [])
		: [];
};

const t = CsMixin,
	a = CsHasImageMixin,
	r = e.getSlottedChildren;

/**
 * `cs-section` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSection extends t(a(PolymerElement)) {
	static get properties() {
		return {
			type: {
				type: String,
				value: "full-bleed",
				reflectToAttribute: !0,
			},
			layout: {
				type: String,
				value: "100",
				reflectToAttribute: !0,
			},
			spacingBottom: {
				type: String,
				value: "none",
				reflectToAttribute: !0,
			},
			spacingTop: {
				type: String,
				value: "none",
				reflectToAttribute: !0,
			},
			imageTooltip: String,
			backgroundOverflow: {
				type: String,
				value: "visible",
				reflectToAttribute: !0,
			},
			backgroundColor: {
				type: String,
				value: "none",
				reflectToAttribute: !0,
			},
			backgroundImageSmallest: String,
			backgroundImageSmall: String,
			backgroundImageMedium: String,
			backgroundImageLarge: String,
			backgroundImageLargest: String,
			mp4Src: String,
			webmSrc: String,
			_columns: {
				type: Array,
				value() {
					return [];
				},
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style>
				:host {
					display: flex;
					flex-direction: column;
					position: relative;
					width: 100%;
				}

				:host([background-color="light"]) {
					background-color: var(--cs-light-color);
				}

				:host([text-color="light"]) {
					color: var(--cs-light-color);
				}

				:host([background-color="dark"]) {
					background-color: var(--cs-dark-color);
				}

				:host([background-overflow="hidden"]) {
					overflow: hidden;
				}

				:host([text-color="dark"]) {
					color: var(--cs-dark-color);
				}

				:host([background-color="brand"]) {
					background-color: var(--cs-theme-color);
					color: var(--cs-light-color);
				}

				:host([type="railed"]) {
					padding-left: var(--cs-rail-mobile);
					padding-right: var(--cs-rail-mobile);
				}

				@media only screen and (min-width: 768px) {
					:host {
						flex-direction: row;
					}

					:host([type="railed"]) {
						padding-left: var(--cs-rail-tablet-portrait);
						padding-right: var(--cs-rail-tablet-portrait);
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([type="railed"]) {
						padding-left: var(--cs-rail-tablet-landscape);
						padding-right: var(--cs-rail-tablet-landscape);
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([type="railed"]) {
						padding-left: calc(var(--cs-rail-desktop));
						padding-right: calc(var(--cs-rail-desktop));
					}
				}

				:host ::slotted(.cspl-section-column) {
					display: block;
					position: relative;
					width: 100%;
				}

				:host ::slotted([text-align="center"]) {
					text-align: center;
				}

				:host ::slotted([text-align="right"]) {
					text-align: right;
				}

				@media only screen and (min-width: 768px) {
					:host ::slotted(.cspl-section-column:not(.cspl-section-column-100)) {
						margin-right: var(--cs-gutter-tablet-portrait);
					}

					:host ::slotted([indent-left="1"]) {
						padding-left: calc(var(--cs-column-tablet-portrait) + var(--cs-gutter-tablet-portrait));
					}

					:host ::slotted([indent-right="1"]) {
						padding-right: calc(var(--cs-column-tablet-portrait) + var(--cs-gutter-tablet-portrait));
					}

					:host ::slotted([indent-right="2"]) {
						padding-right: calc(2 * var(--cs-column-tablet-portrait) + 2 * var(--cs-gutter-tablet-portrait));
					}

					:host ::slotted([indent-right="3"]) {
						padding-right: calc(3 * var(--cs-column-tablet-portrait) + 3 * var(--cs-gutter-tablet-portrait));
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-25) {
						width: calc((5 * var(--cs-column-tablet-portrait)) + (4 * var(--cs-gutter-tablet-portrait)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-25) {
						width: calc(100% * 0.417 - var(--cs-gutter-tablet-portrait) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-50) {
						width: calc((6 * var(--cs-column-tablet-portrait)) + (5 * var(--cs-gutter-tablet-portrait)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-50) {
						width: calc(100% * 0.5 - var(--cs-gutter-tablet-portrait) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-33) {
						width: calc((5 * var(--cs-column-tablet-portrait)) + (4 * var(--cs-gutter-tablet-portrait)));
					}

					:host([type="full-bleed"][layout="33:33:33"]) ::slotted(.cspl-section-column-33) {
						width: calc(100% * 0.333 - 2 * var(--cs-gutter-tablet-portrait) / 3);
					}

					:host([type="full-bleed"][layout="33:66"]) ::slotted(.cspl-section-column-3),
					:host([type="full-bleed"][layout="66:33"]) ::slotted(.cspl-section-column-33) {
						width: calc(100% * 0.333 - 2 * var(--cs-gutter-tablet-portrait) / 3);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-66) {
						width: calc((7 * var(--cs-column-tablet-portrait)) + (6 * var(--cs-gutter-tablet-portrait)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-66) {
						width: calc(100% * 0.666 - var(--cs-gutter-tablet-portrait) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-75) {
						width: calc((7 * var(--cs-column-tablet-portrait)) + (6 * var(--cs-gutter-tablet-portrait)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-75) {
						width: calc(100% * 0.583 - var(--cs-gutter-tablet-portrait) / 2);
					}

					:host ::slotted(.cspl-section-column:last-child) {
						margin-right: var(--cs-zero);
					}
				}

				@media only screen and (min-width: 1024px) {
					:host ::slotted(.cspl-section-column:not(.cspl-section-column-100)) {
						margin-right: var(--cs-gutter-tablet-landscape);
					}

					:host ::slotted([indent-left="1"]) {
						padding-left: calc(var(--cs-column-tablet-landscape) + var(--cs-gutter-tablet-landscape));
					}

					:host ::slotted([indent-right="1"]) {
						padding-right: calc(var(--cs-column-tablet-landscape) + var(--cs-gutter-tablet-landscape));
					}

					:host ::slotted([indent-right="2"]) {
						padding-right: calc(2 * var(--cs-column-tablet-landscape) + 2 * var(--cs-gutter-tablet-landscape));
					}

					:host ::slotted([indent-right="3"]) {
						padding-right: calc(3 * var(--cs-column-tablet-landscape) + 3 * var(--cs-gutter-tablet-landscape));
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-25) {
						width: calc((3 * var(--cs-column-tablet-landscape)) + (2 * var(--cs-gutter-tablet-landscape)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-25) {
						width: calc(100% * 0.25 - var(--cs-gutter-tablet-landscape) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-50) {
						width: calc((6 * var(--cs-column-tablet-landscape)) + (5 * var(--cs-gutter-tablet-landscape)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-50) {
						width: calc(100% * 0.5 - var(--cs-gutter-tablet-landscape) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-33) {
						width: calc((4 * var(--cs-column-tablet-landscape)) + (3 * var(--cs-gutter-tablet-landscape)));
					}

					:host([type="full-bleed"][layout="33:33:33"]) ::slotted(.cspl-section-column-33) {
						width: calc(100% * 0.333 - 2 * var(--cs-gutter-tablet-landscape) / 3);
					}

					:host([type="full-bleed"][layout="33:66"]) ::slotted(.cspl-section-column-3),
					:host([type="full-bleed"][layout="66:33"]) ::slotted(.cspl-section-column-33) {
						width: calc(100% * 0.333 - 2 * var(--cs-gutter-tablet-landscape) / 3);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-66) {
						width: calc((8 * var(--cs-column-tablet-landscape)) + (7 * var(--cs-gutter-tablet-landscape)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-66) {
						width: calc(100% * 0.666 - var(--cs-gutter-tablet-landscape) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-75) {
						width: calc((9 * var(--cs-column-tablet-landscape)) + (8 * var(--cs-gutter-tablet-landscape)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-75) {
						width: calc(100% * 0.75 - var(--cs-gutter-tablet-landscape) / 2);
					}

					:host ::slotted(.cspl-section-column:last-child) {
						margin-right: var(--cs-zero);
					}
				}

				@media only screen and (min-width: 1456px) {
					:host ::slotted(.cspl-section-column:not(.cspl-section-column-100)) {
						margin-right: var(--cs-gutter-desktop);
					}

					:host ::slotted([indent-left="1"]) {
						padding-left: calc(var(--cs-column-desktop) + var(--cs-gutter-desktop));
					}

					:host ::slotted([indent-right="1"]) {
						padding-right: calc(var(--cs-column-desktop) + var(--cs-gutter-desktop));
					}

					:host ::slotted([indent-right="2"]) {
						padding-right: calc(2 * var(--cs-column-desktop) + 2 * var(--cs-gutter-desktop));
					}

					:host ::slotted([indent-right="3"]) {
						padding-right: calc(3 * var(--cs-column-desktop) + 3 * var(--cs-gutter-desktop));
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-25) {
						width: calc((3 * var(--cs-column-desktop)) + (2 * var(--cs-gutter-desktop)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-25) {
						width: calc(100% * 0.25 - var(--cs-gutter-desktop) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-50) {
						width: calc((6 * var(--cs-column-desktop)) + (5 * var(--cs-gutter-desktop)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-50) {
						width: calc(100% * 0.5 - var(--cs-gutter-desktop) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-33) {
						width: calc((4 * var(--cs-column-desktop)) + (3 * var(--cs-gutter-desktop)));
					}

					:host([type="full-bleed"][layout="33:33:33"]) ::slotted(.cspl-section-column-33) {
						width: calc(100% * 0.333 - 2 * var(--cs-gutter-desktop) / 3);
					}

					:host([type="full-bleed"][layout="33:66"]) ::slotted(.cspl-section-column-33),
					:host([type="full-bleed"][layout="66:33"]) ::slotted(.cspl-section-column-33) {
						width: calc(100% * 0.333 - 2 * var(--cs-gutter-desktop) / 3);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-66) {
						width: calc((8 * var(--cs-column-desktop)) + (7 * var(--cs-gutter-desktop)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-66) {
						width: calc(100% * 0.666 - var(--cs-gutter-desktop) / 2);
					}

					:host([type="railed"]) ::slotted(.cspl-section-column-75) {
						width: calc((9 * var(--cs-column-desktop)) + (8 * var(--cs-gutter-desktop)));
					}

					:host([type="full-bleed"]) ::slotted(.cspl-section-column-75) {
						width: calc(100% * 0.75 - var(--cs-gutter-desktop) / 2);
					}

					:host ::slotted(.cspl-section-column:last-child) {
						margin-right: var(--cs-zero);
					}
				}

				.cspl-section__background-image {
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

				.cspl-section__video-wrapper {
					display: none;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

				@media only screen and (min-width: 1025px) {
					.cspl-section__video-wrapper {
						display: block;
					}
				}

				.cspl-section__video {
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			</style>
			<template is="dom-if" if="[[ _hasImage(backgroundImageSmallest, backgroundImageSmall, backgroundImageMedium, backgroundImageLarge, backgroundImageLargest) ]]">
				<picture>
					<source media="(max-width: 320px)" srcset$="[[ backgroundImageSmallest ]]" />
					<source media="(max-width: 767px)" srcset$="[[ backgroundImageSmall ]]" />
					<source media="(max-width: 1023px)" srcset$="[[ backgroundImageMedium ]]" />
					<source media="(max-width: 1455px)" srcset$="[[ backgroundImageLarge ]]" />
					<source media="(min-width: 1456px)" srcset$="[[ backgroundImageLargest ]]" />
					<img class="cspl-section__background-image" src="[[ backgroundImageSmallest ]]" alt="[[ imageTooltip ]]" />
				</picture>
			</template>
			<template is="dom-if" if="[[  _hasVideo(mp4Src, webmSrc) ]]">
				<div class="cspl-section__video-wrapper">
					<cs-html5-video class="cspl-section__video" mp4-src$="[[ mp4Src ]]" webm-src$="[[ webmSrc ]]" autoplay="" muted="" loop=""></cs-html5-video>
				</div>
			</template>
			<slot id="columnSlot" name="section-column"></slot>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}

	static get observers() {
		return ["_observeColumns(_columns, layout)"];
	}

	_hasImage(...e) {
		return e.every((e) => e && e.trim().length);
	}

	_hasVideo() {
		return [...arguments].every((e) => e.trim().length);
	}

	_observeColumns(e, t) {
		const a = "cspl-section-column";
		const r = t.split(":");
		e.forEach((e, t) => {
			const n = [a, `${a}-${r[t]}`];
			e.classList.add(...n);
		});
	}

	_getColumns() {
		this._columns = r(this, ["section-column"]);
	}

	connectedCallback() {
		super.connectedCallback(), this._getColumns(), this.$.columnSlot.addEventListener("slotchange", this._getColumns.bind(this)); // jshint ignore:line
	}
}

customElements.define("cs-section", CsSection);

export default { CsSection };
