/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";

const e = window.nds.getSlottedChildren;

/**
 * `cs-subscription-offer` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSubscriptionOffer extends PolymerElement {
	static get properties() {
		return {
			_logos: {
				type: Array,
				value: () => [],
			},
			backgroundColor: {
				type: String,
				value: "light",
				reflectToAttribute: !0,
			},
			backgroundImageSmallest: String,
			backgroundImageSmall: String,
			backgroundImageMedium: String,
			backgroundImageLarge: String,
			backgroundImageLargest: String,
			copyText: String,
			layout: {
				reflectToAttribute: !0,
				type: String,
				value: "1up",
			},
			textColor: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			titleText: String,
			spacingTop: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			spacingBottom: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			tooltip: {
				type: String,
				value: " ",
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-base-headings"></style>
			<style include="cs-shared-styles-typography-headings"></style>
			<style include="cs-shared-styles-typography-paragraphs"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style>
				:host {
					background-color: var(--cs-light-color);
					color: var(--cs-dark-color);
					display: flex;
					flex-direction: column;
					overflow: hidden;
					padding: 32px calc(var(--cs-rail-mobile));
					position: relative;
					width: 100%;
				}

				@media only screen and (min-width: 768px) {
					:host {
						flex-direction: row;
						padding: 40px calc(var(--cs-rail-tablet-portrait));
					}
				}

				@media only screen and (min-width: 1024px) {
					:host {
						padding: 40px calc(var(--cs-rail-tablet-landscape));
					}
				}

				@media only screen and (min-width: 1456px) {
					:host {
						padding: 64px calc(var(--cs-rail-desktop));
					}
				}

				:host([background-color="dark"]) {
					background-color: var(--cs-dark-color);
				}

				:host([background-color="brand"]) {
					background-color: var(--cs-theme-color);
				}

				:host([text-color="light"]) {
					color: var(--cs-light-color);
				}

				.cspl-subscription-offer__background {
					align-items: center;
					display: flex;
					height: 100%;
					justify-content: center;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

				.cspl-subscription-offer__background-image {
					height: 100%;
				}

				@media only screen and (min-width: 450px) {
					.cspl-subscription-offer__background-image {
						height: auto;
						width: 100%;
					}
				}

				.cspl-subscription-offer__content-container {
					display: flex;
					flex-flow: row wrap;
					position: relative;
					width: 100%;
				}

				@media only screen and (min-width: 768px) {
					.cspl-subscription-offer__content-container {
						margin-right: var(--cs-gutter-tablet-portrait);
						width: calc((7 * var(--cs-column-tablet-portrait)) + (6 * var(--cs-gutter-tablet-portrait)));
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-subscription-offer__content-container {
						margin-right: var(--cs-gutter-tablet-landscape);
						width: calc((6 * var(--cs-column-tablet-landscape)) + (5 * var(--cs-gutter-tablet-landscape)));
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-subscription-offer__content-container {
						margin-right: var(--cs-gutter-desktop);
						width: calc((8 * var(--cs-column-desktop)) + (7 * var(--cs-gutter-desktop)));
					}
				}

				.cspl-subscription-offer__logos {
					flex-basis: auto;
					width: 100%;
				}

				@media only screen and (min-width: 1456px) {
					.cspl-subscription-offer__logos {
						padding-right: var(--cs-gutter-desktop);
						width: 39%;
					}
				}

				.cspl-subscription-offer__logo-wrapper {
					display: inline;
				}

				@media only screen and (max-width: 1455px) {
					.cspl-subscription-offer__logo-wrapper + .cspl-subscription-offer__logo-wrapper::before {
						border-left: 1px solid var(--cs-light-color);
						content: "";
						display: inline;
						margin-left: 8px;
						padding-left: 8px;
					}

					:host([text-color="dark"]) .cspl-subscription-offer__logo-wrapper + .cspl-subscription-offer__logo-wrapper::before {
						border-color: var(--cs-dark-color);
					}
				}

				@media only screen and (min-width: 1024px) and (max-width: 1455px) {
					.cspl-subscription-offer__logo-wrapper + .cspl-subscription-offer__logo-wrapper::before {
						margin-left: 20px;
						padding-left: 20px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-subscription-offer__logo-wrapper {
						display: block;
					}

					.cspl-subscription-offer__logo-wrapper + .cspl-subscription-offer__logo-wrapper {
						margin-top: 40px;
					}
				}

				cs-lazy-image {
					display: inline;
				}

				.cspl-subscription-offer__logo {
					height: 32px;
					vertical-align: middle;
				}

				@media only screen and (min-width: 768px) {
					.cspl-subscription-offer__logo {
						height: 38px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-subscription-offer__logo {
						height: 48px;
					}
				}

				.cspl-subscription-offer__copy {
					width: 100%;
				}

				@media only screen and (min-width: 768px) {
					.cspl-subscription-offer__copy {
						width: calc((6 * var(--cs-column-tablet-portrait)) + (6 * var(--cs-gutter-tablet-portrait)));
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-subscription-offer__copy {
						width: calc((5 * var(--cs-column-tablet-landscape)) + (4 * var(--cs-gutter-tablet-landscape)));
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-subscription-offer__copy {
						width: 61%;
					}
				}

				.cspl-subscription-offer__title {
					margin-bottom: 0;
				}

				@media only screen and (min-width: 1456px) {
					.cspl-subscription-offer__title {
						margin: 0;
					}
				}

				.cspl-subscription-offer__body {
					margin-bottom: 0;
				}

				.cspl-subscription-offer__cta-groups {
					align-items: flex-start;
					flex-direction: column;
					justify-content: center;
					position: relative;
				}

				.cspl-subscription-offer__cta-groups {
					align-content: flex-start;
					display: flex;
					flex-wrap: wrap;
				}

				@media only screen and (max-width: 767px) {
					.cspl-subscription-offer__cta-groups {
						align-content: flex-start;
						margin-top: 28px;
					}

					:host([layout="2up"]) .cspl-subscription-offer__cta-groups {
						margin-left: calc(-0.5 * var(--cs-gutter-mobile));
						margin-right: calc(-0.5 * var(--cs-gutter-mobile));
					}
				}

				@media only screen and (min-width: 768px) {
					.cspl-subscription-offer__cta-groups {
						width: calc((5 * var(--cs-column-tablet-portrait)) + (4 * var(--cs-gutter-tablet-portrait)));
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-subscription-offer__cta-groups {
						width: calc((6 * var(--cs-column-tablet-landscape)) + (5 * var(--cs-gutter-tablet-landscape)));
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-subscription-offer__cta-groups {
						width: calc((4 * var(--cs-column-desktop)) + (3 * var(--cs-gutter-desktop)));
					}
				}

				.cspl-subscription-offer__cta-groups ::slotted([slot="cta-group"]) {
					margin-top: 24px;
				}

				.cspl-subscription-offer__cta-groups ::slotted([slot="cta-group"]:first-of-type) {
					margin-top: 0;
				}

				@media only screen and (max-width: 767px) {
					.cspl-subscription-offer__cta-groups ::slotted([slot="cta-group"]) {
						margin-left: calc(0.5 * var(--cs-gutter-mobile));
						margin-right: calc(0.5 * var(--cs-gutter-mobile));
					}
				}
			</style>
			<template is="dom-if" if="[[ _hasImage(backgroundImageSmallest, backgroundImageSmall, backgroundImageMedium, backgroundImageLarge, backgroundImageLargest) ]]">
				<div class="cspl-subscription-offer__background">
					<cs-lazy-picture>
						<picture>
							<source media="(max-width: 320px)" data-srcset$="[[ backgroundImageSmallest ]]" />
							<source media="(max-width: 767px)" data-srcset$="[[ backgroundImageSmall ]]" />
							<source media="(max-width: 1023px)" data-srcset$="[[ backgroundImageMedium ]]" />
							<source media="(max-width: 1455px)" data-srcset$="[[ backgroundImageLarge ]]" />
							<source media="(min-width: 1456px)" data-srcset$="[[ backgroundImageLargest ]]" />
							<img class="cspl-subscription-offer__background-image" data-src$="[[ backgroundImageMedium ]]" alt$="[[ tooltip ]]" />
						</picture>
					</cs-lazy-picture>
				</div>
			</template>
			<div class="cspl-subscription-offer__content-container">
				<div class="cspl-subscription-offer__logos" hidden$="[[ !_logos.length ]]">
					<template is="dom-repeat" items="[[ _logos ]]" as="logo">
						<div class="cspl-subscription-offer__logo-wrapper">
							<cs-lazy-image><img alt="[[ logo.alt ]]" class="cspl-subscription-offer__logo" data-src$="[[ logo.src ]]" /></cs-lazy-image>
						</div>
					</template>
				</div>
				<div class="cspl-subscription-offer__copy">
					<h1 class="cspl-subscription-offer__title h4">[[ titleText ]]</h1>
					<p class="cspl-subscription-offer__body b2">[[ copyText ]]</p>
				</div>
			</div>
			<div class="cspl-subscription-offer__cta-groups">
				<slot name="cta-group"></slot>
			</div>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_hasImage(...e) {
		return e.every((e) => e.trim().length);
	}
	_setup() {
		(this._logos = [...this.querySelectorAll("img")]),
			e(this, ["cta-group"]).forEach((e) => {
				e.context = "cs-subscription-offer";
			}); // jshint ignore:line
	}
	connectedCallback() {
		super.connectedCallback(), requestAnimationFrame(this._setup.bind(this)); // jshint ignore:line
	}
}

customElements.define("cs-subscription-offer", CsSubscriptionOffer);
