/* jshint ignore:start */
import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-type-kit` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsTypeKit extends PolymerElement {
	static get properties() {
		return {
			kitUrl: {
				type: String,
				value: "//use.typekit.net/txn1klh.js",
			},
			_wfLoading: {
				type: Boolean,
				value: !0,
			},
		};
	}

	static get template() {
		return html``;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super(), (this._wfCheck = this._wfCheck.bind(this)), (this._wfLoaded = this._wfLoaded.bind(this)); // jshint ignore:line
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}

	_loadWebFonts() {
		const e = new CustomEvent("nds.typeKit.active", {
			bubbles: !0,
			composed: !0,
		});
		try {
			Typekit.load({
				active: () => {
					document.dispatchEvent(e);
				},
				inactive: () => {
					document.dispatchEvent(e);
				},
			});
		} catch (t) {
			document.dispatchEvent(e);
		}
	}
	_insertScript() {
		const e = document.createElement("script");
		(e.src = this.kitUrl), (e.onload = this._loadWebFonts), document.body.appendChild(e); // jshint ignore:line
	}
	_wfLoaded() {
		(this._wfLoading = !1), this._removeCommonEventListeners(); // jshint ignore:line
		document.body.removeAttribute("server-rendered");
	}
	_wfCheck() {
		this._wfLoading ||
			this.dispatchEvent(
				new CustomEvent("nds.typeKit.active", {
					bubbles: !0,
					composed: !0,
				})
			); // jshint ignore:line
	}
	_removeCommonEventListeners() {
		document.removeEventListener("nds.typeKit.active", this._wfLoaded);
	}
	connectedCallback() {
		super.connectedCallback(),
			document.addEventListener("nds.typeKit.check", this._wfCheck),
			document.addEventListener("nds.typeKit.active", this._wfLoaded),
			this._insertScript(); // jshint ignore:line
	}
	disconnectedCallback() {
		super.disconnectedCallback(), this._removeCommonEventListeners(), document.removeEventListener("nds.typeKit.check", this._wfCheck); // jshint ignore:line
	}
}

customElements.define("cs-type-kit", CsTypeKit);
