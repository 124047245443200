import {
	dedupingMixin
} from "@polymer/polymer/lib/utils/mixin.js";

/**
 * CsLazyPictureMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsLazyPictureMixin = (base) =>
	/**
	 * @polymerMixinClass
	 */
	class extends base {
		_swapSourceAttributes(e) {
			const t = e.querySelectorAll("source");
			const a = e.querySelector("img");
			if (t.forEach((e) => {
					const t = e.getAttribute("data-srcset");
					requestAnimationFrame(() => {
						t && e.setAttribute("srcset", t); // jshint ignore:line
					});
				}), a) {
				const e = a.getAttribute("data-src");
				requestAnimationFrame(() => {
					e && a.setAttribute("src", e); // jshint ignore:line
				}); // jshint ignore:line
			}
		}
		_loadPictures(e) {
			e.forEach((e) => this._swapSourceAttributes(e));
		}
		_observePictures(e, t) {
			const a = new IntersectionObserver((e) => {
				e.forEach((e) => {
					(e.isIntersecting || 0 < e.intersectionRatio) && (this._swapSourceAttributes(e.target), a.unobserve(e.target)); // jshint ignore:line
				});
			}, t);
			e.forEach((e) => a.observe(e));
		}
		_lazyPictures(e, t) {
			const a = 0 <= window.navigator.userAgent.indexOf("Edge/");
			"function" != typeof IntersectionObserver || a ? this._loadPictures(e) : this._observePictures(e, t); // jshint ignore:line
		}
	};

export const CsLazyPictureMixin = dedupingMixin(internalCsLazyPictureMixin);