import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

const o = CsMixin,
  l = CsDeviceMixin;

var e = window.nds;
/**
 * `cs-event-card` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsEventCard extends o(l(CsElements)) {
  static get properties() {
    return {
      endDate: String,
      activationDate: String,
      tentative: {
        type: Boolean,
        value: !1,
      },
      theme: {
        type: String,
        value: 'light',
      },
      fullListingCardCTATheme: {
        type: String,
        value: 'dark',
        computed: '_computeFullListingCardCTATheme(theme)',
      },
      hoverTheme: {
        type: String,
      },
      media: {
        type: String,
        value: '',
        reflectToAttribute: !0,
      },
      tooltip: {
        type: String,
        value: '',
      },
      eyebrow: {
        type: String,
        value: '',
      },
      eyebrowTextColor: {
        type: String,
      },
      title: {
        type: String,
        value: '',
      },
      subtitle: {
        type: String,
        value: '',
      },
      emptyCard: {
        type: Boolean,
        value: !1,
      },
      fullListingCard: {
        type: Object,
        value: null,
      },
      status: {
        type: String,
        value: '',
        reflectToAttribute: !0,
      },
      open: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-shared-styles-typography-labels"></style>
      <style include="cs-shared-styles-typography-headings"></style>
      <style include="cs-shared-styles-base-headings"></style>
      <style include="cs-shared-styles-base-common"></style>
      <style>
        :host {
          display: block;
        }
        .cspl-evetn-card__full-listing-card-cta {
          margin-bottom: 0;
        }
        .cspl-event-card {
          height: 148px;
          position: relative;
          width: 270px;
        }
        @media screen and (min-width: 321px) {
          .cspl-event-card {
            height: 144px;
            width: 318px;
          }
        }
        @media screen and (min-width: 1024px) {
          .cspl-event-card {
            height: 158px;
            width: 356px;
          }
        }
        :host([status='next']) .cspl-event-card {
          height: 193px;
        }
        @media screen and (min-width: 1024px) {
          :host([status='next']) .cspl-event-card {
            height: 246px;
          }
        }
        .cspl-event-card__full-listing-card-container {
          display: flex;
          flex: 1;
          justify-content: center;
        }
        .cspl-event-card__container {
          align-items: center;
          background-color: #fff;
          border: 1px solid #cbcbcb;
          border-radius: 7px;
          box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: row;
          height: 100%;
          overflow: hidden;
          padding: 10px;
          width: 100%;
        }
        :host([media='']) .cspl-event-card__container {
          padding-left: 15px;
        }
        :host([rtl][media='']) .cspl-event-card__container {
          padding-left: 10px;
          padding-right: 15px;
        }
        :host([empty-card]) .cspl-event-card__container {
          background-color: #000;
          border-color: #cbcbcb;
          box-shadow: none;
          opacity: 0.07;
        }
        :host([empty-card][theme='dark']) .cspl-event-card__container {
          background-color: #fff;
          border-color: #323232;
          opacity: 0.1;
        }
        :host([theme='dark']) .cspl-event-card__container {
          background-color: #262629;
          border-color: #323232;
        }
        .cspl-event-card__media-container {
          align-items: center;
          display: flex;
          height: 90px;
          margin-right: 10px;
          width: 90px;
        }
        @media screen and (min-width: 1024px) {
          .cspl-event-card__media-container {
            height: 138px;
            width: 138px;
          }
        }
        :host([rtl]) .cspl-event-card__media-container {
          margin-left: 10px;
          margin-right: 0;
        }
        .cspl-event-card__media {
          display: block;
          height: 90px;
          object-fit: contain;
          opacity: 0;
          width: 90px;
        }
        .cspl-event-card__media[src] {
          opacity: 1;
        }
        @media screen and (min-width: 1024px) {
          .cspl-event-card__media {
            height: 138px;
            width: 138px;
          }
        }
        .cspl-event-card__content {
          flex: 1;
          max-height: 128px;
        }
        :host([status='next']) .cspl-event-card__content {
          max-height: 173px;
          z-index: 99;
        }
        .cspl-event-card__eyebrow {
          color: var(--cs-theme-color);
          display: block;
          margin: 0;
          text-transform: var(--cs-text-case, uppercase);
        }
        :host([eyebrow-text-color='light']) .cspl-event-card__eyebrow {
          color: var(--cs-light-color);
        }
        :host([eyebrow-text-color='dark']) .cspl-event-card__eyebrow {
          color: var(--cs-dark-color);
        }
        .cspl-event-card__title {
          color: var(--cs-dark-color);
          margin: 4px 0;
        }
        .cspl-event-card__subtitle {
          color: var(--cs-dark-color);
          display: block;
          margin: 0;
        }
        :host([theme='dark']) .cspl-event-card__subtitle,
        :host([theme='dark']) .cspl-event-card__title {
          color: var(--cs-light-color);
        }
        .cspl-event-card__feature-links {
          align-items: flex-start;
          display: none;
          flex-direction: column;
          margin-top: 10px;
        }
        @media screen and (min-width: 1024px) {
          .cspl-event-card__feature-links {
            margin-top: 12px;
          }
        }
        .cspl-event-card__feature-link {
          color: var(--cs-dark-color);
          display: block;
          font-size: 12.5px;
          letter-spacing: 0;
          line-height: 114%;
          margin-bottom: 8px;
          position: relative;
          text-decoration: none;
        }
        .cspl-event-card__feature-link::after {
          background-color: var(--cs-dark-color);
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          right: 0;
          transition: left 0.2s, right 0.2s;
        }
        :host([theme='dark']) .cspl-event-card__feature-link::after {
          background-color: var(--cs-light-color);
        }
        :host([theme='dark']) .cspl-event-card__feature-link {
          color: var(--cs-light-color);
        }
        .cspl-event-card__feature-link:hover,
        :host([theme='dark']) .cspl-event-card__feature-link:hover {
          color: var(--cs-theme-color);
        }
        .cspl-event-card__feature-link:hover::after {
          background-color: var(--cs-theme-color);
          left: -4px;
          right: -4px;
        }
        :host([theme='dark']) .cspl-event-card__feature-link:hover::after {
          background-color: var(--cs-theme-color);
        }
        @media screen and (min-width: 1024px) {
          .cspl-event-card__feature-link {
            font-size: 14px;
            margin-bottom: 6px;
          }
        }
        :host([status='next']) .cspl-event-card__feature-links {
          display: flex;
        }
        .cspl-event-card__feature-links-overlay {
          background: linear-gradient(
            -83deg,
            var(--cs-theme-light-gradient),
            var(--cs-theme-dark-gradient)
          );
          border-radius: 7px;
          bottom: 0;
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: opacity 0.3s ease-in-out;
          z-index: -1;
        }
        :host([hover-theme='dark']) .cspl-event-card__feature-links-overlay {
          background: var(--cs-dark-color);
        }
        :host([hover-theme='light']) .cspl-event-card__feature-links-overlay {
          background: var(--cs-light-color);
        }
        .cspl-event-card__feature-links-overlay-wrap {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        :host(:not([tentative]):not([empty-card]):not([is-touch]):not([status='next']):hover)
          .cspl-event-card__feature-links-overlay,
        :host(:not([tentative]):not([empty-card])[is-touch][open])
          .cspl-event-card__feature-links-overlay {
          opacity: 0.96;
          z-index: 0;
        }
        .cspl-event-card__overlay-feature-link {
          border-bottom: 1px solid #b7b7b7;
          color: #a3a3a3;
          display: block;
          font-size: 18px;
          line-height: 1.2;
          text-align: center;
          text-decoration: none;
          transition: all 0.3s ease-in-out;
          white-space: nowrap;
        }
        .cspl-event-card__feature-links-overlay-wrap:not(:hover)
          .cspl-event-card__overlay-feature-link:first-child,
        .cspl-event-card__overlay-feature-link:hover {
          color: #f3f3f3;
        }
        :host([hover-theme='dark']) .cspl-event-card__overlay-feature-link {
          border-bottom-color: #535353;
        }
        :host([hover-theme='light']) .cspl-event-card__overlay-feature-link {
          border-bottom-color: #b7b7b7;
          color: #8b8b8b;
        }
        :host([hover-theme='light'])
          .cspl-event-card__feature-links-overlay-wrap:not(:hover)
          .cspl-event-card__overlay-feature-link:first-child,
        :host([hover-theme='light'])
          .cspl-event-card__overlay-feature-link:hover {
          color: #161616;
        }
        .cspl-event-card__overlay-feature-link:first-child {
          padding-top: 0;
        }
        .cspl-event-card__overlay-feature-link:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
        :host(:not([tentative]):not([empty-card]):not([is-touch]):not([status='next']):hover)
          .cspl-event-card__overlay-feature-link,
        :host(:not([tentative]):not([empty-card])[is-touch][open])
          .cspl-event-card__overlay-feature-link {
          padding: 10px;
          transition: padding 0.3s ease-in-out;
        }
        .cspl-event-card__side-toggle {
          color: #858585;
          display: flex;
          padding: 5px 10px;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
        .cspl-event-card__side-toggle iron-icon {
          z-index: 999;
        }
        :host([open]) .cspl-event-card__side-toggle {
          color: var(--cs-light-color);
        }
        :host([open][hover-theme='light']) .cspl-event-card__side-toggle {
          color: var(---cs-dark-color);
        }
        :host([rtl]) .cspl-event-card__side-toggle {
          left: 0;
          right: auto;
        }
        :host([status='next']) .cspl-event-card__side-toggle,
        :host([tentative]) .cspl-event-card__side-toggle {
          display: none;
        }
        .cspl-event-card__side-dot {
          background-color: #858585;
          border-radius: 50%;
          height: 5px;
          margin: 5px 2px 0 0;
          transition: transform 0.3s ease-in-out;
          width: 5px;
        }
        :host(:not([tentative]):not([empty-card]):not([is-touch]):not([status='next']):hover)
          .cspl-event-card__side-first-dot {
          transform: translateX(14px);
        }
        :host(:not([tentative]):not([empty-card]):not([is-touch]):not([status='next']):hover)
          .cspl-event-card__side-second-dot {
          transform: translateX(7px);
        }
        :host([rtl]:not([tentative]):not([empty-card]):not([is-touch]):not([status='next']):hover)
          .cspl-event-card__side-first-dot {
          transform: translateX(-14px);
        }
        :host([rtl]:not([tentative]):not([empty-card]):not([is-touch]):not([status='next']):hover)
          .cspl-event-card__side-second-dot {
          transform: translateX(-7px);
        }
        .cspl-event-card__side-toggle > iron-icon {
          height: 20px;
          width: 20px;
        }
      </style>
      <div class="cspl-event-card">
        <div class="cspl-event-card__container">
          <template is="dom-if" if="[[ fullListingCard ]]"
            ><div class="cspl-event-card__full-listing-card-container">
              <cs-cta
                class="cspl-evetn-card__full-listing-card-cta"
                slot="cta"
                type="graphic"
                link-url="[[ fullListingCard.url ]]"
                link-title="[[ fullListingCard.text ]]"
                link-text="[[ fullListingCard.text ]]"
                icon="plus"
                text-color="[[ fullListingCardCTATheme ]]"
              ></cs-cta></div></template
          ><template is="dom-if" if="[[ !fullListingCard ]]"
            ><template is="dom-if" if="[[ media ]]"
              ><div class="cspl-event-card__media-container">
                <cs-lazy-image
                  ><img
                    class="cspl-event-card__media"
                    data-src$="[[ media ]]"
                    alt$="[[ tooltip ]]"
                /></cs-lazy-image></div
            ></template>
            <div class="cspl-event-card__content">
              <span
                class$="cspl-event-card__eyebrow [[ _getEyebrowTypography(status) ]]"
                >[[ eyebrow ]]</span
              >
              <h5
                class$="cspl-event-card__title [[ _getTitleTypography(status) ]]"
              >
                [[ title ]]
              </h5>
              <span class="cspl-event-card__subtitle b3">[[ subtitle ]]</span>
              <div
                class="cspl-event-card__feature-links"
                id="featureLinks"
              ></div>
            </div>
            <div class="cspl-event-card__feature-links-overlay">
              <div
                class="cspl-event-card__feature-links-overlay-wrap"
                id="featureLinksOverlay"
              ></div></div
          ></template>
        </div>
        <template
          is="dom-if"
          if="[[ _showSideToggle(tentative, emptyCard, fullListingCard) ]]"
          ><span class="cspl-event-card__side-toggle" on-click="_toggleSide"
            ><template is="dom-if" if="[[ open ]]"
              ><iron-icon icon="cs-action-16:close"></iron-icon></template
            ><template is="dom-if" if="[[ !open ]]"
              ><span
                class="cspl-event-card__side-dot cspl-event-card__side-first-dot"
              ></span>
              <span
                class="cspl-event-card__side-dot cspl-event-card__side-second-dot"
              ></span>
              <span class="cspl-event-card__side-dot"></span></template></span></template>
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready(), this._setStatus();
  }
  _computeFullListingCardCTATheme(e) {
    return 'dark' === e ? 'light' : 'dark';
  }
  _showSideToggle(e, t, a) {
    return !a && !e && !t;
  }
  _toggleSide() {
    this.isTouch && (this.open = !this.open);
  }
  _setStatus() {
    if (!this.status) {
      var e = new Date().toISOString(),
        t = new Date(this.activationDate).toISOString(),
        a = new Date(this.endDate).toISOString();
      this.status = e < t ? 'upcoming' : e > a ? 'past' : 'next';
    }
  }
  _getEyebrowTypography(e) {
    return 'next' === e ? 'l2' : 'l3';
  }
  _getTitleTypography(e) {
    return 'next' === e ? 'd5' : 'd6';
  }
  _addFeatureLinks() {
    var e = [...this.querySelectorAll('a')].slice(0, 3),
      t = this.shadowRoot.querySelector('#featureLinks'),
      a = this.shadowRoot.querySelector('#featureLinksOverlay');
    e.forEach(e => {
      var i = e.cloneNode(!0);
      (e.className = 'cspl-event-card__feature-link'),
        (i.className = 'cspl-event-card__overlay-feature-link'),
        (i.draggable = !1),
        t.appendChild(e),
        a.appendChild(i);
    });
  }
  connectedCallback() {
    super.connectedCallback(),
      setTimeout(() => {
        this.fullListingCard || this._addFeatureLinks();
      });
  }
}

customElements.define('cs-event-card', CsEventCard);
