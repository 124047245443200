/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsHasImageMixin } from "./mixins/cs-has-image-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";
import { CsMoreIndicatorParentMixin } from "./mixins/cs-more-indicator-parent-mixin.js";

const t = CsMixin,
	a = CsHasImageMixin,
	r = CsMoreIndicatorParentMixin;

/**
 * `cs-header` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsHeader extends t(a(r(PolymerElement))) {
	static get properties() {
		return {
			layout: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			type: {
				type: String,
				value: "ordered",
				reflectToAttribute: !0,
			},
			textPosition: {
				type: String,
				value: "left",
				reflectToAttribute: !0,
			},
			textAlign: {
				type: String,
				value: "left",
				reflectToAttribute: !0,
			},
			textColor: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			eyebrowText: {
				type: String,
				value: "",
			},
			titleText: String,
			subtitleText: String,
			slash: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			backgroundColor: {
				type: String,
				value: "none",
				reflectToAttribute: !0,
			},
			imageSmallest: String,
			imageSmall: String,
			imageMedium: String,
			imageLarge: String,
			imageLargest: String,
			spacingBottom: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-headings"></style>
			<style include="cs-shared-styles-typography-headings"></style>
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style>
				:host {
					display: block;
					overflow: hidden;
					position: relative;
				}

				:host([type="ordered"]) {
					padding-bottom: 8.75%;
					padding-top: 96px;
				}

				@media only screen and (min-width: 768px) {
					:host([type="ordered"]) {
						padding-top: 140px;
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([type="ordered"]) {
						padding-top: 160px;
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([type="ordered"]) {
						padding-top: 200px;
					}
				}

				:host([type="overlap"]) .cspl-header__ordered {
					padding-top: 56px;
					position: relative;
				}

				@media only screen and (min-width: 768px) {
					:host([type="overlap"]) .cspl-header__ordered {
						padding-top: 140px;
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([type="overlap"]) .cspl-header__ordered {
						padding-top: 160px;
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([type="overlap"]) .cspl-header__ordered {
						padding-top: 200px;
					}
				}

				:host(:not([image-small])) .cspl-header__ordered::before {
					bottom: 0;
					content: "";
					height: 100%;
					left: 0;
					position: absolute;
					width: 100%;
				}

				:host([slash]) .cspl-header__ordered::before {
					backface-visibility: hidden;
					transform: skewY(-5deg);
					transform-origin: left top;
				}

				:host([background-color="light"]) .cspl-header__ordered::before {
					background-color: var(--cs-light-color);
				}

				:host([background-color="dark"]) .cspl-header__ordered::before {
					background-color: var(--cs-dark-color);
				}

				:host([background-color="brand"]) .cspl-header__ordered::before {
					background: linear-gradient(-83deg, var(--cs-theme-light-gradient), var(--cs-theme-dark-gradient));
				}

				:host([layout="small"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
					bottom: 32px;
				}

				:host([layout="medium"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
					bottom: 32px;
				}

				:host([layout="large"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
					bottom: 40px;
				}

				@media only screen and (min-width: 768px) {
					:host([layout="small"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 40px;
					}

					:host([layout="medium"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 40px;
					}

					:host([layout="large"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 48px;
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([layout="small"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 44px;
					}

					:host([layout="medium"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 44px;
					}

					:host([layout="large"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 52px;
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([layout="small"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 48px;
					}

					:host([layout="medium"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 48px;
					}

					:host([layout="large"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child::before {
						bottom: 56px;
					}
				}

				.cspl-header__ratio {
					bottom: 0;
					height: 100%;
					left: 0;
					overflow: hidden;
					position: absolute;
					width: 100%;
				}

				:host([slash]) .cspl-header__ratio {
					backface-visibility: hidden;
					transform: skewY(-5deg);
					transform-origin: left top;
				}

				:host([layout="small"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
					bottom: 32px;
				}

				:host([layout="medium"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
					bottom: 32px;
				}

				:host([layout="large"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
					bottom: 40px;
				}

				@media only screen and (min-width: 768px) {
					:host([layout="small"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 40px;
					}

					:host([layout="medium"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 40px;
					}

					:host([layout="large"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 48px;
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([layout="small"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 44px;
					}

					:host([layout="medium"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 44px;
					}

					:host([layout="large"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 52px;
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([layout="small"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 48px;
					}

					:host([layout="medium"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 48px;
					}

					:host([layout="large"][type="overlap"][text-align="left"]) .cspl-header__content .cspl-header__ordered:last-child .cspl-header__ratio {
						bottom: 56px;
					}
				}

				.cspl-header__image {
					display: block;
					width: 100%;
				}

				:host([slash]) .cspl-header__image {
					transform: skewY(5deg);
				}

				.cspl-header__content {
					padding-left: var(--cs-rail-mobile);
					padding-right: var(--cs-rail-mobile);
					position: relative;
					text-shadow: var(--cs-theme-header-text-shadow);
				}

				:host([text-align="center"]) .cspl-header__content {
					text-align: center;
				}

				:host([text-align="right"]) .cspl-header__content {
					text-align: right;
				}

				@media only screen and (min-width: 768px) {
					.cspl-header__content {
						padding-left: calc(var(--cs-rail-tablet-portrait));
						padding-right: calc(var(--cs-rail-tablet-portrait) + 6 * var(--cs-column-tablet-portrait-with-rail) + 6 * var(--cs-gutter-tablet-portrait));
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-header__content {
						padding-left: calc(var(--cs-rail-tablet-landscape));
						padding-right: calc(var(--cs-rail-tablet-landscape) + 6 * var(--cs-column-tablet-landscape-with-rail) + 6 * var(--cs-gutter-tablet-landscape));
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-header__content {
						padding-left: calc(var(--cs-rail-desktop));
						padding-right: calc(var(--cs-rail-desktop) + 6 * var(--cs-column-desktop-with-rail) + 6 * var(--cs-gutter-desktop));
					}
				}

				@media only screen and (min-width: 768px) {
					:host([text-position="center"]) .cspl-header__content {
						padding-left: calc(var(--cs-rail-tablet-portrait) + 2 * var(--cs-column-tablet-portrait-with-rail) + 2 * var(--cs-gutter-tablet-portrait));
						padding-right: calc(var(--cs-rail-tablet-portrait) + 2 * var(--cs-column-tablet-portrait-with-rail) + 2 * var(--cs-gutter-tablet-portrait));
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([text-position="center"]) .cspl-header__content {
						padding-left: calc(var(--cs-rail-tablet-landscape) + 2 * var(--cs-column-tablet-landscape-with-rail) + 2 * var(--cs-gutter-tablet-landscape));
						padding-right: calc(var(--cs-rail-tablet-landscape) + 2 * var(--cs-column-tablet-landscape-with-rail) + 2 * var(--cs-gutter-tablet-landscape));
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([text-position="center"]) .cspl-header__content {
						padding-left: calc(var(--cs-rail-desktop) + 2 * var(--cs-column-desktop-with-rail) + 2 * var(--cs-gutter-desktop));
						padding-right: calc(var(--cs-rail-desktop) + 2 * var(--cs-column-desktop-with-rail) + 2 * var(--cs-gutter-desktop));
					}
				}

				@media only screen and (min-width: 768px) {
					:host([text-position="right"]) .cspl-header__content {
						padding-left: calc(var(--cs-rail-tablet-portrait) + 6 * var(--cs-column-tablet-portrait-with-rail) + 6 * var(--cs-gutter-tablet-portrait));
						padding-right: calc(var(--cs-rail-tablet-portrait));
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([text-position="right"]) .cspl-header__content {
						padding-left: calc(var(--cs-rail-tablet-landscape) + 6 * var(--cs-column-tablet-landscape-with-rail) + 6 * var(--cs-gutter-tablet-landscape));
						padding-right: calc(var(--cs-rail-tablet-landscape));
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([text-position="right"]) .cspl-header__content {
						padding-left: calc(var(--cs-rail-desktop) + 6 * var(--cs-column-desktop-with-rail) + 6 * var(--cs-gutter-desktop));
						padding-right: calc(var(--cs-rail-desktop));
					}
				}

				.cspl-header__eyebrow {
					margin: 0;
				}

				.cspl-header__title {
					margin: 0;
				}

				.cspl-header__title-last-child::after {
					content: "";
					display: block;
					height: 8px;
					width: calc(2 * var(--cs-column-mobile-with-rail) + var(--cs-gutter-mobile));
				}

				:host([text-align="center"]) .cspl-header__title-last-child::after {
					margin-left: auto;
					margin-right: auto;
				}

				:host([text-align="right"]) .cspl-header__title-last-child::after {
					margin-left: auto;
				}

				:host([layout="small"]) .cspl-header__title::after {
					margin-top: 16px;
				}

				:host([layout="medium"]) .cspl-header__title::after {
					margin-top: 16px;
				}

				:host([layout="large"]) .cspl-header__title::after {
					margin-top: 23px;
				}

				:host([text-color="dark"]) .cspl-header__title::after {
					background-color: var(--cs-dark-color);
				}

				:host([text-color="light"]) .cspl-header__title::after {
					background-color: var(--cs-light-color);
				}

				@media only screen and (min-width: 768px) {
					.cspl-header__title-last-child::after {
						width: calc(3 * var(--cs-column-tablet-portrait-with-rail) + 2 * var(--cs-gutter-tablet-portrait));
					}

					:host([layout="small"]) .cspl-header__title::after {
						margin-top: 24px;
					}

					:host([layout="medium"]) .cspl-header__title::after {
						margin-top: 22px;
					}

					:host([layout="large"]) .cspl-header__title::after {
						margin-top: 26px;
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-header__title-last-child::after {
						width: calc(3 * var(--cs-column-tablet-landscape-with-rail) + 2 * var(--cs-gutter-tablet-landscape));
					}

					:host([layout="small"]) .cspl-header__title::after {
						margin-top: 28px;
					}

					:host([layout="medium"]) .cspl-header__title::after {
						margin-top: 26px;
					}

					:host([layout="large"]) .cspl-header__title::after {
						margin-top: 27px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-header__title-last-child::after {
						width: calc(3 * var(--cs-column-desktop-with-rail) + 2 * var(--cs-gutter-desktop));
					}

					:host([layout="small"]) .cspl-header__title::after {
						margin-top: 30px;
					}

					:host([layout="large"]) .cspl-header__title::after {
						margin-top: 25px;
					}
				}

				.cspl-header__subtitle {
					margin: 0;
				}

				.cspl-header__subtitle-last-child::after {
					content: "";
					display: block;
					height: 8px;
					width: calc(2 * var(--cs-column-mobile-with-rail) + var(--cs-gutter-mobile));
				}

				:host([text-align="center"]) .cspl-header__subtitle-last-child::after {
					margin-left: auto;
					margin-right: auto;
				}

				:host([text-align="right"]) .cspl-header__subtitle-last-child::after {
					margin-left: auto;
				}

				.cspl-header__subtitle::after {
					margin-top: 23px;
				}

				:host([text-color="dark"]) .cspl-header__subtitle::after {
					background-color: var(--cs-dark-color);
				}

				:host([text-color="light"]) .cspl-header__subtitle::after {
					background-color: var(--cs-light-color);
				}

				@media only screen and (min-width: 768px) {
					.cspl-header__subtitle-last-child::after {
						width: calc(3 * var(--cs-column-tablet-portrait-with-rail) + 2 * var(--cs-gutter-tablet-portrait));
					}

					.cspl-header__subtitle::after {
						margin-top: 31px;
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-header__subtitle-last-child::after {
						width: calc(3 * var(--cs-column-tablet-landscape-with-rail) + 2 * var(--cs-gutter-tablet-landscape));
					}

					.cspl-header__subtitle::after {
						margin-top: 35px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-header__subtitle-last-child::after {
						width: calc(3 * var(--cs-column-desktop-with-rail) + 2 * var(--cs-gutter-desktop));
					}

					.cspl-header__subtitle::after {
						margin-top: 38px;
					}
				}

				.cspl-header__overlap .cspl-header__content::after {
					content: "";
					display: block;
					height: 8px;
					width: calc(2 * var(--cs-column-mobile-with-rail) + var(--cs-gutter-mobile));
				}

				:host([text-align="center"]) .cspl-header__overlap .cspl-header__content::after {
					margin-left: auto;
					margin-right: auto;
				}

				:host([text-align="center"]) .cspl-header__overlap .cspl-header__content::after {
					margin-left: auto;
				}

				:host([text-color="dark"]) .cspl-header__overlap .cspl-header__content::after {
					background-color: var(--cs-dark-color);
				}

				:host([text-color="light"]) .cspl-header__overlap .cspl-header__content::after {
					background-color: var(--cs-light-color);
				}

				:host([layout="small"]) .cspl-header__overlap .cspl-header__content::after {
					margin-top: 16px;
				}

				:host([layout="medium"]) .cspl-header__overlap .cspl-header__content::after {
					margin-top: 16px;
				}

				:host([layout="large"]) .cspl-header__overlap .cspl-header__content::after {
					margin-top: 23px;
				}

				@media only screen and (min-width: 768px) {
					:host([text-align="left"]) .cspl-header__overlap .cspl-header__content::after {
						width: calc(3 * var(--cs-column-tablet-portrait-with-rail) + 2 * var(--cs-gutter-tablet-portrait));
					}

					:host([layout="small"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 24px;
					}

					:host([layout="medium"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 22px;
					}

					:host([layout="large"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 26px;
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([text-align="left"]) .cspl-header__overlap .cspl-header__content::after {
						width: calc(3 * var(--cs-column-tablet-landscape-with-rail) + 2 * var(--cs-gutter-tablet-landscape));
					}

					:host([layout="small"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 28px;
					}

					:host([layout="medium"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 26px;
					}

					:host([layout="large"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 27px;
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([text-align="left"]) .cspl-header__overlap .cspl-header__content::after {
						width: calc(3 * var(--cs-column-desktop-with-rail) + 2 * var(--cs-gutter-desktop));
					}

					:host([layout="small"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 30px;
					}

					:host([layout="medium"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 26px;
					}

					:host([layout="large"]) .cspl-header__overlap .cspl-header__content::after {
						margin-top: 25px;
					}
				}

				:host([layout="small"]) .cspl-header__overlap .cspl-header__subtitle {
					margin-top: 10px;
				}

				:host([layout="medium"]) .cspl-header__overlap .cspl-header__subtitle {
					margin-top: 10px;
				}

				:host([layout="large"]) .cspl-header__overlap .cspl-header__subtitle {
					margin-top: 17px;
				}

				@media only screen and (min-width: 768px) {
					:host([layout="small"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 18px;
					}

					:host([layout="medium"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 16px;
					}

					:host([layout="large"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 20px;
					}
				}

				@media only screen and (min-width: 1024px) {
					:host([layout="small"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 20px;
					}

					:host([layout="medium"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 18px;
					}

					:host([layout="large"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 19px;
					}
				}

				@media only screen and (min-width: 1456px) {
					:host([layout="small"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 22px;
					}

					:host([layout="medium"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 18px;
					}

					:host([layout="large"]) .cspl-header__overlap .cspl-header__subtitle {
						margin-top: 17px;
					}
				}

				.cspl-header__more-indicator {
					bottom: 0;
					position: absolute;
					width: 100%;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-header__more-indicator {
						padding-left: calc(var(--cs-rail-tablet-landscape));
						padding-right: calc(var(--cs-rail-tablet-landscape));
					}

					:host([text-position="center"]) .cspl-header__more-indicator {
						padding-left: calc(var(--cs-rail-tablet-landscape) + var(--cs-column-tablet-landscape-with-rail) + var(--cs-gutter-tablet-landscape));
						padding-right: calc(var(--cs-rail-tablet-landscape) + var(--cs-column-tablet-landscape-with-rail) + var(--cs-gutter-tablet-landscape));
					}

					:host([text-position="right"]) .cspl-header__more-indicator {
						padding-left: calc(var(--cs-rail-tablet-landscape));
						padding-right: calc(var(--cs-rail-tablet-landscape));
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-header__more-indicator {
						padding-left: calc(var(--cs-rail-desktop));
						padding-right: calc(var(--cs-rail-desktop));
					}

					:host([text-position="center"]) .cspl-header__more-indicator {
						padding-left: calc(var(--cs-rail-desktop) + var(--cs-column-desktop-with-rail) + var(--cs-gutter-desktop));
						padding-right: calc(var(--cs-rail-desktop) + var(--cs-column-desktop-with-rail) + var(--cs-gutter-desktop));
					}

					:host([text-position="right"]) .cspl-header__more-indicator {
						padding-left: calc(var(--cs-rail-desktop));
						padding-right: calc(var(--cs-rail-desktop));
					}
				}

				:host([type="overlap"]) .cspl-header__more-indicator {
					position: static;
				}
			</style>
			<div class="cspl-header__ordered">
				<div class="cspl-header__ratio">
					<template is="dom-if" if="[[ _hasImage(imageSmallest, imageSmall, imageMedium, imageLarge, imageLargest) ]]">
						<picture>
							<source media="(max-width: 320px)" srcset$="[[ imageSmallest ]]" />
							<source media="(max-width: 767px)" srcset$="[[ imageSmall ]]" />
							<source media="(max-width: 1023px)" srcset$="[[ imageMedium ]]" />
							<source media="(max-width: 1455px)" srcset$="[[ imageLarge ]]" />
							<source media="(min-width: 1456px)" srcset$="[[ imageLargest ]]" />
							<img class="cspl-header__image" src="[[ imageSmallest ]]" alt="[[ imageTooltip ]]"
						/></picture>
					</template>
				</div>
				<div class="cspl-header__content">
					<h3 hidden$="[[ !eyebrowText ]]" class$="[[ _getEyebrowClass(textColor) ]]">[[ eyebrowText ]]</h3>
					<h1 class$="[[ _getTitleClass(textColor, layout, subtitleText) ]]">[[ titleText ]]</h1>
					<h2 hidden$="[[ _hideOrderedSubtitle(type, subtitleText) ]]" class$="[[ _getSubtitleClass(textColor, subtitleText) ]]">[[ subtitleText ]]</h2>
				</div>
			</div>
			<div hidden$="[[ _hideOverlapSubtitle(type, subtitleText) ]]" class="cspl-header__overlap">
				<div class="cspl-header__content">
					<h2 class$="[[ _getSubtitleClass(textColor) ]]">[[ subtitleText ]]</h2>
				</div>
			</div>
			<div class="cspl-header__more-indicator" hidden$="[[ _hideMoreIndicator ]]">
				<slot name="more-indicator"></slot>
			</div> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_hideOrderedSubtitle(e, t) {
		return !t || "overlap" === e;
	}
	_hideOverlapSubtitle(e, t) {
		return !t || "ordered" === e;
	}
	_getEyebrowClass(e) {
		return `cspl-header__eyebrow h4 h4-${e}`;
	}
	_getTitleClass(e, t, a) {
		let r;
		let n = "cspl-header__title";
		return (r = "small" === t ? "d3" : "medium" === t ? "d2" : "large" === t ? "d1" : "d3"), a || (n = `${n} ${n}-last-child`), `${n} ${r} ${r}-${e}`;
	}
	_getSubtitleClass(e, t) {
		let a = "cspl-header__subtitle";
		return t && (a = `${a} ${a}-last-child`), `${a} h4 h4-${e}`;
	}
}

customElements.define("cs-header", CsHeader);
