import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

window.nds = window.nds || {};
const e = window.nds.getSlottedChildren;

/**
 * CsQuantitativeDataParentMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsQuantitativeDataParentMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {

        static get properties() {
            return {
                hideQuantitativeData: {
                    type: Boolean,
                    value: !1
                },
                _hideQuantitativeData: {
                    type: Boolean,
                    value: !0,
                    computed: "_computeHideQuantitativeData(_quantitativeData, hideQuantitativeData)"
                },
                _quantitativeData: {
                    type: Array,
                    value() {
                        return [];
                    }
                }
            };
        }
        _computeHideQuantitativeData(e, t) {
            return !e.length || t;
        }
        _getQuantitativeData() {
            this._quantitativeData = e(this, ["quantitative-data"]);
        }
        connectedCallback() {
            super.connectedCallback(), this._getQuantitativeData(), this.shadowRoot.querySelector("slot[name=\"quantitative-data\"]").addEventListener("slotchange", this._getQuantitativeData.bind(this)); // jshint ignore:line
        }
    };

export const CsQuantitativeDataParentMixin = dedupingMixin(internalCsQuantitativeDataParentMixin);