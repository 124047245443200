import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsCurrentLinkMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsCurrentLinkMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                current: {
                    reflectToAttribute: !0,
                    type: Boolean,
                    value: !1
                },
                strictCurrent: {
                    reflectToAttribute: !0,
                    type: Boolean
                }
            };
        }
        _isCurrent() {
            const e = this.shadowRoot.querySelector("a").href;
            if (!e) return !1;
            const t = window.location.href === e;
            const a = window.location.origin + window.location.pathname === e;
            return t || !this.strictCurrent && a;
        }
        _setCurrent() {
            this.current = this._isCurrent(), this.current && this.dispatchEvent(new CustomEvent("cs-current-link-change", {
                detail: {
                    textContent: this.textContent
                },
                bubbles: !0,
                composed: !0
            })); // jshint ignore:line
        }
        connectedCallback() {
            super.connectedCallback(), setTimeout(() => {
                this.current || this._setCurrent(); // jshint ignore:line
            }), window.addEventListener("hashchange", this._setCurrent.bind(this)); // jshint ignore:line
        }
    };

export const CsCurrentLinkMixin = dedupingMixin(internalCsCurrentLinkMixin);