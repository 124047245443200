import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";

import { CsCtaParentMixin } from "./mixins/cs-cta-parent-mixin.js";
import { CsQuantitativeDataParentMixin } from "./mixins/cs-quantitative-data-parent-mixin.js";
import { CsCopyParentMixin } from "./mixins/cs-copy-parent-mixin.js";

import { CsMixin } from "./mixins/cs-mixin.js";
const t = CsMixin,
	a = CsQuantitativeDataParentMixin,
	r = CsCopyParentMixin,
	n = CsCtaParentMixin;

/**
 * `cs-editorial` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsEditorial extends t(a(r(n((PolymerElement))))) {
	static get properties() {
		return {
			layout: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			type: {
				type: String,
				value: "horizontal",
				reflectToAttribute: !0,
			},
			imageOrder: {
				type: String,
				value: "before",
				reflectToAttribute: !0,
			},
			textColor: {
				type: String,
				value: "light",
				reflectToAttribute: !0,
			},
			imageSmallest: String,
			imageSmall: String,
			imageMedium: String,
			imageLarge: String,
			imageTooltip: {
				type: String,
				value: " ",
			},
			eyebrowText: {
				type: String,
				value: "",
			},
			eyebrowSecondaryText: {
				type: String,
				value: "",
			},
			titleText: String,
			bylineText: String,
			spacingTop: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			spacingBottom: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			context: {
				type: String,
				value: "cs-editorial",
			},
		};
	}

	static get template() {
        return html`<style include="cs-shared-styles-typography-paragraphs"></style>
        <style include="cs-shared-styles-typography-labels"></style>
        <style include="cs-shared-styles-typography-headings"></style>
        <style include="cs-shared-styles-base-headings"></style>
        <style include="cs-shared-styles-common-spacing"></style>
        <style include="cs-shared-styles-base-common"></style>
        <style>
				:host {
					display: flex;
					flex-direction: column;
					position: ;
				}
				:host([rtl]) {
					text-align: right;
				}
				@media only screen and (min-width: 1024px) {
					:host([type="horizontal"]) {
						flex-direction: row;
					}
				}
				:host([text-color="dark"]) {
					color: var(--cs-dark-color);
				}
				:host([text-color="light"]) {
					color: var(--cs-light-color);
				}
				:host .cspl-editorial__media {
					min-height: 1px;
				}
				@media only screen and (min-width: 1024px) {
					:host([layout="small"][type="horizontal"]) .cspl-editorial__media {
						flex: 1;
					}
					:host([layout="medium"][type="horizontal"]) .cspl-editorial__media {
						width: calc(8 * var(--cs-column-tablet-landscape) + 7 * var(--cs-gutter-tablet-landscape));
					}
					:host([type="horizontal"][image-order="before"]) .cspl-editorial__media {
						margin-right: var(--cs-gutter-tablet-landscape);
						order: 0;
					}
					:host([rtl][type="horizontal"][image-order="before"]) .cspl-editorial__media {
						margin-left: var(--cs-gutter-tablet-landscape);
						margin-right: 0;
					}
					:host([type="horizontal"][image-order="after"]) .cspl-editorial__media {
						margin-left: var(--cs-gutter-tablet-landscape);
						order: 1;
					}
					:host([rtl][type="horizontal"][image-order="after"]) .cspl-editorial__media {
						margin-left: 0;
						margin-right: var(--cs-gutter-tablet-landscape);
					}
				}
				@media only screen and (min-width: 1456px) {
					:host([layout="medium"][type="horizontal"]) .cspl-editorial__media {
						width: calc(8 * var(--cs-column-desktop) + 7 * var(--cs-gutter-desktop));
					}
					:host([type="horizontal"][image-order="before"]) .cspl-editorial__media {
						margin-right: var(--cs-gutter-desktop);
					}
					:host([type="horizontal"][image-order="after"]) .cspl-editorial__media {
						margin-left: var(--cs-gutter-desktop);
					}
				}
				.cspl-editorial__media-image {
					display: block;
					height: auto;
					width: 100%;
				}
				@media only screen and (min-width: 768px) {
					:host([layout="small"][type="horizontal"]) .cspl-editorial__content {
						flex: 1;
						width: calc(10 * var(--cs-column-tablet-landscape) + 9 * var(--cs-gutter-tablet-landscape));
					}
					:host([rtl][layout="small"][type="horizontal"]) .cspl-editorial__content {
						margin-left: calc(100% - (10 * var(--cs-column-tablet-landscape) + 9 * var(--cs-gutter-tablet-landscape)));
					}
					:host([layout="medium"][type="horizontal"]) .cspl-editorial__content {
						width: calc(10 * var(--cs-column-tablet-landscape) + 9 * var(--cs-gutter-tablet-landscape));
					}
					:host([rtl][layout="medium"][type="horizontal"]) .cspl-editorial__content {
						margin-left: calc(100% - (10 * var(--cs-column-tablet-landscape) + 9 * var(--cs-gutter-tablet-landscape)));
					}
					:host([layout="small"][type="vertical"]) .cspl-editorial__content {
						padding-right: calc(4 * var(--cs-column-tablet-portrait) + 4 * var(--cs-gutter-tablet-portrait));
					}
					:host([rtl][layout="small"][type="vertical"]) .cspl-editorial__content {
						padding-left: calc(4 * var(--cs-column-tablet-portrait) + 4 * var(--cs-gutter-tablet-portrait));
						padding-right: 0;
					}
					:host([layout="medium"][type="vertical"]) .cspl-editorial__content {
						display: flex;
					}
				}
				@media only screen and (min-width: 1024px) {
					:host([type="horizontal"]) .cspl-editorial__content {
						display: flex;
						flex-direction: column;
						justify-content: center;
					}
					:host([layout="medium"][type="horizontal"]) .cspl-editorial__content {
						width: calc(4 * var(--cs-column-tablet-landscape) + 3 * var(--cs-gutter-tablet-landscape));
					}
					:host([rtl][layout="medium"][type="horizontal"]) .cspl-editorial__content {
						margin-left: 0;
					}
					:host([rtl][layout="small"][type="horizontal"]) .cspl-editorial__content {
						margin-left: 0;
					}
					:host([layout="small"][type="vertical"]) .cspl-editorial__content {
						padding-right: calc(4 * var(--cs-column-tablet-landscape) + 4 * var(--cs-gutter-tablet-landscape));
					}
					:host([rtl][layout="small"][type="vertical"]) .cspl-editorial__content {
						margin-left: 0;
					}
				}
				@media only screen and (min-width: 1456px) {
					:host([layout="medium"][type="horizontal"]) .cspl-editorial__content {
						width: calc(4 * var(--cs-column-desktop) + 4 * var(--cs-gutter-desktop));
					}
					:host([layout="small"][type="vertical"]) .cspl-editorial__content {
						padding-right: calc(4 * var(--cs-column-desktop) + 4 * var(--cs-gutter-desktop));
					}
				}
				@media only screen and (min-width: 768px) {
					:host([layout="medium"][type="vertical"]) .cspl-editorial__content-body,
					:host([layout="medium"][type="vertical"]) .cspl-editorial__content-header {
						flex-basis: 50%;
					}
				}
				.cspl-editorial__eyebrow {
					color: var(--cs-theme-color);
					display: flex;
					margin-bottom: 0;
					margin-top: 28px;
				}
				.cspl-editorial__eyebrow-secondary {
					align-items: center;
					display: inline-flex;
					margin-left: 8px;
				}
				:host([rtl]) .cspl-editorial__eyebrow-secondary {
					margin-left: 0;
					margin-right: 8px;
				}
				.cspl-editorial__eyebrow-secondary::before {
					content: "";
					display: block;
					height: 14px;
					margin-right: 10px;
					width: 2px;
				}
				:host([rtl]) .cspl-editorial__eyebrow-secondary::before {
					margin-left: 10px;
					margin-right: 0;
				}
				@media only screen and (min-width: 1456px) {
					.cspl-editorial__eyebrow-secondary::before {
						top: 4px;
					}
				}
				:host([text-color="light"]) .cspl-editorial__eyebrow-secondary {
					color: var(--cs-light-color);
				}
				:host([text-color="light"]) .cspl-editorial__eyebrow-secondary::before {
					background-color: var(--cs-light-color);
				}
				:host([text-color="dark"]) .cspl-editorial__eyebrow-secondary {
					color: var(--cs-dark-color);
				}
				:host([text-color="dark"]) .cspl-editorial__eyebrow-secondary::before {
					background-color: var(--cs-dark-color);
				}
				@media only screen and (min-width: 768px) {
					:host([layout="medium"][type="vertical"]) .cspl-editorial__eyebrow {
						width: 100%;
					}
				}
				@media only screen and (min-width: 1024px) {
					.cspl-editorial__eyebrow {
						margin-top: 40px;
					}
					:host([type="horizontal"]) .cspl-editorial__eyebrow:first-child {
						margin-top: 0;
					}
				}
				@media only screen and (min-width: 1456px) {
					.cspl-editorial__eyebrow {
						margin-top: 51px;
					}
				}
				.cspl-editorial__title {
					margin-bottom: 0;
				}
				[hidden] + .cspl-editorial__title {
					margin-top: 26px;
				}
				@media only screen and (min-width: 768px) {
					:host([layout="medium"][type="vertical"]) .cspl-editorial__title {
						margin-right: var(--cs-gutter-tablet-portrait);
						width: calc(6 * var(--cs-column-tablet-portrait) + 5 * var(--cs-gutter-tablet-portrait));
					}
					:host([rtl][layout="medium"][type="vertical"]) .cspl-editorial__title {
						margin-left: var(--cs-gutter-tablet-portrait);
						margin-right: 0;
					}
				}
				@media only screen and (min-width: 1024px) {
					[hidden] + .cspl-editorial__title {
						margin-top: 36px;
					}
					:host([type="horizontal"]) [hidden] + .cspl-editorial__title {
						margin-top: 0;
					}
					:host([layout="medium"][type="vertical"]) .cspl-editorial__title {
						margin-right: var(--cs-gutter-tablet-landscape);
						width: calc(6 * var(--cs-column-tablet-landscape) + 5 * var(--cs-gutter-tablet-landscape));
					}
				}
				@media only screen and (min-width: 1456px) {
					[hidden] + .cspl-editorial__title {
						margin-top: 48px;
					}
					:host([layout="medium"][type="vertical"]) .cspl-editorial__title {
						margin-right: var(--cs-gutter-desktop);
						width: calc(6 * var(--cs-column-desktop) + 5 * var(--cs-gutter-desktop));
					}
				}
				:host([layout="medium"][type="vertical"]) .cspl-editorial__quantitative-data {
					width: 100%;
				}
				.cspl-editorial__copy {
					margin: 0;
				}
				.cspl-editorial__copy ::slotted(p:first-child) {
					margin-top: 0;
				}
				.cspl-editorial__copy ::slotted(p:last-child) {
					margin-bottom: 0;
				}
				@media only screen and (min-width: 768px) {
					:host([layout="medium"][type="vertical"]) .cspl-editorial__copy {
						margin-top: 40px;
					}
					:host([layout="medium"][type="vertical"]) [hidden] + .cspl-editorial__title + .cspl-editorial__copy {
						margin-top: 26px;
					}
					:host([layout="medium"][type="vertical"]) [hidden] + .cspl-editorial__copy {
						margin-top: -25px;
					}
				}
				:host([text-color="light"]) .cspl-editorial__byline {
					color: #585858;
				}
				:host([text-color="dark"]) .cspl-editorial__byline {
					color: #a3a3a3;
				}
				@media only screen and (min-width: 768px) {
					:host([layout="medium"][type="vertical"]) .cspl-editorial__copy {
						margin-top: 28px;
					}
				}
				@media only screen and (min-width: 1024px) {
					:host([layout="medium"][type="vertical"]) .cspl-editorial__copy {
						margin-top: 40px;
					}
				}
				@media only screen and (min-width: 1456px) {
					:host([layout="medium"][type="vertical"]) .cspl-editorial__copy {
						margin-top: 51px;
					}
				}
			</style>
			<div class="cspl-editorial__media">
				<cs-lazy-picture>
					<picture><source media="(max-width: 320px)" data-srcset$="[[ imageSmallest ]]" />
						<source media="(max-width: 767px)" data-srcset$="[[ imageSmall ]]" />
						<source media="(max-width: 1023px)" data-srcset$="[[ imageMedium ]]" />
						<source media="(min-width: 1024px)" data-srcset$="[[ imageLarge ]]" />
						<img class="cspl-editorial__media-image" data-src$="[[ imageSmallest ]]" alt="[[ imageTooltip ]]" />
					</picture>
				</cs-lazy-picture>
        <slot name="cta-button"><!-- --><slot>
			</div>
			<div class="cspl-editorial__content">
				<div class="cspl-editorial__content-header">
					<h2 class$="[[ _getEyebrowClass(layout, type, textColor) ]]" hidden$="[[ !eyebrowText ]]">
						[[ eyebrowText ]]<span class="cspl-editorial__eyebrow-secondary" hidden$="[[ !eyebrowSecondaryText ]]">[[ eyebrowSecondaryText ]]</span>
					</h2>
					<h3 class$="[[ _getTitleClass(layout, type, textColor) ]]">[[ titleText ]]</h3>
					<div class="cspl-editorial__quantitative-data b3" hidden$="[[ _hideQuantitativeData ]]"><slot name="quantitative-data" id="slotQuantativeData"></slot></div>
				</div>
				<div class="cspl-editorial__content-body">
					<div class$="[[ _getCopyClass(textColor) ]]" hidden$="[[ _hideCopy ]]"><slot name="copy"></slot></div>
					<div class="cspl-editorial__byline b2" hidden$="[[ !bylineText ]]">[[ bylineText ]]</div>
					<div class="cspl-editorial__ctas ctas" hidden$="[[ _hideCtas ]]"><slot name="cta"></slot></div>
				</div>
			</div>`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}

	static get observers() {
		return ["_observeQuantitativeData(_quantitativeData, textColor)"];
	}

	_getEyebrowClass(e, t, a) {
		var i = "l2";
		return "large" === e && "vertical" === t && (i = "h4"), "cspl-editorial__eyebrow ".concat(i, " ").concat(i, "-").concat(a);
	}
	_getTitleClass(e, t, a) {
		var i = "h5";
		return "large" === e && "vertical" === t && (i = "d2"), "cspl-editorial__title ".concat(i, " ").concat(i, "-").concat(a);
	}
	_getBylineClass(e) {
		return "cspl-editorial__byline ".concat("b2", " ").concat("b2", "-").concat(e);
	}
	_getCopyClass(e) {
		return "cspl-editorial__copy b2 b2-".concat(e);
	}
	_observeQuantitativeData(e, t) {
		e.forEach((e) => {
			e.textColor = t;
		});
	}
}

customElements.define("cs-editorial", CsEditorial);
