import {
	dedupingMixin
} from "@polymer/polymer/lib/utils/mixin.js";

/**
 * CsFormElementMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsFormElementMixin = base =>
	/**
	 * @polymerMixinClass
	 */
	class extends base {
		static get properties() {
			return {
				name: {
					type: String
				},
				value: {
					type: String,
					observer: "_valueChanged"
				},
				disabled: {
					type: Boolean,
					value: !1,
					reflectToAttribute: !0
				},
				error: {
					type: Boolean,
					value: !1,
					reflectToAttribute: !0
				},
				required: {
					type: Boolean,
					value: !1
				}
			};
		}
		_setValue(e) {
			this.value = e;
		}
		_valueChanged(e) {
			e.length && (this._empty = !1); // jshint ignore:line
		}
		connectedCallback() {
			super.connectedCallback(),
				setTimeout(() => {
					this.dispatchEvent(
						new CustomEvent("cs-form-element-register", {
							bubbles: !0,
							composed: !0
						})
					);
				}, 0); // jshint ignore:line
		}
	};

export const CsFormElementMixin = dedupingMixin(internalCsFormElementMixin);