/* jshint ignore:start */
import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-subheading` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSubheading extends PolymerElement {
	static get properties() {
		return {
			layout: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			type: {
				type: String,
				value: "ordered",
				reflectToAttribute: !0,
			},
			textAlign: {
				type: String,
				value: "center",
				reflectToAttribute: !0,
			},
			textColor: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			eyebrowText: {
				type: String,
				value: "",
			},
			titleText: String,
			subtitleText: {
				type: String,
				value: "",
			},
			spacingTop: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			spacingBottom: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-headings"></style>
			<style include="cs-shared-styles-typography-headings"></style>
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style>
				:host {
					display: block;
					text-shadow: var(--cs-theme-subheading-text-shadow);
				}

				:host([text-align="left"]) {
					text-align: left;
				}

				:host([text-align="center"]) {
					margin-left: auto;
					margin-right: auto;
					text-align: center;
				}

				.cspl-subheading__eyebrow {
					margin: 0;
				}

				.cspl-subheading__title {
					margin: 0;
				}

				.cspl-subheading__subtitle {
					margin: 0;
				}

				@media only screen and (min-width: 768px) {
					:host {
						max-width: calc((var(--cs-column-tablet-portrait-with-rail) * 10) + (var(--cs-gutter-tablet-portrait) * 9));
					}

					:host([layout="small"]) {
						margin-bottom: var(--cs-small-component-space-tablet-portrait);
						margin-top: var(--cs-small-component-space-tablet-portrait);
					}

					:host([layout="large"]),
					:host([layout="medium"]) {
						margin-bottom: var(--cs-medium-component-space-tablet-portrait);
						margin-top: var(--cs-medium-component-space-tablet-portrait);
					}

					:host([layout="xlarge"]) {
						margin-bottom: var(--cs-large-component-space-tablet-portrait);
						margin-top: var(--cs-large-component-space-tablet-portrait);
					}
				}

				@media only screen and (min-width: 1024px) {
					:host {
						max-width: calc((var(--cs-column-tablet-landscape-with-rail) * 10) + (var(--cs-gutter-tablet-landscape) * 9));
					}

					:host([layout="small"]) {
						margin-bottom: var(--cs-small-component-space-tablet-landscape);
						margin-top: var(--cs-small-component-space-tablet-landscape);
					}

					:host([layout="large"]),
					:host([layout="medium"]) {
						margin-bottom: var(--cs-medium-component-space-tablet-landscape);
						margin-top: var(--cs-medium-component-space-tablet-landscape);
					}

					:host([layout="xlarge"]) {
						margin-bottom: var(--cs-large-component-space-tablet-landscape);
						margin-top: var(--cs-large-component-space-tablet-landscape);
					}
				}

				@media only screen and (min-width: 1456px) {
					:host {
						max-width: calc((var(--cs-column-desktop-with-rail) * 10) + (var(--cs-gutter-desktop) * 9));
					}

					:host([layout="small"]) {
						margin-bottom: var(--cs-small-component-space-desktop);
						margin-top: var(--cs-small-component-space-desktop);
					}

					:host([layout="large"]),
					:host([layout="medium"]) {
						margin-bottom: var(--cs-medium-component-space-desktop);
						margin-top: var(--cs-medium-component-space-desktop);
					}

					:host([layout="xlarge"]) {
						margin-bottom: var(--cs-large-component-space-desktop);
						margin-top: var(--cs-large-component-space-desktop);
					}
				}

				:host(:first-child) {
					margin-top: 0;
				}

				:host(:last-child) {
					margin-bottom: 0;
				}

				:host([type="overlap"]) {
					margin-bottom: -8px;
					position: relative;
					z-index: 1;
				}

				@media only screen and (min-width: 1024px) {
					:host([type="overlap"]) {
						margin-bottom: -24px;
					}
				}
			</style>
			<h2 class$="[[ _getEyebrowClass(textColor) ]]" hidden$="[[ !eyebrowText ]]">[[ eyebrowText ]]</h2>
			<h3 class$="[[ _getTitleClass(layout, textColor) ]]">[[ titleText ]]</h3>
			<h4 class$="[[ _getSubtitleClass(textColor) ]]" hidden$="[[ !subtitleText ]]">[[ subtitleText ]]</h4>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_getEyebrowClass(e) {
		return `cspl-subheading__eyebrow h4 h4-${e}`;
	}
	_getTitleClass(e, t) {
		let a;
		return (a = "small" === e ? "d4" : "medium" === e ? "d3" : "large" === e ? "d2" : "xlarge" === e ? "d1" : "d4"), `cspl-subheading__title ${a} ${a}-${t}`;
	}
	_getSubtitleClass(e) {
		return `cspl-subheading__subtitle h4 h4-${e}`;
	}
}

customElements.define("cs-subheading", CsSubheading);
