import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";

import { FlattenedNodesObserver } from "@polymer/polymer/lib/utils/flattened-nodes-observer.js";

/**
 * `cs-local-footer` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLocalFooter extends PolymerElement {
	static get properties() {
		return {
			theme: {
				type: String,
				value: null,
				reflectToAttribute: !0,
			},
			_studioLinks: {
				type: Array,
				value() {
					return [];
				},
				observer: "_observeStudioLinks",
			},
			hideStudioLinks: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			_studioLinksObserver: Object,
			_ratingLinks: {
				type: Array,
				value() {
					return [];
				},
				observer: "_observeRatingLinks",
			},
			hideRatingLinks: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			_ratingLinksObserver: Object,
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-typography-paragraphs"></style>
			<style>
				:host {
					background: #eaeaea;
					display: block;
					padding: 12px 22px 32px;
				}

				@media only screen and (min-width: 768px) {
					:host {
						padding: 12px 60px 40px;
					}
				}

				@media only screen and (min-width: 1024px) {
					:host {
						padding: 12px 76px 12px;
					}
				}

				@media only screen and (min-width: 1456px) {
					:host {
						padding: 0 168px 16px;
					}
				}

				:host([theme="light"]) {
					background: var(--cs-dark-color);
				}

				:host([theme="brand"]) {
					background: var(--cs-theme-color);
				}

				:host([theme="dark"]) {
					background: var(--cs-light-color);
				}

				.cspl-local-footer__links {
					align-items: baseline;
					display: flex;
					flex-direction: column;
					margin: auto;
					max-width: 1120px;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-footer__links {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-local-footer__links {
						padding-bottom: 0px;
					}
				}

				.cspl-local-footer__local-links,
				.cspl-local-footer__social-links {
					width: 100%;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-footer__local-links,
					.cspl-local-footer__social-links {
						width: auto;
					}
				}

				.cspl-local-footer__local-links,
				.cspl-local-footer__social-links {
					width: 100%;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-footer__local-links,
					.cspl-local-footer__social-links {
						width: auto;
					}
				}

				.cspl-local-footer__info {
					border-top: 1px solid #595959;
					margin: auto;
					max-width: 1120px;
				}

				:host([hide-studio-links][hide-rating-links]) .cspl-local-footer__info {
					display: none;
				}

				@media only screen and (min-width: 768px) {
					.cspl-local-footer__info {
						padding-top: 24px;
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-footer__info {
						align-items: center;
						display: flex;
						justify-content: space-between;
						/* padding-bottom: 28px; */
						padding-top: 28px;
					}

					:host([hide-studio-links]) .cspl-local-footer__info {
						justify-content: flex-end;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-local-footer__info {
						padding-bottom: 32px;
						padding-top: 32px;
					}
				}

				.cspl-local-footer__studio-links {
					align-items: center;
					display: flex;
					flex-wrap: wrap;
					margin: 24px 0;
				}

				:host([hide-studio-links]) .cspl-local-footer__studio-links {
					display: none;
				}

				@media only screen and (min-width: 768px) {
					.cspl-local-footer__studio-links {
						margin: 0 0 32px;
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-footer__studio-links {
						margin: 0;
					}
				}

				:host([hide-rating-links]) .cspl-local-footer__rating-links {
					display: none;
				}

				.cspl-local-footer__signature {
					text-align: right;
					max-width: 1120px;
					margin: auto;
				}

				.cspl-local-footer__signature-space {
					border-radius: 25px;
					height: 50px;
					width: 150px;
				}

				.cspl-local-footer__legal {
					margin: auto;
					max-width: 1120px;
				}

				.cspl-local-footer__legal-disclaimer {
					color: #161616;
					margin: 27px 0 3px;
					max-width: 480px;
				}

				:host([theme="brand"]) .cspl-local-footer__legal-disclaimer {
					color: #fff;
				}

				:host([theme="light"]) .cspl-local-footer__legal-disclaimer {
					color: #a3a3a3;
				}

				@media only screen and (min-width: 768px) {
					.cspl-local-footer__legal-disclaimer {
						margin: 30px 0 -5px;
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-local-footer__legal-disclaimer {
						margin: -4px 0 23px;
						max-width: 572px;
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-local-footer__legal-disclaimer {
						margin: 0 0 34px;
						max-width: 736px;
					}
				}
			</style>
			<section class="cspl-local-footer__links">
				<div class="cspl-local-footer__local-links">
					<slot name="local-links"></slot>
				</div>
				<div class="cspl-local-footer__social-links">
					<slot name="social-links"></slot>
				</div>
			</section>
			<section class="cspl-local-footer__info">
				<div class="cspl-local-footer__studio-links">
					<slot id="studioLinksSlot" name="studio-link"></slot>
				</div>
				<div class="cspl-local-footer__rating-links">
					<slot id="ratingLinksSlot" name="rating-link"></slot>
				</div>
			</section>
			<section class="cspl-local-footer__legal">
				<div class="cspl-local-footer__legal-disclaimer b3">
					<slot name="legal-disclaimer-text"></slot>
				</div>
			</section>
			<section class="cspl-local-footer__signature">
				<slot name="signature"></slot>
			</section> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_observeStudioLinks(e) {
		this.hideStudioLinks = !e.length;
	}
	_setStudioLinks(e) {
		(this._studioLinks = e.target.assignedNodes({
			flatten: !0,
		})),
			this._studioLinksObserver.disconnect(); // jshint ignore:line
	}
	_observeRatingLinks(e) {
		this.hideRatingLinks = !e.length;
	}
	_setRadioLinks(e) {
		(this._ratingLinks = e.target.assignedNodes({
			flatten: !0,
		})),
			this._ratingLinksObserver.disconnect(); // jshint ignore:line
	}
	connectedCallback() {
		super.connectedCallback(),
			(this._studioLinksObserver = new FlattenedNodesObserver(this.$.studioLinksSlot, this._setStudioLinks.bind(this))),
			(this._ratingLinksObserver = new FlattenedNodesObserver(this.$.ratingLinksSlot, this._setRadioLinks.bind(this))); // jshint ignore:line
	}
}

customElements.define("cs-local-footer", CsLocalFooter);
