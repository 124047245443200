/* jshint ignore:start */
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsFormElementMixin } from './mixins/cs-form-element-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsValueValidatorMixin } from './mixins/cs-value-validator-mixin.js';

window.nds = window.nds || {};
const s = CsMixin,
  r = CsValueValidatorMixin,
  c = CsFormElementMixin;
/**
 * `cs-checkbox` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsCheckbox extends s(r(c(CsElements))) {
  static get properties() {
    return {
      checked: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-shared-styles-base-links"></style>
      <style>
        :host {
          display: flex;
        }
        :host([text-color='dark']) {
          color: var(--cs-dark-color);
        }
        :host([text-color='light']) {
          color: var(--cs-light-color);
        }
        .cspl-checkbox {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        .cspl-checkbox__icon {
          cursor: pointer;
          height: 18px;
          margin-right: 16px;
          margin-top: 1px;
          min-width: 18px;
          transform-origin: center;
          transition: all 0.1s linear;
          width: 18px;
        }
        :host([rtl]) .cspl-checkbox__icon {
          margin-left: 16px;
          margin-right: 0;
        }
        .cspl-checkbox__icon--default {
          display: block;
        }
        :host([checked]) .cspl-checkbox__icon--default {
          display: none;
        }
        .cspl-checkbox__icon--checked {
          display: none;
        }
        :host([checked]) .cspl-checkbox__icon--checked {
          display: block;
        }
        .cspl-checkbox__label:hover iron-icon {
          transform: scale(1.1);
        }
        .cspl-checkbox:focus iron-icon {
          box-shadow: 0 0 5px #83bffc;
        }
        :host([error]) iron-icon {
          color: var(--cs-error-color);
        }
        @media only screen and (min-width: 1456px) {
          .cspl-checkbox__icon {
            margin-top: 3px;
          }
        }
        .cspl-checkbox__label {
          cursor: pointer;
          margin: 0;
        }
      </style>
      <input
        id$="[[ name ]]"
        class="cspl-checkbox"
        name$="[[ name ]]"
        type="checkbox"
        checked$="{{ checked }}"
        on-tap="_handleClick"
      /><iron-icon
        icon="cs-form-18:checkbox-default"
        class="cspl-checkbox__icon cspl-checkbox__icon--default"
        on-tap="_handleClick"
      ></iron-icon
      ><iron-icon
        icon="cs-form-18:checkbox-checked"
        class="cspl-checkbox__icon cspl-checkbox__icon--checked"
        on-tap="_handleClick"
      ></iron-icon
      ><label
        class="cspl-checkbox__label b3"
        on-tap="_handleClick"
        for$="[[ name ]]"
        ><slot></slot
      ></label>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  get checkbox() {
    return this.shadowRoot.querySelector('.cspl-checkbox');
  }
  _handleClick(e) {
    'A' !== e.target.nodeName &&
      (e.preventDefault(),
      (this.error = !1),
      (this.checked = !this.checked),
      (this.checkbox.checked = this.checked));
  }
  _setValue(e) {
    (this.checked = e),
      e
        ? this.checkbox.setAttribute('checked', '')
        : this.checkbox.removeAttribute('checked');
  }
  connectedCallback() {
    super.connectedCallback(),
      this._setValue(this.checked),
      (this.type = 'checkbox');
  }
}

customElements.define('cs-checkbox', CsCheckbox);
