/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsMixin } from './mixins/cs-mixin.js';

const e = CsMixin;

/**
 * `cs-network-legal` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsNetworkLegal extends e(PolymerElement) {
  static get properties() {
    return {
      textColor: {
        type: String,
        value: null,
        reflectToAttribute: !0,
      },
      copyrightText: String,
      cookiePreferencesLinkVisible: {
        type: Boolean,
        value: !1,
      },
      cookiePreferencesText: String,
    };
  }

  static get template() {
    return html`<style>
        :host {
          color: #a3a3a3;
          display: block;
          font-family: var(--cs-theme-primary-font-family);
          font-size: 14px;
          line-height: 20px;
        }

        @media only screen and (min-width: 1456px) {
          :host {
            font-size: 16px;
            line-height: 24px;
          }
        }

        :host([text-color='light']) {
          color: #585858;
        }

        .cspl-network-legal__cookie-preferences-link,
        :host ::slotted([slot='link']) {
          color: #a3a3a3;
          cursor: pointer;
          display: inline-block;
          font-family: var(--cs-theme-primary-font-family);
          font-size: 14px;
          line-height: 20px;
          margin: 9px 0px 4px 0;
          text-decoration: none;
        }

        @media only screen and (min-width: 768px) {
          :host ::slotted([slot='link']) {
            margin-bottom: 3px;
            margin-top: 1px;
          }
        }

        @media only screen and (min-width: 1024px) {
          :host ::slotted([slot='link']) {
            margin-bottom: 6px;
            margin-top: 0;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host ::slotted([slot='link']) {
            font-size: 16px;
            line-height: 24px;
            margin: 2px 36px 0 0;
          }
        }

        :host([text-color='light'])
          .cspl-network-legal__cookie-preferences-link {
          color: #585858;
        }

        .copyright {
          margin-top: 9px;
        }

        @media only screen and (min-width: 768px) {
          .copyright {
            margin-top: 10px;
          }
        }

        @media only screen and (min-width: 1024px) {
          .copyright {
            margin-top: 7px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .copyright {
            margin-top: 10px;
          }
        }
      </style>
      <slot name="link"></slot>
      <a
        class="cspl-network-legal__cookie-preferences-link"
        hidden$="!cookiePreferencesLinkVisible"
        on-tap="showCookiePreferences"
        >[[ cookiePreferencesText ]]</a
      >
      <div class="copyright">[[ _computeCopyrightText(copyrightText) ]]</div> `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  showCookiePreferences() {
    truste && truste.eu && truste.eu.clickListener && truste.eu.clickListener(); // jshint ignore:line
  }
  _computeCopyrightText(e) {
    return e.replace('%year%', new Date().getFullYear());
  }
}

customElements.define('cs-network-legal', CsNetworkLegal);
