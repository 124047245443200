import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin.js';

/**
 * MixinNamePascelCase - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsRatioFitMixin = base =>
  /**
   * @polymerMixinClass
   */
  class extends base {
    static get properties() {
      return {
        ratio: {
          type: Number,
          value: 0.5625,
          reflectToAttribute: !0,
        },
      };
    }
    _fit(L) {
      this._setDimensions(L);
      var e = L.getBoundingClientRect(),
        t = e.height,
        a = e.width,
        l = t / a,
        i = this.ratio;
      l < i &&
        this._setDimensions(L, ''.concat(t, 'px'), ''.concat(t / i, 'px')),
        l > i &&
          this._setDimensions(L, ''.concat(a * i, 'px'), ''.concat(a, 'px'));
    }
    _setDimensions(L) {
      var e =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : '',
        t = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : '';
      (L.style.height = e), (L.style.width = t);
    }
    connectedCallback() {
      super.connectedCallback(),
        this.addEventListener('iron-resize', this._onResize);
    }
  };

export const CsRatioFitMixin = dedupingMixin(internalCsRatioFitMixin);
