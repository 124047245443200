/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-spacer` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSpacer extends PolymerElement {
	static get properties() {
		return {
			smallest: String,
			small: String,
			medium: String,
			large: String,
			largest: String,
		};
	}

	static get template() {
		return html`
			<style>
				:host {
					display: block;
					height: var(--cs-theme-spacer-height);
				}
				@media screen and (min-width: 321px) {
					:host {
						height: var(--cs-theme-spacer-height-mobile);
					}
				}
				@media screen and (min-width: 768px) {
					:host {
						height: var(--cs-theme-spacer-height-tablet-portrait);
					}
				}
				@media screen and (min-width: 1024px) {
					:host {
						height: var(--cs-theme-spacer-height-tablet-landscape);
					}
				}
				@media screen and (min-width: 1456px) {
					:host {
						height: var(--cs-theme-spacer-height-desktop);
					}
				}
			</style>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}

	_updateSpacerHeight() {
		var e = Object.assign(
			{},
			this.smallest && {
				"--cs-theme-spacer-height": this.smallest,
			},
			this.small && {
				"--cs-theme-spacer-height-mobile": this.small,
			},
			this.medium && {
				"--cs-theme-spacer-height-tablet-portrait": this.medium,
			},
			this.large && {
				"--cs-theme-spacer-height-tablet-landscape": this.large,
			},
			this.largest && {
				"--cs-theme-spacer-height-desktop": this.largest,
			}
		);
		this.updateStyles(e);
	}

	connectedCallback() {
		super.connectedCallback(), this._updateSpacerHeight();
	}
}

customElements.define("cs-spacer", CsSpacer);
