/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsHasImageMixin } from "./mixins/cs-has-image-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";



// var e = window.nds;
const t = CsMixin,
	a = CsHasImageMixin;

/**
 * `cs-studio-link` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsStudioLink extends t(a(PolymerElement)) {
	static get properties() {
		return {
			linkUrl: String,
			linkTitle: String,
			imageSmallest: {
				type: String,
				value: null,
				reflectToAttribute: !0,
			},
			imageSmall: {
				type: String,
				value: null,
				reflectToAttribute: !0,
			},
			imageMedium: {
				type: String,
				value: null,
				reflectToAttribute: !0,
			},
			imageLarge: {
				type: String,
				value: null,
				reflectToAttribute: !0,
			},
			imageLargest: {
				type: String,
				value: null,
				reflectToAttribute: !0,
			},
			imageAlt: {
				type: String,
				value: " ",
			},
		};
	}

	static get template() {
		return html`<style>
        :host {
          display: block;
          line-height: 0;
          margin-right: 24px
        }

        @media only screen and (min-width:768px) {
          :host {
            margin-right: 28px
          }
        }

        @media only screen and (min-width:1024px) {
          :host {
            margin-right: 32px
          }
        }

        @media only screen and (min-width:1456px) {
          :host {
            margin-right: 40px
          }
        }

        :host(:last-of-type) {
          margin-right: 0
        }

        img {
          max-width: 100%
        }
      </style>
      <template is="dom-if"
        if="[[ _hasImage(imageSmallest, imageSmall, imageMedium, imageLarge, imageLargest) ]]"><a href="[[ linkUrl ]]"
          title="[[ linkTitle ]]" target="_blank">
          <cs-lazy-picture>
            <picture>
              <source media="(max-width: 320px)"
                data-srcset$="[[ imageSmallest ]]">
              <source media="(max-width: 767px)"
                data-srcset$="[[ imageSmall ]]">
              <source media="(max-width: 1023px)"
                data-srcset$="[[ imageMedium ]]">
              <source media="(max-width: 1455px)"
                data-srcset$="[[ imageLarge ]]">
              <source media="(min-width: 1456px)"
                data-srcset$="[[ imageLargest ]]"><img data-src$="[[ imageSmallest ]]"
                alt$="[[ imageAlt ]]"></picture>
          </cs-lazy-picture>
        </template>

        `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
}

customElements.define("cs-studio-link", CsStudioLink);
