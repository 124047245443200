import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsIframeMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsIframeMixin = base =>
  /**
   * @polymerMixinClass
   */
  class t extends base {
    static get properties() {
      return {
        allowfullscreen: {
          observer: '_allowFullscreen',
          type: Boolean,
          value: !0,
          reflectToAttribute: !0,
        },
      };
    }
    _allowFullscreen() {
      var e = 'allowfullscreen',
        t = this.shadowRoot.querySelector('iframe');
      t &&
        ['', 'moz', 'webkit'].forEach(i => {
          this.allowfullscreen
            ? t.setAttribute(''.concat(i).concat(e), '')
            : t.removeAttribute(''.concat(i).concat(e));
        });
    }
  };

export const CsIframeMixin = dedupingMixin(internalCsIframeMixin);
