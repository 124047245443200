/* jshint ignore:start */
import { GestureEventListeners } from "@polymer/polymer/lib/mixins/gesture-event-listeners.js";
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsMixin } from "./mixins/cs-mixin.js";

const e = CsMixin;

/**
 * `cs-category-tag` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsCategoryTag extends e(GestureEventListeners(PolymerElement)) {
	static get properties() {
		return {
			theme: {
				type: String,
				reflectToAttribute: !0,
				value: "dark",
			},
			removable: {
				type: Boolean,
				value: !1,
			},
			filterKey: String,
			labelText: String,
			linkUrl: String,
			linkTitle: String,
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-typography-paragraphs"></style>
			<style>
				:host {
					display: inline-flex;
					margin: 8px 8px 0 0;
				}

				.cspl-tag__link,
				:host([theme="dark"][removable]) .cspl-tag__link-active:hover,
				:host([theme="light"]) .cspl-tag__link-active:hover {
					align-items: center;
					background-color: #d8d8d8;
					border-radius: 4px;
					color: var(--cs-dark-color);
					display: inherit;
					line-height: 1;
					margin: 0;
					padding: 6px 12px;
					position: relative;
					text-decoration: none;
					transition: none;
				}

				.cspl-tag__link-active:hover,
				:host([theme="light"]) .cspl-tag__link,
				:host([theme="light"][removable]) .cspl-tag__link-active:hover {
					background-color: #303030;
					color: var(--cs-light-color);
				}

				:host([removable]) .cspl-tag__link {
					pointer-events: none;
				}

				.cspl-tag__remove-tag-icon {
					background-color: var(--cs-theme-color);
					border-radius: 100%;
					color: #fff;
					cursor: pointer;
					height: 1em;
					margin: 0 0 0 12px;
					padding: 2px;
					pointer-events: initial;
					width: 1em;
				}

				.cspl-tag__remove-tag-icon:hover {
					background-color: #000;
				}

				:host([theme="light"]) .cspl-tag__remove-tag-icon:hover {
					background-color: #fff;
					color: #000;
				}
			</style>
			<a class$="cspl-tag__link b3 [[ _computeActiveLinkClass(linkUrl) ]]" href="[[ linkUrl ]]" title="[[ linkTitle ]]"
				>[[ labelText ]]
				<iron-icon icon="cs-action-16:close" class="cspl-tag__remove-tag-icon" hidden$="[[ !removable ]]" on-tap="_onRemoveTagTap"></iron-icon>
			</a> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_computeActiveLinkClass(e) {
		return e ? "cspl-tag__link-active" : "";
	}
	_onRemoveTagTap(e) {
		e.preventDefault(),
			this.dispatchEvent(
				new CustomEvent("nds.categoryTag.tagRemoved", {
					bubbles: !0,
					composed: !0,
					detail: {
						filter: this.filterKey,
					},
				})
			); // jshint ignore:line
	}
}

customElements.define("cs-category-tag", CsCategoryTag);
