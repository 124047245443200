/* jshint ignore:start */
import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";

import { CsMixin } from "./mixins/cs-mixin.js";
import { CsCtaParentMixin } from "./mixins/cs-cta-parent-mixin.js";

const t = CsMixin,
	a = CsCtaParentMixin;

/**
 * `cs-cta-group` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsCtaGroup extends t(a(PolymerElement)) {
	static get properties() {
		return {
			context: String,
			layout: {
				reflectToAttribute: !0,
				type: String,
				value: "horizontal",
			},
		};
	}

	static get template() {
		return html`<style>
				:host {
					align-items: baseline;
					display: flex;
					flex-wrap: wrap;
				}

				:host([layout="vertical"]) {
					align-items: center;
					flex-direction: column;
				}

				@media only screen and (max-width: 767px) {
					:host([layout="vertical"]) {
						align-items: left;
					}
				}

				:host([layout="vertical"]) ::slotted([slot="cta"]) {
					margin-right: 0;
				}

				:host ::slotted(.cspl-cta-first) {
					margin-bottom: 0;
					margin-top: 0;
				}

				:host([layout="vertical"]) ::slotted([slot="cta"][type="text"]) {
					margin-top: 24px;
				}

				@media only screen and (max-width: 767px) {
					:host ::slotted([slot="cta"]) {
						width: auto;
					}

					:host([layout="horizontal"]) ::slotted([slot="cta"]) {
						margin-left: 16px;
					}

					:host([layout="horizontal"]) ::slotted(.cspl-cta-first) {
						margin-left: 0;
					}

					:host ::slotted(.cspl-cta-first) {
						margin: 0;
					}
				}
			</style>
			<slot name="cta"></slot> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
}

customElements.define("cs-cta-group", CsCtaGroup);
