import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';

/**
 * `cs-pause-animation` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
export class CsPauseAnimation extends PolymerElement {
  static get properties() {
    return {
      enterAnimatable: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      enablePauseAnimation: {
        type: Boolean,
        value: !1,
        computed: '_enablePaused(animationOnExit, animationOnEnter)',
        reflectToAttribute: !0,
      },
      enterPaused: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      enablePauseBehavior: {
        type: Boolean,
        value: !1,
        observer: '_executePauseBehavior',
      },
    };
  }

  static get template() {
    return html`<style>
      /** * OverWrite animation keyframe */
      @keyframes slideUpFadeIn {
        from {
          opacity: var(--cs-fade-in-from-value);
          transform: translate(
            0,
            var(--cs-pause-animation-slide-up-from-value)
          );
        }
        to {
          opacity: var(--cs-fade-in-to-value);
          transform: translate(0, var(--cs-pause-animation-slide-up-to-value));
        }
      }
      @keyframes slideDownFadeIn {
        from {
          opacity: var(--cs-fade-in-from-value);
          transform: translate(
            0,
            var(--cs-pause-animation-slide-down-from-value)
          );
        }
        to {
          opacity: var(--cs-fade-in-to-value);
          transform: translate(
            0,
            var(--cs-pause-animation-slide-down-to-value)
          );
        }
      }
      @keyframes slideInLeftFadeIn {
        from {
          opacity: var(--cs-fade-in-from-value);
          transform: translate(
            var(--cs-pause-animation-slide-in-left-from-value),
            0
          );
        }
        to {
          opacity: var(--cs-fade-in-to-value);
          transform: translate(
            var(--cs-pause-animation-slide-in-left-to-value),
            0
          );
        }
      }
      @keyframes slideInRightFadeIn {
        from {
          opacity: var(--cs-fade-in-from-value);
          transform: translate(
            var(--cs-pause-animation-slide-in-right-from-value),
            0
          );
        }
        to {
          opacity: var(--cs-fade-in-to-value);
          transform: translate(
            var(--cs-pause-animation-slide-in-right-to-value),
            0
          );
        }
      }
      @keyframes slideOutDownFadeOut {
        from {
          opacity: var(--cs-fade-out-from-value);
          transform: translate(
            0,
            var(--cs-pause-animation-slide-out-down-from-value)
          );
        }
        to {
          opacity: var(--cs-fade-out-to-value);
          transform: translate(
            0,
            var(--cs-pause-animation-slide-out-down-to-value)
          );
        }
      }
      @keyframes slideOutUpFadeOut {
        from {
          opacity: var(--cs-fade-out-from-value);
          transform: translate(
            0,
            var(--cs-pause-animation-slide-out-up-from-value)
          );
        }
        to {
          opacity: var(--cs-fade-out-to-value);
          transform: translate(
            0,
            var(--cs-pause-animation-slide-out-up-to-value)
          );
        }
      }
      @keyframes slideOutLeftFadeOut {
        from {
          opacity: var(--cs-fade-out-from-value);
          transform: translate(
            var(--cs-pause-animation-slide-out-left-from-value),
            0
          );
        }
        to {
          opacity: var(--cs-fade-out-to-value);
          transform: translate(
            var(--cs-pause-animation-slide-out-left-to-value),
            0
          );
        }
      }
      @keyframes slideOutRightFadeOut {
        from {
          opacity: var(--cs-fade-out-from-value);
          transform: translate(
            var(--cs-pause-animation-slide-out-right-from-value),
            0
          );
        }
        to {
          opacity: var(--cs-fade-out-to-value);
          transform: translate(
            var(--cs-pause-animation-slide-out-right-to-value),
            0
          );
        }
      }
    </style>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  static get observers() {
    return ['_setPausedStatus(enterPaused, enablePauseAnimation)'];
  }

  _enablePaused(e, t) {
    return e && t;
  }
  _setPausedStatus(e, t) {
    if (!t) return;
    let a = null;
    if (
      (this.shadowRoot && (a = this.shadowRoot.querySelector(i[this.tagName])),
      e)
    ) {
      const e = getComputedStyle(a || this).opacity;
      if (
        (this.style.setProperty('--cs-fade-out-from-value', e),
        this.style.setProperty('--exit-opacity-from-value', e),
        this.exitAnimationKey)
      ) {
        const { key: t, scope: a } = this.exitAnimationKey,
          i =
            ((e - this.enterOpacityFromValue) / this.diffOpacityEnter) *
              this.diffTransFormEnter +
            1 * this.enterTransformFromValue;
        this.style.setProperty(`--exit-translate${a}-from-value`, i + 'px'),
          this.style.setProperty(t + 'from-value', i + 'px');
      }
    }
  }
  _setAnimationValue(e, t, a) {
    if (
      ((this.enterAnimationKey = o[e]),
      (this.exitAnimationKey = o[t]),
      this.enterAnimationKey)
    ) {
      const { key: e, scope: t } = this.enterAnimationKey;
      (this.enterTransformFromValue = a
        .getPropertyValue(e + 'from-value')
        .replace('px', '')),
        (this.enterTransformToValue = a
          .getPropertyValue(e + 'to-value')
          .replace('px', '')),
        (this.diffTransFormEnter =
          this.enterTransformToValue - this.enterTransformFromValue),
        this.updateStyles({
          [`--enter-translate${t}-from-value`]:
            this.enterTransformFromValue + 'px',
        });
    }
    (this.enterOpacityFromValue = a.getPropertyValue(
      '--cs-fade-in-from-value'
    )),
      (this.enterOpacityToValue = a.getPropertyValue('--cs-fade-in-to-value')),
      (this.diffOpacityEnter =
        this.enterOpacityToValue - this.enterOpacityFromValue);
  }
  _executePauseBehavior(e) {
    if (e && this.enablePauseAnimation) {
      const e = getComputedStyle(this),
        t = e.getPropertyValue(n[this.tagName].enter),
        a = e.getPropertyValue(n[this.tagName].exit);
      this._setAnimationValue(t, a, e);
    }
  }
}

customElements.define('cs-pause-animation', CsPauseAnimation);
