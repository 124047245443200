import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsDeviceMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsDeviceMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class t extends base {
        static get properties() {
            return {
                isTouch: {
                    type: Boolean,
                    reflectToAttribute: !0,
                    value: t._isTouch()
                },
                isIos: {
                    type: Boolean,
                    reflectToAttribute: !0,
                    value: /iP(hone|od|ad)/.test(navigator.userAgent)
                }
            };
        }
        static _isTouch() {
            return !t._isWindows10PC() && ("ontouchstart" in window || 0 < window.navigator.maxTouchPoints || 0 < window.navigator.msMaxTouchPoints);
        }
        static _isWindows10PC() {
            const e = window.navigator.userAgent;
            return /(Windows NT 6\.4|Windows NT 10\.\d)/.test(e) && !/Touch/.test(e);
        }
    };

export const CsDeviceMixin = dedupingMixin(internalCsDeviceMixin);