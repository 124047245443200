/* jshint ignore:start */
import { GestureEventListeners } from '@polymer/polymer/lib/mixins/gesture-event-listeners.js';
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js';
import * as Gestures from '@polymer/polymer/lib/utils/gestures.js';
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsMixin } from './mixins/cs-mixin.js';

var e = window.nds;
const t = CsMixin,
  a = e.getSlottedChildren;

/**
 * `cs-dropdown` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsDropDown extends t(GestureEventListeners(PolymerElement)) {
  static get properties() {
    return {
      open: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      theme: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      labelText: {
        type: String,
        value: '',
      },
      disabled: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      _text: String,
      value: {
        type: String,
        notify: !0,
        observer: '_valueChanged',
      },
      icon: {
        type: String,
        notify: !0,
      },
      options: {
        type: Array,
        value() {
          return [];
        },
      },
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-form-select"></style>
      <style>
        :host ::slotted([slot='dropdown-option']) {
          display: none;
        }
      </style>
      <slot name="dropdown-option"></slot>
      <span class="cspl-dropdown__selected" on-click="_handleDropdownClick">
        <span hidden$="[[ !labelText ]]" class="cspl-dropdown__selected-label"
          >[[ labelText ]]</span
        >
        <span class="cspl-dropdown__selected-container">
          <template is="dom-if" if="{{ icon }}">
            <iron-icon
              class="cspl-dropdown__item-icon"
              icon="[[ icon ]]"
            ></iron-icon>
          </template>
          <span class="cspl-dropdown__selected-text">[[ _text ]]</span>
          <div class="cspl-dropdown__chevron-container">
            <div class="cspl-dropdown__chevron"></div>
          </div>
        </span>
      </span>
      <div class="cspl-dropdown__items-container">
        <ul
          class="cspl-dropdown__items"
          id="dropdownItems"
          on-transitionend="_handleTransitionend"
        >
          <template is="dom-repeat" items="{{ options }}" as="option">
            <li class$="{{ _getDropdownItemClass(option, value) }}">
              <span
                class="cspl-dropdown__link"
                data-value$="[[ option.value ]]"
                data-text$="[[ option.text ]]"
                data-icon$="[[ option.icon ]]"
                on-click="_handleSelectedClick"
              >
                <template is="dom-if" if="{{ option.icon }}">
                  <iron-icon
                    class="cspl-dropdown__item-icon"
                    icon="[[ option.icon ]]"
                  ></iron-icon>
                </template>
                <span class="cspl-dropdown__link-text">[[ option.text ]]</span>
                <iron-icon
                  icon="cs-state-16:checked"
                  class="cspl-dropdown__checkmark"
                ></iron-icon>
              </span>
            </li>
          </template>
        </ul>
      </div> `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super(),
      (this._findOption = this._findOption.bind(this)),
      (this._tapHandler = this._tapHandler.bind(this)),
      (this._toggleAttribute = this._toggleAttribute.bind(this)); // jshint ignore:line
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _toggleAttribute(e) {
    let t;
    1 === arguments.length && (t = !this.hasAttribute(e)),
      t ? this.setAttribute(e, '') : this.removeAttribute(e); // jshint ignore:line
  }
  _handleDropdownClick() {
    this._toggleAttribute('open');
  }
  _handleTransitionend(e) {
    if ('max-height' === e.propertyName && this.open) {
      const t = this._findOptionIndex(this.value);
      -1 < t &&
        (this._scrollIntoViewIfNeeded(e.target),
        this._scrollIntoContainerIfNeeded(this._optionElements[t])); // jshint ignore:line
    }
  }
  _scrollIntoViewIfNeeded(e) {
    const t = e.getBoundingClientRect();
    0 > t.top
      ? e.scrollIntoView(!0)
      : t.bottom > document.documentElement.clientHeight &&
        e.scrollIntoView(!1); // jshint ignore:line
  }
  _scrollIntoContainerIfNeeded(e) {
    const t = e.parentNode;
    const a = t.getBoundingClientRect();
    const r = e.getBoundingClientRect();
    r.top < a.top
      ? (t.scrollTop = e.offsetTop)
      : r.bottom > a.bottom &&
        (t.scrollTop = e.offsetTop + r.height - a.height); // jshint ignore:line
  }
  _valueChanged(e) {
    const t = this._findOption(e);
    'undefined' != typeof t && (this._text = t.text),
      this.options.forEach(t => {
        e === t.value && ((this._text = t.text), (this.icon = t.icon)); // jshint ignore:line
      }),
      this.dispatchEvent(
        new CustomEvent('change', {
          bubbles: !0,
          composed: !0,
          detail: {
            value: e,
          },
        })
      ); // jshint ignore:line
  }
  _findOption(e) {
    return this.options.find(t => t.value === e);
  }
  _findOptionIndex(e) {
    for (let t = 0; t < this.options.length; t++)
      if (this.options[t].value === e) return t;
    return -1;
  }
  _handleSelectedClick(e) {
    const t = e.currentTarget.dataset;
    'undefined' != typeof t &&
      ((this.value = t.value),
      'undefined' != typeof t.icon && (this.icon = t.icon)),
      this._toggleAttribute('open'); // jshint ignore:line
  }
  _getDropdownItemClass(e, t) {
    let a = 'cspl-dropdown__item';
    return (
      e.value.toLowerCase() === t.toLowerCase() &&
        (a = `${a} cspl-dropdown__item-current`),
      a
    );
  }
  _getOptions() {
    const e = a(this, ['dropdown-option']);
    return e.map(e => ({
      value: e.getAttribute('value'),
      text: e.textContent,
      icon: e.getAttribute('icon'),
      disabled: e.hasAttribute('disabled'),
    }));
  }
  _setOptions() {
    this.options = this._getOptions();
  }
  _initializeDropdown() {
    this._setOptions();
    const e = this.options;
    if (e.length) {
      const t = this._findOption(this.value);
      this._text = t ? t.text : e[0].text;
    }
  }
  _tapHandler(e) {
    const t = e.composedPath().includes(this);
    this.open && !t && (this.open = !1); // jshint ignore:line
  }
  connectedCallback() {
    super.connectedCallback(),
      Gestures.addListener(document, 'tap', this._tapHandler),
      setTimeout(this._initializeDropdown.bind(this)),
      new FlattenedNodesObserver(this.$.dropdownItems, e => {
        this._optionElements = e.target.querySelectorAll('li');
      }); // jshint ignore:line
  }
  disconnectedCallback() {
    super.disconnectedCallback(),
      Gestures.removeListener(this, 'tap', this._tapHandler); // jshint ignore:line
  }
}

customElements.define('cs-dropdown', CsDropDown);
