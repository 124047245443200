/* jshint ignore:start */
import { GestureEventListeners } from '@polymer/polymer/lib/mixins/gesture-event-listeners.js';
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js';
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsFormElementMixin } from './mixins/cs-form-element-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsValueValidatorMixin } from './mixins/cs-value-validator-mixin.js';

const t = CsMixin,
  a = CsDeviceMixin,
  r = CsFormElementMixin,
  n = CsValueValidatorMixin;

/**
 * `cs-select` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSelect extends t(a(r(n(GestureEventListeners(PolymerElement))))) {
  static get properties() {
    return {
      open: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      theme: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      labelText: String,
      backgroundColor: {
        type: String,
        reflectToAttribute: !0,
      },
      value: {
        type: String,
        notify: !0,
        observer: '_valueChanged',
      },
      options: {
        type: Array,
        value() {
          return [];
        },
      },
      _highlightIndex: {
        type: Number,
        value: 0,
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-form-select"></style>
      <style>
        .cspl-dropdown__touch {
          padding: 0;
        }

        :host(:not([is-touch])) .cspl-dropdown__touch,
        :host([is-touch]) .cspl-dropdown__touch ~ * {
          display: none;
        }

        :host(:not([is-touch])) .cspl-dropdown__selected::before {
          z-index: 1;
        }

        :host(:not([open])) .cspl-dropdown__touch::before {
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
        }

        .cspl-dropdown__touch-select {
          appearance: none;
          background: 0 0;
          border: 0;
          color: var(--cs-dark-color);
          font-family: inherit;
          font-size: var(--cs-theme-dropdown-font-size-mobile);
          height: 100%;
          line-height: var(--cs-theme-dropdown-line-height-mobile);
          margin: 0;
          outline: 0;
          padding: 0 36px 0 24px;
          position: relative;
          width: 100%;
        }

        .cspl-dropdown__touch .cspl-dropdown__chevron {
          left: auto;
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
        }

        :host([disabled]) .cspl-dropdown__touch-select {
          opacity: 0.4;
        }

        :host([theme='light']) .cspl-dropdown__touch-select {
          color: var(--cs-light-color);
        }

        @media only screen and (min-width: 1024px) {
          .cspl-dropdown__touch-select {
            font-size: var(--cs-theme-dropdown-font-size-tablet-landscape);
            line-height: var(--cs-theme-dropdown-line-height-tablet-landscape);
          }
        }

        .cspl-dropdown__selected-text {
          width: calc(100% - 21px);
        }
      </style>
      <span class="cspl-dropdown__selected cspl-dropdown__touch">
        <select
          class="cspl-dropdown__touch-select"
          disabled$="{{ disabled }}"
          value="{{ value::change }}"
          on-blur="_toggleDropdown"
        >
          <template is="dom-repeat" items="{{ options }}" as="option">
            <option
              value="[[ option.value ]]"
              selected$="{{ _computeTouchSelected(option.value, value) }}"
              >{{ option.text }}</option
            >
          </template>
        </select>
        <div class="cspl-dropdown__chevron-container">
          <div class="cspl-dropdown__chevron"></div>
        </div>
      </span>
      <span
        class="cspl-dropdown__selected"
        on-click="_toggleDropdown"
        on-focus="_toggleDropdown"
        tabindex$="[[ _getTabindex(disabled) ]]"
      >
        <span hidden$="[[ !labelText ]]" class="cspl-dropdown__selected-label"
          >[[ labelText ]]</span
        >
        <span class="cspl-dropdown__selected-text">[[ text ]]</span>
        <div class="cspl-dropdown__chevron-container">
          <div class="cspl-dropdown__chevron"></div>
        </div>
      </span>
      <div class="cspl-dropdown__items-container" tabindex="-1">
        <ul
          class="cspl-dropdown__items"
          id="dropdownItems"
          on-transitionend="_handleTransitionend"
        >
          <template is="dom-repeat" items="{{ options }}" as="option">
            <li
              class$="[[ _getDropdownItemClass(option, value, index, _highlightIndex) ]]"
            >
              <span
                class="cspl-dropdown__link"
                data-value$="[[ option.value ]]"
                on-tap="_handleSelectedClick"
                ><span class="cspl-dropdown__link-text">[[ option.text ]]</span>
                <iron-icon
                  icon="cs-state-16:checked"
                  class="cspl-dropdown__checkmark"
                ></iron-icon>
              </span>
            </li>
          </template>
        </ul>
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super(), (this._onClickHandler = this._onClickHandler.bind(this)); // jshint ignore:line
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _findOption(e) {
    return this.options.find(t => t.value === e);
  }
  _findOptionIndex(e) {
    for (let t = 0; t < this.options.length; t++)
      if (this.options[t].value === e) return t;
    return -1;
  }
  _getAncestorByName(e, t) {
    for (let a = e; a.parentElement; ) {
      if (a.nodeName === t) return a;
      a = a.parentElement;
    }
    return null;
  }
  _getDropdownItemClass(e, t, a, r) {
    let n = 'cspl-dropdown__item';
    return (
      e.value === t
        ? (n = `${n} cspl-dropdown__item-current`)
        : a === r && (n = `${n} cspl-dropdown__item--highlight`),
      n
    );
  }
  _getOptions() {
    const e = [];
    const t = [...this.querySelectorAll('cs-select-option')];
    return (
      t.forEach(function (t) {
        const a = {
          value: t.getAttribute('value'),
          text: t.textContent,
          disabled: t.hasAttribute('disabled'),
        };
        e.push(a);
      }),
      e
    );
  }
  _getTabindex(e) {
    return e ? -1 : 0;
  }
  _handleKeydown(e) {
    switch (e.keyCode) {
      case 9:
      case 27:
        this.open = !1;
        break;
      case 38:
        e.preventDefault(), this._handleUpDownKey(-1); // jshint ignore:line
        break;
      case 40:
        e.preventDefault(), this._handleUpDownKey(1); // jshint ignore:line
        break;
      default:
    }
  }
  _handleKeypress(e) {
    switch (e.keyCode) {
      case 13:
        -1 < this._highlightIndex &&
          (this.value = this.options[this._highlightIndex].value),
          this.shadowRoot.querySelector(':focus').blur(),
          this._toggleDropdown(); // jshint ignore:line
        break;
      default:
        this._handleKeySearch(e);
    }
  }
  _handleKeySearch(e) {
    const t = e.key || String.fromCharCode(e.keyCode);
    /^\w$/.test(t) && this._highlightOption(new RegExp(`^${t}`, 'i')); // jshint ignore:line
  }
  _handleUpDownKey(e) {
    if (this.open) {
      const t = this._highlightIndex + e;
      -1 < t && t < this.options.length && this._highlightOptionElement(t); // jshint ignore:line
    } else this._toggleDropdown();
  }
  _handleSelectedClick(e) {
    const t = e.currentTarget.dataset;
    'undefined' != typeof t && (this.value = t.value), this._toggleDropdown(); // jshint ignore:line
  }
  _handleTransitionend(e) {
    if ('max-height' === e.propertyName && this.open) {
      const t = this._findOptionIndex(this.value); // jshint ignore:line
      -1 < t &&
        (this._scrollIntoViewIfNeeded(e.target),
        this._highlightOptionElement(t)); // jshint ignore:line
    }
  }
  _highlightOption(e) {
    const t = this.options;
    for (let a = this._highlightIndex + 1; a < t.length; a++)
      if (e.test(t[a].text)) return void this._highlightOptionElement(a);
    for (let a = 0; a < this._highlightIndex; a++)
      if (e.test(t[a].text)) return void this._highlightOptionElement(a);
  }
  _highlightOptionElement(e) {
    const t = e <= this._highlightIndex;
    const a = this._optionElements[e];
    (this._highlightIndex = e), this._scrollIntoContainerIfNeeded(a, t); // jshint ignore:line
  }
  _toggleDropdown() {
    this.open = !this.open;
  }
  _onClickHandler(e) {
    const t = e.composedPath().includes(this);
    this.open && !t && (this.open = !1); // jshint ignore:line
  }
  _preventSvgFocusable(e) {
    const t = e.querySelector('svg');
    t && t.setAttribute('focusable', 'false'); // jshint ignore:line
  }
  _scrollIntoContainerIfNeeded(e) {
    const t = e.parentNode;
    const a = t.getBoundingClientRect();
    const r = e.getBoundingClientRect();
    r.top < a.top
      ? (t.scrollTop = e.offsetTop)
      : r.bottom > a.bottom &&
        (t.scrollTop = e.offsetTop + r.height - a.height); // jshint ignore:line
  }
  _scrollIntoViewIfNeeded(e) {
    const t = e.getBoundingClientRect();
    0 > t.top
      ? e.scrollIntoView(!0)
      : t.bottom > document.documentElement.clientHeight &&
        e.scrollIntoView(!1); // jshint ignore:line
  }
  _valueChanged(e) {
    const t = this._findOption(e);
    'undefined' != typeof t && (this.text = t.text),
      this.dispatchEvent(
        new CustomEvent('change', {
          bubbles: !0,
          composed: !0,
          detail: {
            value: e,
          },
        })
      ); // jshint ignore:line
  }
  _computeTouchSelected(e, t) {
    return e === t;
  }
  _setupDropdownOptions() {
    if (
      (new FlattenedNodesObserver(this.$.dropdownItems, e => {
        (this._optionElements = e.target.querySelectorAll('li')),
          e.addedNodes.forEach(e => {
            if ('querySelector' in e) {
              const t = e.querySelector('.cspl-dropdown__checkmark');
              t && this._preventSvgFocusable(t); // jshint ignore:line
            }
          }),
          this._valueChanged(this.value); // jshint ignore:line
      }),
      !this.options.length)
    ) {
      const e = this._getOptions();
      (this.options = e),
        this.dispatchEvent(
          new CustomEvent('nds.select.options.loaded', {
            composed: !0,
            bubbles: !0,
            detail: e,
          })
        ); // jshint ignore:line
    }
  }
  connectedCallback() {
    super.connectedCallback(),
      requestAnimationFrame(this._setupDropdownOptions.bind(this)),
      this.addEventListener('focusin', this._toggleDropdown),
      this.addEventListener('keydown', this._handleKeydown),
      this.addEventListener('keypress', this._handleKeypress),
      document.addEventListener('click', this._onClickHandler); // jshint ignore:line
  }
  disconnectedCallback() {
    super.disconnectedCallback(),
      document.removeEventListener('click', this._onClickHandler); // jshint ignore:line
  }
}

customElements.define('cs-select', CsSelect);
