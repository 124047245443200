/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsMixin } from './mixins/cs-mixin.js';

const o = CsMixin;
/**
 * `cs-tabs` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsTabs extends o(PolymerElement) {
  static get properties() {
    return {
      autoselect: {
        type: Boolean,
        value: !1,
      },
      indicate: {
        type: Boolean,
        value: !1,
      },
      leftShadow: {
        readOnly: !0,
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
      },
      disabledLeftShadow: {
        readOnly: !0,
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
      },
      _step: {
        type: Number,
        value: 6,
      },
      trackPositionX: {
        type: Number,
        value: 0,
      },
      _holdDelay: {
        type: Number,
        value: 1,
      },
      _holdJob: {
        type: Number,
      },
      _offsetTop: {
        type: Number,
      },
      isReady: {
        notify: !0,
        readOnly: !0,
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
      },
      rightShadow: {
        readOnly: !0,
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
      },
      disabledRightShadow: {
        readOnly: !0,
        reflectToAttribute: !0,
        type: Boolean,
        value: !1,
      },
      scrollable: {
        type: Boolean,
        value: !1,
      },
      selectable: {
        readOnly: !0,
        type: String,
        value: 'cs-tab',
      },
      autoScroll: {
        type: Boolean,
        value: !1,
      },
      autoScrollTopOffset: {
        type: Number,
        value: 0,
      },
      items: {
        type: Array,
        value: [],
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style>
      <style>
        :host {
          background-color: #585858;
          border-radius: 3px;
          color: rgba(255, 255, 255, 0.5);
          display: flex;
          position: relative;
          user-select: none;
          width: 100%;
        }
        :host::before {
          border-bottom: solid 2px;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          width: 100%;
          z-index: 11;
        }
        :host::before,
        #tabs ::slotted(cs-tab) {
          border-color: rgba(255, 255, 255, 0.1);
        } /* dark variant */
        :host([theme='dark']) {
          background-color: #f1f1f1;
          color: var(--cs-dark-color);
        } /* dark variant */
        :host([theme='dark'])::before,
        :host([theme='dark']) #tabs ::slotted(cs-tab) {
          border-color: rgba(0, 0, 0, 0.08);
        } /* custom variant */
        :host([theme='custom']) {
          background-color: var(--cs-theme-tabs-background-color);
          color: var(--cs-theme-tabs-unselected-type);
        }
        :host([theme='custom'])::before,
        :host([theme='custom']) #tabs ::slotted(cs-tab) {
          border-color: var(--cs-theme-tabs-accent-elements);
        }
        #stage {
          overflow: hidden;
          width: 100%;
        }
        @media only screen and (min-width: 768px) {
          #stage {
            padding-left: 60px;
            padding-right: 60px;
          }
        }
        :host([scrollable]) .cspl-tabs__wrapper {
          box-sizing: content-box;
          height: 50px;
          overflow: hidden;
          width: 100%;
        }
        @media (min-width: 768px) {
          :host([scrollable]) .cspl-tabs__wrapper {
            display: flex;
            height: 60px;
          }
        }
        .cspl-tabs__wrapper {
          display: flex;
          margin: auto;
          position: relative;
        }
        :host([scrollable]) .cspl-tabs__wrapper {
          max-width: calc(
            (var(--cs-column-mobile-with-rail) * 6) +
              (var(--cs-gutter-mobile) * 5)
          );
        }
        @media only screen and (min-width: 768px) {
          :host([scrollable]) .cspl-tabs__wrapper {
            max-width: calc(
              (var(--cs-column-tablet-portrait-with-rail) * 8) +
                (var(--cs-gutter-tablet-portrait) * 7) + 120px
            );
          }
        }
        @media only screen and (min-width: 1456px) {
          :host([scrollable]) .cspl-tabs__wrapper {
            max-width: calc(
              (var(--cs-column-desktop-with-rail) * 12) +
                (var(--cs-gutter-desktop) * 11) + 120px
            );
          }
        } /** * Selected tab */
        #tabs ::slotted(cs-tab.iron-selected) {
          background: rgba(255, 255, 255, 0.08);
          color: rgba(255, 255, 255, 1);
        } /* dark variant */
        :host([theme='dark']) #tabs ::slotted(cs-tab.iron-selected) {
          background: rgba(0, 0, 0, 0.08);
          color: rgba(22, 22, 22, 0.8);
        } /* custom variant */
        :host([theme='custom']) #tabs ::slotted(cs-tab.iron-selected) {
          background: var(--cs-theme-tabs-selected-state);
          color: var(--cs-theme-tabs-selected-type);
        }
        :host([indicate]) {
          border-radius: 0;
        } /* cs-tab hover state */
        #tabs ::slotted(cs-tab:hover) {
          background: rgba(255, 255, 255, 0.08);
          color: rgba(255, 255, 255, 1);
        } /* dark variant */
        :host([theme='dark']) #tabs ::slotted(cs-tab:hover) {
          background: rgba(0, 0, 0, 0.08);
          color: rgba(22, 22, 22, 0.8);
        } /* custom variant */
        :host([theme='custom']) #tabs ::slotted(cs-tab:hover) {
          background: var(--cs-theme-tabs-selected-state);
          color: var(--cs-theme-tabs-selected-type);
        } /* disabled tab */
        #tabs ::slotted(cs-tab[disabled]),
        #tabs ::slotted(cs-tab[disabled]:hover),
        :host([theme='custom']) #tabs ::slotted(cs-tab[disabled]:hover) {
          background: inherit;
          color: rgba(var(--cs-dark-color-rgb), 0.4);
        }
        :host([theme='dark']) #tabs ::slotted(cs-tab[disabled]),
        :host([theme='dark']) #tabs ::slotted(cs-tab[disabled]:hover) {
          background: inherit;
          color: #c2c2c2;
        }
        :host([indicate][scrollable]) #indicator {
          display: block;
        }
        :host([indicate]) #indicator[transitionable] {
          transition: all 0.3s ease;
        }
        #container {
          margin: 0 auto;
          position: relative;
        }
        :host([scrollable]) #container {
          width: 100%;
        }
        #tabs {
          display: flex;
          flex-direction: column;
          padding-right: 120px;
          position: relative;
        }
        :host([rtl]) #tabs {
          padding-left: 120px;
          padding-right: 0;
        }
        @media (min-width: 768px) {
          #tabs {
            flex-direction: row;
          }
        }
        :host([scrollable]) #tabs {
          display: flex;
          flex-direction: row;
          left: 0;
          position: absolute;
          top: 0;
        }
        :host([rtl][scrollable]) #tabs {
          left: auto;
          right: 0;
        }
        #shadow {
          display: none;
        }
        :host([scrollable]) #shadow {
          display: block;
          height: 100%;
          left: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100%;
        }
        :host([rtl][scrollable]) #shadow {
          left: auto;
          right: 0;
        }
        .cs-tabs__arrow {
          display: none;
        }
        :host([scrollable]) .cs-tabs__arrow {
          background: transparent;
          border: 0;
          color: currentColor;
          cursor: pointer;
          height: 100%;
          outline: none;
          padding: 0;
          pointer-events: all;
          position: absolute;
          z-index: 10;
        }
        :host([scrollable]) .cs-tabs__arrow iron-icon {
          display: inline-block;
          opacity: 1;
        } /* Left Arrow */
        :host([scrollable]) .cs-tabs__arrow--left {
          background-image: linear-gradient(
            to left,
            rgba(88, 88, 88, 0),
            rgba(88, 88, 88, 1) 50%
          );
          color: rgba(255, 255, 255, 0.1);
          left: -1px;
          width: 60px;
        } /* dark variant */
        :host([scrollable][theme='dark']) .cs-tabs__arrow--left {
          background-image: linear-gradient(
            to left,
            rgba(88, 88, 88, 0),
            #f1f1f1 50%
          );
          color: rgba(22, 22, 22, 0.1);
        } /* custom variant */
        :host([scrollable][theme='custom']) .cs-tabs__arrow--left {
          background-image: linear-gradient(
            to left,
            rgba(88, 88, 88, 0),
            var(--cs-theme-tabs-background-color) 50%
          );
          color: var(--cs-theme-tabs-inactive-interface-color);
        }
        @media (min-width: 768px) {
          :host([scrollable][left-shadow]) .cs-tabs__arrow--left {
            display: block;
          }
          :host([scrollable][left-shadow][disabled-left-shadow])
            .cs-tabs__arrow--left {
            color: rgba(255, 255, 255, 0.7);
          } /* dark variant */
          :host([theme='dark'][scrollable][left-shadow][disabled-left-shadow])
            .cs-tabs__arrow--left {
            color: rgba(22, 22, 22, 0.5);
          } /* custom variant */
          :host([theme='custom'][scrollable][left-shadow][disabled-left-shadow])
            .cs-tabs__arrow--left {
            color: var(--cs-theme-tabs-interface-color);
          }
        }
        :host([scrollable]) .cs-tabs__arrow--left iron-icon {
          transform: translateX(-10px) rotate(90deg);
        } /* Right Arrow */
        :host([scrollable]) .cs-tabs__arrow--right {
          background-image: linear-gradient(
            to right,
            rgba(88, 88, 88, 0),
            rgba(88, 88, 88, 1) 50%
          );
          color: rgba(255, 255, 255, 0.1);
          right: -1px;
          width: 60px;
        } /* dark variant */
        :host([scrollable][theme='dark']) .cs-tabs__arrow--right {
          background-image: linear-gradient(
            to right,
            rgba(88, 88, 88, 0),
            #f1f1f1 50%
          );
          color: rgba(22, 22, 22, 0.1);
        } /* custom variant */
        :host([scrollable][theme='custom']) .cs-tabs__arrow--right {
          background-image: linear-gradient(
            to right,
            rgba(88, 88, 88, 0),
            var(--cs-theme-tabs-background-color) 50%
          );
          color: var(--cs-theme-tabs-inactive-interface-color);
        }
        @media (min-width: 768px) {
          :host([scrollable][right-shadow]) .cs-tabs__arrow--right {
            display: block;
          }
          :host([scrollable][right-shadow][disabled-right-shadow])
            .cs-tabs__arrow--right {
            color: rgba(255, 255, 255, 0.7);
          } /* dark variant */
          :host([theme='dark'][scrollable][right-shadow][disabled-right-shadow])
            .cs-tabs__arrow--right {
            color: rgba(22, 22, 22, 0.5);
          } /* custom variant */
          :host([theme='custom'][scrollable][right-shadow][disabled-right-shadow])
            .cs-tabs__arrow--right {
            color: var(--cs-theme-tabs-interface-color);
          }
        }
        :host([scrollable]) .cs-tabs__arrow--right iron-icon {
          transform: translateX(10px) rotate(-90deg);
        }
      </style>
      <div class="cspl-tabs__wrapper">
        <div id="stage">
          <div id="container">
            <div id="tabs"><slot id="content"></slot></div>
          </div>
        </div>
        <div id="shadow">
          <button
            class="cs-tabs__arrow cs-tabs__arrow--left"
            on-up="_onScrollButtonUp"
            on-down="_onLeftScrollButtonDown"
          >
            <iron-icon icon="cs-action-16:chevron"></iron-icon></button
          ><button
            class="cs-tabs__arrow cs-tabs__arrow--right"
            on-up="_onScrollButtonUp"
            on-down="_onRightScrollButtonDown"
          >
            <iron-icon icon="cs-action-16:chevron"></iron-icon>
          </button>
        </div>
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super(), (this._setSelectedByHash = this._setSelectedByHash.bind(this));
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  _onLeftScrollButtonDown() {
    clearInterval(this._holdJob),
      this._scrollToLeft(),
      (this._holdJob = setInterval(
        this._scrollToLeft.bind(this),
        this._holdDelay
      ));
  }
  _onRightScrollButtonDown() {
    clearInterval(this._holdJob),
      this._scrollToRight(),
      (this._holdJob = setInterval(
        this._scrollToRight.bind(this),
        this._holdDelay
      ));
  }
  _scrollToRight() {
    this._setScrollLeft(this._step);
  }
  _scrollToLeft() {
    this._setScrollLeft(-this._step);
  }
  _onScrollButtonUp() {
    clearInterval(this._holdJob), (this._holdJob = null);
  }
  _onIronSelect() {
    return this.autoScroll && this._scrollIntoView(), this;
  }
  _isSmoothScrollSupported() {
    return 'scrollBehavior' in document.documentElement.style;
  }
  _updateOffsetTop() {
    this._offsetTop =
      this.getBoundingClientRect().top +
      window.pageYOffset -
      document.documentElement.clientTop -
      this.autoScrollTopOffset;
  }
  _scrollIntoView() {
    this.IsReady &&
      (this._isSmoothScrollSupported()
        ? window.scroll({
            top: this._offsetTop,
            behavior: 'smooth',
          })
        : window.scroll(0, this._offsetTop));
  }
  _onIronResize() {
    this._updateOffsetTop();
  }
  _onTrack(e) {
    var { detail: t = {} } = e;
    return this.scrollable && this._setScrollLeft(-1 * t.ddx || 0), this;
  }
  _scrollToSelected(e, t) {
    var L = t - this.scrollLeft;
    if (L < 0) return this._setScrollLeft(L), this;
    var a = L + e - this.offsetWidth;
    return a > 0 && this._setScrollLeft(a), this;
  }
  _setScrollLeft(e) {
    var t = this.rtl ? 1 : -1,
      L = this.$.tabs.offsetWidth,
      a = this.$.stage.offsetWidth,
      i = (L - a) * t,
      l = this.trackPositionX - e,
      o = l < 0 || (this.rtl && l < i),
      s = l > i || (this.rtl && l > 0);
    this._setDisabledRightShadow(s),
      this._setDisabledLeftShadow(o),
      this._updateTrackPosition(o, s, l, e),
      this._enableScrolls(L > a);
  }
  _updateTrackPosition(e, t, L, a) {
    ((e && a < 0) || (t && a > 0)) &&
      ((this.trackPositionX = L),
      this.transform(
        'translateX('.concat(this.trackPositionX, 'px)'),
        this.$.tabs
      ));
  }
  _enableScrolls(e) {
    e &&
      !this.rightShadow &&
      (this._setRightShadow(!0), this._setLeftShadow(!0));
  }
  _setSelectedByHash() {
    var e = window.decodeURIComponent(window.location),
      t = window.decodeURIComponent(window.location.hash.slice(1)),
      L = this.items
        .filter(e => !e.hasAttribute('disabled'))
        .filter(e => !e.classList.contains('iron-selected'));
    return (
      '' === t
        ? (L = L.filter(t => '' === e.split(t.getAttribute('url'))[1]).pop()) &&
          (this.selected = this.indexOf(L))
        : ((L = L.filter(e => e.getAttribute('url') === '#'.concat(t)).pop()),
          t && L && (this.selected = this.indexOf(L))),
      this
    );
  }
  _autoSelectItem() {
    this.items.length &&
      ((this.selected = this.items.findIndex(e => !e.hasAttribute('disabled'))),
      this._updateSelected());
  }
  connectedCallback() {
    super.connectedCallback(),
      this.setAttribute('role', 'tablist'),
      window.addEventListener('hashchange', this._setSelectedByHash),
      this.addEventListener('iron-select', this._onIronSelect),
      this.addEventListener('iron-resize', this._onIronResize),
      setTimeout(() => {
        this._setSelectedByHash(),
          this.autoselect && !this.selectedItem && this._autoSelectItem(),
          // this.scrollable &&
            // Object(o.b)(this.$.tabs, 'track', this._onTrack.bind(this)),
          this._setScrollLeft(0),
          this._setIsReady(!0);
      });
  }
  disconnectedCallback() {
    super.disconnectedCallback(),
      window.removeEventListener('hashchange', this._setSelectedByHash),
      this._unbindMedia && this._unbindMedia(),
      this._setIsReady(!1);
  }
}

customElements.define('cs-tabs', CsTabs);
