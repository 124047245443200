/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';

const e = window.nds.getSlottedChildren;

/**
 * `cs-network-footer` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsNetworkFooter extends PolymerElement {
  static get properties() {
    return {
      theme: {
        type: String,
        reflectToAttribute: !0,
      },
      hideTruste: {
        type: Boolean,
        value: !1,
      },
      logoImage: String,
      logoTooltip: String,
      logoLinkUrl: String,
      logoLinkText: String,
      logoLinkTooltip: String,
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-typography-paragraphs"></style>
      <style>
        :host {
          background: var(--cs-light-color);
          display: block;
          padding: 8px 22px 30px;
        }

        @media only screen and (min-width: 768px) {
          :host {
            padding: 22px 60px 40px;
          }
        }

        @media only screen and (min-width: 1024px) {
          :host {
            padding: 3px 93px 40px;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host {
            padding: 8px 168px 16px;
          }
        }

        :host([theme='light']) {
          background: var(--cs-dark-color);
        }

        :host([theme='brand']) {
          background: var(--cs-theme-color);
        }

        .cspl-network-footer__section {
          margin: auto;
          max-width: 1120px;
        }

        @media only screen and (min-width: 768px) {
          .cspl-network-footer__section {
            align-items: center;
            display: flex;
            justify-content: space-between;
          }
        }

        @media only screen and (min-width: 768px) {
          .cspl-network-footer__section:first-child {
            align-items: center;
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-network-footer__section:first-child {
            margin-bottom: 8px;
          }
        }

        .cspl-network-footer__logo {
          margin-bottom: 9px;
          width: 47px;
        }

        @media only screen and (min-width: 768px) {
          .cspl-network-footer__logo {
            margin-bottom: 0;
            margin-top: 17px;
            width: 33px;
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-network-footer__logo {
            margin-top: 0;
            width: 49px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-network-footer__logo {
            margin-bottom: 8px;
            width: 64px;
          }
        }

        .cspl-network-footer__network-links {
          margin-bottom: 25px;
        }

        @media only screen and (min-width: 768px) {
          .cspl-network-footer__network-links {
            flex: 1 1 auto;
            margin-bottom: 0;
            margin-left: 25px;
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-network-footer__network-links {
            margin-left: 32px;
          }
        }

        .cspl-network-footer__network-links ::slotted([slot='link']) {
          display: inline-block;
          font-family: 'roboto-condensed';
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          margin: 5px 32px 10px 0;
          text-decoration: none;
          text-transform: uppercase;
        }

        @media only screen and (min-width: 768px) {
          .cspl-network-footer__network-links ::slotted([slot='link']) {
            margin-top: 10px;
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-network-footer__network-links ::slotted([slot='link']) {
            margin-right: 48px;
            margin-top: 16px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-network-footer__network-links ::slotted([slot='link']) {
            font-size: 20px;
            line-height: 24px;
            margin: 16px 52px 16px 0;
          }
        }

        .cspl-network-footer__network-links
          ::slotted([slot='link']:last-of-type) {
          margin-right: 0;
        }

        .cspl-network-footer__locale-selector {
          margin-bottom: 27px;
          min-width: 176px;
        }

        @media only screen and (min-width: 768px) {
          .cspl-network-footer__locale-selector {
            margin-bottom: 0;
            min-width: 168px;
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-network-footer__locale-selector {
            margin-top: 19px;
            min-width: 197px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-network-footer__locale-selector {
            margin-top: 0;
            min-width: 183px;
          }
        }

        .cspl-network-footer__legal {
          margin-top: 19px;
          max-width: 720px;
        }

        @media only screen and (min-width: 768px) {
          .cspl-network-footer__legal {
            margin-left: 60px;
            margin-top: 0;
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-network-footer__legal {
            margin-left: 0;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-network-footer__legal {
            margin-left: 0;
            margin-top: 9px;
          }
        }

        .cspl-network-footer__truste {
          margin-top: 33px;
          width: 130px;
        }

        @media only screen and (min-width: 768px) {
          .cspl-network-footer__truste {
            align-self: center;
            flex: 0 0 auto;
            margin-top: 0;
          }
        }
      </style>
      <div>
        <template is="dom-if" if="{{logoImage}}">
          <section class="cspl-network-footer__section">
            <a href="[[ logoLinkUrl ]]" title="[[ logoLinkTooltip ]]">
              <img
                hidden$="[[ !logoImage ]]"
                class="cspl-network-footer__logo"
                src$="[[ logoImage ]]"
                alt$="[[ logoTooltip ]]"
              />
            </a>
            <div class="cspl-network-footer__network-links">
              <slot name="link"></slot>
            </div>
            <div class="cspl-network-footer__locale-selector">
              <slot name="locale-selector"></slot>
            </div>
          </section>
        </template>
        <section class="cspl-network-footer__section">
          <div class="cspl-network-footer__legal">
            <slot name="network-legal"></slot>
          </div>
          <div class="cspl-network-footer__truste" hidden$="[[ hideTruste ]]">
            <slot name="truste" id="truste"></slot>
          </div>
        </section>
      </div>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _setPreferencesText() {
    const t = e(this, ['network-legal'])[0];
    const a = document.querySelector('#teconsent');
    const r = new MutationObserver(e => {
      e.forEach(e => {
        e.addedNodes &&
          e.addedNodes.length &&
          ((t.cookiePreferencesText = e.addedNodes[0].textContent),
          (t.cookiePreferencesLinkVisible = !0),
          r.disconnect()); // jshint ignore:line
      });
    });
    r.observe(a, {
      childList: !0,
    }),
      t.addEventListener('cookiePreferencesClick', function () {
        truste.showPreferences();
      }); // jshint ignore:line
  }
  _onTrusteSlotChange() {
    const t = e(this, ['truste'])[0];
    t.addEventListener('trusteLoaded', () => {
      this._setPreferencesText();
    });
  }
  connectedCallback() {
    super.connectedCallback(),
      this.$.truste.addEventListener(
        'slotchange',
        this._onTrusteSlotChange.bind(this)
      ); // jshint ignore:line
  }
}

customElements.define('cs-network-footer', CsNetworkFooter);
