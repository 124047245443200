/* jshint ignore:start */
import { GestureEventListeners } from '@polymer/polymer/lib/mixins/gesture-event-listeners.js';
import * as Gestures from '@polymer/polymer/lib/utils/gestures.js';
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsPauseAnimation } from './cs-pause-animation.js';
import { CsCategoryTagParentMixin } from './mixins/cs-category-tag-parent-mixin.js';
import { CsCopyParentMixin } from './mixins/cs-copy-parent-mixin.js';
import { CsCtaParentMixin } from './mixins/cs-cta-parent-mixin.js';
import { CsGetRelValueMixin } from './mixins/cs-get-rel-value-mixin.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsQuantitativeDataParentMixin } from './mixins/cs-quantitative-data-parent-mixin.js';

var e = window.nds;
e.getSlottedChildren = (e, t = ['']) => {
  const a = e.shadowRoot;
  return a
    ? t.reduce((e, t) => {
        const r = a.querySelector(t.length ? `slot[name="${t}"]` : 'slot');
        if (!r) return e;
        const n = r
          .assignedNodes({
            flatten: !0,
          })
          .filter(e => e.nodeType === Node.ELEMENT_NODE);
        return [...e, ...n];
      }, [])
    : [];
};
const r = CsMixin,
  s = CsQuantitativeDataParentMixin,
  d = CsCopyParentMixin,
  p = CsCtaParentMixin,
  c = CsCategoryTagParentMixin,
  h = CsGoogleAnalyticsMixin,
  // m = CsAnimationSystemMixin,
  y = CsGetRelValueMixin,
  _ = CsPauseAnimation,
  u = e.getSlottedChildren;

/**
 * `cs-tile` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsTile extends GestureEventListeners(r(s(d(p(c(h(y(CsElements)))))))) {
  static get properties() {
    return {
      type: {
        type: String,
        value: 'vertical',
        reflectToAttribute: !0,
      },
      style: {
        type: String,
        value: '',
        reflectToAttribute: !0,
      },
      card: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      showCardImage: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      shadowColor: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      textAlign: {
        type: String,
        value: 'left',
        reflectToAttribute: !0,
      },
      textColor: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      linkTarget: String,
      listViewForMobile: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      listViewForDesktop: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      color: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      media: {
        type: String,
        value: '',
      },
      mediaType: {
        type: String,
        value: 'image',
      },
      media1x1: {
        type: String,
        value: '',
      },
      mediaShape: {
        type: String,
        reflectToAttribute: !0,
      },
      tooltip: {
        type: String,
        value: ' ',
      },
      eyebrowIcon: {
        type: String,
        value: 'none',
      },
      eyebrowCustomIcon: String,
      eyebrowText: {
        type: String,
        value: '',
      },
      eyebrowSecondaryText: {
        type: String,
        value: '',
      },
      titleText: {
        type: String,
        value: '',
      },
      bylineText: {
        type: String,
        value: '',
      },
      hideMedia: {
        type: Boolean,
        value: !1,
      },
      hideEyebrow: {
        type: Boolean,
        value: !1,
      },
      hideTitle: {
        type: Boolean,
        value: !1,
      },
      hideByline: {
        type: Boolean,
        value: !1,
      },
      hideBrandStroke: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      linkUrl: String,
      linkTitle: String,
      linkModalId: String,
      context: String,
      scrimColor: {
        type: String,
        value: 'none',
        reflectToAttribute: !0,
      },
      scrimOpacity: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      verticalAlign: {
        type: String,
        reflectToAttribute: !0,
      },
      twoUpOnMobile: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      focused: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      useMouse: {
        type: Boolean,
        value: !1,
      },
      animationsOnMobile: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      _isListView: {
        type: Boolean,
        computed: '_computeListView(listViewForMobile, listViewForDesktop)',
      },
      animationThreshold: String,
      animationExitThreshold: String,
      _hideHtml5Video: {
        type: Boolean,
        value: !1,
      },
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-shared-styles-typography-labels"></style>
      <style include="cs-shared-styles-typography-headings"></style>
      <style include="cs-shared-styles-base-headings"></style>
      <style include="cs-cta-shared-styles"></style>
      <style include="cs-shared-styles-animations-keyframes"></style>
      <style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-animations-base"></style>
      <style include="cs-pause-animation-styles"></style>
      <style>
        /** * Tile */
        :host {
          --cs-theme-custom-tile-background-color: var(
            --cs-theme-tile-background-color
          );
          --cs-theme-custom-tile-color: var(--cs-theme-tile-color);
          --card-drop-shadow-color: rgba(0, 0, 0, 0.5);
          width: 100%;
        }

        :host([animatable]) {
          overflow: hidden;
        }

        .cspl-tile {
          display: flex;
          flex-direction: column;
          height: 100%;
          position: relative;
          width: 100%;
          /* fix issue where block wasn't 100% of parent */
        }

        :host([card]) .cspl-tile {
          animation: dropDefault 0.5s;
          box-shadow: 0 3px 10px -2px var(--card-drop-shadow-color);
        }

        :host([card][attention-seeker]) .cspl-tile {
          animation: dropAttentionSeeker 0.5s;
          box-shadow: 0 10px 10px -2px var(--card-drop-shadow-color);
        }

        :host([card]:hover) .cspl-tile {
          animation: liftCard 0.5s;
          box-shadow: 2px 30px 28px -2px var(--card-drop-shadow-color);
          transform: translate3d(0, -10px, 0);
        }

        @keyframes liftCard {
          0% {
            box-shadow: 0 10px 5px -2px var(--card-drop-shadow-color);
            transform: translate3d(0, 0, 0);
          }

          100% {
            box-shadow: 2px 30px 28px -2px var(--card-drop-shadow-color);
            transform: translate3d(0, -10px, 0);
          }
        }

        @keyframes dropAttentionSeeker {
          0% {
            box-shadow: 2px 30px 28px -2px var(--card-drop-shadow-color);
            transform: translate3d(0, -10px, 0);
          }

          100% {
            box-shadow: 0 10px 10px -2px var(--card-drop-shadow-color);
            transform: translate3d(0, 0, 0);
          }
        }

        @keyframes dropDefault {
          0% {
            box-shadow: 2px 30px 28px -2px var(--card-drop-shadow-color);
            transform: translate3d(0, -10px, 0);
          }

          100% {
            box-shadow: 0 3px 10px -2px var(--card-drop-shadow-color);
            transform: translate3d(0, 0, 0);
          }
        }

        /* dark variant */
        :host([text-color='dark']) .cspl-tile {
          color: var(--cs-dark-color);
        }

        /* light variant */
        :host([text-color='light']) .cspl-tile {
          color: var(--cs-light-color);
        }

        /* vertical grid variant */
        :host([type='vertical'][context='cs-grid']) .cspl-tile {
          display: flex;
          flex-direction: column;
        }

        @media only screen and (min-width: 768px) {
          :host([type='vertical'][context='cs-grid']) .cspl-tile {
            flex-direction: row;
          }
        }

        /* vertical dark variant */
        :host([type|='vertical'][color='dark']) .cspl-tile {
          background-color: var(--cs-dark-color);
        }

        /* vertical card dark variant */
        :host([type='vertical'][color='dark'][card]) .cspl-tile,
        :host([type='overlay'][color='dark'][card]) .cspl-tile {
          background-color: #262629;
        }

        /* vertical light variant */
        :host([type|='vertical'][color='light']) .cspl-tile {
          background-color: var(--cs-light-color);
        }

        /* vertical card light variant */
        :host([type='vertical'][color='light'][card]) .cspl-tile,
        :host([type='overlay'][color='light'][card]) .cspl-tile {
          background-color: #fff;
        }

        /* vertical brand variant */
        :host([type|='vertical'][color='brand']) .cspl-tile {
          background: linear-gradient(
            -83deg,
            var(--cs-theme-light-gradient),
            var(--cs-theme-dark-gradient)
          );
        }

        /* vertical card brand variant */
        :host([type='vertical'][color='brand'][card]) .cspl-tile,
        :host([type='overlay'][color='brand'][card]) .cspl-tile {
          background-color: var(--cs-theme-color);
        }

        :host([color='light'][theme='custom']) .cspl-tile,
        :host([color='dark'][theme='custom']) .cspl-tile,
        :host([color='brand'][theme='custom']) .cspl-tile {
          background-color: var(--cs-theme-custom-tile-background-color);
          color: var(--cs-theme-custom-tile-color);
        }

        /** * Link */
        .cspl-tile__link {
          color: currentColor;
          display: block;
          flex: 1;
          min-height: 110px;
          overflow: hidden;
          position: relative;
          text-decoration: none;
          width: 100%;
          /* fix IE11 flexbox bug */
        }

        .cspl-tile__link[has-link] {
          cursor: pointer;
        }

        /* vertical variant has cta fixed to bottom of tile */
        :host([type|='vertical']) .cspl-tile__link {
          display: flex;
          flex-direction: column;
          height: auto;
          width: 100%;
        }

        .cspl-tile__link[modal-id] {
          cursor: pointer;
        }

        /* horizontal variant */
        :host([type='horizontal']) .cspl-tile__link {
          display: flex;
          flex-direction: column;
        }

        @media only screen and (min-width: 768px) {
          /* horizontal variant */
          :host([type='horizontal']) .cspl-tile__link {
            flex-direction: row;
          }
        }

        /* focus */
        .cspl-tile__link:focus {
          outline: solid white 4px;
          outline-offset: 0;
        }

        .cspl-tile__link:focus::before {
          bottom: 0;
          box-shadow: inset 0 0 0 4px var(--cs-dark-color);
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }

        :host([color='dark']) .cspl-tile__link:focus {
          outline: solid var(--cs-dark-color) 4px;
        }

        :host([color='dark']) .cspl-tile__link:focus::before {
          box-shadow: inset 0 0 0 4px white;
        }

        /* overlay type focus */
        :host([type='overlay']) .cspl-tile__link:focus {
          outline: solid var(--cs-dark-color) 4px;
        }

        :host([type='overlay']) .cspl-tile__link:focus::before {
          box-shadow: inset 0 0 0 4px white;
        }

        /* horizontal type focus */
        :host([type='horizontal']) .cspl-tile__link {
          border: solid transparent 4px;
        }

        :host([type='horizontal']) .cspl-tile__link:focus {
          border: solid var(--cs-dark-color) 4px;
        }

        :host([type='horizontal'][color='dark']) .cspl-tile__link:focus {
          border: solid white 4px;
        }

        :host([type='horizontal']) .cspl-tile__link:focus::before {
          content: none;
        }

        /* active override the focus style */
        .cspl-tile__link:active {
          outline: none;
        }

        .cspl-tile__link:active::before {
          box-shadow: inset 0 0 0 4px transparent;
        }

        :host([color='dark']) .cspl-tile__link:active {
          outline: none;
        }

        :host([color='dark']) .cspl-tile__link:active::before {
          box-shadow: inset 0 0 0 4px transparent;
        }

        /* overlay type active */
        :host([type='overlay']) .cspl-tile__link:active {
          outline: none;
        }

        :host([type='overlay']) .cspl-tile__link:active::before {
          box-shadow: inset 0 0 0 4px transparent;
        }

        :host([type='vertical']) .cspl-tile__link:active::before {
          box-shadow: inset 0 0 0 4px transparent;
        }

        /* horizontal type active */
        :host([type='horizontal']) .cspl-tile__link:active {
          outline: none;
        }

        :host([type='horizontal'][color='dark']) .cspl-tile__link:active {
          outline: none;
        }

        :host([type='horizontal']) .cspl-tile__link:active::before {
          content: none;
        }

        /** * Media */
        .cspl-tile__media {
          flex-shrink: 0;
          line-height: 0;
          overflow: hidden;
          position: relative;
        }

        /* card variant */
        @media only screen and (max-width: 767px) {
          :host([card]:not([show-card-image])) .cspl-tile__media {
            display: none;
          }
        }

        @media only screen and (min-width: 768px) {
          /* Horizontal Layout */
          :host([type='horizontal']) .cspl-tile__media {
            margin-right: var(--cs-gutter-tablet-portrait);
            width: calc(
              (4 * var(--cs-column-tablet-portrait)) +
                (3 * var(--cs-gutter-tablet-portrait))
            );
          }

          :host([rtl][type='horizontal']) .cspl-tile__media {
            margin-left: var(--cs-gutter-tablet-portrait);
            margin-right: 0;
          }
        }

        @media only screen and (min-width: 1024px) {
          /* Horizontal Layout */
          :host([type='horizontal']) .cspl-tile__media {
            margin-right: var(--cs-gutter-tablet-landscape);
            width: calc(
              (4 * var(--cs-column-tablet-landscape)) +
                (3 * var(--cs-gutter-tablet-landscape))
            );
          }

          :host([rtl][type='horizontal']) .cspl-tile__media {
            margin-left: var(--cs-gutter-tablet-landscape);
            margin-right: 0;
          }
        }

        @media only screen and (min-width: 1456px) {
          /* Horizontal Layout */
          :host([type='horizontal']) .cspl-tile__media {
            margin-right: var(--cs-gutter-desktop);
            width: calc(
              (4 * var(--cs-column-desktop)) + (3 * var(--cs-gutter-desktop))
            );
          }

          :host([rtl][type='horizontal']) .cspl-tile__media {
            margin-left: var(--cs-gutter-desktop);
            margin-right: 0;
          }
        }

        /* circle variant */
        :host([media-shape='circle']) .cspl-tile__media {
          padding: 16px;
        }

        @media only screen and (min-width: 1024px) {
          :host([media-shape='circle']) .cspl-tile__media {
            padding: 20px;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([media-shape='circle']) .cspl-tile__media {
            padding: 24px;
          }
        }

        /** * Media Container */
        .cspl-tile__media-container {
          position: relative;
        }

        /* circle variant */
        :host([media-shape='circle']) .cspl-tile__media-container {
          border-radius: 100%;
          overflow: hidden;
        }

        /* rounded variant */
        :host([media-shape='rounded']) .cspl-tile__media-container {
          border-radius: var(--cs-gutter-mobile);
          overflow: hidden;
        }

        /* scrim variant */
        :host([scrim-color='dark']) .cspl-tile__media-container::after,
        :host([scrim-color='light']) .cspl-tile__media-container::after {
          content: '';
          display: block;
          height: 100%;
          left: 0;
          opacity: 0.3;
          /* default medium opacity */
          position: absolute;
          top: 0;
          width: 100%;
        }

        @media only screen and (min-width: 1024px) {
          :host([type='overlay'])
            .cspl-tile__link[href]
            .cspl-tile__media-container::after {
            transition: opacity 0.5s;
          }
        }

        /* scrim dark variant */
        :host([scrim-color='dark']) .cspl-tile__media-container::after {
          background-color: #000;
        }

        /* card dark variant */
        :host([card][scrim-color='dark']) .cspl-tile__media-container::after {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
        }

        /* scrim light variant */
        :host([scrim-color='light']) .cspl-tile__media-container::after {
          background-color: #fff;
        }

        /* card light variant */
        :host([card][scrim-color='light']) .cspl-tile__media-container::after {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
        }

        /* scrim low opacity variant */
        :host([scrim-opacity='low']) .cspl-tile__media-container::after {
          opacity: 0.15;
        }

        @media only screen and (min-width: 1024px) {
          :host([scrim-opacity='low'][type='overlay'])
            .cspl-tile__link[href]:hover
            .cspl-tile__media-container::after {
            opacity: 0.45;
          }
        }

        /* scrim high opacity variant */
        :host([scrim-opacity='high']) .cspl-tile__media-container::after {
          opacity: 0.45;
        }

        @media only screen and (min-width: 1024px) {
          :host([scrim-opacity='high'][type='overlay'])
            .cspl-tile__link[href]:hover
            .cspl-tile__media-container::after {
            opacity: 0.75;
          }
        }

        /** * Image */
        .cspl-tile__image {
          width: 100%;
        }

        .cspl-tile__image:not([src]) {
          max-height: max-content;
        }

        .cspl-tile__image[src=''] {
          display: none;
        }

        @media only screen and (min-width: 1024px) {
          :host([type='overlay']) .cspl-tile__link[href] .cspl-tile__image,
          :host([type='overlay'])
            .cspl-tile__link[href]
            ::slotted([slot='html5-video']) {
            transition: transform 0.5s;
          }

          :host([type='overlay'])
            .cspl-tile__link[href]:hover
            .cspl-tile__image,
          :host([type='overlay'])
            .cspl-tile__link[href]:hover
            ::slotted([slot='html5-video']) {
            transform: scale(1.04);
          }
        }

        /** * Content */
        .cspl-tile__content {
          padding: 24px var(--cs-gutter-mobile) 40px;
          width: 100%;
          /* fix IE11 flexbox bug */
        }

        .cspl-tile__content-wrapper {
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;
          -moz-user-select: none;
          -ms-user-select: none;
          -webkit-user-select: none;
          user-select: none;
        }

        :host([type|='vertical']) .cspl-tile__content-wrapper {
          padding: 24px var(--cs-gutter-mobile);
        }

        :host([type='horizontal']) .cspl-tile__content-wrapper {
          padding: 0 3px 3px;
        }

        :host([type='overlay']) .cspl-tile__content-wrapper {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-end;
          left: 0;
          padding: calc(var(--cs-gutter-mobile) / 2) var(--cs-gutter-mobile);
          position: absolute;
          text-shadow: var(--cs-theme-tile-text-shadow);
          top: 0;
          width: 100%;
        }

        :host([type='overlay'][vertical-align='vertical-center'])
          .cspl-tile__content-wrapper {
          justify-content: center;
        }

        :host([type='overlay'][vertical-align='vertical-top'])
          .cspl-tile__content-wrapper {
          justify-content: flex-start;
        }

        :host([type='overlay'][vertical-align='vertical-space-between'])
          .cspl-tile__content-wrapper {
          justify-content: space-between;
          padding-top: var(--cs-gutter-mobile);
        }

        :host([type='overlay'][media-shape='circle'])
          .cspl-tile__content-wrapper {
          padding: calc(var(--cs-gutter-mobile) * 3.5);
        }

        @media only screen and (min-width: 375px) {
          :host([type='overlay'][media-shape='circle'])
            .cspl-tile__content-wrapper {
            padding: calc(var(--cs-gutter-mobile) * 2.5);
          }
        }

        @media only screen and (min-width: 414px) {
          :host([type='overlay'][media-shape='circle'])
            .cspl-tile__content-wrapper {
            padding: calc(var(--cs-gutter-tablet-portrait) * 4);
          }
        }

        @media only screen and (min-width: 768px) {
          :host([type='overlay'][media-shape='circle'])
            .cspl-tile__content-wrapper {
            padding: calc(var(--cs-gutter-tablet-portrait) * 2.5);
          }
        }

        @media only screen and (min-width: 1024px) {
          :host([type='overlay'][media-shape='circle'])
            .cspl-tile__content-wrapper {
            padding: calc(var(--cs-gutter-tablet-landscape) * 3);
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([type='overlay'][media-shape='circle'])
            .cspl-tile__content-wrapper {
            padding: calc(var(--cs-gutter-desktop) * 3);
          }
        }

        /* overlay variant */
        :host([type='overlay']) .cspl-tile__content,
        :host([type|='vertical']) .cspl-tile__content {
          padding: 0;
        }

        @media only screen and (min-width: 768px) {
          .cspl-tile__content {
            padding: 40px var(--cs-gutter-tablet-portrait) 48px;
          }

          :host([type|='vertical']) .cspl-tile__content-wrapper {
            padding: 24px var(--cs-gutter-tablet-portrait);
          }

          /* horizontal variant */
          :host([type='horizontal']) .cspl-tile__content {
            padding: 0;
          }

          /* horizontal with image variant */
          :host([type='horizontal'])
            .cspl-tile__media:not([hidden])
            + dom-if
            + .cspl-tile__content {
            width: calc(
              (8 * var(--cs-column-tablet-portrait)) +
                (7 * var(--cs-gutter-tablet-portrait))
            );
          }
        }

        @media only screen and (min-width: 1024px) {
          /* horizontal with image variant */
          :host([type='horizontal'])
            .cspl-tile__media:not([hidden])
            + dom-if
            + .cspl-tile__content {
            width: calc(
              (8 * var(--cs-column-tablet-landscape)) +
                (7 * var(--cs-gutter-tablet-landscape))
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          /* horizontal with image variant */
          :host([type='horizontal'])
            .cspl-tile__media:not([hidden])
            + dom-if
            + .cspl-tile__content {
            width: calc(
              (8 * var(--cs-column-desktop)) + (7 * var(--cs-gutter-desktop))
            );
          }
        }

        /* Text Align Center variant */
        :host([text-align='center']) .cspl-tile__content {
          text-align: center;
        }

        /* Text Align Right variant */
        :host([text-align='right']) .cspl-tile__content {
          text-align: right;
        }

        :host([rtl][text-align='right']) .cspl-tile__content {
          text-align: left;
        }

        @media only screen and (max-width: 767px) {
          :host([card][text-align='right']) .cspl-tile__content,
          :host([card][text-align='left']) .cspl-tile__content {
            text-align: center;
          }
        }

        /** * Eyebrow */
        .cspl-tile__eyebrow {
          align-items: center;
          color: var(--cs-theme-secondary-color);
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          margin-left: -0.5em;
          margin-top: 0;
          width: calc(100% + 1em);
        }

        :host([rtl]) .cspl-tile__eyebrow {
          margin-left: 0;
          margin-right: -0.5rem;
        }

        /* card variant */
        :host([card]) .cspl-tile__eyebrow {
          justify-content: center;
        }

        @media only screen and (min-width: 768px) {
          .cspl-tile__eyebrow {
            align-items: flex-start;
            flex-flow: row wrap;
            justify-content: space-between;
          }

          :host([card]) .cspl-tile__title {
            text-align: left;
          }
        }

        /** * Eyebrow icon */
        .cspl-tile__eyebrow-icon {
          fill: currentColor;
          height: 0.8em;
          margin-left: 0.5em;
          margin-right: 8px;
          width: 0.8em;
        }

        :host([rtl]) .cspl-tile__eyebrow-icon {
          margin-left: 8px;
          margin-right: 0.5em;
        }

        /** * Eyebrow primary */
        .cspl-tile__eyebrow-primary {
          display: inline-block;
        }

        /** * Eyebrow secondary */
        .cspl-tile__eyebrow-secondary {
          align-items: center;
          display: inline-flex;
          margin-left: 0.5em;
          margin-right: 0.5em;
        }

        /* light variant */
        :host([text-color='light']) .cspl-tile__eyebrow-secondary {
          color: var(--cs-light-color);
        }

        /* dark variant */
        :host([text-color='dark']) .cspl-tile__eyebrow-secondary {
          color: var(--cs-dark-color);
        }

        :host([text-align='center']) .cspl-tile__eyebrow-primary {
          margin-left: 0.5em;
          margin-right: 0.5em;
        }

        :host([text-align='left']) .cspl-tile__eyebrow-primary {
          margin-right: 0.5em;
        }

        :host([text-align='left']) .cspl-tile__eyebrow-primary-icon--none {
          margin-left: 0.5em;
        }

        :host([text-align='right']) .cspl-tile__eyebrow-primary {
          margin-left: 0.5em;
        }

        :host([text-align='right']) .cspl-tile__eyebrow-primary-icon--none {
          margin-right: 0.5em;
        }

        /* Text Align Center variant */
        :host([text-align='left']) .cspl-tile__eyebrow {
          justify-content: flex-start;
        }

        :host([text-align='center']) .cspl-tile__eyebrow {
          justify-content: center;
        }

        /* Text Align Right variant */
        :host([text-align='right']) .cspl-tile__eyebrow {
          justify-content: flex-end;
        }

        @media only screen and (max-width: 767px) {
          :host([text-align='left']) .cspl-tile__eyebrow {
            align-items: flex-start;
          }

          :host([text-align='right']) .cspl-tile__eyebrow {
            align-items: flex-end;
          }

          :host([card][text-align='left']) .cspl-tile__eyebrow,
          :host([card][text-align='right']) .cspl-tile__eyebrow {
            align-items: center;
          }
        }

        /** * Title */
        .cspl-tile__title {
          font-family: var(--cs-theme-tile-title-font-family);
          font-weight: var(--cs-theme-tile-title-font-weight, bold);
          margin-bottom: 0;
          margin-top: 0;
        }

        /* @todo remove once new typography rules finalized */
        :host([type='overlay']:not([card])) .cspl-tile__title {
          font-size: var(--cs-theme-h5-font-size-mobile);
          line-height: var(--cs-theme-h5-line-height-mobile);
        }

        /* card variant */
        :host([card]) .cspl-tile__title {
          text-align: left;
        }

        :host([rtl][card]) .cspl-tile__title {
          text-align: right;
        }

        :host([card][text-align='center']) .cspl-tile__title {
          text-align: center;
        }

        :host([card][text-align='right']) .cspl-tile__title {
          text-align: right;
        }

        :host([rtl][card][text-align='right']) .cspl-tile__title {
          text-align: left;
        }

        @media only screen and (max-width: 767px) {
          :host([card]) .cspl-tile__title {
            text-align: center;
          }

          :host([type='overlay'][card]) .cspl-tile__content-wrapper {
            padding: 24px var(--cs-gutter-mobile) 40px;
          }

          :host([rtl][card][text-align]) .cspl-tile__title,
          :host([card][text-align]) .cspl-tile__title {
            text-align: center;
          }
        }

        /** * Quantitative data */
        .cspl-tile__quantitative-data {
          margin-bottom: 0;
          margin-top: 0;
        }

        /* card variant */
        :host([card]) .cspl-tile__quantitative-data {
          display: none;
        }

        @media only screen and (min-width: 768px) {
          :host([card]) .cspl-tile__quantitative-data {
            display: block;
          }
        }

        /** * Copy */
        .cspl-tile__copy {
          margin-bottom: 0;
          margin-top: 0;
        }

        /* card variant */
        :host([card]) .cspl-tile__copy {
          display: none;
        }

        @media only screen and (min-width: 768px) {
          :host([card]) .cspl-tile__copy {
            display: block;
          }
        }

        /** * Byline */
        :host([type|='vertical']) .cspl-tile__byline,
        :host([type='horizontal']) .cspl-tile__byline {
          color: #a3a3a3;
        }

        /* card variant */
        :host([card]) .cspl-tile__byline {
          display: none;
        }

        @media only screen and (min-width: 768px) {
          :host([card]) .cspl-tile__byline {
            display: block;
          }
        }

        /** * Overlay eyebrow and byline typography * * @todo remove once new typography rules finalized */
        :host([type='overlay']:not([card])) .cspl-tile__eyebrow,
        :host([type='overlay']:not([card])) .cspl-tile__byline {
          font-size: var(--cs-theme-b3-font-size-mobile);
          line-height: var(--cs-theme-b3-line-height-mobile);
        }

        @media only screen and (min-width: 1456px) {
          :host([type='overlay']) .cspl-tile__eyebrow,
          :host([type='overlay']) .cspl-tile__byline {
            font-size: var(--cs-theme-b3-font-size-desktop);
            line-height: var(--cs-theme-b3-line-height-desktop);
          }
        }

        /** * CTAs */
        .cspl-tile__ctas {
          margin-top: 26px;
        }

        :host([type|='vertical']) .cspl-tile__ctas {
          flex: 1 1 auto;
          flex-direction: column;
          justify-content: flex-end;
        }

        :host([type='overlay']) .cspl-tile__ctas {
          flex-direction: row;
        }

        /* card variant */
        :host([card]) .cspl-tile__ctas {
          display: none;
        }

        @media only screen and (min-width: 768px) {
          :host([card]) .cspl-tile__ctas {
            display: flex;
          }
        }

        @media only screen and (min-width: 768px) {
          .cspl-tile__ctas {
            margin-top: 32px;
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-tile__ctas {
            margin-top: 24px;
          }
        }

        /* Text Align Center variant */
        :host([text-align='center']) .cspl-tile__ctas {
          justify-content: center;
        }

        :host([text-align='center'][type|='vertical']) .cspl-tile__ctas {
          justify-content: flex-end;
        }

        :host([text-align='center'][type='overlay']) .cspl-tile__ctas {
          align-items: baseline;
          align-self: center;
        }

        /* Text Align Right variant */
        :host([text-align='right']) .cspl-tile__ctas {
          justify-content: flex-end;
        }

        :host([text-align='right'][type='overlay']) .cspl-tile__ctas {
          align-items: baseline;
          align-self: flex-end;
        }

        /* * Brand stroke */
        :host([type|='vertical']) .cspl-tile::after {
          background-color: var(--cs-theme-color);
          bottom: 0;
          content: '';
          height: 8px;
          left: 0;
          position: absolute;
          right: 0;
          transition: all var(--cs-interface-expansion);
        }

        @media only screen and (max-width: 767px) {
          :host([type='vertical-small']) .cspl-tile::after {
            display: none;
          }
        }

        :host([hide-brand-stroke]) .cspl-tile::after,
        :host([focused]) .cspl-tile::after {
          display: none;
        }

        /* Category tags */
        :host([type|='vertical']) .cspl-tile__category-tags {
          display: none;
          padding: 0 var(--cs-gutter-tablet-portrait) 24px;
          width: 100%;
        }

        @media only screen and (min-width: 768px) {
          :host([type|='vertical']) .cspl-tile__category-tags {
            display: block;
          }
        }

        /* Card variant */
        :host([card]) .cspl-tile::after {
          display: none;
        }

        /* dispaly 2up on mobile */
        @media only screen and (max-width: 767px) {
          :host([two-up-on-mobile]) .cspl-tile__eyebrow {
            font-size: 14px;
            line-height: 15px;
          }

          :host([two-up-on-mobile]) .cspl-tile__title {
            font-size: 16px;
            line-height: 20px;
          }

          :host([two-up-on-mobile]) .cspl-tile__copy {
            font-size: 14px;
            line-height: 18px;
          }

          :host([two-up-on-mobile][type='vertical']) .cspl-tile::after {
            height: 5px;
          }

          :host([two-up-on-mobile][type='vertical'])
            .cspl-tile__content-wrapper {
            padding: 24px var(--cs-gutter-mobile) 25px;
          }
        }

        @media only screen and (max-width: 320px) {
          :host([two-up-on-mobile][type='vertical'])
            .cspl-tile__content-wrapper {
            padding: 10px 6px 25px;
          }
        }

        /* list view for mobile */
        .cspl-tile__content-wrapper-list-view,
        .cspl-tile__media1x1 {
          display: none;
        }

        .cspl-tile__content-wrapper-list-view {
          overflow: hidden;
        }

        .cspl-tile__content-wrapper-list-view .cspl-tile__eyebrow {
          align-items: center;
          color: var(--cs-theme-color);
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }

        :host([rtl]) .cspl-tile__content-wrapper-list-view .cspl-tile__eyebrow {
          margin-right: 0;
        }

        .cspl-tile__content-wrapper-list-view .cspl-tile__eyebrow-secondary {
          display: block;
          margin-left: 0;
          margin-right: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        :host([type='horizontal']) .cspl-tile__content-wrapper-list-view {
          padding: 0 0 0 24px;
        }

        :host([rtl][type='horizontal']) .cspl-tile__content-wrapper-list-view {
          padding: 0 24px 0 0;
        }

        .cspl-tile__content-wrapper-list-view .cspl-tile__title {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-transform: none;
        }

        @media only screen and (max-width: 767px) {
          :host([type='horizontal'][list-view-for-mobile][media-shape='circle'])
            .cspl-tile__media,
          .cspl-tile__content-wrapper-list-view .cspl-tile__content {
            padding: 0;
          }

          :host([type='horizontal'][list-view-for-mobile])
            .cspl-tile__content-wrapper,
          :host([type='horizontal'][list-view-for-mobile]) .cspl-tile__media {
            display: none;
          }

          :host([type='horizontal'][list-view-for-mobile])
            .cspl-tile__content-wrapper-list-view {
            display: flex;
            text-transform: none;
          }

          :host([type='horizontal'][list-view-for-mobile])
            .cspl-tile__media1x1 {
            display: block;
          }

          :host([type='horizontal'][list-view-for-mobile]) .cspl-tile__link {
            flex-direction: row;
            min-height: auto;
          }

          :host([type='horizontal'][list-view-for-mobile])
            .cspl-tile__media-container {
            align-items: center;
            display: flex;
            justify-content: center;
          }

          .cspl-tile__media1x1 .cspl-tile__image,
          .cspl-tile__media1x1 .cspl-tile__video cs-html5-video {
            display: block;
            height: 112px;
            width: 112px;
          }
        }

        @media only screen and (min-width: 768px) {
          :host([type='horizontal'][list-view-for-desktop][media-shape='circle'])
            .cspl-tile__media,
          .cspl-tile__content-wrapper-list-view .cspl-tile__content {
            padding: 0;
          }

          :host([type='horizontal'][list-view-for-desktop])
            .cspl-tile__content-wrapper,
          :host([type='horizontal'][list-view-for-desktop]) .cspl-tile__media {
            display: none;
          }

          :host([type='horizontal'][list-view-for-desktop])
            .cspl-tile__content-wrapper-list-view {
            display: flex;
            text-transform: none;
          }

          :host([type='horizontal'][list-view-for-desktop])
            .cspl-tile__media1x1 {
            display: block;
            margin: 0;
            width: auto;
          }

          :host([type='horizontal'][list-view-for-desktop]) .cspl-tile__link {
            flex-direction: row;
            min-height: auto;
          }

          :host([type='horizontal'][list-view-for-desktop])
            .cspl-tile__media-container {
            align-items: center;
            display: flex;
            justify-content: center;
          }

          :host([type='horizontal']) .cspl-tile__content-wrapper-list-view {
            padding: 0 0 0 16px;
          }

          :host([rtl][type='horizontal'])
            .cspl-tile__content-wrapper-list-view {
            padding: 0 16px 0 0;
          }

          :host([type='horizontal'][list-view-for-desktop]) .cspl-tile__title {
            font-size: var(--cs-theme-h5-font-size-mobile);
            margin-top: var(--cs-theme-h5-after-l2-margin-top-mobile);
          }

          :host([type='horizontal'][list-view-for-desktop])
            .cspl-tile__eyebrow {
            font-size: var(--cs-theme-l2-font-size-mobile);
          }

          .cspl-tile__media1x1 .cspl-tile__image,
          .cspl-tile__media1x1 .cspl-tile__video cs-html5-video {
            height: 96px;
            width: 96px;
          }
        }

        @media only screen and (max-width: 375px) {
          :host([type='horizontal']) .cspl-tile__content-wrapper-list-view {
            padding: 0 0 0 16px;
          }

          :host([rtl][type='horizontal'])
            .cspl-tile__content-wrapper-list-view {
            padding: 0 16px 0 0;
          }

          .cspl-tile__media1x1 .cspl-tile__image,
          .cspl-tile__media1x1 .cspl-tile__video cs-html5-video {
            height: 96px;
            width: 96px;
          }
        }

        @media only screen and (max-width: 320px) {
          .cspl-tile__media1x1 .cspl-tile__image,
          .cspl-tile__media1x1 .cspl-tile__video cs-html5-video {
            height: 80px;
            width: 80px;
          }

          .cspl-tile__content-wrapper-list-view .cspl-tile__title {
            font-size: var(--cs-theme-tile-title-font-size-xs-mobile);
          }
        }

        /* Type vertical small */
        .cspl-tile__content-wrapper-vertical-small {
          display: none;
          padding-right: 12px;
          padding-top: 12px;
          -webkit-user-select: none;
          user-select: none;
        }

        :host([rtl]) .cspl-tile__content-wrapper-vertical-small {
          padding-left: 12px;
          padding-right: unset;
        }

        :host(:not([color='none'])) .cspl-tile__content-wrapper-vertical-small {
          padding: 12px;
        }

        @media only screen and (max-width: 767px) {
          :host([type='vertical-small']) .cspl-tile__content-wrapper {
            display: none;
          }

          :host([type='vertical-small'])
            .cspl-tile__content-wrapper-vertical-small {
            display: block;
          }
        }

        .cspl-tile__eyebrow-secondary-vertical-small {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .cspl-tile__title-vertical-small {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          margin: 0;
          overflow: hidden;
        }

        .cspl-tile__media-container ::slotted([slot='html5-video']) {
          line-height: unset;
        }
      </style>
      <div
        class="cspl-tile cspl-animate__tile"
        animate-enter-off$="[[ _styleEquals('--cs-animation-enter-tile-keyframe-name', 'none') ]]"
      >
      <!-- removido do ahref
      on-track="_handleTrack" -->
        <a
          class="cspl-tile__link"
          title$="[[ linkTitle ]]"
          id="tileLink"
          modal-id$="[[ linkModalId ]]"
          on-click="_handleClick"
          on-mouseup="_handleMouseUp"
          on-mousedown="_handleMouseDown"
          target$="[[ linkTarget ]]"
          rel="[[ _getRelValue(linkTarget) ]]"
          draggable="false"
          href$="[[ linkUrl ]]"
          has-link$="[[ _hasLink(linkUrl) ]]"
          on-focus="_handleFocus"
          on-blur="_handleBlur"
          style="[[style]]"
        >
          <div class="cspl-tile__media">
            <div class="cspl-tile__media-container">
              <template
                is="dom-if"
                if="[[ !_hideMedia(media, hideMedia, mediaType) ]]"
              >
                <cs-lazy-image>
                  <img
                    draggable$="[[ _isDraggable(context) ]]"
                    class="cspl-tile__image"
                    data-src$="[[ media ]]"
                    src$="[[ media ]]"
                    alt$="[[ tooltip ]]"
                  />
                </cs-lazy-image>
              </template>
              <template is="dom-if" if="[[ _isVideo(mediaType) ]]">
                <cs-lazy-image>
                  <video class="cspl-tile__image" autoplay muted onloadedmetadata="this.muted = true">
                    <source src="[[ media ]]" type="video/mp4" />
                  </video>
                </cs-lazy-image>
              </template>
              <template is="dom-if" if="[[ !_hideHtml5Video ]]">
                <slot name="html5-video"></slot>
              </template>
            </div>
          </div>
          <div class="cspl-tile__content-wrapper">
            <div class="cspl-tile__content">
              <h2
                class$="[[ _getEyebrowClass(textColor, type, card) ]]"
                hidden$="[[ _hideEyebrow(eyebrowText, hideEyebrow) ]]"
              >
                <iron-icon
                  icon="{{ _getEyebrowIconName(eyebrowIcon) }}"
                  class$="[[ _getEyebrowIconClass(eyebrowIcon) ]]"
                  hidden$="[[ _hideIcon(eyebrowIcon, type) ]]"
                ></iron-icon>
                <span class$="[[ _getPrimaryEyebrowClass(eyebrowIcon, type) ]]"
                  >[[ eyebrowText ]]</span
                >
                <span
                  class="cspl-tile__eyebrow-secondary"
                  hidden$="[[ _hideEyebrow(eyebrowSecondaryText, hideEyebrow) ]]"
                  >[[ eyebrowSecondaryText ]]</span
                >
              </h2>
              <h3
                class$="[[ _getTitleClass(textColor, type, card) ]]"
                hidden$="[[ _hideTitle(titleText, hideTitle) ]]"
                inner-h-t-m-l="[[ titleText ]]"
              ></h3>
              <div
                class="cspl-tile__quantitative-data b3"
                hidden$="[[ _hideQuantitativeData ]]"
              >
                <slot name="quantitative-data"></slot>
              </div>
              <div
                class$="[[ _getCopyClass(textColor) ]]"
                hidden$="[[ _hideCopy ]]"
              >
                <slot name="copy"></slot>
              </div>
              <div
                class$="[[ _getBylineClass(type) ]]"
                hidden$="[[ _hideByline(bylineText, hideByline) ]]"
              >
                [[ bylineText ]]
              </div>
            </div>
            <div class="cspl-tile__ctas ctas" hidden$="[[ _hideCtas ]]">
              <slot name="cta"></slot>
            </div>
          </div>
          <template is="dom-if" if="[[ _isListView ]]">
            <div class="cspl-tile__media cspl-tile__media1x1">
              <div class="cspl-tile__media-container">
                <template
                  is="dom-if"
                  if="[[ !_hideMedia1x1(media1x1, media, hideMedia, mediaType) ]]"
                >
                  <cs-lazy-image>
                    <img
                      draggable$="[[ _isDraggable(context) ]]"
                      class="cspl-tile__image"
                      data-src$="[[ _getMedia1x1(media1x1, media) ]]"
                      alt$="[[ tooltip ]]"
                    />
                  </cs-lazy-image>
                </template>
                <template is="dom-if" if="[[ !_hideHtml5Video ]]">
                  <div class="cspl-tile__video"></div>
                </template>
              </div>
            </div>
            <div
              class="cspl-tile__content-wrapper cspl-tile__content-wrapper-list-view"
            >
              <div class="cspl-tile__content">
                <h2
                  class$="[[ _getEyebrowClass(textColor, type, card) ]]"
                  hidden$="[[ _hideEyebrow(eyebrowText, hideEyebrow) ]]"
                >
                  <span
                    class="cspl-tile__eyebrow-secondary"
                    hidden$="[[ _hideEyebrow(eyebrowSecondaryText, hideEyebrow) ]]"
                    >[[ eyebrowSecondaryText ]]</span
                  >
                </h2>
                <h3
                  class$="[[ _getTitleClass(textColor, type, card) ]]"
                  hidden$="[[ _hideTitle(titleText, hideTitle) ]]"
                  inner-h-t-m-l="[[ titleText ]]"
                ></h3>
              </div>
            </div>
          </template>
          <div class="cspl-tile__content-wrapper-vertical-small">
            <div
              class$="[[ _getSmallEyebrowClass(textColor) ]]"
              hidden$="[[ _hideEyebrow(eyebrowSecondaryText, hideEyebrow) ]]"
            >
              [[ eyebrowSecondaryText ]]
            </div>
            <h3
              class$="[[ _getSmallTitleClass(textColor) ]]"
              hidden$="[[ _hideTitle(titleText, hideTitle) ]]"
              inner-h-t-m-l="[[ titleText ]]"
            ></h3>
          </div>
        </a>
        <div class="cspl-tile__category-tags" hidden$="[[ _hideCategoryTags ]]">
          <slot name="category-tag"></slot>
        </div>
      </div>`;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  static get observers() {
    return [
      '_setLink(ctas, titleText)',
      '_observeQuantitativeData(_quantitativeData, textColor)',
    ];
  }
  _handleMouseDown() {
    this.useMouse = !0;
  }
  _handleMouseUp() {
    this.useMouse = !1;
  }
  _handleFocus() {
    this.useMouse || (this.focused = !0);
  }
  _handleBlur() {
    this.focused = !1;
  }
  _hasLink(e) {
    return !!e;
  }
  _handleTrack(e) {
    if (this.linkUrl) {
      const t = this.shadowRoot.querySelector('.cspl-tile__link');
      switch (e.detail.state) {
        case 'start':
          t.removeAttribute('href');
          break;
        case 'end':
          setTimeout(() => {
            t.href = this.linkUrl;
          });
      }
    }
  }
  _getEyebrowClass(e, t, a) {
    return (
      'cspl-tile__eyebrow ' +
      ('overlay' !== t || ('overlay' === t && a) ? 'l2 l2-' + e : '')
    );
  }
  _getSmallEyebrowClass(e) {
    return 'cspl-tile__eyebrow-secondary-vertical-small l2 l2-' + e;
  }
  _getEyebrowIconClass(e) {
    return 'cspl-tile__eyebrow-icon cspl-tile__eyebrow-icon--' + e;
  }
  _getPrimaryEyebrowClass(e, t) {
    return (
      'cspl-tile__eyebrow-primary' +
      ('none' === e || 'custom' === e || 'overlay' !== t
        ? ' cspl-tile__eyebrow-primary-icon--none'
        : '')
    );
  }
  _getEyebrowIconName(e) {
    return 'cs-action-16:' + e;
  }
  _getTitleClass(e, t, a) {
    return (
      'cspl-tile__title ' +
      ('overlay' !== t || ('overlay' === t && a) ? 'h5 h5-' + e : '')
    );
  }
  _getSmallTitleClass(e) {
    return 'cspl-tile__title cspl-tile__title-vertical-small h5 h5-' + e;
  }
  _getCopyClass(e) {
    return 'cspl-tile__copy b2 b2-' + e;
  }
  _getBylineClass(e) {
    return 'cspl-tile__byline ' + ('overlay' !== e ? 'b2' : '');
  }
  _hideMedia(e, t, a) {
    return !e.length || t || 'image' !== a;
  }
  _hideMedia1x1(e, t, a, i) {
    return !this._getMedia1x1(e, t) || a || 'image' !== i;
  }
  _getMedia1x1(e, t) {
    return e || t;
  }
  _hideEyebrow(e, t) {
    return !e.length || t;
  }
  _hideIcon(e, t) {
    return 'none' === e || 'custom' === e || 'overlay' !== t;
  }
  _hideTitle(e, t) {
    return !e.length || t;
  }
  _hideByline(e, t) {
    return !e.length || t;
  }
  _setLink(e, t) {
    if (e.length && 1 === e.length) {
      const a = e[0];
      a.linkUrl && (this.linkUrl = a.linkUrl),
        (this.linkTitle = a.linkTitle),
        (this.linkModalId = a.modalId),
        this._setGAEvent(a, t),
        (a.tabIndex = '-1'),
        (a.ariaHidden = 'true');
    }
  }
  _setGAEvent(e, t) {
    const { intent: a, context: i, linkText: n, linkUrl: o } = e,
      l = o || t || '';
    (this.gaEventName = '/ga/nds/tile'),
      (this.gaEventPayload = {
        intent: a,
        context: i,
        text: n,
        url: l,
      });
  }
  _setCustomIcon(e) {
    if (!e) return;
    const t = document.createElement('span'),
      a = this.shadowRoot.querySelector('.cspl-tile__eyebrow');
    t.innerHTML = e;
    const i = t.firstChild;
    i.setAttribute('class', this._getEyebrowIconClass('custom')),
      a.insertBefore(i, a.firstChild);
  }
  _handleClick() {
    this.blur();
    const e = document.getElementById(this.linkModalId);
    e ? e.open() : this.gaEvent(this.gaEventName, this.gaEventPayload);
  }
  _observeQuantitativeData(e, t) {
    e.forEach(e => {
      e.textColor = t;
    });
  }
  _computeListView(e, t) {
    return e || t;
  }
  _isDraggable(e) {
    return 'cs-stream' === e ? 'false' : '';
  }
  _isCertainType(e) {
    return this.mediaType === e;
  }
  _setHtml5Video() {
    const e = u(this, ['html5-video'])[0];
    if ('video' === this.mediaType && e) {
      if (
        ((this._hideHtml5Video = !e._mp4Video && !e._webmVideo),
        'horizontal' === this.type &&
          (this.listViewForMobile || this.listViewForDesktop))
      ) {
        const t = e.cloneNode(!0),
          a = this.shadowRoot.querySelector('.cspl-tile__video');
        t.removeAttribute('slot'), a.appendChild(t);
      }
    } else this._hideHtml5Video = !0;
  }

  _isVideo(mediaType) {
    return mediaType === 'video';
  }

  connectedCallback() {
    super.connectedCallback(),
      this.dispatchEvent(
        new CustomEvent('nds.catalog.tileReady', {
          bubbles: !0,
          composed: !0,
        })
      ),
      this._setCustomIcon(this.eyebrowCustomIcon),
      // this.setScrollDirection('y', this.$.tileLink),
      setTimeout(() => {
        this._setHtml5Video(true);
      });
  }
}

customElements.define('cs-tile', CsTile);
