import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsLazyPictureMixin } from "./mixins/cs-lazy-picture-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";

const t = CsMixin,
	a = CsLazyPictureMixin;

/**
 * `cs-lazy-picture` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLazyPicture extends t(a(PolymerElement)) {
	static get properties() {
		return {
			_pictures: {
				type: Array,
				value() {
					return [];
				},
				observer: "_lazyPictures",
			},
		};
	}

	static get template() {
		return html`
			<style>
				:host {
					display: block;
					width: 100%;
				}

				:host picture {
					width: 100%;
				}
			</style>
			<slot></slot>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}

	_getPictures() {
		this._pictures = [...this.querySelectorAll("picture")];
	}
	connectedCallback() {
		super.connectedCallback(), setTimeout(this._getPictures.bind(this)); // jshint ignore:line
	}
}

customElements.define("cs-lazy-picture", CsLazyPicture);
