/* jshint ignore:start */
import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-local-nav-shelf-sub-nav-section` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLocalNavShelfSubNavSection extends PolymerElement {
	static get properties() {
		return {
			titleText: String,
			theme: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			open: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			simple: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			_images: {
				type: Array,
				value() {
					return [];
				},
			},
			_links: {
				type: Array,
				value() {
					return [];
				},
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style>
				:host {
					display: block;
					font-family: var(--cs-theme-local-nav-shelf-sub-nav-font-family);
					font-size: var(--cs-theme-local-nav-shelf-sub-nav-section-font-size-tablet-landscape);
				}

				@media only screen and (min-width: 1024px) {
					:host {
						font-size: var(--cs-theme-local-nav-shelf-sub-nav-section-font-size-tablet-landscape);
					}
				}

				@media only screen and (min-width: 1456px) {
					:host {
						font-size: var(--cs-theme-local-nav-shelf-sub-nav-section-font-size-desktop);
					}
				}

				.cspl-local-nav__shelf-nav-links-column {
					flex-basis: 15%;
					margin-right: 25px;
				}

				.cspl-local-nav__shelf-nav-links-column-title {
					border-bottom: solid 1px #ccc;
					color: var(--cs-theme-local-nav-shelf-sub-nav-section-title-font-color-dark);
					font-weight: 700;
					margin: 0;
					opacity: 0;
					padding: 0 0 5px 0;
					text-transform: uppercase;
					transition: 0.3s cubic-bezier(0.5, 0.25, 0.015, 1);
					transition-delay: 0.1s;
					transition-property: transform, opacity;
				}

				:host([simple]) .cspl-local-nav__shelf-nav-links-column-title {
					display: none;
				}

				:host([theme="light"]) .cspl-local-nav__shelf-nav-links-column-title {
					border-color: #333;
					color: var(--cs-theme-local-nav-shelf-sub-nav-section-title-font-color-light);
				}

				:host([open]) .cspl-local-nav__shelf-nav-links-column-title {
					opacity: 1;
					transform: translateY(5px);
				}
			</style>
			<div class="cspl-local-nav__shelf-nav-links-column">
				<p class="cspl-local-nav__shelf-nav-links-column-title">[[ titleText ]]</p>
				<slot id="linksSlot" name="sub-nav-section-link"></slot>
			</div>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	static get observers() {
		return ["_observeTheme(theme, _links)", "_observeOpen(open, _links)"];
	}
	_observeTheme(e, t) {
		t.forEach((t) => {
			t.theme = e;
		});
	}
	_observeOpen(e, t) {
		t.forEach((t) => {
			t.open = e;
		});
	}
	connectedCallback() {
		super.connectedCallback(),
			setTimeout(() => {
				this._links = this.$.linksSlot.assignedNodes({
					flatten: !0,
				});
			}, 0); // jshint ignore:line
	}
}

customElements.define("cs-local-nav-shelf-sub-nav-section", CsLocalNavShelfSubNavSection);
