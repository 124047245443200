import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsModalInvokerMixin } from './mixins/cs-modal-invoker-mixin.js';

const t = CsMixin;
const a = CsGoogleAnalyticsMixin;
const r = CsModalInvokerMixin;

/**
 * `cs-cta` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsCta extends t(a(r(CsElements))) {
  static get properties() {
    return {
      linkUrl: String,
      linkTitle: String,
      linkText: String,
      customIcon: {
        type: String,
        value: null,
      },
      fillColor: {
        type: String,
        value: 'none',
        reflectToAttribute: !0,
      },
      textColor: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      type: {
        type: String,
        value: 'standard',
        reflectToAttribute: !0,
      },
      tooltip: String,
      icon: {
        type: String,
        value: 'none',
        reflectToAttribute: !0,
      },
      targetOs: {
        type: String,
        value: null,
      },
      image: String,
      alt: String,
      fullWidth: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      intent: {
        type: String,
        value: 'misc',
      },
      context: {
        type: String,
        observer: '_setGaEvent',
      },
      emphasis: {
        type: String,
        value: 'primary',
        reflectToAttribute: !0,
      },
      target: String,
      twoUpOnMobile: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      disabled: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      ctaInText: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      marginLeft: String,
      marginRight: String,
      marginNotSet: {
        type: Boolean,
        value: !0,
        computed: '_computeMarginNotSet(marginLeft, marginRight)',
        reflectToAttribute: !0,
      },
      tabIndex: {
        type: String,
        value: '0',
      },
      ariaHidden: String,
      _focused: Boolean,
      hideUnderline: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      customTextColor: {
        type: 'String',
        reflectToAttribute: !0,
      },
      referrerpolicy: String,
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style>
        @keyframes move-left {
          0% {
            transform: rotate(90deg) translateY(0);
          }

          50% {
            transform: rotate(90deg) translateY(10px);
          }

          100% {
            transform: rotate(90deg) translateY(0);
          }
        }

        @keyframes move-right {
          0% {
            transform: rotate(-90deg) translateY(0);
          }

          50% {
            transform: rotate(-90deg) translateY(10px);
          }

          100% {
            transform: rotate(-90deg) translateY(0);
          }
        }

        :host {
          display: flex;
          font-family: var(--cs-theme-cta-font-family);
          font-size: var(--cs-theme-cta-font-size-mobile);
          font-weight: var(--cs-theme-cta-font-weight);
          letter-spacing: var(--cs-theme-cta-letter-spacing);
          line-height: var(--cs-theme-cta-line-height-mobile);
          margin-bottom: 16px;
          position: relative;
          transition: opacity var(--cs-interface-expansion);
          width: 100%;
        }

        :host([cta-in-text]) {
          margin-bottom: unset;
        }

        :host([type='standard-small']) {
          font-size: var(--cs-theme-cta-standard-small-font-size-mobile);
          line-height: var(--cs-theme-cta-standard-small-line-height-mobile);
          margin-bottom: 6px;
        }

        @media only screen and (min-width: 768px) {
          :host([type='standard-small']) {
            font-size: var(--cs-theme-cta-standard-small-font-size-tablet);
            line-height: var(--cs-theme-cta-standard-small-line-height-tablet);
          }
        }

        @media only screen and (min-width: 1024px) {
          :host {
            font-size: var(--cs-theme-cta-font-size-tablet);
            line-height: var(--cs-theme-cta-line-height-tablet);
          }
        }

        @media only screen and (min-width: 1456px) {
          :host {
            font-size: var(--cs-theme-cta-font-size-desktop);
            line-height: var(--cs-theme-cta-line-height-desktop);
          }

          :host([type='standard-small']) {
            font-size: var(--cs-theme-cta-standard-small-font-size-desktop);
            line-height: var(--cs-theme-cta-standard-small-line-height-desktop);
          }
        }

        :host(:last-of-type),
        :host([type='standard-small']:last-of-type) {
          margin-bottom: 0;
        }

        :host([wf-loading]) {
          opacity: 0;
        }

        @media only screen and (min-width: 640px) {
          :host {
            display: inline-flex;
            margin-left: var(--cs-cta-margin-left, 0);
            margin-right: var(--cs-cta-margin-right, 24px);
            vertical-align: top;
            width: auto;
          }

          :host([rtl]) {
            margin-left: var(--cs-cta-margin-left, 24px);
            margin-right: var(--cs-cta-margin-right, 0);
          }

          :host(:last-of-type) {
            margin-bottom: 16px;
          }

          :host(:only-of-type),
          :host([cta-in-text]) {
            margin-left: 0;
            margin-right: 0;
          }

          :host([type='standard-small']) {
            margin-left: var(--cs-cta-margin-left, 0);
            margin-right: var(--cs-cta-margin-right, 6px);
          }

          :host([rtl][type='standard-small']) {
            margin-left: var(--cs-cta-margin-left, 6px);
            margin-right: var(--cs-cta-margin-right, 0);
          }

          :host([type='standard-small']:last-of-type) {
            margin-bottom: 6px;
          }

          :host([type='standard-small']:only-of-type) {
            margin-left: 0;
            margin-right: 0;
          }
        }

        .cspl-cta {
          align-items: center;
          color: var(--cs-dark-color);
          cursor: pointer;
          display: flex;
          justify-content: center;
          position: relative;
          text-decoration: none;
          width: 100%;
        }

        :host([type='standard'][disabled]) .cspl-cta {
          cursor: not-allowed;
        }

        :host([text-color='light']) .cspl-cta,
        :host([text-color='light']) .cspl-cta:hover {
          color: var(--cs-light-color);
          fill: var(--cs-light-color);
        }

        :host([text-color='brand']) .cspl-cta,
        :host([text-color='brand']) .cspl-cta:hover {
          color: var(--cs-theme-color);
          fill: var(--cs-theme-color);
        }

        :host([text-color='cs-access-brand']) .cspl-cta,
        :host([text-color='cs-access-brand']) .cspl-cta:hover {
          color: var(--cs-access-brand-color);
          fill: var(--cs-access-brand-color);
        }

        :host([text-color='origin-brand']) .cspl-cta,
        :host([text-color='origin-brand']) .cspl-cta:hover {
          color: var(--origin-brand-color);
          fill: var(--origin-brand-color);
        }

        :host([text-color='xbox-brand']) .cspl-cta,
        :host([text-color='xbox-brand']) .cspl-cta:hover {
          color: var(--xbox-brand-color);
          fill: var(--xbox-brand-color);
        }

        :host([text-color='playstation-brand']) .cspl-cta,
        :host([text-color='playstation-brand']) .cspl-cta:hover {
          color: var(--playstation-brand-color);
          fill: var(--playstation-brand-color);
        }

        :host([text-color='nintendo-brand']) .cspl-cta,
        :host([text-color='nintendo-brand']) .cspl-cta:hover {
          color: var(--nintendo-brand-color);
          fill: var(--nintendo-brand-color);
        }

        :host([text-color='cs-app-brand']) .cspl-cta,
        :host([text-color='cs-app-brand']) .cspl-cta:hover {
          color: var(--cs-app-brand-color);
          fill: var(--cs-app-brand-color);
        }

        :host([custom-text-color]) .cspl-cta,
        :host([custom-text-color]) .cspl-cta:hover {
          color: var(--cs-cta-custom-text-color);
          fill: var(--cs-cta-custom-text-color);
        }

        :host([type|='standard']) {
          min-width: 136px;
          z-index: 0;
        }

        :host([type='standard-small']) {
          min-width: 80px;
        }

        @media only screen and (min-width: 1456px) {
          :host([type|='standard']) {
            min-width: 160px;
          }

          :host([type='standard-small']) {
            min-width: 100px;
          }
        }

        :host([type|='standard']) .cspl-cta {
          padding: 12px 24px;
        }

        :host([type='standard-small']) .cspl-cta {
          padding: 8px 12px;
        }

        @media only screen and (min-width: 1456px) {
          :host([type|='standard']) .cspl-cta {
            padding: 16px 24px;
          }

          :host([type='standard-small']) .cspl-cta {
            padding: 8px 12px;
          }
        }

        :host([type|='standard']) .cspl-cta::before {
          border-radius: var(--cs-theme-cta-border-radius);
          border-style: solid;
          border-width: 2px;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: bottom var(--cs-interface-expansion),
            left var(--cs-interface-expansion),
            right var(--cs-interface-expansion),
            top var(--cs-interface-expansion);
          z-index: -1;
        }

        :host([type='standard-rounded']) .cspl-cta::before {
          border-radius: var(--cs-theme-cta-standard-rounded-border-radius);
        }

        :host([type|='standard'][text-color='dark']) .cspl-cta::before {
          border-color: var(--cs-dark-color);
        }

        :host([type|='standard'][text-color='light']) .cspl-cta::before {
          border-color: var(--cs-light-color);
        }

        :host([type|='standard'][fill-color='brand']) .cspl-cta::before {
          background-color: var(--cs-theme-color);
          border-color: var(--cs-theme-color);
        }

        :host([type|='standard'][fill-color='dark-brand']) .cspl-cta::before {
          background-color: var(--cs-dark-color);
          border-color: var(--cs-dark-color);
        }

        :host([type|='standard'][fill-color='light-brand']) .cspl-cta::before {
          background-color: var(--cs-light-color);
          border-color: var(--cs-light-color);
        }

        :host([type|='standard'][fill-color='cs-access-brand'])
          .cspl-cta::before {
          background-color: var(--cs-access-brand-color);
          border-color: var(--cs-access-brand-color);
        }

        :host([type|='standard'][fill-color='origin-brand']) .cspl-cta::before {
          background-color: var(--origin-brand-color);
          border-color: var(--origin-brand-color);
        }

        :host([type|='standard'][fill-color='xbox-brand']) .cspl-cta::before {
          background-color: var(--xbox-brand-color);
          border-color: var(--xbox-brand-color);
        }

        :host([type|='standard'][fill-color='playstation-brand'])
          .cspl-cta::before {
          background-color: var(--playstation-brand-color);
          border-color: var(--playstation-brand-color);
        }

        :host([type|='standard'][fill-color='nintendo-brand'])
          .cspl-cta::before {
          background-color: var(--nintendo-brand-color);
          border-color: var(--nintendo-brand-color);
        }

        :host([type|='standard'][fill-color='cs-app-brand']) .cspl-cta::before {
          background-color: var(--cs-app-brand-color);
          border-color: var(--cs-app-brand-color);
        }

        :host([type|='standard'][fill-color='disable-color'])
          .cspl-cta::before {
          background-color: var(--cs-cta-disable-color);
          border-color: var(--cs-cta-disable-color);
        }

        :host([type|='standard'][stroke-color='none']) .cspl-cta::before {
          border: none;
        }

        :host([type|='standard'][stroke-color='dark']) .cspl-cta::before {
          border-color: var(--cs-dark-color);
        }

        :host([type|='standard'][stroke-color='light']) .cspl-cta::before {
          border-color: var(--cs-light-color);
        }

        :host([type|='standard'][stroke-color='brand']) .cspl-cta::before {
          border-color: var(--cs-theme-color);
        }

        :host([type|='standard'][stroke-color='cs-access-brand'])
          .cspl-cta::before {
          border-color: var(--cs-access-brand-color);
        }

        :host([type|='standard'][stroke-color='origin-brand'])
          .cspl-cta::before {
          border-color: var(--origin-brand-color);
        }

        :host([type|='standard'][stroke-color='xbox-brand']) .cspl-cta::before {
          border-color: var(--xbox-brand-color);
        }

        :host([type|='standard'][stroke-color='playstation-brand'])
          .cspl-cta::before {
          border-color: var(--playstation-brand-color);
        }

        :host([type|='standard'][stroke-color='nintendo-brand'])
          .cspl-cta::before {
          border-color: var(--nintendo-brand-color);
        }

        :host([type|='standard'][stroke-color='cs-app-brand'])
          .cspl-cta::before {
          border-color: var(--cs-app-brand-color);
        }

        :host([type|='standard']:not([disabled]))
          .cspl-cta:focus-visible::before,
        :host([type|='standard']:not([disabled])) .cspl-cta:hover::before {
          bottom: -3px;
          left: -3px;
          right: -3px;
          top: -3px;
        }

        :host([type|='standard']) .cspl-cta:focus-visible {
          outline: 0;
        }

        :host([type|='standard']) .cspl-cta:focus-visible .cspl-cta__icon {
          fill: currentColor;
          stroke: currentColor;
        }

        :host([type|='standard']:not([fill-color$='brand']))
          .cspl-cta:focus-visible {
          color: var(--cs-light-color);
          fill: var(--cs-light-color);
        }

        :host([type|='standard']:not([fill-color$='brand']))
          .cspl-cta:focus-visible::before {
          background-color: var(--cs-dark-color);
          border-color: var(--cs-dark-color);
        }

        :host([type='standard-image']:not([fill-color$='brand']))
          .cspl-cta:focus-visible
          .cspl-cta__image {
          filter: invert(1);
        }

        :host([type|='standard']) .cspl-cta:focus-visible::after {
          border: 2px solid var(--cs-light-color);
          border-radius: var(--cs-theme-cta-border-radius);
          bottom: 2px;
          content: '';
          left: 2px;
          pointer-events: none;
          position: absolute;
          right: 2px;
          top: 2px;
          transition: bottom var(--cs-interface-expansion),
            left var(--cs-interface-expansion),
            right var(--cs-interface-expansion),
            top var(--cs-interface-expansion);
        }

        :host([type='standard-rounded']) .cspl-cta:focus-visible::after {
          border-radius: var(--cs-theme-cta-standard-rounded-border-radius);
        }

        :host([type|='standard']:not([disabled]))
          .cspl-cta:focus-visible::after {
          bottom: -1px;
          left: -1px;
          right: -1px;
          top: -1px;
        }

        :host([type|='standard'][text-color='light']:not([fill-color$='brand']))
          .cspl-cta:focus-visible {
          color: var(--cs-dark-color);
          fill: var(--cs-dark-color);
        }

        :host([type|='standard'][text-color='light']:not([fill-color$='brand']))
          .cspl-cta:focus-visible::before {
          background-color: var(--cs-light-color);
          border-color: var(--cs-light-color);
        }

        :host([type|='standard'][text-color='light'])
          .cspl-cta:focus-visible::after {
          border-color: var(--cs-dark-color);
        }

        :host([type|='standard'][text-color='light'][fill-color$='brand'])
          .cspl-cta:focus-visible::after {
          border-color: var(--cs-light-color);
        }

        :host([type|='standard'][text-color='dark'][fill-color$='brand'])
          .cspl-cta:focus-visible::after {
          border-color: var(--cs-dark-color);
        }

        :host([type='text']) {
          margin-bottom: 8px;
        }

        :host([type='text']) .cspl-cta:hover {
          color: var(--cs-theme-color);
        }

        :host([type='text'].cspl-cta-first) {
          margin-top: 0;
        }

        :host([type='text']) .cspl-cta__liner {
          position: relative;
        }

        :host([type='text']) .cspl-cta__liner::before {
          background-color: var(--cs-dark-color);
          bottom: 0;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          transition: left var(--cs-interface-expansion),
            right var(--cs-interface-expansion);
        }

        :host([type='text'][text-color='light']) .cspl-cta__liner::before {
          background-color: var(--cs-light-color);
        }

        :host([type='text'][text-color='cs-access-brand'])
          .cspl-cta__liner::before {
          background-color: var(--cs-access-brand-color);
        }

        :host([type='text'][text-color='origin-brand'])
          .cspl-cta__liner::before {
          background-color: var(--origin-brand-color);
        }

        :host([type='text'][text-color='xbox-brand']) .cspl-cta__liner::before {
          background-color: var(--xbox-brand-color);
        }

        :host([type='text'][text-color='playstation-brand'])
          .cspl-cta__liner::before {
          background-color: var(--playstation-brand-color);
        }

        :host([type='text'][text-color='nintendo-brand'])
          .cspl-cta__liner::before {
          background-color: var(--nintendo-brand-color);
        }

        :host([type='text'][text-color='cs-app-brand'])
          .cspl-cta__liner::before {
          background-color: var(--cs-app-brand-color);
        }

        :host([type='text']) .cspl-cta__liner:hover::before {
          background-color: var(--cs-theme-color);
          left: -4px;
          right: -4px;
        }

        @media only screen and (min-width: 768px) {
          :host([margin-not-set]:not([type='graphic']):last-of-type) {
            margin-right: 0;
          }

          :host([type='graphic'])::before {
            backface-visibility: hidden;
            content: '';
            display: inline-block;
            height: 108px;
          }

          :host([type='graphic'])::before {
            border-left: 1px solid transparent;
          }

          :host([type='graphic']:not(.cspl-cta-first))::before {
            border-left-color: #a3a3a3;
          }

          :host(
              [type='graphic'][text-color='light']:not(.cspl-cta-first)
            )::before {
            border-color: #585858;
          }

          :host([type='graphic']) .cspl-cta__liner {
            display: inline-block;
          }

          :host([type='graphic']:not(:only-of-type)) {
            margin-left: 0;
            margin-right: 0;
          }

          :host([type='graphic']:not(:only-of-type)) .cspl-cta__liner {
            padding-left: var(--cs-cta-margin-left, 24px);
            padding-right: var(--cs-cta-margin-right, 24px);
          }
        }

        :host([type|='standard']:not([icon='none'])) .cspl-cta__liner {
          align-items: center;
          display: flex;
          flex-direction: row-reverse;
        }

        :host([type='standard-image']) .cspl-cta__liner {
          width: 100%;
        }

        :host([type|='standard']:not([icon='none'])) .cspl-cta__icon {
          flex-shrink: 0;
          height: 16px;
          margin-left: 8px;
          width: 16px;
        }

        :host([rtl][type|='standard']:not([icon='none'])) .cspl-cta__icon {
          margin-left: 0;
          margin-right: 8px;
        }

        :host([rtl]) .cspl-cta__icon--arrow {
          transform: rotate(180deg);
        }

        :host([type='graphic']:not([icon='none'])) .cspl-cta__icon,
        :host([type|='standard']:not([icon='none'])) .cspl-cta__icon {
          display: block;
        }

        :host([type='graphic']:not([icon='none'])) .cspl-cta__label {
          display: inline-block;
          margin-top: 10px;
        }

        @media only screen and (min-width: 1456px) {
          :host([type='graphic']:not([icon='none'])) .cspl-cta__label {
            margin-top: 14px;
          }
        }

        :host([type|='standard'][text-color='dark']) .cspl-cta__icon {
          fill: var(--cs-dark-color);
          stroke: var(--cs-dark-color);
        }

        :host([type|='standard'][text-color='light']) .cspl-cta__icon {
          fill: var(--cs-light-color);
          stroke: var(--cs-light-color);
        }

        :host([type|='standard'][text-color='brand']) .cspl-cta__icon {
          fill: var(--cs-theme-color);
          stroke: var(--cs-theme-color);
        }

        :host([type|='standard'][text-color='cs-access-brand'])
          .cspl-cta__icon {
          fill: var(--cs-access-brand-color);
          stroke: var(--cs-access-brand-color);
        }

        :host([type|='standard'][text-color='origin-brand']) .cspl-cta__icon {
          fill: var(--origin-brand-color);
          stroke: var(--origin-brand-color);
        }

        :host([type|='standard'][text-color='xbox-brand']) .cspl-cta__icon {
          fill: var(--xbox-brand-color);
          stroke: var(--xbox-brand-color);
        }

        :host([type|='standard'][text-color='playstation-brand'])
          .cspl-cta__icon {
          fill: var(--playstation-brand-color);
          stroke: var(--playstation-brand-color);
        }

        :host([type|='standard'][text-color='nintendo-brand']) .cspl-cta__icon {
          fill: var(--nintendo-brand-color);
          stroke: var(--nintendo-brand-color);
        }

        :host([type|='standard'][text-color='cs-app-brand']) .cspl-cta__icon {
          fill: var(--cs-app-brand-color);
          stroke: var(--cs-app-brand-color);
        }

        :host([type='graphic']) .cspl-cta__icon {
          height: 40px;
          margin: 0 auto;
          transition: transform var(--cs-interface-expansion);
          width: 40px;
        }

        :host([type='graphic']:not([icon='none']):hover) .cspl-cta__icon {
          transform: scale(1.2);
        }

        :host([type='image']),
        :host([type='standard-image']) {
          line-height: 0;
          margin-bottom: 16px;
          text-indent: -99999px;
          transition: all var(--cs-interface-expansion);
        }

        :host([type='image']:hover) {
          transform: scale(1.05);
        }

        :host([type='image']) .cspl-cta__image,
        :host([type='standard-image']) .cspl-cta__image {
          display: block;
        }

        :host([type='standard-image']) .cspl-cta__image {
          height: 28px;
          margin: 0 auto;
          max-width: 100%;
          width: auto;
        }

        :host([type='arrow-left']),
        :host([type='arrow-right']) {
          background: var(--cs-light-color);
          margin: 0;
          padding: 20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: auto;
          z-index: 3;
        }

        :host([type='arrow-left']) .cspl-cta,
        :host([type='arrow-right']) .cspl-cta {
          direction: ltr;
        }

        :host([text-color='light'][type='arrow-left']),
        :host([text-color='light'][type='arrow-right']) {
          background: var(--cs-dark-color);
        }

        :host([type='arrow-left']) {
          left: 0;
        }

        :host([type='arrow-right']) {
          right: 0;
        }

        :host([text-color='brand']) .cspl-cta__arrow-left,
        :host([text-color='brand']) .cspl-cta__arrow-right {
          fill: var(--cs-theme-color);
          stroke: var(--cs-theme-color);
        }

        .cspl-cta__arrow-left,
        .cspl-cta__arrow-right {
          height: 32px;
          width: 32px;
        }

        .cspl-cta__arrow-left {
          animation: 1s 1s move-left 5;
          transform: rotate(90deg);
        }

        .cspl-cta__arrow-right {
          animation: 1s 1s move-right 5;
          transform: rotate(-90deg);
        }

        :host([full-width]) {
          width: 100%;
        }

        @media only screen and (max-width: 767px) {
          :host([two-up-on-mobile]) .cspl-cta__label {
            font-size: 14px;
          }

          :host([two-up-on-mobile]) .cspl-cta__liner::before {
            height: 1px;
          }

          :host([two-up-on-mobile][type|='standard']) .cspl-cta::before {
            border-width: 1px;
          }

          :host([two-up-on-mobile][type='graphic']) .cspl-cta__icon {
            height: 30px;
          }
        }

        @media only screen and (max-width: 425px) {
          :host([two-up-on-mobile][type|='standard']) {
            min-width: 100%;
          }
        }

        :host([type='inline-text']) .cspl-cta__label,
        :host([type='inline-text']:hover) .cspl-cta__label,
        :host([type|='inline-text-graphic']) .cspl-cta__label,
        :host([type|='inline-text-graphic']:hover) .cspl-cta__label {
          text-decoration: underline;
          text-underline-offset: 0.1em;
        }

        @media only screen and (min-width: 1024px) {
          :host([hide-underline]) .cspl-cta__label {
            text-decoration: none;
          }
        }

        :host([cta-in-text]),
        :host([cta-in-text]) .cspl-cta {
          display: inline;
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          letter-spacing: inherit;
          line-height: inherit;
          width: auto;
        }

        :host([cta-in-text]) .cspl-cta__liner {
          display: inline;
        }

        :host([type='inline-text']) .cspl-cta__liner,
        :host([type|='inline-text-graphic']) .cspl-cta__liner {
          display: inline-block;
        }

        :host([type='inline-graphic']) .cspl-cta__icon,
        :host([type|='inline-text-graphic']) .cspl-cta__icon {
          height: var(--icon-size);
          transform: scale3d(1, 1, 1);
          transform-origin: center;
          transition: transform 0.2s ease-in-out;
          vertical-align: var(--icon-vertical);
          width: var(--icon-size);
        }

        :host([type='inline-text-graphic-left']) .cspl-cta__icon {
          margin-right: 0.1em;
        }

        :host([type='inline-text-graphic-right']) .cspl-cta__icon {
          margin-left: 0.1em;
        }

        :host([type='inline-text-graphic-right'][rtl]) .cspl-cta__icon {
          margin-left: unset;
          margin-right: 0.1em;
        }

        :host([type='inline-text-graphic-left'][rtl]) .cspl-cta__icon {
          margin-left: 0.1em;
          margin-right: unset;
        }

        :host([type='inline-graphic']:hover) .cspl-cta__icon,
        :host([type|='inline-text-graphic']:hover) .cspl-cta__icon {
          transform: scale3d(var(--icon-scale), var(--icon-scale), 1);
        }
      </style>
      <a
        href$="[[ linkUrl ]]"
        title$="[[ linkTitle ]]"
        class="cspl-cta"
        target$="[[ target ]]"
        rel="[[ _getRelValue(target) ]]"
        draggable="[[ !disabled ]]"
        on-focus="_handleFocus"
        on-blur="_handleBlur"
        tabindex$="[[ tabIndex ]]"
        aria-hidden$="[[ ariaHidden ]]"
        referrerpolicy$="[[ referrerpolicy ]]"
      >
        <template is="dom-if" if="[[ _isType(type, 'arrow-left') ]]">
          <iron-icon
            icon="cs-action-16:chevron"
            class="cspl-cta__arrow-left"
          ></iron-icon>
        </template>
        <div class="cspl-cta__liner">
          <template is="dom-if" if="[[ _hasIcon(icon, type) ]]">
            <iron-icon
              icon="{{ _getIconName(type, icon, textColor, fillColor, _focused) }}"
              class$="[[ _getIconClass(icon) ]]"
            ></iron-icon>
          </template>
          <template
            is="dom-if"
            if="[[ _showCtaInTextIcon(_textIconPos, 'left') ]]"
          >
            <iron-icon
              icon="{{ _getCtaInTextIconName(icon) }}"
              class$="[[ _getIconClass(icon) ]]"
            ></iron-icon>
          </template>
          <template is="dom-if" if="[[ _showCtaLabel(linkText, type) ]]">
            <span id="Label" class="cspl-cta__label">[[ linkText ]]</span>
          </template>
          <template
            is="dom-if"
            if="[[ _showCtaInTextIcon(_textIconPos, 'right') ]]"
          >
            <iron-icon
              icon="{{ _getCtaInTextIconName(icon) }}"
              class$="[[ _getIconClass(icon) ]]"
            ></iron-icon>
          </template>
          <template is="dom-if" if="[[ _showImage(type) ]]">
            <cs-lazy-image>
              <img
                class="cspl-cta__image"
                data-src$="[[ image ]]"
                alt$="[[ alt ]]"
              />
            </cs-lazy-image>
          </template>
          <template is="dom-if" if="[[ _isType(type, 'arrow-right') ]]">
            <iron-icon
              icon="cs-action-16:chevron"
              class="cspl-cta__arrow-right"
            ></iron-icon>
          </template>
        </div>
      </a>`;
  }
  _showCtaInTextIcon(t, e) {
    return t === e;
  }
  _setInlineIconPosition() {
    'inline-graphic' === this.type && (this._textIconPos = ''),
      'inline-text-graphic-left' === this.type && (this._textIconPos = 'left'),
      'inline-text-graphic-right' === this.type &&
        (this._textIconPos = 'right');
  }
  _computeMarginNotSet(t, e) {
    return !t && !e;
  }
  _getIconClass(t) {
    return 'eapl-cta__icon eapl-cta__icon--' + t;
  }
  _getIconName(t, e, a, o, r) {
    return this.ctaInText
      ? this._getCtaInTextIconName(e)
      : 'youtube' === e
      ? this._getYoutubeIconName(a, o, r)
      : `ea-action-${this._getIconSize(t)}:${e}`;
  }
  _getCtaInTextIconName(t) {
    return 'ea-cta-in-text:' + t;
  }
  _getYoutubeIconName(t, e, a) {
    let o = 'light' === t || t.includes('brand');
    return (
      !e.includes('brand') && a && (o = !o),
      'ea-action-192:youtube-' + (o ? 'light' : 'dark')
    );
  }
  _getIconSize(t) {
    return 'graphic' === t ? 40 : 16;
  }
  _hasIcon(t, e) {
    return (
      'none' !== t &&
      'custom' !== t &&
      'text' !== e &&
      !e.includes('inline-text')
    );
  }
  _setFirstChildClass() {
    const t = [].slice
      .call(this.parentNode.childNodes)
      .find(t => 'EA-CTA' === t.nodeName);
    t &&
      !t.classList.contains('eapl-cta-first') &&
      t.classList.add('eapl-cta-first');
  }
  _showImage(t) {
    return 'image' === t || 'standard-image' === t;
  }
  _showCtaLabel(t, e) {
    return t && !this._showImage(e) && 'inline-graphic' !== e;
  }
  _getDeviceOS() {
    const t = window.navigator.userAgent;
    let e = '';
    return (
      /iPad|iPhone|iPod/.test(t)
        ? (e = 'ios')
        : /Android/.test(t) && (e = 'android'),
      e
    );
  }
  _hideCta(t) {
    const e = this._getDeviceOS();
    t && 'all' !== t && e && t !== e && (this.hidden = !0);
  }
  _setCustomIcon(t) {
    if (!t || 'custom' !== this.icon) return;
    const e = document.createElement('span'),
      a = this.shadowRoot.querySelector('.eapl-cta__liner');
    e.innerHTML = t;
    const o = e.firstChild;
    o.setAttribute('class', this._getIconClass('custom')),
      a.insertBefore(o, a.firstChild);
  }
  _setGaEvent() {
    let t =
      arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null;
    (this.gaEventName = '/ga/nds/cta'),
      (this.gaEventPayload = {
        context: t,
        intent: this.intent,
        text: this.linkText,
        url: this.linkUrl || this.modalId,
      });
  }
  _hideLinkText() {
    !this.linkText && this.$.Label && (this.$.Label.hidden = !0);
  }
  _isType(t, e) {
    return t === e;
  }
  _setCustomMargin() {
    if (this.marginLeft && this.marginRight) {
      const t = Object.assign(
        {},
        {
          '--ea-cta-margin-left': this.marginLeft,
        },
        {
          '--ea-cta-margin-right': this.marginRight,
        }
      );
      this.updateStyles(t);
    }
  }
  _setCustomColor() {
    this.customTextColor &&
      this.updateStyles({
        '--ea-cta-custom-text-color': this.customTextColor,
      });
  }
  _handleFocus() {
    this._focused = !0;
  }
  _handleBlur() {
    this._focused = !1;
  }
  _isCtaInText() {
    ('inline-text' !== this.type &&
      'inline-graphic' !== this.type &&
      'inline-text-graphic-left' !== this.type &&
      'inline-text-graphic-right' !== this.type) ||
      (this.ctaInText = !0);
  }
  ready() {
    super.ready(), this._setCustomIcon(this.customIcon);
  }
  connectedCallback() {
    super.connectedCallback(),
      this._setGaEvent(),
      this._setFirstChildClass(),
      this._hideLinkText(),
      this._setCustomMargin(),
      this._setCustomColor(),
      this._isCtaInText(),
      this._setInlineIconPosition();
  }
}

customElements.define('cs-cta', CsCta);
