import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-details-table` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsDetailsTable extends PolymerElement {
	static get properties() {
		return {
			_details: {
				type: Array,
				value: () => [],
			},
			spacingTop: {
				type: String,
				value: "none",
				reflectToAttribute: !0,
			},
			spacingBottom: {
				type: String,
				value: "none",
				reflectToAttribute: !0,
			},
			theme: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			rowLayout: {
				type: String,
				value: "side-by-side",
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-base-headings"></style>
			<style include="cs-shared-styles-typography-paragraphs"></style>
			<style include="cs-shared-styles-typography-headings"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style include="cs-shared-styles-base-links"></style>
			<style include="cs-shared-styles-base-badge"></style>
			<style>
				@media (min-width: 768px) {
					:host {
						border-collapse: collapse;
						border-spacing: 0;
						display: table;
					}
					:host([row-layout="stacked"]) {
						display: block;
					}
				}
				:host([theme="dark"]) {
					color: var(--cs-dark-color);
				}
				:host([theme="light"]) {
					color: var(--cs-light-color);
				}
				.cspl-details-table__row {
					margin-bottom: 16px;
				}
				@media (min-width: 768px) {
					.cspl-details-table__row {
						border-bottom-style: solid;
						border-bottom-width: 1px;
						display: table-row;
						margin-bottom: 0;
					}
					:host([row-layout="stacked"]) .cspl-details-table__row {
						border: none;
						display: block;
						margin-bottom: 16px;
					}
				}
				:host([theme="dark"]) .cspl-details-table__row {
					border-bottom-color: #eaeaea;
				}
				:host([theme="light"]) .cspl-details-table__row {
					border-bottom-color: #585858;
				}
				@media (min-width: 768px) {
					.cspl-details-table__cell {
						display: table-cell;
						padding: 4px 24px 4px 0;
					}
					:host([row-layout="stacked"]) .cspl-details-table__cell {
						display: block;
						padding: 0;
					}
				}
				.cspl-details-table__cell--label {
					font-weight: 700;
				}
				@media (min-width: 768px) {
					.cspl-details-table__cell--label {
						white-space: nowrap;
					}
				}
			</style>
			<template is="dom-repeat" items="[[ _details ]]" as="detail">
				<div class="cspl-details-table__row">
					<div class="cspl-details-table__cell cspl-details-table__cell--label">[[ detail.label ]]</div>
					<div class="cspl-details-table__cell cspl-details-table__cell--text" inner-h-t-m-l="[[ detail.text ]]"></div>
				</div>
			</template>`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}

	_getDetails() {
		var e = [];
		return (
			this.querySelectorAll("cs-details-table-row").forEach((t) => {
				var i = t.querySelector("[label]"),
					l = t.querySelector("[text]");
				e.push({
					label: i && i.innerText,
					text: l && l.innerHTML,
				});
			}),
			e
		);
	}
	connectedCallback() {
		super.connectedCallback(),
			setTimeout(() => {
				this._details = this._getDetails();
			}, 0);
	}
}

customElements.define("cs-details-table", CsDetailsTable);
