import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';

import { CsHasImageMixin } from './mixins/cs-has-image-mixin.js';

import { CsMixin } from './mixins/cs-mixin.js';

import { CsCtaParentMixin } from './mixins/cs-cta-parent-mixin.js';

import { CsMoreIndicatorParentMixin } from './mixins/cs-more-indicator-parent-mixin.js';

import { CsDeviceMixin } from './mixins/cs-device-mixin.js';

import './shared-styles/cs-shared-styles-animations-base.js';

window.nds = window.nds || {};
var e = window.nds;
const t = CsMixin,
  a = e.getSlottedChildren,
  r = CsHasImageMixin,
  n = CsCtaParentMixin,
  o = CsMoreIndicatorParentMixin,
  i = CsDeviceMixin;

/**
 * `cs-hero` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsHero extends t(r(n(o(i(PolymerElement))))) {
  static get properties() {
    return {
      layout: {
        type: String,
        value: 'large',
        reflectToAttribute: !0,
      },
      textPosition: {
        type: String,
        value: 'center',
        reflectToAttribute: !0,
      },
      textAlign: {
        type: String,
        value: 'center',
        reflectToAttribute: !0,
      },
      textColor: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
      slash: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      backgroundColor: {
        type: String,
        value: 'brand',
        reflectToAttribute: !0,
      },
      imageSmallest: String,
      imageSmall: String,
      imageMedium: String,
      imageLarge: String,
      imageLargest: String,
      imageTooltip: String,
      logoImage: String,
      logoImageSmallest: String,
      logoImageSmall: String,
      logoImageMedium: String,
      logoImageLarge: String,
      logoImageLargest: String,
      logoImageTooltip: String,
      logoTooltip: String,
      eyebrowText: {
        type: String,
        value: '',
      },
      titleText: {
        type: String,
        value: '',
      },
      subtitleText: {
        type: String,
        value: '',
      },
      spacingBottom: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      spacingTop: {
        type: String,
        value: 'none',
        reflectToAttribute: !0,
      },
      scrimColor: {
        type: String,
        value: 'none',
        reflectToAttribute: !0,
      },
      scrimOpacity: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      stacked: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      mgSmallest: String,
      mgSmall: String,
      mgMedium: String,
      mgLarge: String,
      mgLargest: String,
      mgTooltip: String,
      fgSmallest: String,
      fgSmall: String,
      fgMedium: String,
      fgLarge: String,
      fgLargest: String,
      fgTooltip: String,
      copyText: {
        type: String,
        value: '',
      },
      customBackgroundColor: String,
      showVideoOnAllBreakpoints: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      _html5Video: {
        type: Array,
        value: () => [],
      },
      _heroAccolades: {
        type: Array,
        value: () => [],
      },
      _countdownClocks: {
        type: Array,
        value: () => [],
      },
      context: {
        type: String,
        value: 'cs-hero',
      },
      leftImageSmallest: String,
      leftImageSmall: String,
      leftImageMedium: String,
      leftImageLarge: String,
      leftImageLargest: String,
      leftImageTooltip: String,
      rightImageSmallest: String,
      rightImageSmall: String,
      rightImageMedium: String,
      rightImageLarge: String,
      rightImageLargest: String,
      rightImageTooltip: String,
      rightImageActive: {
        type: Boolean,
        reflectToAttribute: !0,
      },
      leftImageActive: {
        type: Boolean,
        reflectToAttribute: !0,
      },
      transition: Boolean,
      transitionOut: {
        type: Boolean,
        readOnly: !0,
        reflectToAttribute: !0,
      },
      logoSpriteFrames: {
        type: Number,
        value: 0,
      },
      logoSpriteSpeed: {
        type: Number,
        value: 300,
      },
      customStyles: {
        type: Object,
        value: () => ({}),
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-base-headings"></style>
      <style include="cs-shared-styles-base-images"></style>
      <style include="cs-shared-styles-typography-headings"></style>
      <style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-shared-styles-common-spacing"></style>
      <style include="cs-cta-shared-styles"></style>
      <style include="cs-shared-styles-animations-keyframes"></style>
      <style include="cs-shared-styles-animations-base"></style>
      <style>
        @keyframes fadein {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        :host {
          display: block;
          overflow: hidden;
          position: relative;
        }

        :host([transition]) {
          animation: fadein 0.5s;
        }

        :host([transition-out]) {
          transition: 0.5s;
          transition-property: opacity, visibility;
        }

        :host([transition-out]) {
          opacity: 0;
          visibility: hidden;
        }

        :host([background-color='dark'][stacked]) {
          background-color: var(--cs-dark-color);
        }

        :host([background-color='light'][stacked]) {
          background-color: var(--cs-light-color);
        }

        :host([background-color='brand'][stacked]) {
          background: linear-gradient(
            -83deg,
            var(--cs-theme-light-gradient),
            var(--cs-theme-dark-gradient)
          );
        }

        :host([custom-background-color][stacked]) {
          background-color: var(--custom-background-color);
        }

        :host([slash]) {
          height: 0;
          left: 0;
          position: relative;
          top: 0;
          width: 100%;
        }

        :host([slash]) {
          padding-bottom: 166.66%;
        }

        @media only screen and (min-width: 768px) {
          :host([layout='medium'][slash]) {
            padding-bottom: 50%;
          }
        }

        @media only screen and (min-width: 1024px) {
          :host([layout='medium'][slash]) {
            padding-bottom: 40%;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([layout='medium'][slash]) {
            padding-bottom: 28.57%;
          }
        }

        @media only screen and (min-width: 768px) {
          :host([layout='large'][slash]),
          :host([layout='xlarge'][slash]) {
            padding-bottom: 66.667%;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([layout='large'][slash]),
          :host([layout='xlarge'][slash]) {
            padding-bottom: 56.25%;
          }
        }

        :host([slash]) .cspl-hero__ratio {
          overflow: hidden;
          position: relative;
          transform: skewY(-5deg);
          transform-origin: left top;
        }

        .cspl-hero__ratio {
          padding-bottom: 166.66%;
        }

        :host([background-color='dark']:not([image-largest]))
          .cspl-hero__ratio {
          background-color: var(--cs-dark-color);
        }

        :host([background-color='light']:not([image-largest]))
          .cspl-hero__ratio {
          background-color: var(--cs-light-color);
        }

        :host([background-color='brand']:not([image-largest]))
          .cspl-hero__ratio {
          background: linear-gradient(
            -83deg,
            var(--cs-theme-light-gradient),
            var(--cs-theme-dark-gradient)
          );
        }

        :host([custom-background-color]:not([image-largest]))
          .cspl-hero__ratio {
          background: var(--custom-background-color);
        }

        :host([scrim-color='custom']) .cspl-hero__ratio::after,
        :host([scrim-color='dark']) .cspl-hero__ratio::after,
        :host([scrim-color='light']) .cspl-hero__ratio::after {
          content: '';
          display: block;
          height: 100%;
          left: 0;
          opacity: 0.3;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 1;
        }

        :host([scrim-color='dark']) .cspl-hero__ratio::after {
          background-color: #000;
        }

        :host([scrim-color='light']) .cspl-hero__ratio::after {
          background-color: #fff;
        }

        :host([custom-background-color]) .cspl-hero__ratio::after {
          background-color: var(--custom-background-color);
        }

        :host([scrim-opacity='low']) .cspl-hero__ratio::after {
          opacity: 0.15;
        }

        :host([scrim-opacity='high']) .cspl-hero__ratio::after {
          opacity: 0.45;
        }

        :host([scrim-color='dark']) .cspl-hero__ratio::after,
        :host([scrim-color='light']) .cspl-hero__ratio::after {
          content: '';
          display: block;
          height: 100%;
          left: 0;
          opacity: 0.3;
          position: absolute;
          top: 0;
          width: 100%;
        }

        :host([slash]) .cspl-hero__image {
          backface-visibility: hidden;
          transform: skewY(5deg);
          transform-origin: left top;
        }

        .cspl-hero__image--left,
        .cspl-hero__image--right {
          opacity: 0;
          transition: opacity 0.2s linear, visibility 0s 0.2s;
          visibility: hidden;
          z-index: 2;
        }

        :host([left-image-active]) .cspl-hero__image--left,
        :host([right-image-active]) .cspl-hero__image--right {
          opacity: 1;
          transition-delay: 0s;
          visibility: visible;
        }

        :host([layout='medium'][stacked]) .cspl-hero__ratio {
          padding-bottom: 56.25%;
        }

        :host([layout='large'][stacked]) .cspl-hero__ratio,
        :host([layout='xlarge'][stacked]) .cspl-hero__ratio {
          padding-bottom: 100%;
        }

        @media only screen and (min-width: 768px) {
          :host([layout='medium']) .cspl-hero__ratio,
          :host([layout='medium'][stacked]) .cspl-hero__ratio {
            padding-bottom: 50%;
          }
        }

        @media only screen and (min-width: 1024px) {
          :host([layout='medium']) .cspl-hero__ratio,
          :host([layout='medium'][stacked]) .cspl-hero__ratio {
            padding-bottom: 40%;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([layout='medium']) .cspl-hero__ratio,
          :host([layout='medium'][stacked]) .cspl-hero__ratio {
            padding-bottom: 28.57%;
          }
        }

        @media only screen and (min-width: 768px) {
          :host([layout='large']) .cspl-hero__ratio,
          :host([layout='large'][stacked]) .cspl-hero__ratio,
          :host([layout='xlarge']) .cspl-hero__ratio,
          :host([layout='xlarge'][stacked]) .cspl-hero__ratio {
            padding-bottom: 66.667%;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([layout='large']) .cspl-hero__ratio,
          :host([layout='large'][stacked]) .cspl-hero__ratio,
          :host([layout='xlarge']) .cspl-hero__ratio,
          :host([layout='xlarge'][stacked]) .cspl-hero__ratio {
            padding-bottom: 56.25%;
          }
        }

        .cspl-hero__image {
          display: block;
          left: 0;
          line-height: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        .cspl-hero_video {
          display: none;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        :host([show-video-on-all-breakpoints]) .cspl-hero_video {
          display: block;
        }

        @media only screen and (min-width: 1024px) {
          .cspl-hero_video {
            display: block;
          }
        }

        .cspl-hero__content {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          left: 0;
          padding: 32px var(--cs-rail-mobile);
          position: absolute;
          text-align: center;
          text-shadow: var(--cs-theme-hero-text-shadow);
          top: 0;
          width: 100%;
          z-index: 4;
        }

        :host([text-align='left']) .cspl-hero__content {
          align-items: flex-start;
          text-align: left;
        }

        :host([rtl][text-align='left']) .cspl-hero__content {
          text-align: right;
        }

        :host([text-align='right']) .cspl-hero__content {
          align-items: flex-end;
          text-align: right;
        }

        :host([rtl][text-align='right']) .cspl-hero__content {
          text-align: left;
        }

        @media only screen and (min-width: 768px) {
          .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-portrait,
              calc(
                var(--cs-rail-tablet-portrait) + 2 *
                  var(--cs-column-tablet-portrait-with-rail) + 2 *
                  var(--cs-gutter-tablet-portrait)
              )
            );
            padding-right: var(
              --cs-hero-content-padding-right-portrait,
              calc(
                var(--cs-rail-tablet-portrait) + 2 *
                  var(--cs-column-tablet-portrait-with-rail) + 2 *
                  var(--cs-gutter-tablet-portrait)
              )
            );
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-landscape,
              calc(
                var(--cs-rail-tablet-landscape) + 2 *
                  var(--cs-column-tablet-landscape-with-rail) + 2 *
                  var(--cs-gutter-tablet-landscape)
              )
            );
            padding-right: var(
              --cs-hero-content-padding-right-landscape,
              calc(
                var(--cs-rail-tablet-landscape) + 2 *
                  var(--cs-column-tablet-landscape-with-rail) + 2 *
                  var(--cs-gutter-tablet-landscape)
              )
            );
            padding-top: 36px;
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-desktop,
              calc(
                var(--cs-rail-desktop) + 2 * var(--cs-column-desktop-with-rail) +
                  2 * var(--cs-gutter-desktop)
              )
            );
            padding-right: var(
              --cs-hero-content-padding-right-desktop,
              calc(
                var(--cs-rail-desktop) + 2 * var(--cs-column-desktop-with-rail) +
                  2 * var(--cs-gutter-desktop)
              )
            );
            padding-top: 40px;
          }
        }

        @media only screen and (min-width: 768px) {
          :host([text-position='left']) .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-portrait,
              var(--cs-rail-tablet-portrait)
            );
            padding-right: var(
              --cs-hero-content-padding-right-portrait,
              calc(
                var(--cs-rail-tablet-portrait) + 6 *
                  var(--cs-column-tablet-portrait-with-rail) + 6 *
                  var(--cs-gutter-tablet-portrait)
              )
            );
          }
        }

        @media only screen and (min-width: 1024px) {
          :host([text-position='left']) .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-landscape,
              var(--cs-rail-tablet-landscape)
            );
            padding-right: var(
              --cs-hero-content-padding-right-landscape,
              calc(
                var(--cs-rail-tablet-landscape) + 6 *
                  var(--cs-column-tablet-landscape-with-rail) + 6 *
                  var(--cs-gutter-tablet-landscape)
              )
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([text-position='left']) .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-desktop,
              var(--cs-rail-desktop)
            );
            padding-right: var(
              --cs-hero-content-padding-right-desktop,
              calc(
                var(--cs-rail-desktop) + 6 * var(--cs-column-desktop-with-rail) +
                  6 * var(--cs-gutter-desktop)
              )
            );
          }
        }

        @media only screen and (min-width: 768px) {
          :host([text-position='right']) .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-portrait,
              calc(
                var(--cs-rail-tablet-portrait) + 6 *
                  var(--cs-column-tablet-portrait-with-rail) + 6 *
                  var(--cs-gutter-tablet-portrait)
              )
            );
            padding-right: var(
              --cs-hero-content-padding-right-portrait,
              var(--cs-rail-tablet-portrait)
            );
          }
        }

        @media only screen and (min-width: 1024px) {
          :host([text-position='right']) .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-landscape,
              calc(
                var(--cs-rail-tablet-landscape) + 6 *
                  var(--cs-column-tablet-landscape-with-rail) + 6 *
                  var(--cs-gutter-tablet-landscape)
              )
            );
            padding-right: var(
              --cs-hero-content-padding-right-landscape,
              var(--cs-rail-tablet-landscape)
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([text-position='right']) .cspl-hero__content {
            padding-left: var(
              --cs-hero-content-padding-left-desktop,
              calc(
                var(--cs-rail-desktop) + 6 * var(--cs-column-desktop-with-rail) +
                  6 * var(--cs-gutter-desktop)
              )
            );
            padding-right: var(
              --cs-hero-content-padding-right-desktop,
              var(--cs-rail-desktop)
            );
          }
        }

        .cspl-hero__eyebrow {
          margin: 0;
          width: 100%;
        }

        .cspl-hero__logo-sprite {
          transform: scale(0.4);
        }

        @media only screen and (min-width: 768px) {
          .cspl-hero__logo-sprite {
            transform: scale(0.6);
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-hero__logo-sprite {
            transform: scale(0.8);
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-hero__logo-sprite {
            transform: scale(1);
          }
        }

        .cspl-hero__title {
          margin: 0;
          width: 100%;
        }

        .cspl-hero__body {
          margin: 0;
          width: 100%;
        }

        .cspl-hero__ctas {
          width: 100%;
          padding-top: 16px;
        }

        .cspl-hero__ratio--fade {
          display: block;
          height: 100%;
          width: 100%;
          z-index: 1;
        }

        .cspl-hero__ratio--fade::before {
          background: linear-gradient(
            90deg,
            var(--custom-background-color),
            transparent
          );
          content: '';
          height: 100%;
          opacity: 1;
          position: absolute;
          right: 2240px;
          top: 0;
          width: 320px;
          z-index: 3;
        }

        .cspl-hero__ratio--fade::after {
          background: linear-gradient(
            90deg,
            transparent,
            var(--custom-background-color)
          );
          content: '';
          height: 100%;
          left: 2240px;
          opacity: 1;
          position: absolute;
          top: 0;
          width: 320px;
          z-index: 3;
        }

        .cspl-hero__bg {
          z-index: 1;
        }

        @media only screen and (min-width: 768px) {
          .cspl-hero__bg {
            width: 100%;
          }
        }

        :host([stacked][layout='medium']) .cspl-hero__bg {
          width: 100%;
        }

        .cspl-hero__mg {
          display: none;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;
        }

        @media only screen and (min-width: 768px) {
          .cspl-hero__mg {
            display: block;
          }
        }

        .cspl-hero__fg {
          display: none;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 75%;
          z-index: 3;
        }

        @media only screen and (min-width: 768px) {
          .cspl-hero__fg {
            display: block;
          }
        }

        :host([text-position='left']) .cspl-hero__fg {
          left: 75%;
        }

        :host([text-position='right']) .cspl-hero__fg {
          left: 25%;
        }

        :host([stacked]) .cspl-hero__content {
          position: relative;
        }

        @media only screen and (min-width: 768px) {
          .cspl-hero__content,
          :host([stacked]) .cspl-hero__content {
            position: absolute;
          }
        }

        .cspl-hero__logo {
          flex-shrink: 0;
          max-width: 100%;
        }

        :host([left-image-active]) .cspl-hero__logo,
        :host([right-image-active]) .cspl-hero__logo {
          opacity: 0;
        }

        .cspl-hero__accolades {
          width: 100%;
        }

        .cspl-hero__subtitle {
          margin: 0;
          width: 100%;
        }

        :host([left-image-active]) .cspl-hero__subtitle,
        :host([right-image-active]) .cspl-hero__subtitle {
          opacity: 0;
        }

        @media only screen and (max-width: 1023px) {
          .cspl-hero__ctas ::slotted(cs-cta[type='arrow-left']),
          .cspl-hero__ctas ::slotted(cs-cta[type='arrow-right']) {
            margin-top: 40px;
            top: auto;
            transform: translateY(0);
          }
        }

        .cspl-hero__countdown-clock {
          width: 100%;
        }

        .cspl-hero__more-indicator {
          bottom: 0;
          padding-top: 0;
          position: absolute;
          width: 100%;
        }

        :host([slash]) .cspl-hero__more-indicator {
          position: static;
        }

        @media only screen and (min-width: 1024px) {
          .cspl-hero__more-indicator {
            padding-left: calc(
              var(--cs-rail-tablet-landscape) +
                var(--cs-column-tablet-landscape-with-rail) +
                var(--cs-gutter-tablet-landscape)
            );
            padding-right: calc(
              var(--cs-rail-tablet-landscape) +
                var(--cs-column-tablet-landscape-with-rail) +
                var(--cs-gutter-tablet-landscape)
            );
            padding-top: 36px;
          }

          :host([text-position='left']) .cspl-hero__more-indicator,
          :host([text-position='right']) .cspl-hero__more-indicator {
            padding-left: var(--cs-rail-tablet-landscape);
            padding-right: var(--cs-rail-tablet-landscape);
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-hero__more-indicator {
            padding-left: calc(
              var(--cs-rail-desktop) + var(--cs-column-desktop-with-rail) +
                var(--cs-gutter-desktop)
            );
            padding-right: calc(
              var(--cs-rail-desktop) + var(--cs-column-desktop-with-rail) +
                var(--cs-gutter-desktop)
            );
            padding-top: 40px;
          }

          :host([text-position='left']) .cspl-hero__more-indicator,
          :host([text-position='right']) .cspl-hero__more-indicator {
            padding-left: var(--cs-rail-desktop);
            padding-right: var(--cs-rail-desktop);
          }
        }
      </style>
      <div class="cspl-hero__ratio">
        <template is="dom-if" if="[[ customBackgroundColor ]]">
          <div class="cspl-hero__ratio--fade"></div>
        </template>
        <template
          is="dom-if"
          if="[[
                            _hasImage(leftImageSmallest, leftImageSmall, leftImageMedium, leftImageLarge, leftImageLargest)
                        ]]"
        >
          <picture>
            <source
              media="(max-width: 320px)"
              srcset$="[[ leftImageSmallest ]]"
            />
            <source media="(max-width: 767px)" srcset$="[[ leftImageSmall ]]" />
            <source
              media="(max-width: 1023px)"
              srcset$="[[ leftImageMedium ]]"
            />
            <source
              media="(max-width: 1455px)"
              srcset$="[[ leftImageLarge ]]"
            />
            <source
              media="(min-width: 1456px)"
              srcset$="[[ leftImageLargest ]]"
            />
            <img
              class="cspl-hero__image cspl-hero__image--left"
              src="[[ leftImageSmallest ]]"
              alt="[[ leftImageTooltip ]]"
            />
          </picture>
        </template>
        <template
          is="dom-if"
          if="[[ _hasImage(imageSmallest, imageSmall, imageMedium, imageLarge, imageLargest) ]]"
        >
          <picture>
            <source media="(max-width: 320px)" srcset$="[[ imageSmallest ]]" />
            <source media="(max-width: 767px)" srcset$="[[ imageSmall ]]" />
            <source media="(max-width: 1023px)" srcset$="[[ imageMedium ]]" />
            <source media="(max-width: 1455px)" srcset$="[[ imageLarge ]]" />
            <source media="(min-width: 1456px)" srcset$="[[ imageLargest ]]" />
            <img
              class="cspl-hero__image cspl-animate__bg"
              src="[[ imageSmallest ]]"
              alt="[[ imageTooltip ]]"
              animate-enter-off$="[[
                                    _styleEquals('--cs-animation-enter-background-keyframe-name', 'none')
                                ]]"
            />
          </picture>
        </template>
        <template
          is="dom-if"
          if="[[
                            _hasImage(
                                rightImageSmallest, rightImageSmall, rightImageMedium, rightImageLarge, rightImageLargest
                            )
                        ]]"
        >
          <picture>
            <source
              media="(max-width: 320px)"
              srcset$="[[ rightImageSmallest ]]"
            />
            <source
              media="(max-width: 767px)"
              srcset$="[[ rightImageSmall ]]"
            />
            <source
              media="(max-width: 1023px)"
              srcset$="[[ rightImageMedium ]]"
            />
            <source
              media="(max-width: 1455px)"
              srcset$="[[ rightImageLarge ]]"
            />
            <source
              media="(min-width: 1456px)"
              srcset$="[[ rightImageLargest ]]"
            />
            <img
              class="cspl-hero__image cspl-hero__image--right"
              src="[[ rightImageSmallest ]]"
              alt="[[ rightImageTooltip ]]"
            />
          </picture>
        </template>
        <template
          is="dom-if"
          if="[[ _hasImage(mgSmallest, mgSmall, mgMedium, mgLarge, mgLargest) ]]"
        >
          <picture>
            <source media="(max-width: 320px)" srcset$="[[ mgSmallest ]]" />
            <source media="(max-width: 767px)" srcset$="[[ mgSmall ]]" />
            <source media="(max-width: 1023px)" srcset$="[[ mgMedium ]]" />
            <source media="(max-width: 1455px)" srcset$="[[ mgLarge ]]" />
            <source media="(min-width: 1456px)" srcset$="[[ mgLargest ]]" />
            <img
              class="cspl-hero__image cspl-hero__mg cspl-animate__mg"
              src="[[ mgSmallest ]]"
              alt="[[ mgTooltip ]]"
              animate-enter-off$="[[ _isMiddlegroundEnterAnimationOff() ]]"
            />
          </picture>
        </template>
        <template
          is="dom-if"
          if="[[ _hasImage(fgSmallest, fgSmall, fgMedium, fgLarge, fgLargest) ]]"
        >
          <picture>
            <source media="(max-width: 320px)" srcset$="[[ fgSmallest ]]" />
            <source media="(max-width: 767px)" srcset$="[[ fgSmall ]]" />
            <source media="(max-width: 1023px)" srcset$="[[ fgMedium ]]" />
            <source media="(max-width: 1919px)" srcset$="[[ fgLarge ]]" />
            <source media="(min-width: 1920px)" srcset$="[[ fgLargest ]]" />
            <img
              class="cspl-hero__image cspl-hero__fg cspl-animate__fg"
              src="[[ fgSmallest ]]"
              alt="[[ fgTooltip ]]"
              animate-enter-off$="[[ _isForegroundEnterAnimationOff() ]]"
            />
          </picture>
        </template>
        <div class="cspl-hero_video" hidden$="[[ _hideChildren(_html5Video) ]]">
          <slot name="html5-video"></slot>
        </div>
      </div>
      <div
        class="cspl-hero__content cspl-animate__content"
        animate-enter-off$="[[ _styleEquals('--cs-animation-enter-content-keyframe-name', 'none') ]]"
      >
        <div
          class="cspl-hero__accolades"
          hidden$="[[ _hideChildren(_heroAccolades) ]]"
        >
          <slot name="hero-accolades"></slot>
        </div>
        <h3
          hidden$="[[ !eyebrowText ]]"
          class$="[[ _getEyebrowClass(textColor, layout) ]]"
          animate-enter-off$="[[ _styleEquals('--cs-animation-enter-eyebrow-keyframe-name', 'none') ]]"
        >
          [[ eyebrowText ]]
        </h3>
        <template is="dom-if" if="[[logoImage]]">
          <template is="dom-if" if="[[ !logoSpriteFrames ]]">
            <img
              class="cspl-hero__logo"
              src="{{ logoImage }}"
              alt="{{ logoTooltip }}"
            />
          </template>
          <template is="dom-if" if="[[ logoSpriteFrames ]]">
            <div
              id="logoSpriteContainer"
              class="cspl-hero__logo-sprite"
              style$="background-image: url([[ logoImage ]])"
            ></div>
          </template>
        </template>
        <template
          is="dom-if"
          if="[[
                            _hasImage(logoImageSmallest, logoImageSmall, logoImageMedium, logoImageLarge, logoImageLargest)
                        ]]"
        >
          <picture>
            <source
              media="(max-width: 320px)"
              srcset$="[[ logoImageSmallest ]]"
            />
            <source media="(max-width: 767px)" srcset$="[[ logoImageSmall ]]" />
            <source
              media="(max-width: 1023px)"
              srcset$="[[ logoImageMedium ]]"
            />
            <source
              media="(max-width: 1455px)"
              srcset$="[[ logoImageLarge ]]"
            />
            <source
              media="(min-width: 1456px)"
              srcset$="[[ logoImageLargest ]]"
            />
            <img
              class="cspl-hero__logo"
              src="[[ logoImageSmallest ]]"
              alt="[[ logoImageTooltip ]]"
            />
          </picture>
        </template>
        <h1
          hidden$="[[ !titleText ]]"
          class$="[[ _getTitleClass(textColor, layout) ]]"
          animate-enter-off$="[[ _styleEquals('--cs-animation-enter-title-keyframe-name', 'none') ]]"
        >
          [[ titleText ]]
        </h1>
        <h2
          hidden$="[[ !subtitleText ]]"
          class$="[[ _getSubtitleClass(textColor, layout) ]]"
          animate-enter-off$="[[ _styleEquals('--cs-animation-enter-subtitle-keyframe-name', 'none') ]]"
        >
          [[ subtitleText ]]
        </h2>
        <h6
          hidden$="[[ !copyText ]]"
          class="cspl-hero__body b2 cspl-animate__body"
          animate-enter-off$="[[ _styleEquals('--cs-animation-enter-body-keyframe-name', 'none') ]]"
          inner-h-t-m-l="[[ copyText ]]"
        ></h6>
        <div
          class="cspl-hero__ctas ctas cspl-animate__ctas"
          hidden$="[[ _hideCtas ]]"
          animate-enter-off$="[[ _styleEquals('--cs-animation-enter-ctas-keyframe-name', 'none') ]]"
        >
          <slot name="cta"></slot>
        </div>
        <slot name="powered"></slot>
        <div
          class="cspl-hero__countdown-clock"
          hidden$="[[ _hideChildren(_countdownClocks) ]]"
        >
          <slot name="countdown-clock"></slot>
        </div>
      </div>
      <div
        class="cspl-hero__more-indicator"
        hidden$="[[ _hideMoreIndicators ]]"
      >
        <slot name="more-indicator"></slot>
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super(),
      (this._arrowEnter = this._arrowEnter.bind(this)),
      (this._arrowLeave = this._arrowLeave.bind(this)),
      (this._arrowClick = this._arrowClick.bind(this)); // jshint ignore:line
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  _getEyebrowClass(e, t) {
    let a;
    return (
      (a =
        t === 'medium'
          ? 'h6'
          : t === 'large'
          ? 'h4'
          : t === 'xlarge'
          ? 'h4'
          : 'h4'),
      `cspl-hero__eyebrow ${a} ${a}-${e}`
    );
  }
  _getTitleClass(e, t) {
    let a;
    return (
      (a =
        t === 'medium'
          ? 'd4'
          : t === 'large'
          ? 'd3'
          : t === 'xlarge'
          ? 'd1'
          : 'd2'),
      `cspl-hero__title ${a} ${a}-${e}`
    );
  }
  _getSubtitleClass(e, t) {
    let a;
    return (
      (a =
        t === 'medium'
          ? 'h6'
          : t === 'large'
          ? 'h4'
          : t === 'xlarge'
          ? 'h4'
          : 'h4'),
      `cspl-hero__subtitle ${a} ${a}-${e}`
    );
  }
  _hideChildren(e) {
    return !e.length;
  }
  _hasImage(...e) {
    return e.every(e => e && e.trim().length);
  }
  _hasVideo() {
    return [...arguments].every(e => e.trim().length);
  }
  _getHtml5VideoChildren() {
    this._html5Video = a(this, ['html5-video']);
  }
  _getHeroAccoladesChildren() {
    var e = window.nds;
    const a = e.getSlottedChildren;
    this._heroAccolades = a(this, ['hero-accolades']);
  }
  _getCountdownClockChildren() {
    var e = window.nds;
    const a = e.getSlottedChildren;
    this._countdownClocks = a(this, ['countdown-clock']);
  }
  _getChildren(e) {
    const t = e.target.getAttribute('name');
    switch (t) {
      case 'html5-video':
        this._getHtml5VideoChildren();
        break;
      case 'hero-accolades':
        this._getHeroAccoladesChildren();
        break;
      case 'countdown-clock':
        this._getCountdownClockChildren();
        break;
      default:
    }
  }
  _arrowEnter(e) {
    const t = e.target.getAttribute('type');
    t === 'arrow-left'
      ? (this.leftImageActive = !0)
      : t === 'arrow-right' && (this.rightImageActive = !0); // jshint ignore:line
  }
  _arrowLeave() {
    (this.leftImageActive = !1), (this.rightImageActive = !1); // jshint ignore:line
  }
  _arrowClick(e) {
    const t = e.target.getAttribute('link-url');
    e.preventDefault(),
      e.target.removeEventListener('mouseleave', this._arrowLeave),
      this.addEventListener('transitionend', () => {
        window.location.href = t;
      }),
      this._setTransitionOut(!0); // jshint ignore:line
  }
  _animateSprite() {
    if (!this.logoSpriteFrames) return;
    const e = new Image(); // jshint ignore:line
    let t = 1;
    let a, r, n;
    const o = () => {
      (n += r), (a.style.backgroundPosition = `${n}px`); // jshint ignore:line
    };
    const i = () => {
      setTimeout(() => {
        t < this.logoSpriteFrames && (t++, requestAnimationFrame(o), i()); // jshint ignore:line
      }, this.logoSpriteSpeed); // jshint ignore:line
    };
    const s = () => {
      a = this.shadowRoot.querySelector('#logoSpriteContainer');
      a &&
        ((r = e.width / this.logoSpriteFrames),
        (n = r),
        (a.style.width = `${r}px`),
        (a.style.height = `${e.height}px`),
        (a.style.backgroundPosition = `${n}px`),
        i()); // jshint ignore:line
    }; // jshint ignore:line
    (e.onload = setTimeout(s, this.logoSpriteSpeed)), (e.src = this.logoImage); // jshint ignore:line
  }
  _initArrowCtas() {
    var w = window.nds;
    const a = w.getSlottedChildren;
    const e = a(this, ['cta']).filter(
      e =>
        e.getAttribute('type') === 'arrow-left' ||
        e.getAttribute('type') === 'arrow-right'
    );
    e.forEach(e => {
      e.addEventListener('click', this._arrowClick);
      this.isTouch ||
        (e.addEventListener('mouseenter', this._arrowEnter),
        e.addEventListener('mouseleave', this._arrowLeave)); // jshint ignore:line
    });
  }
  _updateCustomStyles() {
    var e = this.customStyles,
      t = Object.assign(
        {},
        e.xl &&
          e.xl.paddingLeft && {
            '--cs-hero-content-padding-left-desktop': e.xl.paddingLeft,
          },
        e.xl &&
          e.xl.paddingRight && {
            '--cs-hero-content-padding-right-desktop': e.xl.paddingRight,
          },
        e.l &&
          e.l.paddingLeft && {
            '--cs-hero-content-padding-left-landscape': e.l.paddingLeft,
          },
        e.l &&
          e.l.paddingRight && {
            '--cs-hero-content-padding-right-landscape': e.l.paddingRight,
          },
        e.m &&
          e.l.paddingLeft && {
            '--cs-hero-content-padding-left-portrait': e.m.paddingLeft,
          },
        e.m &&
          e.l.paddingRight && {
            '--cs-hero-content-padding-right-portrait': e.m.paddingRight,
          }
      );
    this.updateStyles(t);
  }
  _setCustomBackgroundColor() {
    this.customBackgroundColor &&
      this.updateStyles({
        '--custom-background-color': this.customBackgroundColor,
      }); // jshint ignore:line
  }
  connectedCallback() {
    super.connectedCallback(),
      setTimeout(() => {
        this._initArrowCtas(), this._animateSprite(); // jshint ignore:line
      }),
      this._setCustomBackgroundColor(),
      this._getHtml5VideoChildren(),
      this._getHeroAccoladesChildren(),
      this._getCountdownClockChildren(),
      this.shadowRoot.addEventListener(
        'slotchange',
        this._getChildren.bind(this)
      ), // jshint ignore:line
      this._updateCustomStyles(); // jshint ignore:line
  }
}

customElements.define('cs-hero', CsHero);
