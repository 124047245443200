/* jshint ignore:start */
import { GestureEventListeners } from "@polymer/polymer/lib/mixins/gesture-event-listeners.js";
import * as Gestures from "@polymer/polymer/lib/utils/gestures.js";
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsHasImageMixin } from "./mixins/cs-has-image-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";

window.nds = window.nds || {};

const t = CsMixin,
	a = CsHasImageMixin;

/**
 * `cs-image` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsImage extends t(a(GestureEventListeners(PolymerElement))) {
	static get properties() {
		return {
			tooltip: String,
			smallest: String,
			small: String,
			medium: String,
			large: String,
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style>
				:host {
					display: block;
					height: 100%;
					width: 100%;
				}

				.cspl-image__ratio {
					line-height: 0;
					padding-top: 56.25%;
					position: relative;
				}

				img {
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			</style>
			<div class="cspl-image__ratio">
				<template is="dom-if" if="[[ _hasImage(smallest, small, medium, large) ]]">
					<img alt="[[ tooltip ]]" src="[[ smallest ]]" srcset$="[[ _getSrcset(smallest, small, medium, large) ]]" />
				</template>
			</div>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_getSrcset(e, t, a, r) {
		return `${e} 320w,${t} 768w,${a} 1024w,${r} 1120w`;
	}
	_handleOnTap() {
		this.dispatchEvent(
			new CustomEvent("nds.image.tapped", {
				bubbles: !0,
				composed: !0,
			})
		);
	}
	connectedCallback() {
		super.connectedCallback(), Gestures.addListener(this, "tap", this._handleOnTap); // jshint ignore:line
	}
}

customElements.define("cs-image", CsImage);
