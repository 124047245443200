import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

const e = window.nds.getSlottedChildren;
/**
 * CsCtaParentMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsCtaParentMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get observers() {
            return ["_setCTAContext(ctas, context)"];
        }
        static get properties() {
            return {
                context: String,
                hideCtas: {
                    type: Boolean,
                    value: !1
                },
                _hideCtas: {
                    type: Boolean,
                    value: !0,
                    computed: "_computeHideCtas(ctas, hideCtas)"
                },
                ctas: {
                    type: Array,
                    value() {
                        return [];
                    }
                }
            };
        }
        _computeHideCtas(e, t) {
            return !e.length || t;
        }
        _setCTAContext(e, t) {
            e.forEach((e) => {
                e.context = t;
            });
        }
        _getCtas() {
            this.ctas = e(this, ["cta"]);
        }
        connectedCallback() {
            super.connectedCallback(), setTimeout(this._getCtas.bind(this)), this.addEventListener("slotchange", this._getCtas.bind(this)); // jshint ignore:line
        }
    };

export const CsCtaParentMixin = dedupingMixin(internalCsCtaParentMixin);