import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";
import { afterNextRender } from "@polymer/polymer/lib/utils/render-status.js";
import './gold-cc-input.js';
import { validateCcInput } from "@polymer/gold-cc-input/cc-validator.js";
import "./cs-order-items.js";
import "./shop-select.js";
import "./shop-input.js";

/**
 * `cs-smart-link` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSmartLink extends PolymerElement {
	static get properties() {
		return {
			api: String,

			id: String,


			terms: String,

			countdown: String,

			message: Object,

			routeData: Object,

			loading: {
				type: Boolean,
				readOnly: true,
				value: false,
				reflectToAttribute: true,
			},

			route: {
				type: Object,
				notify: true,
			},

			state: {
				type: String,
				value: "init",
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style include="cs-shared-styles-common-catalog"></style>
			<style include="cs-shared-styles-base-headings"></style>
			<style include="iron-flex iron-flex-alignment iron-flex-factors"></style>
			<style include="shop-select shop-input">
				:host {
					display: block;
				}

				shop-input,
				shop-select {
					font-size: 16px;
				}

				a:active {
					color: var(--cs-theme-color);
				}

				.disclaimer {
					color: var(--cs-color-gray);
				}

				a {
					color: var(--cs-theme-color);
					text-decoration: none;
				}

				.d4 {
					margin: 0;
					padding: 0;
				}
				.cards {
					width: 50px;
					height: 35px;
				}

				.segure {
					width: 100px;
					height: 35px;
				}

				shop-select {
					margin-bottom: 20px;
				}

				.text--right {
					text-align: right;
				}

				.checkout-box {
					font-weight: bold;
					text-align: right;
					margin-right: 20px;
					padding: 10px 0;
					color: var(--cs-theme-color);
				}

				.paper-card {
					width: 100%;
					-webkit-border-radius: 8px;
					-moz-border-radius: 8px;
					border-radius: 8px;
					border: 0.5px solid var(--cs-color-gray);
					margin-bottom: 16px;
				}

				.subtotal {
					margin: 0 0 0 24px;
					font-size: 32px;
					font-weight: 300;
				}

				.row {
					@apply --layout-horizontal;
					@apply --layout-end;
				}

				.column {
					@apply --layout-vertical;
				}

				.help {
					display: block;
					color: var(--cs-color-gray);
				}

				.help-mobile {
					display: none;
					color: var(--cs-color-gray);
				}

				.row > .flex,
				.input-row > * {
					@apply --layout-flex;
				}

				.input-row > *:not(:first-child) {
					margin-left: 8px;
				}

				.shop-select-label {
					line-height: 20px;
				}

				.order-summary-row {
					line-height: 24px;
				}

				.total-row {
					font-weight: 500;
					margin: 30px 0;
				}

				paper-spinner-lite {
					--paper-spinner-color: var(--cs-theme-color);
				}

				.cspay-plan-select__back-button {
					border: 1px solid #000;
					border-radius: 5px;
					box-sizing: border-box;
					cursor: pointer;
					text-align: center;
					display: block;
					margin: 24px 0;
					padding: 12px 24px;
					font-size: var(--cspay-body-1_-_font-size);
					line-height: var(--cspay-body-1_-_line-height);
				}

				@media (max-width: 767px) {
					.paper-card {
						border: 0;
						margin: 0;
						padding: 0;
					}
					.card-content {
						border: 0;
						margin: 0;
						padding: 0;
					}
					.help {
						display: none;
					}

					.help-mobile {
						display: block;
					}
				}

				ol,
				ul {
					list-style: none;
					padding: 0;
				}
			</style>
			<app-location route="{{_route}}" path="{{path}}"></app-location>
			<app-route active="{{routeActive}}" data="{{routeData}}" route="[[route]]" pattern="/:state"> </app-route>
			<iron-ajax id="ajaxData" on-error="responseHandler" url="[[api]]/os/{{id}}" on-response="_handleResponseData"></iron-ajax>
			<div hidden$="[[loading]]">
				<iron-pages id="pages" selected="[[state]]" attr-for-selected="state">
					<div state="init">
						<cs-section
							slot="section"
							type="full-bleed"
							image-tooltip=""
							layout="100"
							spacing-top="large"
							spacing-bottom="small"
							background-color="none"
							background-overflow="hidden"
							background-image-smallest=""
							background-image-small=""
							background-image-medium=""
							background-image-large=""
							background-image-largest=""
						>
							<!-- start-full-bleed -->
							<cs-section-column slot="section-column" indent-left="" indent-right="" class="cspl-section-column cspl-section-column-100"> </cs-section-column>
							<!-- end-full-bleed -->
						</cs-section>
						<cs-section
							slot="section"
							type="railed"
							image-tooltip=""
							layout="100"
							spacing-top="large"
							text-align="left"
							spacing-bottom="none"
							background-color="none"
							background-overflow="none"
							hidden$="{{waiting}}"
						>
							<cs-section-column slot="section-column" indent-left="" indent-right="" class="cspl-section-column cspl-section-column-100">
								<cs-subheading
									type="ordered"
									layout="small"
									text-align="left"
									text-color="dark"
									spacing-top="medium"
									subtitle-text="{{data.destinationPerson.name}}"
									title-text="Pedido {{data.code}}"
									eyebrow-text="Data {{_formatDate(data.dueDate,'DD/MM/YYYY')}}"
									spacing-bottom="none"
								>
								</cs-subheading>
								<div class="subsection">
									<cs-text slot="text" text-color="dark">
										<div class="b2">Items</div>
									</cs-text>
									<div class="list">
										<dom-repeat items="{{_getFeaturedItem(data.orderProducts)}}">
											<template>
												<cs-order-items data="{{item}}"></cs-order-items>
											</template>
										</dom-repeat>
									</div>
									<div class="checkout-box">Total: <span class="subtotal">[[numberFormat(total)]]</span></div>
								</div>
								<a href="{{terms}}" target="_blank" class="cspay-plan-select__back-button"
									>Termos de uso e contrato de licença</a
								>
								<cs-text slot="text" text-color="dark">
									<div class="help">
										<h5>Você tem alguma dúvida?</h5>
										<ul>
											<li>
												<iron-icon icon="icons:perm-phone-msg"></iron-icon>
												<span class="display-text">Entre em contato: 67 3029-7657</span>
											</li>
											<li>
												<iron-icon icon="icons:event"></iron-icon>
												<span class="display-text">Segunda a sexta, das 9h às 17h</span>
											</li>
										</ul>
									</div>
								</cs-text>
							</cs-section-column>
							<cs-section-column
								slot="section-column"
								indent-left="1"
								indent-right="0"
								text-color="dark"
								text-align="left"
								class="cspl-section-column-100 cspl-section-column cspl-section-column-100"
							>
								<cs-text slot="text" text-align="left" text-color="dark">
									<paper-card elevation="0" animated-shadow="false" class="paper-card border" animated="" aria-label="">
										<div class="card-content">
											<div>Digite os dados do cartão e click em <strong>"Concluir compra"</strong> para finalizar.</div>
											<iron-form
												id="checkoutForm"
												headers="{{makeheaders()}}"
												on-iron-form-error="responseHandler"
												on-iron-form-response="_didReceiveResponse"
												on-iron-form-presubmit="_willSendRequest"
											>
												<form method="POST" action="[[api]]/close/smartlink" enctype="application/x-www-form-urlencoded">
													<input type="hidden" id="code" name="code" required value="{{id}}" />
													<div class="row input-row">
														<gold-cc-input
															id="ccNumber"
															name="ccNumber"
															auto-validate
															label="Número do Cartão"
															error-message="Digite um número de cartão válido!"
															card-type='["visa", "mastercard", "discover", "amex"]'
															required
															value="{{ccNumber}}"
															on-blur="_getCardType"
														>
														</gold-cc-input>
													</div>
													<div class="row input-row">
														<shop-input>
															<input
																type="text"
																id="ccName"
																name="ccName"
																pattern=".{3,}"
																placeholder="Nome do titular (como está no cartão)"
																required
																value="[[ccName]]"
																autocomplete="cc-name"
															/>
															<shop-md-decorator error-message="Invalid Cardholder Name" aria-hidden="true">
																<label for="ccName">Nome do titular (como está no cartão)</label>
																<shop-underline></shop-underline>
															</shop-md-decorator>
														</shop-input>
													</div>
													<input type="hidden" id="cardType" name="cardType" value="[[cardType]]" />
													<div class="row input-row">
														<div class="column">
															<label for="ccExpMonth">Data de Validade</label>
															<shop-select>
																<select id="ccExpMonth" name="ccExpMonth" required autocomplete="cc-exp-month" aria-label="Expiry month">
																	<option value="01" selected>Jan</option>
																	<option value="02">Fev</option>
																	<option value="03">Mar</option>
																	<option value="04">Abr</option>
																	<option value="05">Mai</option>
																	<option value="06">Jun</option>
																	<option value="07">Jul</option>
																	<option value="08">Ago</option>
																	<option value="09">Set</option>
																	<option value="10">Out</option>
																	<option value="11">Nov</option>
																	<option value="12">Dez</option>
																</select>
																<shop-md-decorator aria-hidden="true">
																	<shop-underline></shop-underline>
																</shop-md-decorator>
															</shop-select>
														</div>
														<shop-select>
															<select id="ccExpYear" name="ccExpYear" required autocomplete="cc-exp-year" aria-label="Expiry year">
																<option value="2020" selected>2020</option>
																<option value="2021">2021</option>
																<option value="2022">2022</option>
																<option value="2023">2023</option>
																<option value="2024">2024</option>
																<option value="2025">2025</option>
																<option value="2026">2026</option>
																<option value="2027">2027</option>
																<option value="2028">2028</option>
																<option value="2029">2029</option>
																<option value="2030">2030</option>
															</select>
															<shop-md-decorator aria-hidden="true">
																<shop-underline></shop-underline>
															</shop-md-decorator>
														</shop-select>
														<shop-input>
															<input
																type="number"
																id="ccCVV"
																pattern="[0-9]*"
																maxlength="4"
																name="ccCVV"
																placeholder="Código de Segurança"
																required
																value="[[ccCVV]]"
															/>
															<shop-md-decorator error-message="Invalid CVV" aria-hidden="true">
																<label for="ccCVV">Código de Segurança</label>
																<shop-underline></shop-underline>
															</shop-md-decorator>
														</shop-input>
													</div>
													<div hidden$="[[!_hasItems]]">
														<cs-cta
															id="submitButton"
															slot="cta"
															type="standard"
															link-text="Concluir compra"
															icon="none"
															fill-color="xbox-brand"
															text-color="light"
															stroke-color="none"
															intent="navigation"
															emphasis="secondary"
															on-tap="_submit"
															class="cspl-cta-first"
														>
														</cs-cta>
														<p class="disclaimer">
															Atenção! Ao efetuar o pagamento você está concordando com os termos de uso e contrato de licença da CRIUS Tecnologia.
														</p>
														<cs-text slot="text" text-color="dark">
															<div class="help-mobile">
																<h5 class="b2">Você tem alguma dúvida?</h5>
																<ul>
																	<li>
																		<iron-icon icon="icons:perm-phone-msg"></iron-icon>
																		<span class="display-text">Entre em contato: 67 3029-7657</span>
																	</li>
																	<li>
																		<iron-icon icon="icons:event"></iron-icon>
																		<span class="display-text">Segunda a sexta, das 9h às 17h</span>
																	</li>
																</ul>
															</div>
														</cs-text>
													</div>
													<div class="card-actions">
														<div class="horizontal layout wrap">
															<div class="flex">
																<small>Formas de pagamento:</small>
																<div class="horizontal layout wrap">
																	<shop-image class="cards" src="../images/amex.png" alt="Amex"></shop-image>
																	<shop-image class="cards" src="../images/visa.png" alt="Visa"></shop-image>
																	<shop-image class="cards" src="../images/mastercard.png" alt="mastercard"></shop-image>
																</div>
															</div>
															<div class="end-justified text--right">
																<small>Ambiente seguro:</small>
																<div class="horizontal layout wrap">
																	<div class="flex">
																		<shop-image
																			class="segure"
																			src="../images/selo-seguranca-cielo.png"
																			alt="Ambiente seguro Cielo"
																		></shop-image>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</form>
											</iron-form>
										</div>
									</paper-card>
								</cs-text>
							</cs-section-column>
						</cs-section>
					</div>
					<div state="success">
						<cs-hero
							layout="medium"
							text-position="center"
							text-align="center"
							text-color="dark"
							stacked=""
							background-color="none"
							title-text="{{message.title}}"
							copy-text="{{message.content}}"
							image-tooltip=""
							spacing-bottom="none"
							spacing-top="none"
							scrim-color="none"
							scrim-opacity="medium"
							custom-styles="{}"
						>
							<cs-cta
								slot="cta"
								type="standard"
								link-url="/"
								link-text="Ir para o site"
								icon="none"
								fill-color="brand"
								text-color="light"
								stroke-color="none"
								target="_self"
								intent="navigation"
								emphasis="primary"
								margin-not-set=""
								class="cspl-cta-first"
							>
							</cs-cta>
						</cs-hero>
					</div>
					<div state="error">
						<cs-hero
							layout="medium"
							text-position="center"
							text-align="center"
							text-color="dark"
							stacked=""
							background-color="none"
							title-text="Este link expirou!"
							copy-text="Entre em contato com a equipe da CRIUS Tecnlogia para gerar uma nova validade."
							image-tooltip=""
							spacing-bottom="none"
							spacing-top="none"
							scrim-color="none"
							scrim-opacity="medium"
							custom-styles="{}"
						>
							<cs-cta
								slot="cta"
								type="standard"
								link-url="/"
								link-text="Ir para o site"
								icon="none"
								fill-color="brand"
								text-color="light"
								stroke-color="none"
								target="_self"
								intent="navigation"
								emphasis="primary"
								margin-not-set=""
								class="cspl-cta-first"
							>
							</cs-cta>
						</cs-hero>
					</div>
				</iron-pages>
				<div class="text--center">
					<paper-spinner-lite active="[[loading]]"></paper-spinner-lite>
				</div>
			</div>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
		afterNextRender(this, function () {
			var split = this.path.split("/");
			this.id = split[2];
			this._setLoading(true);
			this.$.submitButton._setLoading(false);
			this.$.ajaxData.generateRequest();
		});
	}

	_handleResponseData(event) {
		this._setLoading(false);
		if (event.detail.response != undefined && event.detail.response.data) {
			this.data = event.detail.response.data;
			if (this.data.orderStatus === "finalizado") {
				this.message = {
					title: "Ops!",
					content: "Este link já não exite mais. Entre em contato com nossa equipe para mais informações.",
				};
				this._pushState("success");
			} else if (this.data.orderStatus === "expirado") {
				this._pushState("error");
			}
		}
	}

	_getFeaturedItem(items) {
		this.total = 0;
		if (items != undefined) {
			for (let index = 0; index < items.length; index++) {
				const element = items[index];
				if (element.costPrice != null) {
					this.total += element.costPrice * element.quantity;
					element.amount = element.costPrice * element.quantity;
				} else if (element.salePrice != null) {
					this.total += element.salePrice * element.quantity;
					element.amount = element.salePrice * element.quantity;
				}
				if (element.recurrency === "Monthly") {
					element.recurrency = "Mensal";
				} else if (element.recurrency === "Bimonthly") {
					element.recurrency = "Bimestral";
				} else if (element.recurrency === "Quarterly") {
					element.recurrency = "Trimestral";
				} else if (element.recurrency === "SemiAnnual") {
					element.recurrency = "Semestral";
				} else if (element.recurrency === "Annual") {
					element.recurrency = "Anual";
				} else if (element.recurrency === "none") {
					element.recurrency = "";
				}
				for (let index = 0; index < element.product.files.length; index++) {
					const subelement = element.product.files[index];
					if (subelement.binding === "produto-item-store") {
						element.image = subelement.downloadURL;
					}
				}
			}
		}
		return items ? items : {};
	}

	_submit() {
		if (this._validateForm()) {
			this.$.checkoutForm.submit();
		}
	}

	_validateForm() {
		let form = this.$.checkoutForm;
		let firstInvalid = false;
		let nativeForm = form._form;

		for (let el, i = 0; (el = nativeForm.elements[i]), i < nativeForm.elements.length; i++) {
			if (el.checkValidity()) {
				el.removeAttribute("aria-invalid");
			} else {
				if (!firstInvalid) {
					// announce error message
					if (el.nextElementSibling) {
						this.dispatchEvent(
							new CustomEvent("announce", {
								bubbles: true,
								composed: true,
								detail: el.nextElementSibling.getAttribute("error-message"),
							})
						);
					}
					if (el.scrollIntoViewIfNeeded) {
						// safari, chrome
						el.scrollIntoViewIfNeeded();
					} else {
						// firefox, edge, ie
						el.scrollIntoView(false);
					}
					el.focus();
					firstInvalid = true;
				}
				el.setAttribute("aria-invalid", "true");
			}
		}

		return !firstInvalid;
	}

	_willSendRequest(e) {
		let form = e.target;
		let body = form.request && form.request.body;
		this.$.submitButton._setLoading(true);
		if (!body) {
			return;
		}
	}

	_reset() {
		this.ccNumber = "";
		this.ccName = "";
		this.ccCVV = "";
		this.$.ccExpMonth.selectedIndex = 0;
		this.$.ccExpYear.selectedIndex = 0;
	}

	responseHandler(event) {
		this._setLoading(false);
		this.$.submitButton._setLoading(false);
		this._pushState("error");
		this._reset();
		let message = {
			title: "Ocorreu um problema!",
			content: "Código de erro:" + event.detail.error,
		};
		document.querySelector("cs-shell")._showMessage(message);
	}

	_didReceiveResponse(event) {
		var response = event.detail.response;
		if (response.data.length) {
			response = response.data[0];
		}
		this.response = response.data;
		this.$.submitButton._setLoading(false);
		this._reset();
		if (response.success) {
			this.message = {
				title: "Obrigado!",
				content: "Sua compra foi realizada com sucesso.",
			};
			this._pushState("success");
		} else {
			let message = {
				title: "Ocorreu um erro!",
				content: "Não foi possível processar a transação. Código: " + this.response.ReturnCode,
			};
			document.querySelector("cs-shell")._showMessage(message);
		}
	}

	_getCardType() {
		const result = validateCcInput(this.$.ccNumber.value);
		let valid = result.valid && result.length_valid;
		if (valid && this.cardTypes && this.cardTypes.length > 0) {
			valid = this.cardTypes.indexOf(result.card_type.name) !== -1;
		}

		this.cardType = valid ? result.card_type.name : "";
	}

	_formatDate(date, format) {
    var today  = new Date();
		return date === null ? "" : today.toLocaleDateString("pt-BR");
	}

	numberFormat(number) {
		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
		}).format(number);
	}

	_pushState(state) {
		this.state = state;
	}
}

customElements.define("cs-smart-link", CsSmartLink);
