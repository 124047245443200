/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsLazyImageMixin } from "./mixins/cs-lazy-image-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";

const t = CsMixin,
	a = CsLazyImageMixin;

/**
 * `cs-lazy-image` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsLazyImage extends t(a(PolymerElement)) {
	static get properties() {
		return {
			_images: {
				type: Array,
				value() {
					return [];
				},
				observer: "_lazyImages",
			},
		};
	}

	static get template() {
		return html`
			<style>
				:host {
					display: block;
					width: 100%;
          height: 100%;
				}

				:host img {
					width: 100%;
				}
			</style>
			<slot></slot>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_getImages() {
		this._images = [...this.querySelectorAll("img")];
	}
	connectedCallback() {
		super.connectedCallback(), setTimeout(this._getImages.bind(this)); // jshint ignore:line
	}
}

customElements.define("cs-lazy-image", CsLazyImage);
