import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin.js';

/**
 * MixinNamePascelCase - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsGetRelValueMixin = base =>
  /**
   * @polymerMixinClass
   */
  class extends base {
    static get properties() {
      return {};
    }
    _getRelValue(e = '') {
      return '_blank' === e ? 'noopener' : '';
    }
  };

export const CsGetRelValueMixin = dedupingMixin(internalCsGetRelValueMixin);
