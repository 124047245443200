/* jshint ignore:start */
import { html } from "@polymer/polymer/polymer-element.js";
import { CsElements } from "./cs-elements.js";
import { CsDateValidatorMixin } from "./mixins/cs-date-validator-mixin.js";
import { CsDeviceMixin } from "./mixins/cs-device-mixin.js";
import { CsFormElementMixin } from "./mixins/cs-form-element-mixin.js";
import { CsInputElementInteractionsMixin } from "./mixins/cs-input-element-interactions-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";
import { CsValueValidatorMixin } from "./mixins/cs-value-validator-mixin.js";

window.nds = window.nds || {};
const d = CsMixin,
	s = CsDeviceMixin,
	o = CsValueValidatorMixin,
	p = CsFormElementMixin,
	u = CsDateValidatorMixin,
	h = CsInputElementInteractionsMixin;
/**
 * `cs-date-field` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsDateField extends d(o(s(p(u(h(CsElements)))))) {
	static get properties() {
		return {
			dayPlaceholder: {
				type: String,
			},
			disabled: {
				type: Boolean,
				value: !1,
			},
			errorText: {
				type: String,
				value: "",
			},
			format: {
				type: String,
				value: "mm-dd-yyyy",
				reflectToAttribute: !0,
			},
			helpText: {
				type: String,
				value: "",
			},
			labelText: String,
			monthPlaceholder: String,
			range: String,
			yearPlaceholder: String,
			value: {
				notify: !0,
			},
			_inputType: {
				type: String,
				value: "text",
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style include="cs-shared-styles-typography-paragraphs"></style>
			<style include="cs-shared-styles-form-tooltip"></style>
			<style include="cs-shared-styles-form-input"></style>
			<style>
				:host {
					cursor: text;
				}
				.cspl-date-field__fieldset {
					display: flex;
					opacity: 0;
				}
				.cspl-date-field__input {
					margin: 0;
					max-width: 2em;
					padding: 0;
				}
				.cspl-date-field__input .cspl-input {
					padding: 0;
				}
				:host([rtl]) .cspl-date-field__input .cspl-input {
					direction: rtl;
					unicode-bidi: bidi-override;
				}
				.cspl-date-field__day,
				.cspl-date-field__year {
					pointer-events: none;
					position: relative;
				}
				.cspl-date-field__year {
					max-width: 3em;
				}
				.cspl-date-field__divider {
					padding: 0 8px;
				}
				:host([text-color="light"]) .cspl-date-field__divider {
					color: var(--cs-light-color);
				}
				.cspl-date-field__divider:nth-of-type(1) {
					order: 2;
				}
				.cspl-date-field__divider:nth-of-type(2) {
					order: 4;
				}
				.cspl-date-field__year {
					order: 5;
				}
				:host([format="dd-mm-yyyy"]) .cspl-date-field__day {
					order: 1;
				}
				:host([format="dd-mm-yyyy"]) .cspl-date-field__month {
					order: 3;
				}
				:host([format="mm-dd-yyyy"]) .cspl-date-field__day {
					order: 3;
				}
				:host([format="mm-dd-yyyy"]) .cspl-date-field__month {
					order: 1;
				}
				:host([format="yyyy-mm-dd"]) .cspl-date-field__day {
					order: 5;
				}
				:host([format="yyyy-mm-dd"]) .cspl-date-field__month {
					order: 3;
				}
				:host([format="yyyy-mm-dd"]) .cspl-date-field__year {
					order: 1;
				}
				:host(:not([_empty])) .cspl-date-field__day,
				:host(:not([_empty])) .cspl-date-field__year,
				:host([current]) .cspl-date-field__day,
				:host([current]) .cspl-date-field__year {
					pointer-events: all;
				}
				:host(:not([_empty])) .cspl-date-field__fieldset,
				:host([current]) .cspl-date-field__fieldset {
					opacity: 1;
					transform: translateY(4px);
				}
				:host([disabled]) .cspl-date-field__day,
				:host([disabled]) .cspl-date-field__month,
				:host([disabled]) .cspl-date-field__year {
					pointer-events: none;
				}
				@media only screen and (min-width: 768px) and (max-width: 1023px) {
					.cspl-date-field__input {
						max-width: 26px;
					}
					.cspl-date-field__year {
						max-width: 40px;
					}
					.cspl-date-field__divider {
						padding: 0 1px;
					}
				}
				@media only screen and (min-width: 1024px) {
					:host(:not([_empty])) .cspl-date-field__fieldset,
					:host([current]) .cspl-date-field__fieldset {
						transform: translateY(6px);
					}
				}
				@media only screen and (min-width: 1456px) {
					:host(:not([_empty])) .cspl-date-field__fieldset,
					:host([current]) .cspl-date-field__fieldset {
						transform: translateY(13px);
					}
				}
				.cspl-date-field__label {
					top: 20px;
				}
				@media only screen and (min-width: 1024px) {
					.cspl-date-field__label {
						top: 22px;
					}
				}
				@media only screen and (min-width: 1456px) {
					.cspl-date-field__label {
						top: 24px;
					}
				}
			</style>
			<label class="cspl-date-field__label cspl-input__label b2" for="month"> [[ labelText ]]</label>
			<div class="cspl-date-field__fieldset">
				<iron-input allowed-pattern="[0-9]" class="cspl-date-field__input cspl-date-field__month" id="ironMonth"
					><input
						class="cspl-input"
						name="month"
						id="month"
						disabled$="[[ disabled ]]"
						type$="[[ _inputType ]]"
						inputmode="numeric"
						pattern="\\d*"
						placeholder$="[[ monthPlaceholder ]]"
						size="3"
						minlength="2"
						maxlength="2" /></iron-input
				><span class="cspl-date-field__divider">/</span
				><iron-input allowed-pattern="[0-9]" class="cspl-date-field__input cspl-date-field__day" id="ironDay"
					><input
						class="cspl-input"
						name="day"
						id="day"
						disabled$="[[ disabled ]]"
						type$="[[ _inputType ]]"
						inputmode="numeric"
						pattern="\\d*"
						placeholder$="[[ dayPlaceholder ]]"
						size="3"
						minlength="2"
						maxlength="2" /></iron-input
				><span class="cspl-date-field__divider">/</span
				><iron-input allowed-pattern="[0-9]" class="cspl-date-field__input cspl-date-field__year" id="ironYear"
					><input
						class="cspl-input"
						name="year"
						id="year"
						disabled$="[[ disabled ]]"
						type$="[[ _inputType ]]"
						inputmode="numeric"
						pattern="\\d*"
						placeholder$="[[ yearPlaceholder ]]"
						size="5"
						minlength="4"
						maxlength="4"
				/></iron-input>
			</div>
			<div class="cspl-input__tooltip cspl-input__tooltip--error" hidden$="[[ _hideToolTip(errorText) ]]">[[ errorText ]]</div>
			<div class="cspl-input__tooltip cspl-input__tooltip--help" hidden$="[[ _hideToolTip(helpText) ]]">[[ helpText ]]</div>`;
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super(),
			(this._handleTapOffDateField = this._handleTapOffDateField.bind(this)),
			(this._handleValueLength = this._handleValueLength.bind(this)),
			(this._handleInputBlur = this._handleInputBlur.bind(this)),
			(this._handleInputFocus = this._handleInputFocus.bind(this)),
			(this._handleInputKeyUp = this._handleInputKeyUp.bind(this)),
			(this._handleInputKeyDown = this._handleInputKeyDown.bind(this)),
			(this._handleInputClick = this._handleInputClick.bind(this));
	}
	_hideToolTip(e) {
		return !e;
	}
	_handleDateFieldClick() {
		this._getNextInput().focus();
	}
	_handleTapOffDateField(e) {
		-1 === e.composedPath().indexOf(this) && this._handleDateFieldBlur();
	}
	_handleDateFieldBlur() {
		this._checkForError(), (this.current = !1);
	}
	_handleInputFocus(e) {
		e.target === this._getNextInput() || this.current || this._getNextInput().focus(), (this.current = !0);
	}
	_handleInputBlur() {
		this._getValue(), (this._empty = this.isEmpty());
	}
	_handleInputKeyUp(e) {
		var t = e.composedPath()[0];
		-1 === [9, 16, 37, 39].indexOf(e.keyCode) && this._autoTab(t);
	}
	_handleInputClick(e) {
		e.stopPropagation();
	}
	_handleInputKeyDown(e) {
		9 === e.keyCode ? this._handleTabEvent(e) : 13 === e.keyCode && this._handleDateFieldBlur();
	}
	_handleTabEvent(e) {
		var t = e.composedPath()[0];
		e.shiftKey && e.target.previous
			? (e.preventDefault(), this._focusPrevious(t))
			: e.shiftKey
			? this._handleDateFieldBlur()
			: e.target.next
			? (e.preventDefault(), this._focusNext(t))
			: this._handleDateFieldBlur();
	}
	isEmpty() {
		return this._getValue(), !(this.value.day || this.value.month || this.value.year);
	}
	_autoTab(e) {
		this._isFilled(e) && e.next && e.next.focus();
	}
	_focusNext(e) {
		e.next && e.next.focus();
	}
	_focusPrevious(e) {
		e.previous && e.previous.focus();
	}
	_handleValueLength(e) {
		var t = e.composedPath()[0],
			i = t.maxLength;
		t.value.length > i && (t.value = t.value.slice(0, i));
	}
	_isFilled(e) {
		return e.getAttribute("maxlength") === e.value.length.toString();
	}
	_assignTargetsDay() {
		return "1" === window.getComputedStyle(this.$.ironDay).order
			? {
					next: this.$.month,
					previous: null,
			  }
			: "5" === window.getComputedStyle(this.$.ironDay).order
			? {
					next: null,
					previous: this.$.month,
			  }
			: {
					next: this.$.year,
					previous: this.$.month,
			  };
	}
	_assignTargetsMonth() {
		return "3" === window.getComputedStyle(this.$.ironMonth).order && "1" === window.getComputedStyle(this.$.ironYear).order
			? {
					next: this.$.day,
					previous: this.$.year,
			  }
			: "3" === window.getComputedStyle(this.$.ironMonth).order && "5" === window.getComputedStyle(this.$.ironYear).order
			? {
					next: this.$.year,
					previous: this.$.day,
			  }
			: {
					next: this.$.day,
					previous: null,
			  };
	}
	_assignTargetsYear() {
		return "1" === window.getComputedStyle(this.$.ironYear).order
			? {
					next: this.$.month,
					previous: null,
			  }
			: "1" === window.getComputedStyle(this.$.ironDay).order
			? {
					next: null,
					previous: this.$.month,
			  }
			: {
					next: null,
					previous: this.$.day,
			  };
	}
	_getValue() {
		return (
			(this.value = {
				day: this.$.day.value,
				month: this.$.month.value,
				year: this.$.year.value,
			}),
			this.value
		);
	}
	_getNextInput() {
		var e = [...this.shadowRoot.querySelectorAll("input")].map((e) => ({
				order: parseInt(window.getComputedStyle(e.parentNode).order, 10),
				inputField: e,
			})),
			t = this._sortByKey(e, "order");
		for (var i of t) if (!this._isFilled(i.inputField)) return i.inputField;
		return t.slice(-1)[0].inputField;
	}
	_sortByKey(e, t) {
		return e.sort(function (e, i) {
			return e[t] < i[t] ? -1 : e[t] > i[t] ? 1 : 0;
		});
	}
	_setValue(e) {
		var t = e.split("-").map((e) => parseInt(e, 10)),
			i = new Date(t[0], t[1] - 1, t[2]);
		this._setValueFromDate(i), (this._empty = !1);
	}
	_leadWithZero(e) {
		return e < 10 ? "0" + e : e;
	}
	_setValueFromDate(e) {
		(this.$.day.value = this._leadWithZero(e.getDate())), (this.$.month.value = this._leadWithZero(e.getMonth() + 1)), (this.$.year.value = e.getFullYear()), this._getValue();
	}
	_checkForError() {
		return this.isEmpty() || (this.error = !this._validate()), this.error;
	}
	_setupInputListeners() {
		(this.inputs = [...this.shadowRoot.querySelectorAll("input")]),
			(this._inputType = this.isTouch ? "number" : "text"),
			(this.type = "date"),
			this.inputs.map((e) => {
				this.isTouch && e.addEventListener("keyup", this._handleValueLength),
					e.addEventListener("blur", this._handleInputBlur),
					e.addEventListener("focus", this._handleInputFocus),
					e.addEventListener("keyup", this._handleInputKeyUp),
					e.addEventListener("keydown", this._handleInputKeyDown),
					e.addEventListener("click", this._handleInputClick);
			});
	}
	connectedCallback() {
		super.connectedCallback(),
			(this.$.month.next = this._assignTargetsMonth().next),
			(this.$.day.next = this._assignTargetsDay().next),
			(this.$.year.next = this._assignTargetsYear().next),
			(this.$.month.previous = this._assignTargetsMonth().previous),
			(this.$.day.previous = this._assignTargetsDay().previous),
			(this.$.year.previous = this._assignTargetsYear().previous),
			this.addEventListener("click", this._handleDateFieldClick),
			window.addEventListener("click", this._handleTapOffDateField),
			this._setupInputListeners();
	}
	disconnectedCallback() {
		super.disconnectedCallback(),
			this.removeEventListener("click", this._handleDateFieldClick),
			window.removeEventListener("click", this._handleTapOffDateField),
			this.inputs.map((e) => {
				this.isTouch && e.removeEventListener("keyup", this._handleValueLength),
					e.removeEventListener("blur", this._handleInputBlur),
					e.removeEventListener("focus", this._handleInputFocus),
					e.removeEventListener("keyup", this._handleInputKeyUp),
					e.removeEventListener("keydown", this._handleInputKeyDown),
					e.removeEventListener("click", this._handleInputClick);
			});
	}
}

customElements.define("cs-date-field", CsDateField);
