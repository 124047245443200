import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsGoogleAnalyticsMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsGoogleAnalyticsMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                gaEventName: String,
                gaEventPayload: Object
            };
        }
        _fireAnalytics() {
            this.gaEventName && this.gaEventPayload && this.gaEvent(this.gaEventName, this.gaEventPayload); // jshint ignore:line
        }
        gaEvent(e, t = {}) {
            window.tlm_ga && window.tlm_ga.isReady() ? this.dispatchEvent(new CustomEvent(e, {
                detail: t,
                bubbles: !0,
                composed: !0
            })) : document.addEventListener("/ga/ready", () => {
                this.dispatchEvent(new CustomEvent(e, {
                    detail: t,
                    bubbles: !0,
                    composed: !0
                }));
            }, !1); // jshint ignore:line
        }
        connectedCallback() {
            super.connectedCallback(), this.addEventListener("click", this._fireAnalytics); // jshint ignore:line
        }
    };

export const CsGoogleAnalyticsMixin = dedupingMixin(internalCsGoogleAnalyticsMixin);