/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsCtaParentMixin } from "./mixins/cs-cta-parent-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";


const t = CsMixin,
	a = CsCtaParentMixin;

/**
 * `cs-product-conversion` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsProductConversion extends t(a(PolymerElement)) {
	static get properties() {
		return {
			titleText: String,
			textColor: {
				type: String,
				value: "",
				reflectToAttribute: !0,
			},
			backgroundColor: {
				type: String,
				value: "",
				reflectToAttribute: !0,
			},
			spacingTop: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			spacingBottom: {
				type: String,
				value: "medium",
				reflectToAttribute: !0,
			},
			context: {
				type: String,
				value: "cs-product-conversion",
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-typography-paragraphs"></style>
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-typography-labels"></style>
			<style include="cs-shared-styles-common-spacing"></style>
			<style include="cs-cta-shared-styles"></style>
			<style>
				:host {
					display: block;
					padding: 32px var(--cs-rail-mobile) 16px var(--cs-rail-mobile);
				}

				@media only screen and (min-width: 768px) {
					:host {
						padding-left: var(--cs-rail-tablet-portrait);
						padding-right: var(--cs-rail-tablet-portrait);
					}
				}

				@media only screen and (min-width: 1024px) {
					:host {
						align-items: center;
						display: flex;
						height: 96px;
						padding: 0 var(--cs-rail-tablet-landscape) 0 var(--cs-rail-tablet-landscape);
					}
				}

				@media only screen and (min-width: 1456px) {
					:host {
						height: 112px;
						padding-left: calc(var(--cs-rail-desktop));
						padding-right: calc(var(--cs-rail-desktop));
					}
				}

				:host([text-color="light"]) {
					color: var(--cs-light-color);
				}

				:host([background-color="light"]) {
					background-color: var(--cs-light-color);
				}

				:host([text-color="dark"]) {
					color: var(--cs-dark-color);
				}

				:host([background-color="dark"]) {
					background-color: var(--cs-dark-color);
				}

				.cspl-product-conversion__title {
					margin-bottom: 0;
					margin-top: 0;
					text-align: center;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-product-conversion__title {
						margin-right: var(--cs-gutter-tablet-landscape);
						text-align: left;
						width: calc(6 * var(--cs-column-tablet-landscape-with-rail) + 5 * var(--cs-gutter-tablet-landscape));
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-product-conversion__title {
						margin-right: var(--cs-gutter-desktop);
						width: calc(6 * var(--cs-column-desktop-with-rail) + 5 * var(--cs-gutter-desktop));
					}
				}

				.cspl-product-conversion__ctas {
					align-items: center;
					display: flex;
					flex-direction: column;
					margin-top: 17px;
				}

				@media only screen and (min-width: 768px) {
					.cspl-product-conversion__ctas {
						flex-direction: row;
						justify-content: center;
					}
				}

				@media only screen and (min-width: 1024px) {
					.cspl-product-conversion__ctas {
						justify-content: flex-end;
						margin-top: 16px;
						width: calc(6 * var(--cs-column-tablet-landscape-with-rail) + 5 * var(--cs-gutter-tablet-landscape));
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-product-conversion__ctas {
						width: calc(6 * var(--cs-column-desktop-with-rail) + 5 * var(--cs-gutter-desktop));
					}
				}
			</style>
			<h2 class$="[[ _getTitleClass(textColor) ]]">[[ titleText ]]</h2>
			<div class="cspl-product-conversion__ctas ctas">
				<slot name="cta"></slot>
			</div> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_getTitleClass(e) {
		return `cspl-product-conversion__title l1 l1-${e}`;
	}
}

customElements.define("cs-product-conversion", CsProductConversion);
