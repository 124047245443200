import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

window.nds = window.nds || {};
const e = {
    VERIFIED: "nds.agecheck.verified",
    LAUNCH: "nds.agecheck.launch",
    ERROR: "nds.agecheck.error"
};
/**
 * CsAgeCheckMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsAgeCheckMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                ageCheck: {
                    type: Boolean,
                    value: !1,
                    reflectToAttribute: !0
                },
                ageCheckVerified: {
                    type: Boolean,
                    value: !1,
                    reflectToAttribute: !0
                },
                ageCheckError: {
                    type: Boolean,
                    value: !1,
                    reflectToAttribute: !0
                }
            };
        }
        isAgeGateCoverHidden(e, t) {
            return !e || e && t;
        }
        launchAgeGateEvent() {
            this.dispatchEvent(new CustomEvent(e.LAUNCH, {
                bubbles: !0,
                composed: !0
            }));
        }
        handleAgeGateSuccess() {
            this.dispatchEvent(new CustomEvent(e.VERIFIED, {
                bubbles: !0,
                composed: !0
            }));
        }
        handleAgeGateError() {
            this.dispatchEvent(new CustomEvent(e.ERROR, {
                bubbles: !0,
                composed: !0
            }));
        }
        _setAgeCheckVerified() {
            this.ageCheckVerified = !0;
        }
        _setAgeCheckError() {
            this.ageCheckError = !0;
        }
        constructor() {
            super(), this._setAgeCheckVerified = this._setAgeCheckVerified.bind(this), this._setAgeCheckError = this._setAgeCheckError.bind(this); // jshint ignore:line
        }
        connectedCallback() {
            super.connectedCallback(), document.addEventListener(e.VERIFIED, this._setAgeCheckVerified), document.addEventListener(e.ERROR, this._setAgeCheckError); // jshint ignore:line
        }
        disconnectedCallback() {
            super.disconnectedCallback(), document.removeEventListener(e.VERIFIED, this._setAgeCheckVerified), document.removeEventListener(e.ERROR, this._setAgeCheckError); // jshint ignore:line
        }
    };

export const CsAgeCheckMixin = dedupingMixin(internalCsAgeCheckMixin);