/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

const t = CsMixin,
  a = CsGoogleAnalyticsMixin;

/**
 * `cs-social-link` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSocialLink extends t(a(PolymerElement)) {
  static get properties() {
    return {
      linkUrl: String,
      linkTitle: String,
      linkTarget: String,
      theme: {
        type: String,
        value: 'brand',
        reflectToAttribute: !0,
      },
      socialNetwork: {
        type: String,
        observer: '_observeSocialNetwork',
      },
      textColor: {
        type: String,
        value: 'dark',
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`
      <style>
        :host {
          display: inline-block;
          margin-bottom: 23px;
          margin-inline-end: 24px;
        }

        :host(:last-of-type) {
          margin-inline-end: 0;
        }

        @media only screen and (min-width: 768px) {
          :host {
            margin-bottom: 26px;
          }
        }

        @media only screen and (min-width: 1024px) {
          :host {
            margin-bottom: 0;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host {
            margin-inline-end: 32px;
          }
        }

        a {
          text-decoration: none;
        }

        iron-icon {
          height: 22px;
          transition: all 0.1s linear 0.1s;
          width: 22px;
        }

        :host(:hover) iron-icon {
          transform: scale(1.272);
        }

        :host([text-color='dark']) iron-icon {
          fill: #282828;
        }

        :host([text-color='light']) iron-icon {
          fill: #fff;
        }
      </style>
      <a
        href$="[[ linkUrl ]]"
        title$="[[ linkTitle ]]"
        target$="[[ linkTarget ]]"
        rel$="[[ _getRelValue(linkTarget) ]]"
      >
        <iron-icon
          icon="[[ _getIconName(socialNetwork) ]]"
          role="img"
          aria-label$="[[ linkTitle ]]"
        ></iron-icon>
      </a>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _getIconName(e) {
    return 'youtube' === e
      ? 'dark' === this.textColor
        ? 'cs-action-192:youtube-dark'
        : 'cs-action-192:youtube-light'
      : 'cs-action-16:' + e;
  }
  _observeSocialNetwork(e) {
    (this.gaEventName = '/ga/social/follow'),
      (this.gaEventPayload = {
        network: e,
      });
  }
}

customElements.define('cs-social-link', CsSocialLink);
