/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-html5-video` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsHtml5Video extends PolymerElement {
	static get properties() {
		return {
			mp4Src: {
				type: String,
				default: null,
				reflectToAttribute: !0,
			},
			webmSrc: {
				type: String,
				default: null,
				reflectToAttribute: !0,
			},
			autoplay: {
				type: Boolean,
				default: !1,
				reflectToAttribute: !0,
			},
			controls: {
				type: Boolean,
				default: !1,
				reflectToAttribute: !0,
			},
			loop: {
				type: Boolean,
				default: !1,
				reflectToAttribute: !0,
			},
			muted: {
				type: Boolean,
				default: !1,
				reflectToAttribute: !0,
			},
			preload: {
				type: String,
				default: null,
				reflectToAttribute: !0,
			},
			poster: {
				type: String,
				default: null,
				reflectToAttribute: !0,
			},
			isBackground: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style>
				:host {
					display: block;
				}

				video {
					width: 100%;
				}

				:host([is-background]) video::-webkit-media-controls-start-playback-button {
					display: none !important;
				}
			</style>
			<video autoplay="[[ autoplay ]]" controls="[[ controls ]]" loop="[[ loop ]]" muted="[[ muted ]]" poster$="[[ poster ]]" preload$="[[ preload]]" id="video">
				<source src$="[[ mp4Src ]]" type="video/mp4" />
				<source src$="[[ webmSrc ]]" type="video/webm" />
			</video> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super(), (this._autoplay = this._autoplay.bind(this)); // jshint ignore:line
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	play() {
		this.$.video.play();
	}
	pause() {
		this.$.video.paused || this.$.video.pause(); // jshint ignore:line
	}
	_autoplay() {
		this.$.video.paused && this.$.video.play(); // jshint ignore:line
	}
	connectedCallback() {
		super.connectedCallback(), this.autoplay && (setTimeout(this.play.bind(this)), this.$.video.addEventListener("canplaythrough", this._autoplay.bind(this))); // jshint ignore:line
	}
	disconnectedCallback() {
		super.disconnectedCallback(), this.autoplay && this.$.video.removeEventListener("canplaythrough", this._autoplay.bind(this)); // jshint ignore:line
	}
}

customElements.define("cs-html5-video", CsHtml5Video);
