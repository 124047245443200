/* jshint ignore:start */
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";

/**
 * `cs-quantitative-data` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsQuantitativeData extends PolymerElement {
	static get properties() {
		return {
			textColor: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-typography-paragraphs"></style>
			<style>
				.cspl-quantitative-data {
					color: inherit;
					display: inline;
					margin: 0;
				}

				.cspl-quantitative-data__items {
					display: inline-flex;
					flex-direction: row;
					justify-content: center;
					margin-bottom: 0;
					margin-top: 0;
				}

				.cspl-quantitative-data__items dd:first-of-type,
				.cspl-quantitative-data__items dt {
					display: inline;
					margin: 0;
				}

				.cspl-quantitative-data__items dt {
					padding-left: 4px;
				}

				:host(:first-of-type) :first-child {
					padding-left: 0;
				}

				:host(:last-of-type) :last-child {
					padding-right: 0;
				}

				dd:not(:first-of-type) {
					margin: 0 0 0 7px;
					padding-left: 9px;
					position: relative;
				}

				dd:nth-of-type(n + 2)::before {
					background-color: currentColor;
					content: "";
					display: block;
					height: 11px;
					left: 0;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 2px;
				}

				@media only screen and (min-width: 1456px) {
					:host dd:nth-of-type(n + 2)::before {
						height: 13px;
					}
				}
			</style>
			<dl id="quantitativeDataList" class="cspl-quantitative-data__items b3"></dl> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_loadData() {
		const e = [...this.querySelectorAll("dd, dt")];
		const t = document.createDocumentFragment();
		e.forEach((e) => t.appendChild(e)), this.$.quantitativeDataList.appendChild(t); // jshint ignore:line
	}
	connectedCallback() {
		super.connectedCallback(), requestAnimationFrame(this._loadData.bind(this)); // jshint ignore:line
	}
}

customElements.define("cs-quantitative-data", CsQuantitativeData);
