/* jshint ignore:start */
import { GestureEventListeners } from "@polymer/polymer/lib/mixins/gesture-event-listeners.js";
import * as Gestures from "@polymer/polymer/lib/utils/gestures.js";
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsMixin } from "./mixins/cs-mixin.js";

window.nds.data = window.nds.data || {};
var e = window.nds;
const t = CsMixin,
	a = e.data;

/**
 * `cs-network-nav` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsNetworkNav extends t(GestureEventListeners(PolymerElement)) {
	static get properties() {
		return {
			loginUrl: {
				type: String,
				value: "/login",
			},
			logoutUrl: {
				type: String,
				value: "/logout",
			},
			content: String,
			open: {
				type: Boolean,
				reflectToAttribute: !0,
				value: !1,
			},
			currentSection: String,
			visible: {
				type: String,
				reflectToAttribute: !0,
				value: "scrollup",
			},
			hideLogo: {
				type: Boolean,
				value: !1,
			},
			hideProfile: {
				type: Boolean,
				value: !1,
			},
			hideSearch: {
				type: Boolean,
				value: !1,
			},
			hideExplore: {
				type: Boolean,
				value: !1,
			},
			hideHelp: {
				type: Boolean,
				value: !1,
			},
			_scrollY: {
				type: Number,
				value: 0,
			},
			_lastScrollY: {
				type: Number,
				value: 0,
			},
			_displayNetworkNavRAF: Number,
		};
	}

	static get template() {
		return html`<style include="cs-shared-styles-base-common"></style>
			<style>
				:host {
					background-color: var(--cs-dark-color);
					display: block;
					min-height: 40px;
					position: fixed;
					top: 0;
					transform: translate3d(0, 0, 0);
					transition: transform 0.6s cubic-bezier(0.5, 0.25, 0.015, 1);
					width: 100%;
					will-change: transform;
					z-index: var(--cs-network-nav-layer);
				}

				:host(.show-nav) {
					position: fixed;
					top: 0;
					transition-duration: 0.4s;
				}

				:host([visible="top"]) {
					position: absolute;
				}

				:host([visible="never"]) {
					display: none;
				}

				:host(.hide-nav) {
					transform: translate3d(0, -40px, 0);
				}

				:host(.closing),
				:host(.no-transition) {
					transition: none;
				}

				:host::after {
					box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
					content: "";
					height: 100%;
					opacity: 0;
					position: absolute;
					top: 0;
					transform: translate3d(0, 0, 0);
					transition: opacity 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
					width: 100%;
					will-change: opacity;
					z-index: -1;
				}

				:host([open])::after {
					opacity: 1;
				}

				:host::before {
					opacity: 0;
					position: absolute;
					transform: translate3d(0, 0, 0);
					transition: opacity 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
					will-change: opacity;
					z-index: -1;
				}

				:host([open])::before {
					background-color: rgba(0, 0, 0, 0.25);
					bottom: 0;
					content: "";
					display: block;
					left: 0;
					opacity: 1;
					position: fixed;
					right: 0;
					top: 0;
				}

				.cspl-network-nav__menu {
					display: flex;
					height: 40px;
					justify-content: flex-end;
					list-style: none;
					margin: 0 32px 0 0;
					padding: 0;
					position: relative;
					z-index: 1;
				}

				.cspl-network-nav__menu-item {
					align-items: center;
					cursor: pointer;
					display: inline-flex;
					height: 40px;
					justify-content: center;
					list-style: none;
					width: 40px;
				}

				.cspl-network-nav__menu-item:not(:first-child) {
					margin-left: 20px;
				}

				.cspl-network-nav__menu-item:not(.current):not(:hover) {
					opacity: 0.7;
				}

				.cspl-network-nav__menu-item[link-ref="profile"] {
					padding: 8px;
				}

				.cspl-network-nav__menu-item svg {
					fill: var(--cs-light-color);
					height: 18px;
					width: 18px;
				}

				.cspl-network-nav__cs-logo a {
					align-items: center;
					display: flex;
					height: 100%;
					justify-content: center;
					width: 100%;
				}

				.cspl-network-nav__cs-logo iron-icon {
					color: #a3a3a3;
					height: 32px;
					pointer-events: none;
					width: 32px;
				}

				.cspl-network-nav__body {
					align-content: center;
					display: flex;
					height: 0;
					overflow: hidden;
					position: relative;
					transform: translate3d(0, 0, 0);
					transition: height 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
					will-change: height;
				}

				:host([open]) .cspl-network-nav__body {
					height: 520px;
				}

				@media only screen and (min-width: 1456px) {
					:host([open]) .cspl-network-nav__body {
						height: 660px;
					}
				}

				.cspl-network-nav__section {
					align-items: center;
					color: #fff;
					display: flex;
					height: 100%;
					justify-content: center;
					left: 0;
					opacity: 0;
					pointer-events: none;
					position: absolute;
					top: 0;
					transition: all 0.5s cubic-bezier(0.5, 0.25, 0.015, 1);
					width: 100%;
					will-change: transform, opacity;
				}

				@media (max-width: 767px) {
					.cspl-network-nav__section[link-target="search"] cs-section {
						align-self: flex-start;
						margin-top: 80px;
					}
				}

				:host([open]) .cspl-network-nav__section:not(.current) {
					transform: translate3d(0, -200px, 0);
				}

				:host([open]) .cspl-network-nav__section.current {
					opacity: 1;
					pointer-events: auto;
				}

				.cspl-network-nav__close {
					color: #fff;
					cursor: pointer;
					height: 24px;
					opacity: 0;
					pointer-events: none;
					position: absolute;
					right: 28px;
					text-align: center;
					top: 34px;
					width: 24px;
				}

				:host([open]) .cspl-network-nav__close {
					opacity: 1;
					pointer-events: auto;
				}

				.cspl-network-nav__close iron-icon {
					height: 13px;
					width: 13px;
				}
			</style>
			<iron-ajax id="ajax"></iron-ajax>
			<div id="content"></div> `;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super(),
			(this._enableNewContent = this._enableNewContent.bind(this)),
			(this._fetchNavContent = this._fetchNavContent.bind(this)),
			(this._onScroll = this._onScroll.bind(this)),
			(this._openNetworkNav = this._openNetworkNav.bind(this)),
			(this._handleTouch = this._handleTouch.bind(this)); // jshint ignore:line
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	static get observers() {
		return ["_currentSectionChanged(currentSection)"];
	}
	_isCurrentSection(e) {
		return this.currentSection === e;
	}
	_handleNavTap(e) {
		const t = e.currentTarget.getAttribute("link-ref");
		e.target.href || (this._isCurrentSection(t) ? this._closeNetworkNav() : this._openNetworkNav(t)); // jshint ignore:line
	}
	_handleCloseClick() {
		this._closeNetworkNav();
	}
	_getAjaxUrl(e) {
		let t = `${this.content}?authenticated=${e}`;
		for (const a in this.properties) 0 === a.indexOf("hide") && this[a] && (t = t.concat(`&${a}=1`)); // jshint ignore:line
		return t;
	}
	_enableNewContent() {
		this.fetchedContent = !1;
	}
	_fetchNavContent(e) {
		if (!this.fetchedContent) {
			(this.fetchedContent = !0),
				(a.user = e.detail || {
					authenticated: !1,
				}); // jshint ignore:line
			const t = a.user.authenticated || !1;
			(this.$.ajax.url = this._getAjaxUrl(t)),
				(this.$.ajax.handleAs = "text"),
				this.$.ajax.generateRequest(),
				this.$.ajax.addEventListener("response", this._handleContentResponse.bind(this)); // jshint ignore:line
		}
	}
	_handleContentResponse({ detail: { response: e } }) {
		this.$.ajax.removeEventListener("response", this._handleContentResponse),
			(this.$.content.innerHTML = e),
			this._setUpEvents(),
			this._setSectionDisplayOptions(),
			this._setLoginUrl(),
			this._setLogoutUrl(),
			this.dispatchEvent(
				new CustomEvent("nds.network-nav.content-fetched", {
					bubbles: !0,
					composed: !0,
					detail: e,
				})
			); // jshint ignore:line
	}
	_setSectionDisplayOptions() {
		const e = [...this.querySelectorAll("cs-section")];
		e.forEach((e) => {
			e.backgroundOverflow = "hidden";
		});
	}
	_setUpEvents() {
		const e = [...this.$.content.querySelectorAll(".cspl-network-nav__menu-item")];
		const t = this.$.content.querySelector(".cspl-network-nav__close");
		e.forEach((e) => {
			Gestures.addListener(e, "tap", this._handleNavTap.bind(this)),
				"search" === e.getAttribute("link-ref") && e.addEventListener("click", this._focusContextSearch.bind(this)); // jshint ignore:line
		}),
			t.addEventListener("click", this._handleCloseClick.bind(this)); // jshint ignore:line
	}
	_setLoginUrl() {
		const e = this.$.content.querySelector('section[link-target="profile"] cs-stream');
		const t = e ? e.querySelector('cs-cta[icon="profile"]') : null;
		t && (t.linkUrl = this.loginUrl); // jshint ignore:line
	}
	_setLogoutUrl() {
		const e = this.$.content.querySelector('cs-player-details cs-cta[type="text"]');
		e && (e.linkUrl = this.logoutUrl); // jshint ignore:line
	}
	_toggleWithForce(e, t) {
		const a = this.classList.contains(e);
		t && !a ? this.classList.add(e) : !t && a && this.classList.remove(e); // jshint ignore:line
	}
	_toggleClassOnLocalNav(e, t = !0) {
		this.localNav && this._toggleWithForce.call(this.localNav, e, t); // jshint ignore:line
	}
	_openNavSection(e) {
		this._openNetworkNav(e.detail);
	}
	_focusContextSearch() {
		const e = this.shadowRoot.querySelector("cs-context-search");
		e && "search" === this.currentSection && e.focusSearchInput(); // jshint ignore:line
	}
	_openNetworkNav(e) {
		(this.open = !0),
			(this.currentSection = e),
			(this.closeListener = this._handlekeydown.bind(this)),
			(this.offNavClickListener = this._handleOffNavClick.bind(this)),
			this._toggleClassOnLocalNav(`${this.visible}-net-nav-open`, !0),
			(document.querySelector("body").style.overflow = "hidden"),
			document.addEventListener("keydown", this.closeListener),
			document.addEventListener("click", this.offNavClickListener); // jshint ignore:line
	}
	_handleTouch(e) {
		this.open && e.preventDefault(); // jshint ignore:line
	}
	_closeNetworkNav() {
		(this.open = !1),
			this._clearCurrentClass(),
			(this.currentSection = null),
			this.classList.add("closing"),
			setTimeout(() => {
				this._postAnimationCleanup();
			}, 500),
			(document.querySelector("body").style.overflow = ""),
			document.removeEventListener("keydown", this.closeListener),
			document.removeEventListener("click", this.offNavClickListener); // jshint ignore:line
	}
	_postAnimationCleanup() {
		this._toggleClassOnLocalNav(`${this.visible}-net-nav-open`, !1),
			("scrollup" === this.visible || "always" === this.visible) &&
				((this.style.top = ""), this.classList.add("show-nav"), this.classList.add("no-transition"), this.classList.remove("closing")); // jshint ignore:line
	}
	_showNetworkNav() {
		this._toggleClassOnLocalNav("net-nav-onscreen", !0), this.classList.add("show-nav"), this.classList.remove("hide-nav"); // jshint ignore:line
	}
	_hideNetworkNav() {
		this._toggleClassOnLocalNav("net-nav-onscreen", !1), this.classList.remove("show-nav"), this.classList.add("hide-nav"); // jshint ignore:line
	}
	_toggleLocalNav(e) {
		this.localNav &&
			(40 <= e
				? (this.classList.add("cspl-site-nav-hidden"), this.localNav.classList.remove("static-mode"))
				: (this.classList.remove("cspl-site-nav-hidden"), this.localNav.classList.add("static-mode"))); // jshint ignore:line
	}
	_toggleNetworkNav(e) {
		"never" === this.visible ||
			(this.classList.remove("no-transition"),
			e > this._lastScrollY ? this._hideNetworkNav() : (0 == e || e < this._lastScrollY) && this._showNetworkNav(),
			(this._lastScrollY = e)); // jshint ignore:line
	}
	_onScroll() {
		const e = window.scrollY || window.pageYOffset;
		e !== this._scrollY && (this._scrollY = 0 < e ? e : 0); // jshint ignore:line
	}
	_displayNetworkNav() {
		const e = this.visible,
			t = this._scrollY;
		"top" === e ? this._toggleLocalNav(t) : this._toggleNetworkNav(t), (this._displayNetworkNavRAF = window.requestAnimationFrame(() => this._displayNetworkNav())); // jshint ignore:line
	}
	_clearCurrentClass() {
		for (; this.shadowRoot.querySelector(".current"); ) this.shadowRoot.querySelector(".current").classList.remove("current"); // jshint ignore:line
	}
	_currentSectionChanged(e) {
		if (e) {
			const t = this.shadowRoot.querySelector(`.cspl-network-nav__section[link-target=${e}]`);
			const a = this.shadowRoot.querySelector(`.cspl-network-nav__menu-item[link-ref=${e}]`);
			this._clearCurrentClass(), a.classList.add("current"), t.classList.add("current"); // jshint ignore:line
		}
	}
	_handleOffNavClick(e) {
		this._isNetworkNavinPath(e) || this._closeNetworkNav(); // jshint ignore:line
	}
	_handlekeydown(e) {
		27 === e.keyCode && this._closeNetworkNav(); // jshint ignore:line
	}
	_isNetworkNavinPath(e) {
		return -1 < e.path.indexOf(this);
	}
	connectedCallback() {
		super.connectedCallback(),
			(this.fetchedContent = !1),
			this._displayNetworkNav(),
			requestAnimationFrame(() => {
				(this.localNav = document.querySelector("cs-local-nav")),
					this.localNav && ("top" === this.visible || "scrollup" === this.visible) && window.addEventListener("scroll", this._onScroll); // jshint ignore:line
			}),
			document.addEventListener("player-refresh", this._enableNewContent),
			document.addEventListener("player-state", this._fetchNavContent),
			document.addEventListener("logged-out", this._fetchNavContent),
			window.addEventListener("activate-network-nav", this._openNetworkNav),
			document.addEventListener("touchmove", this._handleTouch); // jshint ignore:line
	}
	disconnectedCallback() {
		super.disconnectedCallback(),
			this.localNav && ("top" === this.visible || "scrollup" === this.visible) && window.removeEventListener("scroll", this._onScroll),
			document.removeEventListener("player-refresh", this._enableNewContent),
			document.removeEventListener("player-state", this._fetchNavContent),
			document.removeEventListener("logged-out", this._fetchNavContent),
			document.removeEventListener("activate-network-nav", this._openNetworkNav),
			document.removeEventListener("touchmove", this._handleTouch),
			window.cancelAnimationFrame(this._displayNetworkNavRAF); // jshint ignore:line
	}
}

customElements.define("cs-network-nav", CsNetworkNav);
