import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * CsLinkIconContainerMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsLinkIconContainerMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                icon: {
                    type: String,
                    value: ""
                },
                iconColor: {
                    type: String,
                    value: ""
                },
                iconBackgroundColor: {
                    type: String,
                    value: "none"
                },
                hovered: {
                    type: Boolean,
                    value: !1
                }
            };
        }
        _hasIcon(e) {
            return !!e;
        }
        connectedCallback() {
            super.connectedCallback(), this.addEventListener("mouseenter", () => {
                this.hovered = !0;
            }), this.addEventListener("mouseleave", () => {
                this.hovered = !1;
            }); // jshint ignore:line
        }
    };

export const CsLinkIconContainerMixin = dedupingMixin(internalCsLinkIconContainerMixin);