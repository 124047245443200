import { dedupingMixin } from '@polymer/polymer/lib/utils/mixin.js';

window.nds = window.nds || {};
const e = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
const t = /^[0-9]/;
// const t = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
/**
 * CsValueValidatorMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsValueValidatorMixin = base =>
  /**
   * @polymerMixinClass
   */
  class extends base {
    static get properties() {
      return {};
    }
    hasValue() {
      return this.checked || this.value;
    }
    isValidatable() {
      return !!(this.required || (!this.required && this.hasValue()));
    }
    validateValue() {
      let a;
      if (this.validPattern)
        return (a = new RegExp(this.validPattern)), a.test(this.value);
      switch (this.type) {
        case 'email':
          return this.value.match(e);
        case 'password':
          return this.value.match(t);
        case 'date':
          return this._validateDateFieldValue(this.value, this.range);
        default:
          return !0;
      }
    }
    _validate() {
      return !this.isValidatable() || (this.hasValue() && this.validateValue());
    }
  };

export const CsValueValidatorMixin = dedupingMixin(
  internalCsValueValidatorMixin
);
