/* jshint ignore:start */
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsFormElementMixin } from './mixins/cs-form-element-mixin.js';
import { CsInputElementInteractionsMixin } from './mixins/cs-input-element-interactions-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';
import { CsValueValidatorMixin } from './mixins/cs-value-validator-mixin.js';

window.nds = window.nds || {};
const o = CsMixin,
  s = CsValueValidatorMixin,
  u = CsFormElementMixin,
  d = CsInputElementInteractionsMixin;
/**
 * `cs-input` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsInput extends o(s(u(d(CsElements)))) {
  static get properties() {
    return {
      labelText: String,
      allowedPattern: String,
      validPattern: String,
      type: String,
      value: String,
      preventInvalidInput: String,
      checked: Boolean,
      autocomplete: String,
      helpText: String,
      errorText: String,
      textColor: {
        type: String,
        value: '',
        reflectToAttribute: !0,
      },
      backgroundColor: {
        type: String,
        value: '',
        reflectToAttribute: !0,
      },
      theme: {
        type: String,
        value: '',
        reflectToAttribute: !0,
      },
      current: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      error: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      disabled: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      helpActive: {
        type: Boolean,
        value: !1,
        reflectToAttribute: !0,
      },
      empty: {
        type: Boolean,
        value: !0,
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`<style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-shared-styles-form-tooltip"></style>
      <style include="cs-shared-styles-form-input"></style>
      <style>
        :host {
          cursor: text;
        }
      </style>
      <label class="cspl-input__label b2" for="input">[[ labelText ]]</label>
      <iron-input
        id="ironInput"
        allowed-pattern$="[[allowedPattern]]"
        bind-value="{{value}}"
        class="cspl-input__iron-input"
        invalid="{{invalid}}"
        value="{{value::input}}"
        validator$="[[validator]]"
        ><input
          id="input"
          class="cspl-input__input"
          aria-labelledby$="[[_ariaLabelledBy]]"
          aria-describedby$="[[_ariaDescribedBy]]"
          disabled$="[[disabled]]"
          title$="[[title]]"
          prevent-invalid-input$="[[preventInvalidInput]]"
          valid-pattern$="[[validPattern]]"
          type$="[[type]]"
          pattern$="[[pattern]]"
          required$="[[required]]"
          autocomplete$="[[autocomplete]]"
          autofocus$="[[autofocus]]"
          inputmode$="[[inputmode]]"
          minlength$="[[minlength]]"
          maxlength$="[[maxlength]]"
          min$="[[min]]"
          max$="[[max]]"
          step$="[[step]]"
          name$="[[name]]"
          autocomplete="[[autocomplete]]"
          placeholder$="[[placeholder]]"
          readonly$="[[readonly]]"
          list$="[[list]]"
          size$="[[size]]"
          autocapitalize$="[[autocapitalize]]"
          autocorrect$="[[autocorrect]]"
          tabindex$="[[tabindex]]"
          autosave$="[[autosave]]"
          results$="[[results]]"
          accept$="[[accept]]"
          multiple$="[[multiple]]"
          on-change="_handleChange"
        />
      </iron-input>
      <div
        class="cspl-input__tooltip cspl-input__tooltip--error"
        hidden$="[[ _hideToolTip(errorText) ]]"
      >
        [[ errorText ]]
      </div>
      <div
        class="cspl-input__tooltip cspl-input__tooltip--help"
        hidden$="[[ _hideToolTip(helpText) ]]"
      >
        [[ helpText ]]
      </div>`;
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  _setFocus() {
    this.current || this.$.input.focus();
  }
  _hideToolTip(e) {
    return !e;
  }
  _handleChange(e) {
    e.currentTarget.dispatchEvent(
      new CustomEvent('input', {
        bubbles: !0,
        composed: !0,
      })
    );
  }
  _handleFocus() {
    (this.empty = !0), (this.current = !0);
  }
  _handleBlur() {
    (this.current = !1),
      this.value && '' !== this.value
        ? ((this.empty = !1), (this.error = !this._validate()))
        : this.required || ((this.empty = !0), (this.error = !1));
  }
  constructor() {
    super(),
      (this._handleFocus = this._handleFocus.bind(this)),
      (this._handleBlur = this._handleBlur.bind(this)),
      (this._setFocus = this._setFocus.bind(this));
  }
  connectedCallback() {
    super.connectedCallback(),
      this.$.input.addEventListener('focus', this._handleFocus),
      this.$.input.addEventListener('blur', this._handleBlur),
      this.addEventListener('click', this._setFocus),
      this._handleBlur();
  }
  disconnectedCallback() {
    super.disconnectedCallback();
  }
}

customElements.define('cs-input', CsInput);
