/* jshint ignore:start */
import { GestureEventListeners } from "@polymer/polymer/lib/mixins/gesture-event-listeners.js";
import * as Gestures from "@polymer/polymer/lib/utils/gestures.js";
import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import { CsGoogleAnalyticsMixin } from "./mixins/cs-google-analytics-mixin.js";
import { CsLinkIconContainerMixin } from "./mixins/cs-link-icon-container-mixin.js";
import { CsMixin } from "./mixins/cs-mixin.js";
import { CsModalInvokerMixin } from "./mixins/cs-modal-invoker-mixin.js";

var e = window.nds;
e.getAncestorByName = (e, t) => {
	const a = e.shadowRoot;
	for (let a = e; a.parentElement; ) {
		if (a.nodeName === t) return a;
		a = a.parentElement;
	}
	return null;
};
e.getSlottedChildren = (e, t = [""]) => {
	const a = e.shadowRoot;
	return a
		? t.reduce((e, t) => {
				const r = a.querySelector(t.length ? `slot[name="${t}"]` : "slot");
				if (!r) return e;
				const n = r
					.assignedNodes({
						flatten: !0,
					})
					.filter((e) => e.nodeType === Node.ELEMENT_NODE);
				return [...e, ...n];
		  }, [])
		: [];
};
const t = CsMixin,
	a = e.getSlottedChildren,
	r = e.getAncestorByName,
	n = CsModalInvokerMixin,
	o = CsLinkIconContainerMixin,
	i = CsGoogleAnalyticsMixin;

/**
 * `cs-cta-dropdown` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsCtaDropdown extends t(n(o(i(GestureEventListeners(PolymerElement))))) {
	static get properties() {
		return {
			context: String,
			intent: {
				type: String,
				value: "navigation",
			},
			open: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			hasChildren: {
				type: Boolean,
				value: !1,
			},
			theme: {
				type: String,
				value: "dark",
				reflectToAttribute: !0,
			},
			ctaTitleText: String,
			ctaLink: String,
			disabled: {
				type: Boolean,
				value: !1,
				reflectToAttribute: !0,
			},
			borderColor: {
				type: String,
				reflectToAttribute: !0,
			},
			fillColor: {
				type: String,
				reflectToAttribute: !0,
				value: "none",
			},
			textColor: {
				type: String,
				reflectToAttribute: !0,
			},
		};
	}

	static get template() {
		return html`
			<style include="cs-shared-styles-base-common"></style>
			<style include="cs-shared-styles-form-select"></style>
			<style>
				:host {
					height: 100%;
					margin-right: 0;
				}

				.cspl-cta-dropdown__wrapper {
					align-items: center;
					display: flex;
					height: 100%;
				}

				.cspl-cta-dropdown__selected {
					background-color: transparent;
					color: var(--cs-dark-color);
					display: none;
					height: 44px;
					justify-content: center;
					min-width: 172px;
					padding-left: 25px;
					padding-right: 25px;
					text-transform: none;
					z-index: 2;
				}

				:host([open]) .cspl-cta-dropdown__selected::before {
					border-width: 2px;
					bottom: -3px;
					left: -3px;
					right: -3px;
					top: -3px;
				}

				.cspl-dropdown__items-container::before {
					display: none;
				}

				.cspl-cta-dropdown__selected::before {
					border: solid 2px var(--cs-dark-color);
				}

				@media only screen and (min-width: 768px) {
					.cspl-cta-dropdown__selected {
						display: flex;
					}
				}

				:host([theme="light"]) .cspl-cta-dropdown__selected {
					background-color: transparent;
				}

				:host([fill-color="none"]) .cspl-cta-dropdown__selected {
					background-color: transparent;
				}

				:host([fill-color="light"]) .cspl-cta-dropdown__selected {
					background-color: var(--cs-light-color);
				}

				:host([fill-color="dark"]) .cspl-cta-dropdown__selected {
					background-color: var(--cs-dark-color);
				}

				:host([fill-color="brand"]) .cspl-cta-dropdown__selected {
					background-color: var(--cs-theme-color);
				}

				:host([text-color="light"]) .cspl-cta-dropdown__selected {
					color: var(--cs-light-color);
				}

				:host([text-color="dark"]) .cspl-cta-dropdown__selected {
					color: var(--cs-dark-color);
				}

				:host([text-color="brand"]) .cspl-cta-dropdown__selected {
					color: var(--cs-theme-color);
				}

				:host([border-color="none"]) .cspl-cta-dropdown__selected::before {
					border: none;
				}

				:host([border-color="light"]) .cspl-cta-dropdown__selected::before {
					border-color: var(--cs-light-color);
				}

				:host([border-color="dark"]) .cspl-cta-dropdown__selected::before {
					border-color: var(--cs-dark-color);
				}

				:host([border-color="brand"]) .cspl-cta-dropdown__selected::before {
					border-color: var(--cs-theme-color);
				}

				.cspl-cta-dropdown__cta-text {
					padding-right: 0;
				}

				.cspl-cta-dropdown__items-container-wrapper {
					cursor: default;
					display: flex;
					padding-top: 8px;
					position: absolute;
					right: 0;
					top: 100%;
					transform: scaleY(0);
					transform-origin: center top;
					transition: transform var(--cs-transition-duration) var(--cs-transition-easing);
					transition-delay: 0.1s;
					z-index: 1;
				}

				@media only screen and (min-width: 768px) {
					:host([open]) .cspl-cta-dropdown__items-container-wrapper {
						transform: scaleY(1);
						transition-delay: 0s;
					}
				}

				.cspl-cta-dropdown__items-container {
					background-color: rgba(255, 255, 255, 0.9);
					border-radius: 4px;
					opacity: 1;
					position: relative;
					z-index: 2;
				}

				:host([theme="light"]) .cspl-cta-dropdown__items-container {
					background-color: rgba(0, 0, 0, 0.9);
					border-color: #222;
				}

				.cspl-cta-dropdown__items-container ::slotted(cs-container) {
					align-content: stretch;
					display: flex;
					flex-direction: column;
					margin: 0 16px 8px;
					text-transform: none;
					white-space: nowrap;
				}

				.cspl-cta-dropdown__items-container ::slotted(cs-container:first-child) {
					margin-top: 16px;
				}

				.cspl-cta-dropdown__items-container ::slotted(cs-container:last-child) {
					margin-bottom: 0;
				}

				.cspl-cta-dropdown__items-container ::slotted(cs-link) {
					font-size: var(--cs-theme-local-nav-shelf-sub-nav-link-font-size-mobile);
					margin: 0 32px 16px 16px;
					text-transform: none;
					white-space: nowrap;
				}

				@media only screen and (min-width: 1024px) {
					.cspl-cta-dropdown__items-container ::slotted(cs-link) {
						font-size: var(--cs-theme-local-nav-shelf-sub-nav-link-font-size-tablet-landscape);
					}
				}

				@media only screen and (min-width: 1456px) {
					.cspl-cta-dropdown__items-container ::slotted(cs-link) {
						font-size: var(--cs-theme-local-nav-shelf-sub-nav-link-font-size-desktop);
					}
				}

				:host([theme="dark"]) .cspl-cta-dropdown__items-container ::slotted(cs-link) {
					color: var(--cs-theme-local-nav-font-color-dark);
				}

				:host([theme="light"]) .cspl-cta-dropdown__items-container ::slotted(cs-link) {
					color: var(--cs-theme-local-nav-font-color-light);
				}

				:host([theme="brand"]) .cspl-cta-dropdown__items-container ::slotted(cs-link) {
					color: var(--cs-theme-local-nav-font-color-light);
				}


				.cspl-cta-dropdown__items-container ::slotted(cs-link:first-child) {
					margin-top: 29px;
				}

				.cspl-cta-dropdown__items-container ::slotted(cs-link:last-child) {
					margin-bottom: 29px;
				}

				:host([theme="dark"]) .cspl-cta-dropdown__items-container ::slotted(cs-link:hover),
				:host([theme="light"]) .cspl-cta-dropdown__items-container ::slotted(cs-link:hover) {
					color: var(--cs-theme-local-nav-font-color-hover);
				}

				:host([text-color="brand"]) .cspl-cta-dropdown__items-container ::slotted(cs-link:hover) {
					color: var(--cs-light-color);
				}

				.cspl-cta-dropdown__items-container ::slotted(cs-container),
				.cspl-cta-dropdown__items-container ::slotted(cs-link) {
					opacity: 0;
					transition: opacity var(--cs-transition-duration) var(--cs-transition-easing);
				}

				:host([open]) .cspl-cta-dropdown__items-container ::slotted(cs-container),
				:host([open]) .cspl-cta-dropdown__items-container ::slotted(cs-link) {
					opacity: 1;
					transition-delay: 0.2s;
				}
			</style>
			<div class="cspl-cta-dropdown__wrapper" on-mouseenter="_openDropdown" on-mouseleave="_closeDropdown">
				<span class="cspl-dropdown__selected cspl-cta-dropdown__selected" on-tap="_handleDropdownClick">
					<template is="dom-if" if="[[ _hasIcon(icon) ]]">
						<cs-icon-container
							id="icon"
							icon="[[ icon ]]"
							color="[[ iconColor ]]"
							hovered="[[ hovered ]]"
							background-color="[[ _getIconBackgroundColor(borderColor, fillColor, theme, textColor, iconBackgroundColor) ]]"
						></cs-icon-container>
					</template>
					<span class="cspl-cta-dropdown__cta-text">{{ ctaTitleText }}</span>
					<div class="cspl-dropdown__chevron" hidden$="[[ !hasChildren ]]"></div>
				</span>
				<div class="cspl-cta-dropdown__items-container-wrapper">
					<div class="cspl-cta-dropdown__items-container">
						<slot id="dropdownContainer"></slot>
						<slot name="local-nav-drawer-sub-link"></slot>
					</div>
				</div>
			</div>
		`;
	}

	/**
	 * Instance of the element is created/upgraded. Use: initializing state,
	 * set up event listeners, create shadow dom.
	 * @constructor
	 */
	constructor() {
		super(), (this._tapHandler = this._tapHandler.bind(this)); // jshint ignore:line
	}

	/**
	 * Use for one-time configuration of your component after local
	 * DOM is initialized.
	 */
	ready() {
		super.ready();
	}
	_getIconBackgroundColor(e, t, a, r, n) {
		return "none" !== e || "none" !== t ? "none" : "none" === n ? ("brand" === r ? "brand" : a) : n;
	}
	_tapHandler(e) {
		const t = r(e.target, this.nodeName);
		this.open && (!t || t !== this) && (this.open = !1); // jshint ignore:line
	}
	_gaEvent(e, t = {}) {
		const a = Object.assign(
			{},
			{
				context: this.context,
				intent: this.intent,
				text: this.ctaTitleText,
				url: this._getGAUrl(),
			},
			t
		);
		this.gaEvent(e, a);
	}
	_getGAUrl() {
		return this._links.length ? this._links[0].getAttribute("link-url") || this._links[0].getAttribute("link-href") || this._links[0].href : "";
	}
	_handleDropdownClick() {
		this.hasChildren
			? (this.open = !this.open) // jshint ignore:line
			: (this._gaEvent("/ga/nds/cta/dropdown", {
					url: this.ctaLink,
			  }),
			  (window.location.href = this.ctaLink)); // jshint ignore:line
	}
	_openDropdown() {
		this.hasChildren &&
			((this.open = !0),
			this._gaEvent("/ga/nds/cta/dropdown-state", {
				state: "open",
			})); // jshint ignore:line
	}
	_closeDropdown() {
		(this.open = !1),
			this.hasChildren &&
				this._gaEvent("/ga/nds/cta/dropdown-state", {
					state: "closed",
				}); // jshint ignore:line
	}
	connectedCallback() {
		super.connectedCallback(),
			(this.context = this.context || this.parentNode.tagName.toLowerCase()),
			Gestures.addListener(document, "tap", this._tapHandler),
			setTimeout(() => {
				const e = a(this);
				(this._links = e.map((e) => ("EA-CONTAINER" === e.tagName ? e.querySelector("cs-cta") : e))),
					(this.hasChildren = 0 < e.length),
					this._links.forEach((e) => e.setAttribute("context", this.context)); // jshint ignore:line
			}); // jshint ignore:line
	}
	disconnectedCallback() {
		super.disconnectedCallback(), document.removeEventListener(this._tapHandler); // jshint ignore:line
	}
}

customElements.define("cs-cta-dropdown", CsCtaDropdown);
