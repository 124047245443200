import {
    dedupingMixin
} from '@polymer/polymer/lib/utils/mixin.js';

/**
 * MixinNamePascelCase - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsBodyScrollMixin = (base) =>
    /**
     * @polymerMixinClass
     */
    class extends base {
        static get properties() {
            return {
                _currentScrollY: {
                    type: Number,
                    value: 0
                }
            };
        }
        _preventScroll() {
            this._currentScrollY = window.scrollY || window.pageYOffset, document.body.style.overflow = "hidden", document.body.style.position = "fixed", document.body.style.top = `${-1*this._currentScrollY}px`; // jshint ignore:line
        }
        _addScroll() {
            document.body.removeAttribute("style"), document.documentElement.scrollTop = document.body.scrollTop = this._currentScrollY; // jshint ignore:line
        }
    };

export const CsBodyScrollMixin = dedupingMixin(internalCsBodyScrollMixin);