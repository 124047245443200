import {
	dedupingMixin
} from "@polymer/polymer/lib/utils/mixin.js";

/**
 * CsLazyImageMixin - Description for mixin
 * @polymerMixin
 * @mixinFunction
 */
let internalCsLazyImageMixin = base =>
	/**
	 * @polymerMixinClass
	 */
	class extends base {
		_swapAttributes(e) {
			const t = e.getAttribute("data-src");
			const a = e.getAttribute("data-srcset");
			requestAnimationFrame(() => {
				t && e.setAttribute("src", t), a && e.setAttribute("srcset", a); // jshint ignore:line
			});
		}
		_loadImages(e) {
			e.forEach(e => this._swapAttributes(e));
		}
		_observeImages(e, t) {
			const a = new IntersectionObserver(e => {
				e.forEach(e => {
					(e.isIntersecting || 0 < e.intersectionRatio) && (this._swapAttributes(e.target), a.unobserve(e.target)); // jshint ignore:line
				});
			}, t);
			e.forEach(e => a.observe(e));
		}
		_lazyImages(
			e,
			t = {
				rootMargin: "250px 0px"
			}
		) {
			"function" == typeof IntersectionObserver ? this._observeImages(e, t) : this._loadImages(e); // jshint ignore:line
		}
	};

export const CsLazyImageMixin = dedupingMixin(internalCsLazyImageMixin);