/* jshint ignore:start */
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { CsCatalogMixin } from './mixins/cs-catalog-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

window.nds = window.nds || {};
var e = window.nds;
const t = CsMixin,
  a = CsCatalogMixin,
  r = e.getSlottedChildren;

/**
 * `cs-box-set` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsBoxSet extends t(a(PolymerElement)) {
  static get properties() {
    return {
      layout: {
        type: String,
        value: '3up',
        reflectToAttribute: !0,
      },
      gameBoxScrimColor: {
        type: String,
        value: 'none',
      },
      gameBoxScrimOpacity: {
        type: String,
        value: 'medium',
      },
      spacingBottom: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
      spacingTop: {
        type: String,
        value: 'medium',
        reflectToAttribute: !0,
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-base-common"></style>
      <style include="cs-shared-styles-common-spacing"></style>
      <style include="cs-shared-styles-common-catalog"></style>
      <style>
        :host {
          display: block;
        }

        @media only screen and (min-width: 1024px) {
          :host([layout='2up']) {
            margin-left: calc(
              var(--cs-column-tablet-landscape-with-rail) +
                var(--cs-gutter-tablet-landscape)
            );
            margin-right: calc(
              var(--cs-column-tablet-landscape-with-rail) +
                var(--cs-gutter-tablet-landscape)
            );
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([layout='2up']) {
            margin-left: calc(
              var(--cs-column-desktop-with-rail) + var(--cs-gutter-desktop)
            );
            margin-right: calc(
              var(--cs-column-desktop-with-rail) + var(--cs-gutter-desktop)
            );
          }
        }

        .cspl-box-set-items {
          display: flex;
          flex-flow: row wrap;
          margin: calc(-0.5 * var(--cs-gutter-mobile))
            calc(-0.5 * var(--cs-gutter-mobile));
        }

        @media only screen and (min-width: 768px) {
          .cspl-box-set-items {
            margin: calc(-0.5 * var(--cs-gutter-tablet-portrait))
              calc(-0.5 * var(--cs-gutter-tablet-portrait));
          }
        }

        @media only screen and (min-width: 1024px) {
          .cspl-box-set-items {
            margin: calc(-0.5 * var(--cs-gutter-tablet-landscape))
              calc(-0.5 * var(--cs-gutter-tablet-landscape));
          }
        }

        @media only screen and (min-width: 1456px) {
          .cspl-box-set-items {
            margin: calc(-0.5 * var(--cs-gutter-desktop))
              calc(-0.5 * var(--cs-gutter-desktop));
          }
        }

        :host .cspl-catalog__items ::slotted(cs-container),
        :host .cspl-catalog__items cs-container {
          flex-basis: auto;
          padding: calc(0.5 * var(--cs-gutter-mobile));
          width: 100%;
        }

        @media only screen and (min-width: 768px) {
          :host .cspl-catalog__items ::slotted(cs-container),
          :host .cspl-catalog__items cs-container {
            padding: calc(0.5 * var(--cs-gutter-tablet-portrait));
            width: 50%;
          }
        }

        @media only screen and (min-width: 1024px) {
          :host .cspl-catalog__items ::slotted(cs-container),
          :host .cspl-catalog__items cs-container {
            padding: calc(0.5 * var(--cs-gutter-tablet-landscape));
            width: 33.33%;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host .cspl-catalog__items ::slotted(cs-container),
          :host cs-container {
            padding: calc(0.5 * var(--cs-gutter-desktop));
          }
        }

        @media only screen and (min-width: 768px) {
          :host([layout='2up']) .cspl-catalog__items ::slotted(cs-container),
          :host([layout='2up']) .cspl-catalog__items cs-container {
            width: 50%;
          }
        }

        @media only screen and (min-width: 1456px) {
          :host([layout='2up']) .cspl-catalog__items ::slotted(cs-container),
          :host([layout='2up']) .cspl-catalog__items cs-container {
            padding: calc(0.5 * var(--cs-gutter-desktop));
          }
        }
      </style>
      <iron-ajax
        auto=""
        url="[[ filterUrl ]]"
        handle-as="text"
        on-response="_handleFilterAjaxResponse"
        on-error="_handleFilterAjaxError"
      ></iron-ajax>
      <iron-ajax id="csCatalogAjax" handle-as="text"></iron-ajax>
      <slot name="filter"></slot>
      <div class="cspl-box-set-items cspl-catalog__items" id="catalogContainer">
        <slot name="container"></slot>
      </div>
      <div class="cspl-processing-icon" id="processing">
        <iron-icon icon="cs-action-24:loading"></iron-icon>
      </div>
      <slot name="pagination"></slot>
      <slot name="load-more"></slot>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }
  get _catalogItems() {
    return r(this, ['container']);
  }
  get _localDOMContainers() {
    return this.$.catalogContainer.querySelectorAll('[slot="container"]');
  }
  _showRows(e, t) {
    [...this._localDOMContainers].forEach((a, r) => {
      r >= e && r < t
        ? a.removeAttribute('hidden')
        : a.setAttribute('hidden', !0); // jshint ignore:line
    });
  }
  _processCatalogItems() {
    this._localDOMContainers.forEach(e => {
      const t = e.querySelector('[slot="game-box"]');
      (t.context = 'cs-box-set'),
        (t.scrimColor = this.gameBoxScrimColor),
        (t.scrimOpacity = this.gameBoxScrimOpacity); // jshint ignore:line
    });
  }
  _moveGameBoxes() {
    const e = this._catalogItems;
    const t = document.createDocumentFragment();
    [...e].forEach(e => {
      t.appendChild(e);
    }),
      this.$.catalogContainer.appendChild(t); // jshint ignore:line
  }
  _setup() {
    this._moveGameBoxes(),
      this._processCatalogItems(),
      this.dispatchEvent(
        new CustomEvent('nds.catalog.itemsReady', {
          bubbles: !0,
          composed: !0,
          detail: {
            items: this._localDOMContainers,
          },
        })
      ); // jshint ignore:line
  }
  connectedCallback() {
    super.connectedCallback(), setTimeout(this._setup.bind(this)); // jshint ignore:line
  }
}

customElements.define('cs-box-set', CsBoxSet);
