/* jshint ignore:start */
import { html } from '@polymer/polymer/polymer-element.js';
import { CsElements } from './cs-elements.js';
import { CsDeviceMixin } from './mixins/cs-device-mixin.js';
import { CsGoogleAnalyticsMixin } from './mixins/cs-google-analytics-mixin.js';
import { CsMixin } from './mixins/cs-mixin.js';

window.nds = window.nds || {};
const s = CsMixin,
  c = CsDeviceMixin,
  l = CsGoogleAnalyticsMixin;

/**
 * `cs-social-share-twitter` Description
 *
 * @customElement
 * @polymer
 * @demo
 *
 */
class CsSocialDirectLink extends s(l(c(CsElements))) {
  static get properties() {
    return {
      theme: {
        type: String,
        value: 'light',
        reflectToAttribute: !0,
      },
      copiedText: {
        type: String,
        value: 'Copied to clipboard',
      },
      hideCopiedText: {
        type: Boolean,
        value: !1,
      },
      shareUrl: {
        type: String,
        value: () => window.location.href,
      },
      tabIndex: {
        type: String,
        value: '0',
      },
      _copying: {
        type: Boolean,
        value: !1,
        notify: !0,
      },
      _currentUrl: {
        type: String,
        value: () => window.location.href,
      },
    };
  }

  static get template() {
    return html`
      <style include="cs-shared-styles-typography-paragraphs"></style>
      <style include="cs-social-button-shared-styles"></style>
      <style>
        :host {
          --copied-text-color: #c2c2c2;
          display: flex;
          flex-shrink: 0;
          position: relative;
        }

        :host([theme='light']) {
          --copied-text-color: #181818;
        }

        .cspl-social-direct-link__button {
          z-index: 3;
        }

        :host([theme='light']) .cspl-social-direct-link__button {
          background: var(--cs-dark-color);
        }

        :host([theme='dark']) .cspl-social-direct-link__button {
          background: var(--cs-light-color);
        }

        :host([theme='brand']) .cspl-social-direct-link__button {
          background: var(--cs-theme-color);
        }

        :host([channel='link']) .cspl-social-direct-link__button {
          z-index: 3;
        }

        :host([theme='light']) .cspl-social-direct-link__icon {
          color: var(--cs-light-color);
        }

        :host([theme='dark']) .cspl-social-direct-link__icon {
          color: var(--cs-dark-color);
        }

        :host([theme='brand']) .cspl-social-direct-link__icon {
          color: #fff;
        }

        .cspl-social-direct-link__copied-text,
        .cspl-social-direct-link__field {
          align-items: center;
          background: var(--cs-light-color);
          border-bottom: 2px solid var(--copied-text-color);
          border-radius: 20px;
          border-right: 2px solid var(--copied-text-color);
          border-top: 2px solid var(--copied-text-color);
          box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0;
          outline: 0;
          padding-left: calc(40px + 8px);
          padding-right: 20px;
          position: absolute;
          white-space: nowrap;
          z-index: 2;
        }

        :host([rtl]) .cspl-social-direct-link__copied-text,
        :host([rtl]) .cspl-social-direct-link__field {
          border-left: 2px solid var(--copied-text-color);
          border-right: none;
          padding-left: 20px;
          padding-right: calc(40px + 8px);
        }

        .cspl-social-direct-link__copied-text {
          color: var(--copied-text-color);
          height: 40px;
          left: 1px;
          opacity: 0;
          top: 0;
          visibility: hidden;
        }

        :host([rtl]) .cspl-social-direct-link__copied-text {
          left: auto;
          right: 1px;
        }

        :host([theme='dark']) .cspl-social-direct-link__copied-text {
          background-color: var(--cs-dark-color);
        }

        .cspl-social-direct-link__copied-text--visible {
          animation-duration: 2s;
          animation-name: copied;
        }

        .cspl-social-direct-link__field {
          direction: ltr;
          height: auto;
          left: auto;
          overflow: hidden;
          top: -999999px;
          width: 100px;
        }

        .cspl-social-direct-link__field--visible {
          height: 40px;
          left: 0;
          overflow: auto;
          top: 0;
          width: auto;
        }

        @keyframes copied {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 1;
            visibility: visible;
          }

          100% {
            opacity: 0;
            visibility: hidden;
          }
        }
      </style>
      <div
        class="cspl-social-direct-link__button cspl-social-button"
        tabindex$="[[ tabIndex ]]"
        on-click="_copyLink"
      >
        <iron-icon
          class="cspl-social-direct-link__icon"
          icon="cs-action-16:direct-link"
        ></iron-icon>
      </div>
      <span
        class$="b3 cspl-social-direct-link__copied-text {{ _computeCopiedClass(_copying)}}"
        on-animationend="_onAnimationEnd"
        >[[ copiedText ]]</span
      >
      <div class="cspl-social-direct-link__field" id="field">
        [[ shareUrl ]]
      </div>
    `;
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Use for one-time configuration of your component after local
   * DOM is initialized.
   */
  ready() {
    super.ready();
  }

  _computeCopiedClass() {
    return this._copying && !this.hideCopiedText
      ? 'cspl-social-direct-link__copied-text--visible'
      : '';
  }
  _onAnimationEnd() {
    (this._copying = !1),
      this.dispatchEvent(
        new CustomEvent('direct-link-copying', {
          bubbles: !0,
          composed: !0,
        })
      );
  }
  _copyLink() {
    const e = this.$.field,
      t = document.createRange(),
      i = window.getSelection();

    this.hideCopiedText ||
      this._copying ||
      this.dispatchEvent(
        new CustomEvent('direct-link-copying', {
          bubbles: !0,
          composed: !0,
        })
      ),
      t.selectNodeContents(e),
      i.removeAllRanges(),
      i.addRange(t),
      (this._copying = document.execCommand('copy')),
      this.gaEvent('/ga/social/share', {
        network: 'permalink',
      });
    if (document.execCommand('copy')) {
      window.alert('Link Copiado!');
    }
  }
  _handleKeyUp(e) {
    let { key: t } = e;
    'Enter' === t && this._copyLink();
  }
  connectedCallback() {
    super.connectedCallback(),
      this.dispatchEvent(
        new CustomEvent('nds.socialLink.ready', {
          bubbles: !0,
          composed: !0,
        })
      ),
      this.addEventListener('keyup', this._handleKeyUp);
  }
}

customElements.define('cs-social-direct-link', CsSocialDirectLink);
